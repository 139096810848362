<template>
  <li class="vue--dropdown-menu-link">
    <BaseAnchor
      class="vue--dropdown-menu-link__link"
      :class="computedClasses"
      v-bind="$attrs"
      tabindex="0"
      @focus="parentEmit('focusedIn')"
      @focusout="parentEmit('focusedOut')"
      ><slot>{{ value }}</slot></BaseAnchor
    >
  </li>
</template>

<script>
import { isInList } from '~/lib/prop-validators';
export default {
  name: 'BaseDropdownMenuLink',
  status: 'ready',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: null,
      validator: isInList(['nested']),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--dropdown-menu-link--${this.variant}`]: !!this.variant,
      };
    },
  },

  methods: {
    parentEmit(name) {
      this.$parent.$emit(name, this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu-link {
  list-style: none;
  margin: 0;
  white-space: nowrap;

  &__link {
    display: block;
    padding: space(xxs) space(m);

    &:focus {
      outline: global(focusState, outline);
    }
  }

  &--nested {
    padding-left: space(l);
  }
}
</style>
