<template>
  <span
    :aria-hidden="decorative"
    :aria-label="title"
    class="material-design-icon help-icon"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg
      :fill="fillColor"
      :width="size"
      :height="size"
      class="material-design-icon__svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92A3.4 3.4 0 0 0 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.4-.33.6-.83.6-1.4a2 2 0 0 0-2-2 2 2 0 0 0-2 2H8c0-2.2 1.8-4 4-4s4 1.8 4 4c0 .9-.36 1.7-.93 2.27v-.03z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'HelpIcon',

  status: 'ready',

  props: {
    title: {
      type: String,
      default: 'Help Icon',
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
