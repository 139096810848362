import { ORG_PERMISSIONS } from '../../../permissions/defs';
import type { Route } from '../../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName/add',
    component: 'OnboardingAdd/OnboardingAdd',
    sentryTeam: 'bolt',
    meta: {
      title: 'Where is the code you want to test',
      pageDesc: 'Integrations',
      routeName: 'Onboarding Add Integrations',
      noSiteHeader: true,
      requirements: {
        page: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_ADD,
          ],
        },
      },
    },
  },
];
