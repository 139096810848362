<template>
  <span class="vue--avatar" :class="computedClasses">
    <span v-if="loading" class="vue--avatar__loading"></span>

    <span v-if="showDefault" class="vue--avatar__default">
      <span class="vue--avatar__text" role="img" :aria-label="alt">
        {{ firstLetter }}
      </span>
    </span>

    <img
      v-else
      v-show="!loading"
      :src="src"
      :alt="alt"
      @error="onImgError"
      @load="onImgLoad"
    />
  </span>
</template>

<script>
import * as gravatar from 'gravatar';
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseAvatar',

  props: {
    /**
     * Provide an avatar URL or fallback to gravatar.
     */
    avatar: {
      type: String,
      default: null,
    },
    /**
     * The email used for the avatar image
     */
    email: {
      type: String,
      default: null,
    },
    /**
     * A string alternative fallback for the image
     */
    alt: {
      type: String,
      default: '',
    },
    /**
     * Size modifier.
     */
    size: {
      type: String,
      default: 'small',
      validator: isInList(['extra-small', 'small', 'large']),
    },
    /**
     * Invert the colours
     */
    inverted: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      showDefault: false,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--avatar--${this.size}`]: this.size !== 'small',
        'vue--avatar--inverted': this.inverted,
      };
    },
    src() {
      return this.avatar
        ? this.avatar
        : gravatar.url(this.email, {
            size: this.imageSize,
            default: 404,
            protocol: 'https',
          });
    },
    firstLetter() {
      return this.alt.charAt(0).toUpperCase();
    },
    imageSize() {
      return this.size === 'large' ? 64 : 24;
    },
  },

  mounted() {
    if (this.avatar) {
      this.loading = false;
    }
  },

  methods: {
    onImgError() {
      this.loading = false;
      this.showDefault = true;
    },
    onImgLoad() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--avatar {
  $self: &;

  margin: 0;
  padding: 0;
  line-height: 0;
  display: block;

  img {
    border-radius: 50%;
    height: rem(24px);
    width: rem(24px);
  }

  &__loading {
    display: block;
    border-radius: 50%;
    background-color: color(brand, light);
    width: rem(24px);
    height: rem(24px);
  }

  &__default {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    background-color: color(brand, light);
    color: color(ui, white);
    width: rem(24px);
    height: rem(24px);
  }

  &__text {
    display: flex;
    align-items: center;
    font-size: rem(13px);
  }

  &--extra-small {
    #{$self} {
      &__default {
        width: rem(18px);
        height: rem(18px);
      }

      &__text {
        font-size: rem(11px);
      }
    }
    img {
      height: rem(18px);
      width: rem(18px);
    }
  }

  &--large {
    height: rem(32px);
    width: rem(32px);

    #{$self} {
      &__default {
        width: rem(32px);
        height: rem(32px);
      }

      &__text {
        font-size: rem(20px);
        padding-top: space(xxs);
      }

      &__loading {
        width: rem(32px);
        height: rem(32px);
      }
    }
    img {
      height: rem(32px);
      width: rem(32px);
    }
  }

  &--inverted {
    #{$self} {
      &__default {
        color: color(ui, white);
        background-color: rgba(255, 255, 255, 0.2);
      }

      &__loading {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}
</style>
