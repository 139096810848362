<template>
  <div
    v-if="visible"
    ref="menu"
    class="vue--org-switcher-menu"
    data-snyk-test="TheOrgSwitcherMenu: menu"
    @keydown.esc="$emit('close')"
    @focusout="lostFocus"
  >
    <TheOrgSwitcherGroupList
      v-if="!hasError"
      v-bind="data"
      @click="switchGroup"
    />
    <div class="vue--org-switcher-menu__column">
      <BaseNoResults
        v-if="hasError"
        class="margin-top--m"
        preset-graphic="failure"
        title="Failed to load"
      >
        <p>
          Please
          <BaseButton variant="link" size="small" @click="getData"
            >try again</BaseButton
          >. If this problem persists, please contact
          <a href="mailto:support@snyk.io">support</a>.
        </p>
      </BaseNoResults>
      <BaseLoadingSpinner
        v-else-if="isLoading"
        size="large"
        class="margin-top--m"
      />
      <template v-else>
        <TheOrgSwitcherOrgList
          :active-group="activeGroup"
          :active-group-is-personal="activeGroupIsPersonal"
          :url-patterns="data.urlPatterns"
        />
        <TheOrgSwitcherCreateOrg
          v-if="activeGroup"
          :active-group="activeGroup"
          :active-group-is-personal="activeGroupIsPersonal"
          :url-patterns="data.urlPatterns"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { request } from '~/lib/request';
import TheOrgSwitcherGroupList from '~/components/TheOrgSwitcherGroupList/TheOrgSwitcherGroupList';
import TheOrgSwitcherOrgList from '~/components/TheOrgSwitcherOrgList/TheOrgSwitcherOrgList';
import TheOrgSwitcherCreateOrg from '~/components/TheOrgSwitcherCreateOrg/TheOrgSwitcherCreateOrg';

export default {
  name: 'TheOrgSwitcherMenu',
  components: {
    TheOrgSwitcherGroupList,
    TheOrgSwitcherOrgList,
    TheOrgSwitcherCreateOrg,
  },
  inject: ['group'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: true,
      hasError: false,
      data: {},
      activeGroupIsPersonal: !this.group,
      activeGroup: null,
      initialState: {},
    };
  },
  watch: {
    visible(isVisible) {
      if (!isVisible && this.activeGroup?.id !== this.group?.id) {
        this.switchGroup(this.initialState);
      }
    },
  },
  async mounted() {
    await this.getData();
    this.initialState = {
      group: this.activeGroup,
      isPersonal: this.activeGroupIsPersonal,
    };
  },
  methods: {
    lostFocus(e) {
      if (e.relatedTarget && !this.$refs.menu.contains(e.relatedTarget)) {
        this.$emit('close');
      }
    },
    async getData(isRetry = false) {
      this.isLoading = true;
      this.hasError = false;

      try {
        const response = await request('/org-switcher');
        const json = await response.json();
        this.data = this.decorate(json);

        const { groups, personal } = this.data;
        this.activeGroupIsPersonal = !this.group;
        this.activeGroup = this.group
          ? groups.find(({ id }) => this.group.id === id)
          : personal;
        this.isLoading = false;
      } catch (err) {
        if (!isRetry) {
          setTimeout(() => this.getData(true), 100);
        } else {
          this.isLoading = false;
          this.hasError = true;

          if (window.Sentry && window.Sentry.captureException) {
            window.Sentry.captureException(err);
          }
        }
      }
    },
    decorate(json) {
      if (this.group) {
        const selectedGroupId = this.group.id;
        json.groups.some((group, index) => {
          if (group.id === selectedGroupId) {
            json.groups[index].selected = true;
            return true;
          }
        });
      } else {
        json.personal.selected = true;
      }

      return json;
    },
    switchGroup({ group, isPersonal }) {
      this.activeGroupIsPersonal = isPersonal;
      this.activeGroup = group;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-menu {
  z-index: 2;
  position: relative;
  background-color: color(ui, white);
  display: flex;
  width: 100%;
  border: 1px solid color(action);
  box-shadow: space(xxs) space(xxs) 0 rgba(color(neutral, 48), 0.2);

  @include media-query(s) {
    height: rem(363px);
    width: rem(450px);
  }

  &__column {
    display: flex;
    flex: 1;
    flex-direction: column;
    order: 2;
    padding: space(xs);
  }
}
</style>
