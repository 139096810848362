<template>
  <div class="vue--progress-bar" :class="computedClasses">
    <div class="vue--progress-bar__bar" :style="computedStyles">
      <slot />
    </div>
  </div>
</template>

<script>
import { isInList } from '../../lib/prop-validators';
export default {
  name: 'BaseProgressBar',

  props: {
    variant: {
      type: String,
      default: 'success',
      validator: isInList(['success', 'danger', 'info', 'warning']),
    },
    value: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--progress-bar--${this.variant}`]: !!this.variant,
        'vue--progress-bar--disabled': this.disabled,
      };
    },
    computedStyles() {
      return {
        width: `${this.value}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--progress-bar {
  $self: &;

  padding: space(xxs);

  &__bar {
    padding: space(xs);
    transition: width 0.25s ease-in-out;
  }

  &--success {
    border: 1px solid color(alert, success-text);

    #{$self}__bar {
      background-color: color(alert, success);

      ::v-deep * {
        color: color(alert, success-text);
      }
    }
  }
  &--danger {
    border: 1px solid color(alert, danger-text);

    #{$self}__bar {
      background-color: color(alert, danger);

      ::v-deep * {
        color: color(alert, danger-text);
      }
    }
  }
  &--warning {
    border: 1px solid color(alert, warning-text);

    #{$self}__bar {
      background-color: color(alert, warning);

      ::v-deep * {
        color: color(alert, warning-text);
      }
    }
  }
  &--info {
    border: 1px solid color(alert, info-text);

    #{$self}__bar {
      background-color: color(alert, info);

      ::v-deep * {
        color: color(alert, info-text);
      }
    }
  }

  &--disabled {
    border: 1px solid color(disabled, border);

    #{$self}__bar {
      background-color: color(disabled, bg);

      ::v-deep * {
        color: color(disabled, text);
      }
    }
  }
}
</style>
