<template>
  <div
    class="vue--gradient-icon"
    :class="computedClasses"
    :style="computedStyles"
  >
    <component :is="icon" title colorize />
  </div>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseGradientIcon',
  status: 'ready',

  props: {
    /**
     * The icon Vue component.
     */
    icon: {
      type: [Function, Object],
      required: true,
    },
    /**
     * The start (lightest) color for the gradient.
     */
    light: {
      type: String,
      required: true,
    },
    /**
     * The end (darkest) color for the gradient.
     */
    dark: {
      type: String,
      required: true,
    },
    /**
     * Colorize the supplied icon.
     */
    colorize: {
      type: Boolean,
      default: false,
    },
    /**
     * Sizing for the element.
     */
    size: {
      type: String,
      default: null,
      validator: isInList(['small', 'large']),
    },
  },
  computed: {
    computedClasses() {
      return {
        [`vue--gradient-icon--${this.size}`]: !!this.size,
      };
    },
    computedStyles() {
      return `background-image: linear-gradient(to bottom, ${this.light}, ${this.dark})`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--gradient-icon {
  display: flex;
  border-radius: 50%;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  width: space(xl);
  height: space(xl);

  ::v-deep svg {
    color: color(ui, white);
    height: rem(30px);
    width: rem(30px);
  }

  &--small {
    width: space(l);
    height: space(l);

    ::v-deep svg {
      height: rem(20px);
      width: rem(20px);
    }
  }

  &--large {
    width: space(xxl);
    height: space(xxl);

    ::v-deep svg {
      height: rem(42px);
      width: rem(42px);
    }
  }
}
</style>
