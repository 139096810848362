<template>
  <BaseBlock class="vue--promo">
    <header class="vue--promo__header">
      <BaseHeading :level="4">
        <BaseBadge
          v-if="badge"
          class="vue--promo__badge"
          size="small"
          variant="action"
        >
          {{ badge }}
        </BaseBadge>
        {{ title }}
      </BaseHeading>
      <button
        v-if="dismissible"
        class="vue--promo__close"
        data-snyk-test="BasePromo: close"
        @click="onClose"
      >
        <CloseCircleOutlineIcon title="Remove this panel" />
      </button>
    </header>
    <div class="vue--promo__body">
      <BaseProse>
        <slot />
      </BaseProse>
    </div>
    <img class="vue--promo__image" :src="imgSrc" role="presentation" />
  </BaseBlock>
</template>

<script>
import CloseCircleOutlineIcon from 'icons/CloseCircleOutline';

export default {
  name: 'BasePromo',

  components: {
    CloseCircleOutlineIcon,
  },

  props: {
    /**
     * Optional badge text.
     */
    badge: {
      type: String,
      default: null,
    },
    /**
     * Title text.
     */
    title: {
      type: String,
      default: 'Did you know…',
    },
    /**
     * Image url.
     */
    imgSrc: {
      type: String,
      default:
        'https://res.cloudinary.com/snyk/image/upload/v1601294412/BasePromo%20backgrounds/promo-default.svg',
    },
    /**
     * Is the promo dismissible?
     */
    dismissible: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--promo {
  overflow: hidden;
  padding: space(m) space(m) space(m) space() + space(s);
  position: relative;

  &:before {
    background-color: color(alert, info-border);
    border-radius: global(border-radius, micro) 0 0 global(border-radius, micro);
    display: block;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: space(xs);
  }

  &__header {
    margin-bottom: space(xs);
  }

  &__badge {
    margin-right: space(xs);
    position: relative;
    top: -2px;
  }

  &__close {
    color: color(base, dimmed);
    cursor: pointer;
    position: absolute;
    right: space();
    top: space();
    transition: color 0.25s ease-in-out;

    &:hover {
      color: color(base, body);
    }
  }

  &__body {
    color: color(base, body);
    padding-right: space(m) * 2;

    @include media-query(m) {
      padding-right: 30%;
    }

    ::v-deep a {
      color: color(action, create);
    }
  }

  &__image {
    bottom: 0;
    opacity: 0.05;
    position: absolute;
    right: space(m);
    width: 50%;

    @include media-query(m) {
      opacity: 0.2;
      right: space(m) * 2;
      max-height: 300px;
      max-width: 25%;
    }
  }
}
</style>
