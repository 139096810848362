<template>
  <BaseMultipleValueInput
    class="vue--snyk-id-input"
    v-bind="$attrs"
    :placeholder="placeholder"
    :input-placeholder="inputPlaceholder"
    :character-limit="characterLimit"
    :values="ids"
    :additional-entry-keys="['Comma', 'Space']"
    @create="handleCreation"
    @update="handleUpdate"
    @paste="handlePaste"
  />
</template>

<script>
export default {
  name: 'BaseSnykIdInput',

  model: {
    prop: 'ids',
    event: 'update',
  },

  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Add a Snyk ID value',
    },
    inputPlaceholder: {
      type: String,
      default: 'type here or paste a comma separated string',
    },
    characterLimit: {
      type: Number,
      default: 10000,
    },
  },

  data() {
    return {
      animateDuplicateValue: null,
    };
  },

  methods: {
    handleCreation(id) {
      if (this.ids.includes(id)) {
        this.animateDuplicateValue = id;
        return;
      }

      this.$emit('update', [...this.ids, id]);
    },
    handleUpdate(values) {
      this.$emit('update', values);
    },
    handlePaste(string) {
      const pastedIds = string.includes(',') ? string.split(',') : [string];
      const validatedIds = [];

      pastedIds.forEach((id) => {
        if (!id.length) return;

        const formattedSnykId = id.trim();

        if (
          formattedSnykId &&
          !this.ids.includes(formattedSnykId) &&
          !validatedIds.includes(formattedSnykId)
        ) {
          validatedIds.push(formattedSnykId);
        }
      });

      this.$emit('update', [...this.ids, ...validatedIds]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--cve-input {
  &__prefix {
    font-size: rem(14px);
  }
}
</style>
