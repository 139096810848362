import { request } from '~/lib/request';
import {
  PR_CHECK_MARK_AS_SUCCESSFUL,
  SET_PR_CHECK_ISSUES,
  SET_PR_CHECK_ISSUES_LOADING,
  SET_PR_CHECK_ISSUES_LOADING_ERROR,
} from '../../mutation-types';
import { UPDATE_PR_ISSUE_SNIPPETS } from '../../mutation-types';
import has from 'lodash/has';

const defaultState = {
  issueData: [],
  testType: null, // License, security or code
  pullRequestName: null,
  groupLinkText: null,
  org: null,
  result: null,
  groupLink: null,
  project: null,
  projectLink: null,
  snippets: null,
  showCombinedChecksMigration: null,
  oldVersionSha: null,
  newVersionSha: null,
  settings: { policy: '' },
  prCheckLoading: null,
  prCheckRequestId: null,
  prCheckLoadingError: false,
  snippetPaths: new Set(),
  snippetPathsLoading: new Set(),
  snippetPathsLoaded: new Set(),
  markedAsSuccessful: false,
  commitStatusDelivered: false,
  canMarkAsSuccessful: false,
};

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    settingsText: ({ settings }) => {
      let text = '';
      if (settings.policy === 'only_new') {
        text = 'Newly introduced issues only';
      } else {
        text += 'Fail for any issue';
      }
      if (settings.severityThreshold === 'high') {
        text += ' of high severity';
      }
      if (settings.failOnlyFixable) {
        text += ' when there is a fix';
      }
      return text;
    },
    issuesAmount: (state) => {
      return state.issueData.length;
    },
    testedSha: ({ newVersionSha }) => {
      return newVersionSha ? newVersionSha.substring(0, 8) : null;
    },
    breadcrumbEntries: (state) => [
      {
        label: `Tested ${state.pullRequestName}`,
      },
      {
        label: state.groupLinkText,
        ...{ url: state.groupLink },
      },
      {
        label: `This ${state.pullRequestName} ${state.testType} test`,
      },
    ],
    SnippetPaths: (state) => state.SnippetPaths,
    SnippetPathsLoading: (state) => state.SnippetPathsLoading,
    SnippetPathsLoaded: (state) => state.SnippetPathsLoaded,
  },

  actions: {
    async getPRCheck(
      { commit, state },
      { org, projectId, prCheckId, license },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.prCheckLoading && !state.result) {
        commit(SET_PR_CHECK_ISSUES_LOADING, true);

        try {
          const projectRoot = `/org/${org.name}/project/${projectId}`;
          const path = `${projectRoot}/get-pr-check/${prCheckId}`;
          const res = await request(path, {
            method: 'POST',
            body: { license: license },
          });
          const { prCheckData } = await res.json();
          commit(SET_PR_CHECK_ISSUES, prCheckData);
        } catch (err) {
          commit(SET_PR_CHECK_ISSUES_LOADING_ERROR, err.requestId);
        }
        commit(SET_PR_CHECK_ISSUES_LOADING, false);
      }
    },
    async markAsSuccessful({ commit }, { org, projectId, prCheckId }) {
      const projectRoot = `/org/${org.name}/project/${projectId}`;
      const url = `${projectRoot}/get-pr-check/${prCheckId}/mark-as-successful`;
      const res = await request(url, {
        method: 'POST',
      });
      if (res.ok) {
        commit(PR_CHECK_MARK_AS_SUCCESSFUL);
      }
    },
  },

  mutations: {
    [SET_PR_CHECK_ISSUES](state, prCheckData) {
      const keys = Object.keys(prCheckData);
      keys.map((key) => {
        state[key] = prCheckData[key];
      });
    },
    [SET_PR_CHECK_ISSUES_LOADING](state, prCheckLoading) {
      state.prCheckLoading = prCheckLoading;
    },
    [SET_PR_CHECK_ISSUES_LOADING_ERROR](state, requestId) {
      state.prCheckLoadingError = true;
      state.prCheckRequestId = requestId;
    },
    [UPDATE_PR_ISSUE_SNIPPETS](
      state,
      { snippets, status, snippetFilePaths = [] },
    ) {
      const isIssueAffected = (issue) => {
        return (snippetFilePaths || []).includes(
          issue.metadata.primaryFilePath,
        );
      };

      if (status === 'error' || status === 'pending') {
        state.issueData.forEach(function (issue, index) {
          if (isIssueAffected(issue)) {
            // Only alter status if it's null
            // or the issue is affected by the currently processed file paths.
            this[index].snippet.status = status;
          }
        }, state.issueData);

        // Update set of loading snippet paths.
        if (status === 'pending') {
          snippetFilePaths.forEach((path) => {
            state.snippetPathsLoading.add(path);
          });
        } else if (status === 'error') {
          snippetFilePaths.forEach((path) => {
            state.snippetPathsLoading.delete(path);
          });
        }
      } else {
        state.issueData.forEach(function (issue, index) {
          if (!isIssueAffected(issue)) {
            return;
          }

          const uniqueRuleId = `${issue.list[0].result.locations?.[0].physicalLocation?.artifactLocation?.uri}:${issue.list[0].result.locations?.[0].physicalLocation?.region.startLine}`;
          if (has(snippets, uniqueRuleId)) {
            const snippet = snippets[uniqueRuleId];
            this[index].snippet = {
              content: snippet.content,
              startLineNumber:
                //snippet.startLineNumber is always the last line in snippet.content
                snippet.startLineNumber - (snippet.content.length - 1),
              status: 'complete',
            };
          } else {
            this[index].snippet.status = 'error';
          }
        }, state.issueData);

        // Mark snippet file paths as processed.
        snippetFilePaths.forEach((path) => {
          state.snippetPathsLoaded.add(path);
          state.snippetPathsLoading.delete(path);
        });
      }
    },
    [PR_CHECK_MARK_AS_SUCCESSFUL](state) {
      state.markedAsSuccessful = true;
    },
  },
};
