<script>
import { isInList } from '~/lib/prop-validators';
import { createDebounce } from '~/lib/debounce';

/**
 * A whole page virtual scroller for a list of items that will only render the items from the top to the end of the currently visible area in order to improve performance
 */
export default {
  name: 'VirtualScroller',
  status: 'ready',
  props: {
    /** Best guess for the typical height of an item within this scroller in pixels */
    predictedItemHeight: {
      type: Number,
      required: true,
    },
    /**
     * Set the wrapper for the VirtualScroller. Will use window if not specified.
     */
    container: {
      type: [
        Object,
        typeof window !== 'undefined' && window.HTMLElement,
      ].filter(Boolean),
      default: null,
    },
    /**
     * Set the wrapper for the VirtualScroller.
     */
    wrapper: {
      type: String,
      default: 'div',
      validator: isInList(['div', 'ul']),
    },
    /**
     * Set the tag for the spacer element at the bottom.
     */
    spacerTag: {
      type: String,
      default: 'div',
      validator: isInList(['div', 'li']),
    },
  },
  data() {
    return {
      heights: [],
      scrollTop: 0,
      containerHeight: 0,
      containerElement: null,
      setDebounce: createDebounce(),
    };
  },
  watch: {
    container() {
      if (this.containerElement) {
        this.containerElement.removeEventListener('scroll', this.handleScroll);
        this.containerElement.removeEventListener('resize', this.handleResize);
      }
      this.initContainer();
    },
  },
  created() {
    this.initContainer();
  },
  updated() {
    // avoid orgSwitcher dance by re-calculating height
    // see https://snyk.slack.com/archives/CVAFNNKU2/p1612867963173600
    this.calculateHeight();
    this.$nextTick(() => {
      const htmlAsArray = Array.from(this.$el.children);
      const children = htmlAsArray.slice(0, htmlAsArray.length - 1); // remove final padding div
      children.forEach((child, i) => (this.heights[i] = child.offsetHeight));
    });
  },
  destroyed() {
    this.containerElement.removeEventListener('scroll', this.handleScroll);
    this.containerElement.removeEventListener('resize', this.handleResize);
  },
  methods: {
    initContainer() {
      if (typeof window == 'undefined') {
        // SSR
        return;
      }

      this.containerElement = this.container || window;
      this.calculateHeight();
      this.$nextTick(() => {
        this.calculateHeight();
      });
      this.containerElement.addEventListener('scroll', this.handleScroll);
      this.containerElement.addEventListener('resize', this.handleResize);
    },
    calculateHeight() {
      this.containerHeight =
        typeof this.containerElement.innerHeight === 'number'
          ? this.containerElement.innerHeight
          : this.containerElement.clientHeight;
    },
    calculateLayout() {
      const children = this.$slots.default || [];
      const visibleItems = [];
      let index = 0;
      let height = 0;

      // Calculate which items to render up to the bottom of the visible area + 1 extra item for smoothness when scrolling
      const screenBottom = this.scrollTop + this.containerHeight;
      while (
        height < screenBottom + this.predictedItemHeight &&
        index < children.length
      ) {
        visibleItems.push(children[index]);
        if (children[index].tag || children[index].text.trim()) {
          height += this.itemHeight(index);
        }
        index++;
      }

      // Calculate the space to be added after the final rendered item
      const remaining = children.slice(index);
      const heights = remaining.map((item, key) => this.itemHeight(key));
      const spaceAfter = heights.reduce((a, b) => a + b, 0);

      return { visibleItems, spaceAfter };
    },
    itemHeight(index) {
      return this.heights[index] || this.predictedItemHeight;
    },
    handleScroll() {
      const containerScrollTop =
        typeof this.containerElement.scrollY === 'number'
          ? this.containerElement.scrollY
          : this.containerElement.scrollTop;

      const newScroll = containerScrollTop - this.$refs.wrapper.offsetTop;
      if (newScroll !== this.scrollTop) this.scrollTop = newScroll;
    },
    handleResize() {
      this.calculateHeight();
    },
  },
  render(createElement) {
    const { visibleItems, spaceAfter } = this.calculateLayout();
    const style = `height: ${spaceAfter}px`;

    const spacerDiv = createElement(this.spacerTag, { style });

    // Emit event with visible items.
    this.setDebounce(() => {
      this.$emit('renderItems', visibleItems);
    });

    return createElement(this.wrapper, { ref: 'wrapper' }, [
      ...visibleItems,
      spacerDiv,
    ]);
  },
};
</script>
