import { GROUP_PERMISSIONS } from '../../../permissions/defs';
import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [
  {
    path: '/group/:groupPublicId/enterprise-reporting',
    component: 'Looker/Looker',
    sentryTeam: 'governance',
    meta: {
      title: 'Enterprise Reporting',
      routeName: 'Enterprise Reporting',
      pageDesc: 'Snyk Enterprise Reporting dashboard',
      assertFlag: 'looker',
      requirements: {
        page: {
          permissions: [GROUP_PERMISSIONS.READ],
        },
      },
    },
  },
];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/group/:groupPublicId/enterprise-reporting/:path*',
    sentryTeam: 'governance',
  },
];
