<template>
  <ul>
    <li v-for="(item, index) in truncatedItems" :key="index">
      {{ item }}
    </li>
    <li v-if="truncatedItems.length < items.length">
      <a data-snyk-test="list expand link" @click="expand">
        ...and {{ items.length - truncatedItems.length }} more
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BaseTruncatedList',
  status: 'ready',
  props: {
    /** The items to be displayed in the list */
    items: {
      type: Array,
      required: true,
    },
    /** The max items to display before expanding the list*/
    defaultLength: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    truncatedItems() {
      if (this.expanded || this.items.length <= this.defaultLength) {
        return this.items;
      }
      return this.items.slice(0, this.defaultLength);
    },
  },
  methods: {
    expand() {
      this.expanded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
</style>
