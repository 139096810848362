<template>
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <template v-if="preRender">
      <component :is="wrapper" v-show="open" :class="className">
        <slot />
      </component>
    </template>
    <template v-else>
      <component :is="wrapper" v-if="open" :class="className">
        <slot />
      </component>
    </template>
  </transition>
</template>

<script>
export default {
  name: 'LayoutTransitionExpand',
  status: 'ready',

  props: {
    preRender: {
      type: Boolean,
      default: false,
    },
    open: {
      type: Boolean,
      default: false,
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    wrapper: {
      type: String,
      default: 'div',
    },
  },

  data() {
    return {
      isAnimating: false,
    };
  },

  computed: {
    isIE() {
      return (
        typeof window !== 'undefined' &&
        /Trident\/|MSIE /.test(window.navigator.userAgent)
      );
    },
    className() {
      return this.isIE ? 'vue--expand-legacy' : 'vue--expand';
    },
  },

  methods: {
    async enter(element) {
      this.handleLoading();

      if (!this.isIE) {
        const width = getComputedStyle(element).width;
        element.style.width = width;
        element.style.position = 'absolute';
        element.style.opacity = 0;
        element.style.height = 'auto';
        // We use `setTimeout` because we need to ensure
        // any child elements have finished painting
        // before capturing the element height.
        setTimeout(async () => {
          const height = getComputedStyle(element).height;
          element.style.width = null;
          element.style.position = null;
          element.style.opacity = 1;
          element.style.height = 0;
          // Force repaint to make sure the
          // animation is triggered correctly.
          getComputedStyle(element).height;
          // Trigger the animation.
          // We use `$nextTick` because we need
          // to make sure the browser has finished
          // painting after setting the `height`
          // to `0` in the line above.
          await this.$nextTick();
          element.style.height = height;
        }, 100);
      }
    },
    async afterEnter(element) {
      if (!this.isIE) {
        // We use `$nextTick` because we need
        // to make sure the browser has finished
        // painting after setting the `height`
        // in `enter` method above.
        await this.$nextTick();
        element.style.height = 'auto';
      }
      this.completeLoading();
    },
    leave(element) {
      if (!this.isIE) {
        const height = getComputedStyle(element).height;
        element.style.height = height;
        // Force repaint to make sure the
        // animation is triggered correctly.
        getComputedStyle(element).height;
        setTimeout(() => {
          element.style.height = 0;
        });
      }
    },
    afterLeave() {
      this.completeLoading();
    },
    handleLoading() {
      if (this.showLoading) {
        this.isAnimating = true;
        this.$emit('loading');
      }
    },
    completeLoading() {
      if (this.showLoading && this.isAnimating) {
        this.isAnimating = false;
        this.$emit('complete');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--expand {
  &__container {
    overflow: hidden;
  }

  &.expand-enter-active,
  &.expand-leave-active {
    transition: height 0.25s ease-in-out;
    overflow: hidden;
  }

  &.expand-enter,
  &.expand-leave-to {
    height: 0;
  }
}

.vue--expand-legacy {
  &__container {
    overflow: hidden;
  }

  &.expand-enter-active,
  &.expand-leave-active {
    height: auto;
    overflow: hidden;
  }

  &.expand-enter,
  &.expand-leave-to {
    height: 0;
  }
}
</style>
