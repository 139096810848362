<template>
  <div class="vue--banner">
    <slot />
    <CloseIcon
      title="Remove banner from page"
      class="vue--banner__close-icon"
      data-snyk-test="banner-dismiss"
      @click="dismiss"
    />
  </div>
</template>

<script>
import CloseIcon from 'icons/Close';

export default {
  name: 'BaseBanner',

  status: 'ready',

  components: {
    CloseIcon,
  },
  methods: {
    dismiss() {
      this.$emit('dismiss');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--banner {
  $self: &;

  color: white;

  background: webkit-linear-gradient(
    330.58deg,
    #8d3396,
    #7d4198 8.69%,
    #31358e 59.71%
  );
  background: -o-linear-gradient(
    330.58deg,
    #8d3396 0,
    #7d4198 8.69%,
    #31358e 59.71%
  );
  background: linear-gradient(
    119.42deg,
    #8d3396,
    #7d4198 8.69%,
    #31358e 59.71%
  );
  font-size: 20px;
  padding: space(m) + space(s);
  position: relative;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: space(m);

  &__close-icon {
    position: absolute;
    right: space(s);
    top: space(s);
    color: color(ui, white);
    cursor: pointer;
  }
}
</style>
