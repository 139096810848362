function registerOnDoneFetching(Vue) {
  const origFetch = window.fetch;

  const allFetchRequests = [];

  window.fetch = (...args) => {
    const request = origFetch.apply(this, args);
    allFetchRequests.push(request);
    return request;
  };

  return async (callback) => {
    await Vue.nextTick();
    await Promise.all(allFetchRequests.map((p) => p.catch(() => null)));
    if (callback) {
      callback();
    }
  };
}

export default registerOnDoneFetching;
