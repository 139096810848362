<template>
  <div class="vue--tabs">
    <component :is="wrapper" class="vue--tabs__wrapper">
      <nav class="vue--tabs__nav" data-snyk-test="secondary-nav">
        <ul class="vue--tabs__items" role="tablist">
          <li
            v-for="(item, index) in computedTabPanes"
            :key="item.url"
            class="vue--tabs__item"
            :class="item.class"
            :aria-current="item.ariaCurrent"
            :data-snyk-test="tabTestHook(item)"
            tabindex="0"
            role="tab"
            @click="onTabClick(item, index)"
            @keyup.enter="onTabClick(item, index)"
          >
            <component :is="tooltipComponent(item)" v-bind="item.tooltip">
              <component
                :is="linkComponent"
                :href="getPath(item)"
                class="vue--tabs__link"
                tabindex="-1"
                @click="onLinkClick"
              >
                {{ item.label }}
                <BaseBadge
                  v-if="item.pill && (showPillOnAllTabs || index === activeTab)"
                  :data-snyk-test="pillTestHook(item)"
                  class="vue--tabs__pill"
                  size="small"
                  pill
                >
                  {{ item.pill }}
                </BaseBadge>
              </component>
            </component>
          </li>
        </ul>
      </nav>
    </component>
    <div
      v-for="(item, index) in computedSlots"
      :key="index"
      class="vue--tabs__pane"
      :class="computedPaneClasses"
    >
      <component :is="wrapper">
        <keep-alive>
          <slot :name="item.slot"></slot>
        </keep-alive>
      </component>
    </div>
  </div>
</template>

<script>
import trimEnd from 'lodash/trimEnd';

import { getTabNameFromQueryParam, getUrlParameter } from '~/lib/url-utils';

import BaseTooltip from '~/components/BaseTooltip/BaseTooltip';
import LayoutContainer from '~/components/LayoutContainer/LayoutContainer';
import { hasRequiredKeys } from '~/lib/prop-validators';

export default {
  name: 'BaseTabs',

  status: 'ready',

  components: {
    LayoutContainer,
  },

  props: {
    /**
     * Panes for display. Objects require `label`, `slot` and `url`. `pill` as an optional argument
     */
    panes: {
      type: Array,
      validator: (panes) => {
        const requiredProperties = ['label', 'slot', 'url'];
        return panes.every(hasRequiredKeys(requiredProperties));
      },
      required: true,
    },
    /**
     * Update the URL and browser history on tab change
     */
    updateHistory: {
      type: Boolean,
      default: true,
    },

    /**
     * For switching the style of the URLs between query (`?tab=myTabName`) and path-based (`/my/tab/name`)
     */
    usePathBasedTabs: {
      type: Boolean,
      default: false,
    },

    /**
     * The page URL before tabs. This removes the need for routing logic to determine tab names when
     * using path-based URLs.
     */
    baseUrl: {
      type: String,
      default: '',
    },

    /**
     * Show the pill on all tabs, instead of just the active tab.
     */
    showPillOnAllTabs: {
      type: Boolean,
      default: true,
    },

    /**
     * For rendering tabs inline
     */
    inline: {
      type: Boolean,
      default: false,
    },
  },

  data: function () {
    return {
      activeTab: this.getActiveTab(),
    };
  },

  computed: {
    computedTabPanes() {
      return this.panes.map((entry, index) => {
        return {
          class: {
            'vue--tabs__item--active': index === this.activeTab,
            'vue--tabs__item--disabled': !!entry?.disabled,
          },
          ariaCurrent: index === this.activeTab ? 'page' : null,
          isActive: index === this.activeTab,
          ...entry,
        };
      });
    },
    computedSlots() {
      return this.computedTabPanes.filter((entry) => entry.isActive);
    },

    wrapper() {
      return this.inline ? 'span' : LayoutContainer;
    },

    linkComponent() {
      return this.updateHistory ? 'a' : 'span';
    },

    computedPaneClasses() {
      return {
        'vue--tabs__pane--inline': this.inline,
      };
    },
  },

  mounted() {
    const activePanes = this.computedTabPanes.filter((entry) => entry.isActive);

    if (this.updateHistory === true && activePanes.length) {
      history.replaceState(
        { index: this.activeTab },
        '',
        this.getPath(activePanes[0]),
      );
    }

    window.addEventListener('popstate', this.goBack);
  },

  beforeDestroy() {
    window.removeEventListener('popstate', this.goBack);
  },

  methods: {
    getActiveTab() {
      if (this.usePathBasedTabs) {
        return getUrlParameter(this.baseUrl)
          ? this.getTabIndex(getUrlParameter(this.baseUrl))
          : 0;
      } else {
        return getTabNameFromQueryParam('tab')
          ? this.getTabIndex(getTabNameFromQueryParam('tab'))
          : 0;
      }
    },

    setTab(index) {
      this.activeTab = index;
    },

    getPath(item) {
      return this.usePathBasedTabs
        ? `${trimEnd(this.baseUrl, '/')}/${item.url}`
        : `${location.pathname}?tab=${item.url}`;
    },

    onLinkClick(event) {
      if (!this.updateHistory) {
        return;
      }
      if (event.metaKey || event.ctrlKey) {
        // Open tab in new window
        return event.stopPropagation();
      }
      // Prevent navigation to link
      event.preventDefault();
    },

    onTabClick(item, index) {
      if (item?.disabled) return;

      this.setTab(index);
      const state = {
        index,
      };
      const { pageTitle } = item;
      if (this.updateHistory === true) {
        const path = this.getPath(item);

        history.pushState(state, pageTitle, path);
      }

      this.$emit('click', item);
    },

    getTabIndex(tabQueryParam) {
      return this.panes.findIndex((tab) => tab.url === tabQueryParam);
    },

    goBack(e) {
      if (this.updateHistory === true && e.state) {
        this.setTab(e.state.index);
      }
    },

    tabTestHook(item) {
      return `BaseTabs: ${item.label}`;
    },

    tooltipComponent(item) {
      return item?.tooltip ? BaseTooltip : 'span';
    },

    pillTestHook(item) {
      return `BaseTabs: ${item.label} pill`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--tabs {
  $self: &;

  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    width: 100%;
  }

  &__nav {
    @media print {
      display: none;
    }

    position: relative;
    top: 1px;
  }

  &__items {
    display: flex;
    margin: 0 0;
    padding: 0 0;
  }

  &__link {
    color: color(base, dimmed);
    border: 1px solid transparent;
    border-top-width: 3px;
    border-bottom: none;
    display: inline-block;
    height: 44px;
    padding: space(s) - 3px space() space(s);
    cursor: pointer;
    font-size: rem(15px);
    outline: none;

    &:hover {
      color: color(base, body);
      text-decoration: none;
    }
  }

  &__pill {
    margin-left: space(xxs);
    position: relative;
    top: -#{space(xxxs)};
  }

  &__item {
    white-space: nowrap;
    list-style-type: none;
    color: color(action);
    margin: 0;

    &--active {
      background-color: color(ui, white);

      #{$self}__link {
        color: color(base, body);
        border: 1px solid color(neutral, 84);
        border-bottom: none;
        border-top: none;
        padding-top: space(s);
        position: relative;

        &::after {
          background-color: color(action);
          display: block;
          content: '';
          height: 3px;
          left: -1px;
          position: absolute;
          top: 0;
          width: calc(100% + 2px);
        }
      }
    }

    &--disabled {
      #{$self}__link {
        color: rgba(color(base, dimmed), 0.6);
        cursor: no-drop;
      }
    }
  }

  &__pane {
    background-color: color(ui, white);
    border-top: 1px solid color(neutral, 84);
    flex: 1;
    padding: space(l) 0;

    &--inline {
      border-bottom: 1px solid color(neutral, 84);
      border-left: 1px solid color(neutral, 84);
      border-right: 1px solid color(neutral, 84);
      border-radius: 0 0 global(border-radius, micro)
        global(border-radius, micro);
      padding: space(m) space();
    }
  }
}
</style>
