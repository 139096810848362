<template>
  <div
    ref="handle"
    v-on-clickaway="closeMenu"
    tabindex="-1"
    class="vue--dropdown-menu"
    :class="computedClasses"
    @keydown.esc="handleEscKey"
  >
    <div
      class="vue--dropdown-menu__handle"
      data-snyk-text="BaseDropdownMenu: handle"
      tabindex="0"
      :aria-expanded="isOpen ? 'true' : 'false'"
      @click="toggleMenu"
      @keydown.enter="toggleMenu"
      @keydown.space.prevent="toggleMenu"
      @keydown.tab="focusMenu"
    >
      <slot name="handle" />
    </div>
    <div
      ref="menu"
      class="vue--dropdown-menu__menu"
      @focusout="trackKeyboardFocus"
    >
      <slot name="header" />
      <ul class="vue--dropdown-menu__menu--primary">
        <slot />
      </ul>
      <ul v-if="hasSecondarySlot" class="vue--dropdown-menu__menu--secondary">
        <slot name="secondary" />
      </ul>
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';
import { isInList } from '~/lib/prop-validators';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'BaseDropdownMenu',
  status: 'ready',

  mixins: [clickaway],

  props: {
    /**
     * Options menu is open onload.
     */
    open: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
      validator: isInList(['extra-small', 'small']),
    },
    /**
     * Dropdown menu alignment.
     */
    align: {
      type: String,
      default: 'left',
      validator: isInList(['left', 'right']),
    },
    /**
     * Should the menu open when tabbed to?
     */
    openOnFocus: {
      type: Boolean,
      default: true,
    },
    /**
     * Should the menu close itself (due to e.g. something else happening on the page)
     */
    forceClose: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: this.open,
      trappedFocus: false,
      selected: this.defaultSelected,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--dropdown-menu--align-${this.align}`]: this.align,
        [`vue--dropdown-menu--${this.size}`]: !!this.size,
        open: this.isOpen,
      };
    },
    hasSecondarySlot() {
      return !!this.$slots.secondary;
    },
  },

  watch: {
    open() {
      this.isOpen = this.open;
    },
    forceClose() {
      this.isOpen = false;
    },
  },

  created() {
    this.$on('focusedIn', this.trapFocus);
    this.$on('focusedOut', this.releaseFocus);
    this.$on('click', (value) => {
      this.selected = value;
      this.releaseFocus();
      this.closeMenu();
    });
    this.$nextTick(() => {
      if (this.isOpen) {
        this.setupPopper();
      }
    });
  },

  beforeDestroy() {
    this.popper && this.popper.destroy();
  },

  methods: {
    toggleMenu() {
      if (!this.forceClose) {
        this.isOpen = !this.isOpen;
        this.setupPopper();
      }
    },
    openMenu() {
      if (!this.forceClose) {
        this.isOpen = true;
        this.setupPopper();
      }
    },
    focusMenu() {
      if (this.openOnFocus) {
        this.openMenu();
      }
    },
    closeMenu() {
      setTimeout(() => {
        if (!this.trappedFocus) {
          this.isOpen = false;
        }
      });
    },
    setupPopper() {
      if (this.popper === undefined) {
        this.popper = createPopper(this.$refs.handle, this.$refs.menu, {
          placement: this.align === 'left' ? 'bottom-start' : 'bottom-end',
          modifiers: [
            {
              name: 'menu',
              options: {
                elements: this.$refs.menu,
                padding: 8,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ],
        });
      } else {
        this.popper.update();
      }
    },
    trapFocus() {
      this.trappedFocus = true;
    },
    releaseFocus() {
      this.trappedFocus = false;
    },
    handleEscKey() {
      this.isOpen = false;
    },
    trackKeyboardFocus(e) {
      if (e.relatedTarget && !this.$refs.menu.contains(e.relatedTarget)) {
        this.isOpen = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu {
  $self: &;

  position: relative;

  &__handle {
    cursor: pointer;

    &:focus {
      outline: 1px dotted rgba(color(action), 0.75);
    }
  }

  &__menu {
    background-color: color(ui, white);
    border-radius: global(border-radius, half);
    box-shadow: global(box-shadow, tile);
    display: none;
    position: absolute;
    z-index: 1;

    .open & {
      display: inline-block;
    }

    &:focus {
      background-color: #0c0;
    }

    &--secondary {
      background: color(ui, off-white);
      border-top: 1px solid color(neutral, 90);
      border-radius: 0 0 global(border-radius, half) global(border-radius, half);
    }

    &--secondary,
    &--primary {
      padding: space() 0;
    }
  }

  &--align-right {
    justify-content: flex-end;
  }

  &--extra-small {
    font-size: rem(12px);
  }

  &--small {
    font-size: rem(13px);
  }
}
</style>
