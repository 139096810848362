import { ORG_PERMISSIONS } from '../../permissions/defs';
import type { Route } from '../../types';

export const routes: Route[] = [
  {
    path: '/account',
    component: 'AccountSettings/AccountSettings',
    sentryTeam: 'unicorn',
    meta: {
      title: 'Account settings',
      pageDesc: 'Account management for Snyk',
    },
    children: [
      {
        path: '',
        component: 'AccountSettingsGeneral/AccountSettingsGeneral',
        sentryTeam: 'unicorn',
        meta: {
          title: 'Account general settings',
          pageDesc: 'Account management for Snyk',
          requirements: {
            canManageServiceAccounts: {
              permissions: [
                ORG_PERMISSIONS.READ,
                ORG_PERMISSIONS.SERVICE_ACCOUNT_READ,
                ORG_PERMISSIONS.SERVICE_ACCOUNT_CREATE,
              ],
              entitlements: ['serviceAccounts'],
            },
            canUseApi: {
              entitlements: ['api'],
            },
            canViewEmailNotifications: {
              entitlements: ['allowEmails'],
            },
            canViewAuthorizedApplications: {
              enabled: ['showAccountSettingsGeneralAuthorizedApplications'],
            },
          },
        },
      },
      {
        path: 'notifications',
        component: 'AccountSettingsNotifications/AccountSettingsNotifications',
        sentryTeam: 'unicorn',
        meta: {
          title: 'Account notification preferences',
          pageDesc: 'Account management for Snyk - Notification settings',
          routeName: 'User Notification Settings Vue',
          requirements: {
            page: {
              entitlements: ['allowEmails'],
            },
            canViewProductNotifications: {
              entitlements: ['productNotifications'],
            },
            canViewEmailNotifications: {
              entitlements: ['allowEmails'],
            },
          },
        },
      },
      {
        path: 'share',
        component:
          'AccountSettingsShareWithAFriend/AccountSettingsShareWithAFriend',
        sentryTeam: 'unicorn',
        meta: {
          title: 'Share with a friend',
          pageDesc: 'Share Snyk with friends',
          requirements: {
            canViewEmailNotifications: {
              entitlements: ['allowEmails'],
            },
          },
        },
      },
    ],
  },
];
