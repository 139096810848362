<template>
  <div class="vue--enumerated-text-area">
    <div class="vue--enumerated-text-area--title">
      <slot name="label"></slot>
    </div>
    <div class="vue--enumerated-text-area--body">
      <div v-if="text">
        <div v-for="(line, index) in content.list" :key="line">
          <pre
            v-if="enumerate"
            class="vue--enumerated-text-area--body--number"
            >{{ formatLineNumber(index) }}</pre
          >
          <p class="vue--enumerated-text-area--body--line">{{ line }}</p>
        </div>

        <p
          v-if="hasUnlistedItems"
          class="vue--enumerated-text-area--body--remaining"
        >
          {{ unlistedItemsDescription }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EnumeratedTextArea',

  props: {
    enumerate: {
      type: Boolean,
      default: true,
    },
    text: {
      type: String,
      required: true,
    },
    maxLines: {
      type: Number,
      default: 3,
    },
    itemType: {
      type: String,
      required: true,
    },
    pluralForm: {
      type: String,
      default: null,
    },
  },

  computed: {
    paddingLength() {
      const numOfLines = this.content.list.length.toString();

      return numOfLines.length;
    },

    content() {
      const list = this.text.split('\n');

      return {
        list: list.slice(0, this.maxLines),
        remaining: list.length - this.maxLines,
      };
    },

    hasUnlistedItems() {
      return this.content.remaining > 0;
    },

    unlistedItemsDescription() {
      const remaining = this.content.remaining;
      const noun = this.fixNounForm(this.itemType, remaining);

      if (remaining == 1) {
        return `... and one more ${noun}.`;
      }

      return `... and ${remaining} other ${noun}.`;
    },
  },

  methods: {
    fixNounForm(noun, number) {
      if (this.pluralForm) {
        return number > 1 ? this.pluralForm : noun;
      }

      return noun + (number > 1 ? 's' : '');
    },

    formatLineNumber(num) {
      return (num + 1).toString().padStart(this.paddingLength, ' ');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--enumerated-text-area {
  display: flex;
  flex-direction: column;
  width: 60%;

  &--title {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &--body {
    overflow-y: scroll;
    height: 90%;
    border: 1px solid #ccc;
    padding: 5px;
    background: white;
    font-family: monospace;

    &--number {
      color: darkgray;
      display: inline;
      margin: 0;
    }

    &--line {
      color: gray;
      display: inline;
    }

    &--remaining {
      font-weight: bold;
    }
  }
}
</style>
