<template>
  <div v-if="org" class="vue--upgrade-button">
    <BaseButton
      v-if="billingUrl"
      wrapper="a"
      class="vue--upgrade-button__button"
      :href="billingUrl"
      variant="cta"
      size="small"
      data-snyk-test="UpgradeButton: button"
      @click="trackClick"
    >
      Start free trial
    </BaseButton>
  </div>
</template>

<script>
import { analyticsTrack, itly } from '~/lib/analytics';

export default {
  inject: ['org'],

  props: {
    origin: {
      required: true,
      type: String,
      validator: (value) => ['Header', 'Announcements'].includes(value),
    },
  },

  computed: {
    billingUrl() {
      return this.org ? `/org/${this.org.name}/manage/billing` : null;
    },
  },

  methods: {
    trackClick() {
      analyticsTrack('Upgrade button clicked', {
        org: this.org,
      });

      itly.activateTrialIsClicked({ origin: this.origin });
    },
  },
};
</script>
