<template>
  <div v-if="canCreateOrgs" class="vue--org-switcher-create-org">
    <BaseButton
      class="vue--org-switcher-create-org__link"
      variant="link"
      size="small"
      href="#"
      wrapper="a"
      data-snyk-test="TheOrgSwitcherCreateOrg: button"
      @click="onCreateNewOrgClicked"
    >
      <PlusCircleOutlineIcon decorative /> Create a new organization
    </BaseButton>
  </div>
</template>

<script>
import PlusCircleOutlineIcon from 'icons/PlusCircleOutline';

import { itly } from '~/lib/analytics';

export default {
  name: 'TheOrgSwitcherCreateOrg',
  components: {
    PlusCircleOutlineIcon,
  },
  props: {
    activeGroup: {
      type: Object,
      required: true,
    },
    activeGroupIsPersonal: {
      type: Boolean,
      required: true,
    },
    urlPatterns: {
      type: Object,
      required: true,
    },
  },

  computed: {
    canCreateOrgs() {
      return this.activeGroup?.canCreateOrgs;
    },
    createOrgUrl() {
      return this.activeGroupIsPersonal
        ? this.urlPatterns.personalCreateOrg
        : this.urlPatterns.groupCreateOrg.replace(':id', this.activeGroup.id);
    },
  },

  methods: {
    onCreateNewOrgClicked() {
      let redirected = false;
      const redirect = (() => {
        if (!redirected) {
          window.location.assign(this.createOrgUrl);
          redirected = true;
        }
      }).bind(this);

      itly.createNewOrgIsClicked({
        segment: {
          callback: redirect,
        },
      });

      setTimeout(redirect, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-create-org {
  display: block;
  border-top: 2px solid color(neutral, 90);
  padding: space(s);
  margin-top: auto;
}
</style>
