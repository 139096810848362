import {
  SET_SNAPSHOT_DEP_GRAPH,
  SET_SNAPSHOT_DEP_GRAPH_LOADING,
  SET_SNAPSHOT_DEP_GRAPH_LOADING_ERROR,
} from '../../mutation-types';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  state: {
    depGraph: null,
    depGraphLoading: false,
    depGraphLoadingError: null,
  },

  getters: {
    depGraph: (state) => state.depGraph,
    depGraphLoading: (state) => state.depGraphLoading,
    depGraphLoadingError: (state) => state.depGraphLoadingError,
  },

  actions: {
    async getDepGraph(
      { rootState, commit, state },
      { orgName, projectPublicId, snapshotPublicId },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.depGraphLoading && !state.depGraph) {
        commit(SET_SNAPSHOT_DEP_GRAPH_LOADING, true);

        try {
          const path = `${rootState.baseUrl}/org/${orgName}/project/${projectPublicId}/dep-graph/${snapshotPublicId}`;
          const res = await request(path);

          const { depGraph } = await res.json();
          commit(SET_SNAPSHOT_DEP_GRAPH, depGraph);
        } catch (err) {
          commit(SET_SNAPSHOT_DEP_GRAPH_LOADING_ERROR, true);
        }
        commit(SET_SNAPSHOT_DEP_GRAPH_LOADING, false);
      }
    },
  },

  mutations: {
    [SET_SNAPSHOT_DEP_GRAPH](state, depGraph) {
      state.depGraph = depGraph;
    },
    [SET_SNAPSHOT_DEP_GRAPH_LOADING](state, depGraphLoading) {
      state.depGraphLoading = depGraphLoading;
    },
    [SET_SNAPSHOT_DEP_GRAPH_LOADING_ERROR](state, depGraphLoadingError) {
      state.depGraphLoadingError = depGraphLoadingError;
    },
  },
};
