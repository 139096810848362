function registerGlobalComponents(Vue) {
  const requireComponent = require.context(
    '~/components',
    true,
    /Base[A-Z]\w+\.(vue|js)$/,
  );
  requireComponent.keys().forEach(function (fileName) {
    let baseComponentConfig = requireComponent(fileName);
    baseComponentConfig = baseComponentConfig.default || baseComponentConfig;
    const baseComponentName =
      baseComponentConfig.name ||
      fileName.replace(/^.+\//, '').replace(/\.\w+$/, '');
    Vue.component(baseComponentName, baseComponentConfig);
  });
}

export default registerGlobalComponents;
