<template>
  <h1
    v-if="hasPathData"
    class="vue--logged-out-site-title"
    data-snyk-test="TheLoggedOutSiteTitle"
  >
    <a :href="pathData.path" class="vue--logged-out-site-title__link">
      {{ pathData.title }}
    </a>
  </h1>
</template>

<script>
export default {
  name: 'TheLoggedOutSiteTitle',
  props: {
    pathname: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pathsData: [
        { path: '/vuln', title: 'Vulnerability DB' },
        { path: '/test', title: 'Test Results' },
      ],
    };
  },
  computed: {
    pathData() {
      return this.pathsData.find(({ path }) => this.pathname.startsWith(path));
    },
    hasPathData() {
      return !!this.pathData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';
.vue--logged-out-site-title {
  color: color(ui, white);
  font-size: rem(22px);
  margin: 0.25em 0;

  @include media-query(s) {
    margin: 0.25em 0.5em;
  }

  &__link {
    color: color(ui, white);

    &:hover {
      color: color(ui, white);
    }
  }
}
</style>
