import type { Route } from '../../types';

export const routes: Route[] = [
  {
    path: '/login',
    component: 'Login/Login',
    sentryTeam: 'bolt',
    noAuth: true,
    redirectAuth: true,
    meta: {
      title: 'Snyk Login | Develop fast. Stay secure.',
      pageDesc: 'Login to Snyk. Continuously find and fix vulnerabilities.',
      noSiteHeader: true,
    },
    children: [
      {
        path: '',
        component: 'Login/LoginGeneral/LoginGeneral',
        sentryTeam: 'bolt',
        noAuth: true,
        redirectAuth: true,
        meta: {
          title: 'Log in or sign up to secure your projects',
          routeName: 'Login Classic and Custom pages',
          pageDesc: 'Log in to Snyk',
          noSiteHeader: true,
        },
      },
      {
        path: 'sso',
        component: 'Login/LoginSSO/LoginSSO',
        sentryTeam: 'bolt',
        noAuth: true,
        redirectAuth: true,
        meta: {
          title: 'Log in via SSO',
          routeName: 'Log in via SSO',
          pageDesc: 'Log in via SSO',
          noSiteHeader: true,
        },
      },
    ],
  },
];
