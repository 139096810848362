import { render, staticRenderFns } from "./BaseSeverity.vue?vue&type=template&id=72513317&scoped=true&"
import script from "./BaseSeverity.vue?vue&type=script&lang=js&"
export * from "./BaseSeverity.vue?vue&type=script&lang=js&"
import style0 from "./BaseSeverity.vue?vue&type=style&index=0&id=72513317&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72513317",
  null
  
)

export default component.exports