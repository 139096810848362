import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/org/:orgName/integrations/:path*',
    sentryTeam: 'comet',
  },
];
