import { UPDATE_ORGS } from '~/store/mutation-types';
import errorHandler from '~/lib/error-handler';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  /**
   * example state:
   * state: {
   *   orgs: [
   *     {
   *       id: '111-222-333',
   *       displayName: 'my org',
   *     }
   *    ]
   * }
   */
  state: {
    lastFetchedTimestamp: 0,
    orgs: [],
  },

  getters: {
    allOrgs(state) {
      return state.orgs;
    },
  },

  actions: {
    async getOrgs({ commit, state }, { groupPublicId, force = false }) {
      try {
        // Reduces the frequency that fetching orgs can occur to a minimum of 5 mins. Each group currently fires
        // off a request on load, and then each time you open or close the expandable block. This is a stop-gap
        // solution until a bigger cleanup can occur.
        if (force || Date.now() - state.lastFetchedTimestamp > 5 * 60 * 1000) {
          state.lastFetchedTimestamp = Date.now();
          const url = `/group/${groupPublicId}/policies/api/orgs`;
          const response = await request(url);
          const orgs = await response.json();

          commit(UPDATE_ORGS, orgs);
        }
      } catch (e) {
        //todo: change this to the new alerts system when it’s ready
        errorHandler(e);
      }
    },
  },

  mutations: {
    [UPDATE_ORGS](state, orgs) {
      state.orgs = orgs;
    },
  },
};
