<template>
  <div class="vue--checkbox-described">
    <BaseCheckbox v-bind="{ ...$attrs, ...$props }" @change="onChange" />
    <div class="vue--checkbox-described__description">
      <!-- @slot Description for the checkbox. -->
      <slot />
    </div>
  </div>
</template>

<script>
/**
 * A checkbox with a more detailed description that cannot fit in the label.
 * All props and attributes will be passed to the underlying checkbox component.
 * */
export default {
  name: 'BaseCheckboxDescribed',
  status: 'ready',

  inheritAttrs: false,
  methods: {
    onChange(isChecked) {
      this.$emit('change', isChecked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--checkbox-described {
  &__description {
    margin-top: 0;
    margin-left: space(m);
  }
}
</style>
