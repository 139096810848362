<template>
  <div class="vue--layout-space-between">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LayoutSpaceBetween',
  status: 'ready',
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--layout-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
</style>
