<template>
  <LayoutFormElement
    class="vue--range-slider"
    :inline="inline"
    :fill-width="true"
  >
    <template v-if="label" #label>
      <label class="vue--range-slider__label" :for="$attrs.id" v-text="label" />
    </template>
    <slot name="intro" />
    <VueSlider
      class="vue--range-slider__field"
      :value="value"
      :min="min"
      :max="max"
      v-bind="$attrs"
      :tooltip-style="toolTipStyle"
      :process-style="processStyle"
      :dot-attrs="dotAttrs"
      v-on="$listeners"
      @dragging="onDragging"
      @drag-start="onDragStart"
      @drag-end="onDragEnd"
      @error="onError"
    />
    <div class="vue--range-slider__offscreen" v-text="offscreenNote" />
    <BaseInputNote
      v-if="note"
      class="vue--range-slider__note"
      :variant="noteVariant"
    >
      {{ note }}
    </BaseInputNote>
  </LayoutFormElement>
</template>

<script>
import LayoutFormElement from '~/components/LayoutFormElement/LayoutFormElement';
import 'vue-slider-component/theme/default.css';
import { createAsyncComponent } from '~/lib/chunk-load-handler';

/**
 * Implements wrapper for
 * [vue-slider-component](https://nightcatsama.github.io/vue-slider-component)
 */

const VueSlider = createAsyncComponent(() =>
  import(/* webpackChunkName: "vue-slider-component" */ 'vue-slider-component'),
);

export default {
  name: 'BaseRangeSlider',

  status: 'beta',

  components: {
    VueSlider,
    LayoutFormElement,
  },

  inheritAttrs: false,

  model: {
    event: 'change',
  },

  props: {
    /**
     * The value(s) of the range slider currently selected
     */
    value: {
      type: [Number, Array],
      required: true,
    },
    /**
     * The min range value of the slider field.
     */
    min: {
      type: Number,
      default: 0,
    },
    /**
     * The max range value of the slider field.
     */
    max: {
      type: Number,
      default: 1000,
    },
    /**
     * The label for the range slider field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * The aria-label for the range slider field.
     */
    ariaLabel: {
      type: String,
      required: false,
    },
    /**
     * The hint for the range slider field.
     */
    hint: {
      type: String,
      default: null,
    },
    /**
     * Whether the form input field is displayed inline.
     * `true, false`
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * The color we want to display for the tooltip and process line
     * can be overwritten via dot options
     */
    styleColor: {
      type: String,
      default: '#4b45a1', // matches color action
    },
    /**
     * A note to be displayed below this field.
     */
    note: {
      type: String,
      default: null,
    },
    /**
     * Whether to show an error for this field
     * `true, false`
     */
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    offscreenNote() {
      // aids accessibility by adding a long-form note to allow screen readers
      // to give more information than a hint/note/label would present
      let selectedValues = '';
      if (Array.isArray(this.value)) {
        selectedValues += this.value.join(' to ');
      } else {
        selectedValues += this.value;
      }
      return (
        `The slider shows a range from ${this.min} to ${this.max}.\n` +
        `The value(s) currently selected are: ${selectedValues}.`
      );
    },

    toolTipStyle() {
      return {
        backgroundColor: this.styleColor,
        borderColor: this.styleColor,
        padding: '8px 16px',
        fontSize: '18px',
      };
    },

    processStyle() {
      return {
        backgroundColor: this.styleColor,
      };
    },

    noteVariant() {
      return this.error ? 'error' : 'default';
    },

    dotAttrs() {
      return {
        'aria-label': this.ariaLabel || this.label,
      };
    },
  },
  methods: {
    onDragging(value) {
      this.$emit('dragging', value);
    },
    onDragStart() {
      this.$emit('drag-start');
    },
    onDragEnd() {
      this.$emit('drag-end');
    },
    onError(type, message) {
      this.$emit('error', type, message);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--range-slider {
  $self: &;

  &__note {
    margin-top: space(xs);
  }

  &__offscreen {
    // followed guidelines here:
    // https://a11y-guidelines.orange.com/web_EN/exemples/masquage/index.html
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }

  // necessary due to reset styling & snyk-ui overrides
  ::v-deep .vue-slider-rail {
    background-color: color(neutral, 78);
  }
  ::v-deep .vue-slider-dot {
    background-color: white;
    width: space() + space(xxs) !important;
    height: space() + space(xxs) !important;

    .vue-slider-dot-handle {
      box-shadow: none;
      border: 1px solid color(neutral, 48);
    }
  }
}
</style>
