import { GROUP_PERMISSIONS, ORG_PERMISSIONS } from 'shared/permissions/defs';

export { navigationItems };

const navigationItems = {
  group: [
    {
      label: 'Organizations',
      path: '/group/:groupPublicId/organisations',
      requirements: {
        features: ['visibleOrgs'],
        permissions: [GROUP_PERMISSIONS.ORG_LIST],
      },
    },
    {
      label: 'Reports',
      path: '/group/:groupPublicId/reports',
      requirements: {
        enabled: ['showGroupReports'],
      },
      validators: ['NON_ZERO_ORGS_LENGTH'],
      permissions: [GROUP_PERMISSIONS.REPORT_READ],
    },
    {
      label: 'Policies',
      path: '/group/:groupPublicId/policies',
      requirements: {
        enabled: ['showGroupPolicies'],
        entitlements: ['policies'],
        permissions: [GROUP_PERMISSIONS.POLICY_READ],
      },
    },
    {
      label: 'Enterprise Reporting',
      path: '/group/:groupPublicId/enterprise-reporting',
      requirements: {
        features: ['looker'],
      },
    },
    {
      label: 'Members',
      path: '/group/:groupPublicId/manage/members',
      requirements: {
        features: ['newGroupMembers'],
        permissions: [GROUP_PERMISSIONS.READ, GROUP_PERMISSIONS.USER_READ],
      },
    },
  ],
  org: [
    {
      label: 'Dashboard',
      path: '/org/:orgName',
      exactMatch: true,
      requirements: {
        enabled: ['showOrgDashboard'],
        permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.PROJECT_READ],
      },
    },
    {
      label: 'Reports',
      path: '/org/:orgName/reports',
      requirements: {
        enabled: ['showOrgReports'],
        permissions: [ORG_PERMISSIONS.REPORT_READ],
      },
    },
    {
      label: 'Projects',
      path: '/org/:orgName/projects',
      requirements: {
        permissions: [ORG_PERMISSIONS.PROJECT_READ],
      },
    },
    {
      label: 'Integrations',
      path: '/org/:orgName/integrations',
      requirements: {
        enabled: ['showOrgIntegrations'],
        permissions: [ORG_PERMISSIONS.INTEGRATION_READ],
      },
    },
    {
      label: 'Members',
      path: '/org/:orgName/manage/members',
      requirements: {
        features: ['newOrgMembers'],
        entitlements: ['userManagement'],
        permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.USER_READ],
      },
    },
  ],
  account: [
    {
      label: 'Account settings',
      path: '/account',
    },
    {
      label: 'Notification preferences',
      path: '/account/notifications',
      requirements: {
        entitlements: ['allowEmails'],
      },
    },
    {
      label: 'Latest product updates',
      path: 'https://updates.snyk.io',
      requirements: {
        enabled: ['showReleaseNotesLink'],
        entitlements: ['productNotifications'],
      },
    },
    {
      label: '👑 Admin',
      path: '/admin',
      validators: ['IS_ADMIN'],
      separator: true,
    },
    {
      label: 'Share with a friend',
      path: '/account/share',
      separator: true,
    },
  ],
  settings: [
    {
      label: 'Org Settings',
      path: '/org/:orgName/manage',
      requirements: {
        enabled: ['showOrgSettings'],
        permissions: [ORG_PERMISSIONS.SETTINGS_READ],
      },
      validators: ['ORG_CONTEXT'],
    },
    {
      label: 'Group Settings',
      path: '/group/:groupPublicId/manage',
      requirements: {
        enabled: ['showGroupSettings'],
        permissions: [GROUP_PERMISSIONS.SETTINGS_READ],
      },
      validators: ['GROUP_CONTEXT'],
    },
  ],
  mock: [
    {
      label: 'Item 1',
      path: '/org/foo',
      requirements: {
        enabled: ['showGroupReports'],
        features: ['fakeFeature'],
      },
    },
    {
      label: 'Item 2',
      path: 'https://snyk.io/',
    },
  ],
};
