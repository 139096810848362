<template>
  <li class="vue--dropdown-menu-info">
    <slot>
      {{ value }}
    </slot>
  </li>
</template>

<script>
export default {
  name: 'BaseDropdownMenuInfo',
  status: 'ready',

  props: {
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu-info {
  list-style: none;
  margin: 0;
  padding: space(xxs) space(m);
  white-space: nowrap;
}
</style>
