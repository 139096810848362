<template>
  <div class="vue--empty-state" :class="computedClasses">
    <header class="vue--empty-state__header">
      <component :is="graphicComponent" class="vue--empty-state__graphic" />
      <h2 class="vue--empty-state__heading">
        <!-- @slot *(Required)* The heading slot. -->
        <slot name="heading" />
      </h2>
    </header>
    <div v-if="hasDefault" class="vue--empty-state__body">
      <!-- @slot *(Optional)* The default slot. -->
      <slot />
    </div>
    <div v-if="hasActions" class="vue--empty-state__actions">
      <!-- @slot *(Optional)* The actions slot. -->
      <slot name="actions" />
    </div>
    <footer v-if="hasFootnote" class="vue--empty-state__footer">
      <!-- @slot *(Optional)* The footnote slot. -->
      <slot name="footnote" />
    </footer>
  </div>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export const variants = [
  'high-five',
  'no-results',
  'all-secure',
  'generating',
  'failure',
];

/**
 * `EmptyState` is used to provide feedback when there is no data to show. This can come in may forms for example no search results were found,
 * some form of failure occurred, or a request is in progress like generating reports.
 */
export default {
  name: 'BaseEmptyState',

  components: {
    GraphicAllSecure: () => import('./GraphicAllSecure'),
    GraphicAllSecureInverted: () => import('./GraphicAllSecureInverted'),
    GraphicFailure: () => import('./GraphicFailure'),
    GraphicFailureInverted: () => import('./GraphicFailureInverted'),
    GraphicGenerating: () => import('./GraphicGenerating'),
    GraphicGeneratingInverted: () => import('./GraphicGeneratingInverted'),
    GraphicHighFive: () => import('./GraphicHighFive'),
    GraphicHighFiveInverted: () => import('./GraphicHighFiveInverted'),
    GraphicNoResults: () => import('./GraphicNoResults'),
    GraphicNoResultsInverted: () => import('./GraphicNoResultsInverted'),
  },

  props: {
    /**
     * The variant prop controls what graphic is shown.<br/>*Valid options*: `high-five`, `no-results`, `all-secure`, `generating`, `failure`
     */
    variant: {
      type: String,
      default: null,
      validator: isInList(variants),
    },
    /**
     * Invert the component styling for a dark background.
     */
    inverted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--empty-state--inverted': this.inverted,
      };
    },
    graphicComponent() {
      if (!this.variant) return;

      const toInitialCap =
        this.variant.charAt(0).toUpperCase() + this.variant.slice(1);
      const toCamelCase = toInitialCap.replace(/-./g, (match) =>
        match[1].toUpperCase(),
      );
      const isInverted = this.inverted ? 'Inverted' : '';

      return `Graphic${toCamelCase}${isInverted}`;
    },
    hasDefault() {
      return !!this.$slots.default;
    },
    hasActions() {
      return !!this.$slots.actions;
    },
    hasFootnote() {
      return !!this.$slots.footnote;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--empty-state {
  $self: &;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: space(m) space(m) space(l);
  max-width: rem(420px);

  &__header {
    text-align: center;
  }

  &__graphic {
    display: block;
    margin: 0 auto space(m);
  }

  &__body {
    color: color(base, body);
    margin-top: space(xs);
    text-align: center !important; // Override _reset.scss
  }

  &__actions {
    margin-top: space(m);
  }

  &__footer {
    font-size: rem(14px);
    margin-top: space(m);
  }

  &--inverted {
    #{$self}__heading {
      color: color(base, inverted);
    }

    #{$self}__body {
      color: color(neutral, 72);
    }
  }
}
</style>
