<template>
  <div class="vue--checkbox-switch" :class="computedClasses">
    <label class="vue--checkbox-switch__label">
      <span class="vue--checkbox-switch__container">
        <input
          :checked="checked"
          :disabled="disabled"
          class="vue--checkbox-switch__input"
          type="checkbox"
          @click="onChange"
        />
        <span class="vue--checkbox-switch__bar"></span>
      </span>
      <span v-if="computedLabel" class="vue--checkbox-switch__text">
        {{ computedLabel }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BaseCheckboxSwitch',

  status: 'ready',

  model: {
    prop: 'checked',
    event: 'toggled',
  },

  props: {
    /**
     * Optional label. Accepts a string or an array containing two values
     * (on label and off label) e.g. <code>:label="[ \'Enable\', \'Disable\' ]"</code>
     */
    label: {
      type: [String, Array],
      default: null,
      validator(value) {
        if (Array.isArray(value) && value.length !== 2) {
          // eslint-disable-next-line no-console
          console.error(
            "Passing an array to the label prop requires an on and off value e.g. :label=\"[ 'Enable', 'Disable' ]\".",
          );
          return false;
        }

        return true;
      },
    },
    /**
     * Is switch on (checked) onload?
     */
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isChecked: this.checked,
    };
  },

  computed: {
    computedLabel() {
      return Array.isArray(this.label)
        ? this.isChecked
          ? this.label[1]
          : this.label[0]
        : this.label;
    },
    computedClasses() {
      return {
        'vue--checkbox-switch__disabled': this.disabled,
      };
    },
  },
  methods: {
    onChange() {
      if (this.disabled) {
        return;
      }
      this.isChecked = !this.isChecked;

      /**
       * Toggled event.
       *
       * @type {boolean}
       */
      this.$emit('toggled', !this.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--checkbox-switch {
  $self: &;

  &__label {
    @include font-stack(semibold);

    display: inline-block;
    line-height: 1.4;
  }

  &__text {
    margin-left: space(xxs);
  }

  &__input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + #{$self}__bar {
      background-color: rgba(color(action, create), 0.4);

      &::before {
        background-color: color(action, create);
        transform: translateX(15px);
      }
    }

    &:focus + #{$self}__bar {
      box-shadow: 0 0 1px rgba(color(action, create), 0.4);
    }
  }

  &__container {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 34px;
  }

  &__bar {
    position: absolute;
    cursor: pointer;
    top: 5px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(color(neutral, 48), 0.4);
    height: 10px;
    transition: all 0.4s ease;
    border-radius: global(border-radius);

    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 0;
      bottom: -5px;
      background-color: color(neutral, 48);
      transition: all 0.4s ease;
      border-radius: 50%;
    }
  }

  &__disabled {
    color: color(disabled, text);
  }
}
</style>
