<!-- cSpell:words scrollto  -->
<template>
  <BaseBlock
    :id="id"
    :class="computedClasses"
    :variant="blockVariant"
    class="vue--card"
    data-snyk-test="card"
    :wrapper="conditionalFormElement"
    @submit.prevent="submit"
  >
    <header v-if="hasHeading" class="vue--card__header">
      <slot name="header">
        <BaseAnchor
          fallback-wrapper="div"
          :href="linkToId"
          class="vue--card__anchor"
          tabindex="0"
        >
          <h2>
            <!-- @slot Card heading title slot. -->
            <slot name="heading" />
            <span v-if="linkToId" class="vue--card__anchor-icon">
              <LinkVariantIcon decorative />
            </span>
          </h2>
        </BaseAnchor>
      </slot>
    </header>

    <div v-if="hasIntro" class="vue--card__intro">
      <!-- @slot Optional intro text slot. -->
      <slot name="intro" />
    </div>

    <BaseAlert
      v-if="alertState && alertState.message"
      class="vue--card__alert"
      :variant="alertState.variant"
      :dismissible="alertState.dismissible"
      @dismiss="dismiss"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="alertState.message" />
    </BaseAlert>
    <div class="vue--card__body">
      <!-- @slot The default slot for the main content body of the card -->
      <slot />
    </div>

    <footer v-if="hasFormActions" class="vue--card__form-actions">
      <!-- @slot Optional form actions slot for form buttons. -->
      <slot name="formActions" />
    </footer>
    <footer v-if="hasFooter" class="vue--card__footer">
      <!-- @slot Optional footer slot of supporting text/links. -->
      <slot name="footer" />
    </footer>
  </BaseBlock>
</template>

<script>
import VueScrollTo from 'vue-scrollto';
import LinkVariantIcon from 'icons/LinkVariant';
import { isInList } from '~/lib/prop-validators';

/**
 * A base card component. Minimal by design to keep its usage flexible.
 */
export default {
  name: 'BaseCard',

  status: 'ready',

  components: { LinkVariantIcon },

  props: {
    /**
     * The card style. Available options `success`, `warning`, `danger`.
     */
    variant: {
      type: String,
      default: null,
      validator: isInList([
        'default',
        'cta',
        'success',
        'warning',
        'danger',
        'info',
        'white',
      ]),
    },
    /**
     * Object to hydrate an alert from, or null if no alert should be shown:<br/>
     * ```
     * {
     *   variant: 'success',                     // `success`, `danger`, `warning`.
     *   message: 'Saved changes successfully.', // content to display.
     *   dismissible: 'auto',                    // `true`, `false`, `auto`.
     * }
     * ```
     */
    alert: {
      type: Object,
      default: null,
    },
    /**
     * The card anchor id.
     */
    id: {
      type: String,
      default: null,
    },
    /**
     * Remove padding from body element.
     */
    noPadding: {
      type: Boolean,
      default: false,
    },
    /**
     * Render the card with legacy styles. Use this option when
     * placing a Vue `Card` alongside non-migrated Snyk-UI cards.
     */
    legacy: {
      type: Boolean,
      default: false,
    },
    /**
     * Render with reduced padding and spacing.
     */
    condensed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      alertState: this.alert,
    };
  },
  computed: {
    computedClasses() {
      return {
        [`vue--card--${this.variant}`]: !!this.variant,
        'vue--card--no-padding': this.noPadding,
        'vue--card--legacy': this.legacy,
        'vue--card--condensed': this.condensed,
      };
    },
    blockVariant() {
      return !['white'].includes(this.variant) ? this.variant : null;
    },
    conditionalFormElement() {
      return this.hasFormActions ? 'form' : 'div';
    },
    hasHeading() {
      return Boolean(this.$slots.heading) || Boolean(this.$slots.header);
    },
    hasIntro() {
      return Boolean(this.$slots.intro);
    },
    hasNote() {
      return Boolean(this.note);
    },
    hasNoteUrl() {
      return Boolean(this.url);
    },
    hasFooter() {
      return Boolean(this.$slots.footer);
    },
    hasFormActions() {
      return Boolean(this.$slots.formActions);
    },
    linkToId() {
      return this.id ? `#${this.id}` : null;
    },
  },
  watch: {
    alert: {
      handler: function (newVal, oldVal) {
        if (oldVal !== newVal) {
          this.alertState = newVal;
        }
      },
    },
  },
  mounted() {
    if (this.id) {
      const hashedId = `#${this.id}`;

      if (hashedId === window.location.hash) {
        this.$nextTick(() => {
          VueScrollTo.scrollTo(hashedId);
        });
      }
    }
  },
  methods: {
    dismiss() {
      this.alertState = null;
    },
    submit(e) {
      this.$emit('submit', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--card {
  $self: &;

  &:not(&--legacy) {
    background-color: color(ui, off-white);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    + #{$self} {
      margin-top: space(m);
    }

    a #{$self} {
      &:hover,
      &:focus {
        cursor: pointer;
      }
    }

    #{$self} {
      &__anchor {
        color: color(base, body);
        display: block;
        padding: space() space(m) space() - space(xxs);
        width: 100%;

        &:hover,
        &:focus {
          text-decoration: none;

          #{$self}__anchor-icon {
            opacity: 1;
          }
        }
      }

      &__anchor-icon {
        color: color(neutral, 84);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        ::v-deep svg {
          height: 20px;
          width: 20px;
        }
      }

      &__header {
        align-items: center;
        background-color: color(ui, white);
        border-radius: global(border-radius, half) global(border-radius, half) 0
          0;
        display: flex;
        justify-content: space-between;
        position: relative;

        ::v-deep svg {
          position: relative;
          top: -1px;
          margin-right: space(xxs);
        }

        h2 {
          font-size: rem(18px);
          font-weight: 500;
        }
      }

      &__note {
        font-size: rem(13px);
      }

      &__intro {
        background-color: color(ui, white);
        padding: 0 space(m) space() - space(xxs);
      }

      &__body {
        border-top: 1px solid color(neutral, 84);
        height: 100%;
        padding: space(m);
        position: relative;

        &:first-child {
          border-top: none;
        }

        & > :first-child {
          margin-top: 0;
        }

        & > :last-child {
          margin-bottom: 0;
        }

        + #{$self}__alert {
          border-top: none;
        }
      }

      &__footer {
        @include font-stack(regular, sans-serif, italic);

        align-items: center;
        background-color: color(ui, white);
        border-radius: 0 0 global(border-radius, half)
          global(border-radius, half);
        border-top: 1px solid color(neutral, 84);
        color: color(base, dimmed);
        font-size: rem(13px);
        margin-top: auto;
        padding: space() space(m);
      }

      &__form-actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        background-color: color(ui, white);
        border-radius: 0 0 global(border-radius, half)
          global(border-radius, half);
        border-top: 1px solid color(neutral, 84);
        margin-top: auto;
        padding: space(s) space(m);

        + #{$self}__footer {
          margin-top: 0;
        }
      }
    }
  }

  &--no-padding:not(&--legacy) {
    #{$self}__body {
      padding: 0;
    }
  }

  &--condensed:not(&--legacy) {
    #{$self} {
      &__anchor {
        padding: space(s) space(s) space(s) - space(xxxs);
      }
      &__intro {
        padding: 0 space(s) space(s) - space(xxs);
      }
      &__body {
        padding: space(s);
      }
      &__form-actions {
        padding: space(s) space(s);
      }
      &__footer {
        padding: space(s) space(s);
      }
    }
  }

  &--warning:not(&--legacy) {
    background-color: color(alert, warning);

    #{$self}__header {
      ::v-deep svg {
        color: color(action, active);
      }
    }

    #{$self}__body {
      background-color: color(alert, warning);
      border-top-color: color(alert, warning-border);
    }

    #{$self}__form-actions,
    #{$self}__footer {
      border-color: color(alert, warning-border);
    }

    #{$self}__form-actions + #{$self}__footer {
      border-color: color(neutral, 84);
    }
  }

  &--danger:not(&--legacy) {
    background-color: color(alert, danger);

    #{$self}__header {
      ::v-deep svg {
        color: color(action, destroy);
      }
    }

    #{$self}__body {
      background-color: color(alert, danger);
      border-top-color: color(alert, danger-border);
    }

    #{$self}__form-actions,
    #{$self}__footer {
      border-color: color(alert, danger-border);
    }

    #{$self}__form-actions + #{$self}__footer {
      border-color: color(neutral, 84);
    }
  }

  // TODO: cta modifier is deprecated in favour of success.
  &--cta:not(&--legacy),
  &--success:not(&--legacy) {
    background-color: color(alert, success);

    #{$self}__header {
      ::v-deep svg {
        color: color(action, create);
      }
    }

    #{$self}__body {
      background-color: color(alert, success);
      border-top-color: color(alert, success-border);
    }

    #{$self}__form-actions,
    #{$self}__footer {
      border-color: color(alert, success-border);
    }

    #{$self}__form-actions + #{$self}__footer {
      border-color: color(neutral, 84);
    }
  }

  &--info:not(&--legacy) {
    background-color: color(alert, info);

    #{$self}__header {
      ::v-deep svg {
        color: color(action);
      }
    }

    #{$self}__body {
      background-color: color(alert, info);
      border-top-color: color(alert, info-border);
    }

    #{$self}__form-actions,
    #{$self}__footer {
      border-color: color(alert, info-border);
    }

    #{$self}__form-actions + #{$self}__footer {
      border-color: color(neutral, 84);
    }
  }

  &--white:not(&--legacy) {
    background-color: color(ui, white);

    #{$self}__body {
      background-color: color(ui, white);
    }
  }

  // TODO: The `legacy` modifier (applied via the legacy prop)
  // allows us to render a card with legacy styles. This way it
  // can be used alongside unmigrated Handlebars cards without
  // looking stylistically out of place.
  &--legacy {
    background-color: color(ui, white);
    border-radius: global(border-radius, micro);
    padding: space(s);

    &:not(#{$self}--danger) {
      box-shadow: inset 0 0 0 1px #e5e8ed;
    }

    &#{$self}--success {
      background-color: rgba(125, 204, 204, 0.45);
      border: 1px solid rgba(33, 92, 92, 0.31);
    }

    &#{$self}--info {
      background-color: #edecf6;
      border: 1px solid rgb(147, 143, 198);
    }

    + #{$self} {
      margin-top: space();
    }

    #{$self} {
      &__anchor {
        color: color(base, body);
        position: relative;

        &:hover,
        &:focus {
          text-decoration: none;

          &[href]::after {
            opacity: 1;
          }
        }

        &[href]::after {
          background-color: color(neutral, 84);
          content: '#';
          display: block;
          height: space(m);
          left: -36px;
          line-height: space(m);
          opacity: 0;
          position: absolute;
          top: 0;
          transition: opacity 0.2s ease-in-out;
          width: space(m);
          text-align: center;
        }
      }

      &__anchor-icon {
        display: none;
      }

      &__header {
        border-bottom: 1px solid color(neutral, 84);
        margin-bottom: space();
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: rem(18px);
          margin: 0 0 space(xs);
          font-weight: 500;
          margin-left: -1 * space(s);
          padding-left: space(s);
        }

        &--note {
          font-size: rem(13px);
          margin: 0;
        }
      }

      &__intro {
        margin-bottom: space(m);
      }

      &__body {
        & > :first-child {
          margin-top: 0;
        }

        & > :last-child {
          margin-bottom: 0;
        }
      }

      &__alert {
        margin-bottom: space(s);
      }

      &__footer {
        @include font-stack(regular, sans-serif, italic);

        color: color(base, dimmed);
        border-top: 1px solid color(neutral, 84);
        font-size: rem(13px);
        margin-top: space();
        padding-top: space(s);
      }
    }
  }
}
</style>
