import { ORG_PERMISSIONS } from '../../permissions/defs';
import type { Route, CodeownerRoute } from '../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName',
    component: 'OrgDashboard/OrgDashboard',
    sentryTeam: 'spark',
    meta: {
      title: 'Dashboard',
      routeName: 'Dashboard',
      pageDesc: 'Snyk Dashboard',
      requirements: {
        page: {
          permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.PROJECT_READ],
        },
        canAddProjects: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_ADD,
          ],
          enabled: ['showAddProjects'],
        },
        canSeeCriticalPromo: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.FEATURE_FLAG_READ,
            ORG_PERMISSIONS.FEATURE_FLAG_EDIT,
          ],
        },
        showCriticalSeverity: { features: ['showCriticalSeverity'] },
        canViewReports: { entitlements: ['reports'] },
        hasPermissionToReadReports: {
          permissions: [ORG_PERMISSIONS.REPORT_READ],
        },
      },
    },
  },
];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/org/:orgName',
    sentryTeam: 'spark',
  },
];
