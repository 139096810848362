<template>
  <div class="vue--button-group" :class="computedClasses" role="group">
    <BaseButtonGroupButton
      v-for="option in preparedOptions"
      :key="option.value"
      :selected="value && value.includes(option.value)"
      :size="size"
      v-bind="option"
      :variant="variant"
      @click="onClick"
    />
  </div>
</template>

<script>
import {
  isInList,
  isStringArray,
  hasRequiredKeys,
  onlyHasPermittedKeys,
} from '~/lib/prop-validators';
import BaseButtonGroupButton from '../BaseButtonGroupButton/BaseButtonGroupButton';

export default {
  name: 'BaseButtonGroup',
  components: {
    BaseButtonGroupButton,
  },
  props: {
    /**
     * Array of selected values corresponding to the options array.
     */
    value: {
      type: Array,
      validator: isStringArray(),
      default: () => [],
    },
    variant: {
      type: String,
      validator: isInList(['pill']),
      default: null,
    },
    /**
     * Array of options. Each option has a `value`, `label` (optional),
     * `note` (optional), `icon` (optional).
     */
    options: {
      type: Array,
      validator: (prop) =>
        prop.every(
          (v) =>
            hasRequiredKeys(['value'])(v) &&
            onlyHasPermittedKeys(['value', 'label', 'note', 'icon']) &&
            typeof v.value === 'string',
        ),
      default: () => [],
    },
    /**
     *
     */
    size: {
      type: String,
      validator: isInList('large'),
      default: null,
    },
    /**
     * Makes the component full-width.
     */
    expanded: {
      type: Boolean,
      default: false,
    },
    /**
     * Allows multiple options to be selected.
     */
    multiSelect: {
      type: Boolean,
      default: false,
    },
    /**
     * Allow an option to be unselected. Only applied when `multi-select` is not set.
     */
    unselectable: {
      type: Boolean,
      default: false,
    },
    /**
     * Disables the ability to change the selection.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        ['vue--button-group--expanded']: this.expanded,
        [`vue--button-group--${this.variant}`]: !!this.variant,
      };
    },
    preparedOptions() {
      let options = this.options;

      if (this.disabled) {
        options = options.map((option) => {
          return {
            ...option,
            disabled: true,
          };
        });
      }

      return options;
    },
  },
  methods: {
    onClick: function (newValue) {
      if (this.multiSelect) {
        const values = new Set(this.value);

        // Toggle value in set
        if (values.has(newValue)) values.delete(newValue);
        else values.add(newValue);

        this.$emit('input', Array.from(values));
      } else {
        if (this.unselectable && this.value[0] === newValue) {
          // Unselect value
          this.$emit('input', []);
        } else {
          // Change single selection
          this.$emit('input', [newValue]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--button-group {
  background-color: color(ui, off-white);
  border: 1px solid color(neutral, 84);
  display: inline-grid;
  grid-auto-flow: column;
  border-radius: global(border-radius, micro);

  &--expanded {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  }

  &--pill {
    border-radius: global(border-radius, round);
    padding: space(xxxs);
  }
}
</style>
