<template>
  <component
    :is="rootComponent"
    class="vue--block-expandable"
    :class="computedClasses"
    :data-snyk-test="`BaseExpandable: ${isOpen ? 'is open' : 'is closed'}`"
    tabindex="0"
    @keydown="handleKeydown"
  >
    <div class="vue--block-expandable__header">
      <div class="vue--block-expandable__container">
        <div class="vue--block-expandable__title" @click="toggleCard">
          <BaseLoadingSpinner
            v-if="loading"
            size="extra-small"
            class="vue--block-expandable__chevron"
          />
          <ChevronDownIcon
            v-else
            title="Expand this section"
            :class="{ 'vue--block-expandable__chevron--collapsed': !isOpen }"
            class="vue--block-expandable__chevron"
          />
          <div class="vue--block-expandable__text">
            <slot name="title" />
          </div>
        </div>
        <div v-if="hasAdditional" class="vue--block-expandable__additional">
          <slot name="header" />
        </div>
      </div>
    </div>

    <LayoutTransitionExpand
      :open="isOpen"
      :show-loading="showLoading"
      @loading="startLoading"
      @complete="stopLoading"
    >
      <div class="vue--block-expandable__content">
        <slot name="default" />
      </div>
    </LayoutTransitionExpand>
  </component>
</template>

<script>
import ChevronDownIcon from 'icons/ChevronDown';
import BaseBlock from '~/components/BaseBlock/BaseBlock';
import LayoutTransitionExpand from '~/components/LayoutTransitionExpand/LayoutTransitionExpand';
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseExpandable',
  status: 'ready',
  components: {
    ChevronDownIcon,
    LayoutTransitionExpand,
  },
  props: {
    /**
     * Should the card be expanded onload?
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable closure of expandable block, for in the
     * event of something being interacted with within
     * the element e.g. a BaseModal.
     */
    disableClosure: {
      type: Boolean,
      default: false,
    },
    /**
     * Size modifier e.g. `default`, `large`.
     */
    size: {
      type: String,
      default: 'small',
      validator: isInList(['small', 'large']),
    },
    /**
     * Remove padding from expanded content.
     */
    noPadding: {
      type: Boolean,
      default: false,
    },
    /**
     * Sticks the Block's header to the top of the page if the expanded part is longer than the visible page
     */
    sticky: {
      type: Boolean,
      default: false,
    },
    /**
     * The breakpoint width at which point the title and additional content become rowed.
     */
    columned: {
      type: Boolean,
      default: false,
    },
    /**
     * Variant to render. Available modifiers: minimal.
     */
    variant: {
      type: String,
      default: 'default',
      validator: isInList(['default', 'minimal']),
    },
    /**
     * Show loading spinner during expansion.
     */
    showLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isOpen: this.open,
      loading: false,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--block-expandable--${this.size}`]: !!this.size,
        'vue--block-expandable--no-padding': this.noPadding,
        'vue--block-expandable--columned': this.columned,
        'vue--block-expandable--sticky': this.sticky,
        'vue--block-expandable--open': this.isOpen,
        'vue--block-expandable--disabled': this.disableClosure,
        [`vue--block-expandable--${this.variant}`]: !!this.variant,
      };
    },
    hasAdditional() {
      return this.$slots.header;
    },
    rootComponent() {
      return this.variant === 'minimal' ? 'div' : BaseBlock;
    },
  },

  watch: {
    open() {
      this.isOpen = this.open;
    },
  },

  methods: {
    handleKeydown(e) {
      if (!this.disableClosure && (e.keyCode === 13 || e.keyCode === 32)) {
        e.preventDefault();
        this.toggleCard();
      }
    },
    toggleCard() {
      if (!this.disableClosure) {
        this.isOpen = !this.isOpen;
        this.$emit('open', this.isOpen);
      }
    },
    startLoading() {
      this.loading = true;
    },
    stopLoading() {
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--block-expandable {
  $self: &;

  + #{$self} {
    margin-top: space(s);
  }

  &__container {
    cursor: pointer;
    align-items: stretch;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  }

  &__additional {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: space(s);
  }

  &__title {
    @include font-stack(regular);

    align-items: center;
    display: flex;
    position: relative;
    width: 100%;
  }

  &__text {
    display: inline;
    flex-grow: 1;
  }

  &__chevron {
    display: inline-block;
    margin-right: space(xs);
    transition: transform 0.2s ease-in-out;
    position: relative;

    &--collapsed {
      transform: rotate(-90deg);
    }
  }

  &--large {
    #{$self}__title {
      font-size: em(18px);
    }

    #{$self}__chevron {
      ::v-deep svg {
        height: rem(24px);
        width: rem(24px);
      }
    }
  }

  &--small {
    #{$self}__title {
      font-size: em(16px);
    }

    #{$self}__chevron {
      ::v-deep svg {
        height: rem(20px);
        width: rem(20px);
      }
    }
  }

  &--sticky {
    #{$self}__header {
      background-color: inherit;
      position: sticky;
      top: 0;
      z-index: 3;
    }

    &#{$self}--open {
      #{$self}__header {
        border-bottom: 1px solid rgba(color(neutral, 84), 0.5);
      }
    }
  }

  &--columned {
    #{$self} {
      &__container {
        flex-direction: column;
      }
    }
  }

  &--default {
    &:focus:not(#{$self}--disabled) {
      @include hoverState();
    }

    #{$self}__title {
      padding: space(s);
    }

    &#{$self}--large {
      &:not(#{$self}--no-padding) #{$self}__content {
        padding: 0 space(m) space() space() + 24px + space(xs);
      }
    }

    &#{$self}--small {
      &:not(#{$self}--no-padding) #{$self}__content {
        padding: 0 space() space(s) space(s) + 20px + space(xs);
      }
    }
  }

  &--minimal {
    position: relative;
    width: 100%;

    #{$self}__chevron {
      top: -1px;
    }

    #{$self}__title {
      padding: 0;
      position: relative;
    }

    #{$self}__additional {
      padding: 0;
    }

    &#{$self}--large {
      &:not(#{$self}--no-padding) #{$self}__content {
        padding: 0 space(m) space() 24px + space(xs);
      }
    }

    &#{$self}--small {
      &:not(#{$self}--no-padding) #{$self}__content {
        padding: 0 space() space(s) space(xs) + 20px;
      }
    }
  }

  &--no-padding {
    > #{$self}__content {
      padding: 0 !important;
    }
  }
}
</style>
