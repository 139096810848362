import trim = require('lodash/trim');

export function getTabNameFromQueryParam(name: string): null | string {
  if (typeof window == 'undefined') {
    return null;
  }

  const url: string = window.location.href;
  const filteredName = name.replace('[[]]g', '\\$&');
  const regex = new RegExp(`[?&]${filteredName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/* Going with some super rudimentary logic here. I'm not trying to build
 * a router.  The idea is that we pas in the base URL of the page the tabs
 * live on and the last URL parameter is the tab name.
 *
 * For example: Using `http://localhost:8000/org/[org-name]/reports/issues`
 * the basePath is `/org/[org-name]/reports` and the tab
 * name is 'issues'.
 */
export function getUrlParameter(basePath = ''): null | string {
  const url: string = window.location.href;
  // handle any starting and ending slashes
  const baseURL = `${window.location.host}/${trim(basePath, '/\\')}`;
  // get just the bit we care about
  const routeParams = url.replace(baseURL, '');
  // now get all params without any query parameters or hashes
  const regex = new RegExp(/([^?&#]*)/);
  const results = regex.exec(routeParams);

  if (!results) return null;
  if (!results[1]) return '';

  const params = results[1].split('/');
  return params[params.length - 1];
}
