import { render, staticRenderFns } from "./BaseDropdownMenuInfo.vue?vue&type=template&id=61b53986&scoped=true&"
import script from "./BaseDropdownMenuInfo.vue?vue&type=script&lang=js&"
export * from "./BaseDropdownMenuInfo.vue?vue&type=script&lang=js&"
import style0 from "./BaseDropdownMenuInfo.vue?vue&type=style&index=0&id=61b53986&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61b53986",
  null
  
)

export default component.exports