<template>
  <component
    :is="wrapper"
    class="vue--block"
    :class="computedClasses"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseBlock',
  status: 'ready',

  props: {
    /**
     * The block variant. Available options: `instruction`, `warning`, `success`, `danger`, `info`, `severity-critical`, `severity-high`, `severity-medium`, `severity-low`.
     */
    variant: {
      type: String,
      default: null,
      validator: isInList([
        'instruction',
        'warning',
        'success',
        'danger',
        'info',
        `severity-critical`,
        `severity-high`,
        `severity-medium`,
        `severity-low`,
      ]),
    },
    /**
     * Set the wrapper for the block.
     */
    wrapper: {
      type: String,
      default: 'div',
      validator: isInList(['div', 'form']),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--block--${this.variant}`]: !!this.variant,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--block {
  $self: &;

  background-color: color(ui, white);
  box-shadow: global(hoverState, defaultBoxShadow);
  border-radius: global(border-radius, micro);
  padding: 1px;

  &--instruction {
    background-color: color(ui, canvas);
    box-shadow: inset 0 0 0 1px darken(color(ui, canvas), 10%),
      0 0 0 2px transparent;
  }

  &--danger {
    background-color: color(alert, danger);
    box-shadow: inset 0 0 0 1px color(alert, danger-border),
      0 0 0 2px transparent;
  }

  &--success {
    background-color: color(alert, success);
    box-shadow: inset 0 0 0 1px color(alert, success-border),
      0 0 0 2px transparent;
  }

  &--warning {
    background-color: color(alert, warning);
    box-shadow: inset 0 0 0 1px color(alert, warning-border),
      0 0 0 2px transparent;
  }

  &--info {
    background-color: color(alert, info);
    box-shadow: inset 0 0 0 1px color(alert, info-border), 0 0 0 2px transparent;
  }

  &--severity-critical {
    box-shadow: inset 0 0 0 1px color(severity, high), 0 0 0 2px transparent;
  }

  &--severity-high {
    box-shadow: inset 0 0 0 1px color(severity, high), 0 0 0 2px transparent;
  }

  &--severity-medium {
    box-shadow: inset 0 0 0 1px color(severity, medium), 0 0 0 2px transparent;
  }

  &--severity-low {
    box-shadow: inset 0 0 0 1px color(severity, low), 0 0 0 2px transparent;
  }
}
</style>
