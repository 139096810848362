<template>
  <span
    :aria-hidden="decorative"
    :aria-label="title"
    class="material-design-icon warning-icon"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg
      :fill="fillColor"
      :width="size"
      :height="size"
      class="material-design-icon__svg"
      viewBox="0 0 24 24"
    >
      <path
        d="M2.36 18.064c-0.84 1.42-0.16 2.57 1.52 2.57h16.24c1.68 0 2.36-1.14 1.52-2.57l-8.11-13.63c-0.85-1.42-2.21-1.43-3.060 0l-8.1 13.63zM12.91 17.914h-1.82v-1.82h1.82v1.82zM12.91 14.274h-1.82v-5.46h1.82v5.46z"
      ></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'WarningIcon',

  status: 'ready',

  props: {
    title: {
      type: String,
      default: 'Warning icon',
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
