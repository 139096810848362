var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseMultipleValueInput',_vm._b({staticClass:"vue--key-value-input",attrs:{"values":_vm.tags,"data":_vm.preparedData,"display-key":_vm.displayKey,"loading":_vm.isLoading,"placeholder":_vm.placeholder,"new-label-context":_vm.displayKey,"selection-only":_vm.selectionOnly,"restrict-characters":_vm.restrictCharacters,"character-limit":_vm.characterLimits[_vm.displayKey],"read-only":_vm.readOnly},on:{"create":_vm.handleCreation,"loading":_vm.setLoading,"query":_vm.setInputValue,"update":_vm.update,"opened":_vm.opened,"closed":_vm.closed},scopedSlots:_vm._u([{key:"chip",fn:function(ref){
var tag = ref.value;
var isRemovable = ref.isRemovable;
var removeValue = ref.removeValue;
return [_c('div',{staticClass:"vue--key-value-input__chip",class:{
        'shake-vertical-subtle': _vm.animateDuplicate === _vm.tagAsString(tag),
      },on:{"animationend":function($event){_vm.animateDuplicate = false}}},[_c('BaseChip',{attrs:{"value":tag,"text":((tag.key) + ":" + (tag.value)),"removable":isRemovable,"color":"orange"},on:{"click":function($event){return removeValue(tag)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return removeValue(tag)}}},[[_c('strong',[_vm._v(_vm._s(tag.key)+":")]),_vm._v(_vm._s(tag.value))]],2)],1)]}},{key:"preInput",fn:function(){return [(_vm.temporaryTag)?_c('span',{staticClass:"vue--key-value-input__temporary",attrs:{"data-snyk-test":"BaseTagsInput: pre input"}},[_c('strong',[_vm._v(_vm._s(_vm.temporaryTag.key)+":")])]):_vm._e()]},proxy:true},{key:"preItems",fn:function(){return [(!_vm.readOnly)?_c('strong',{attrs:{"data-snyk-test":"BaseTagsInput: pre items"}},[_vm._v("\n      Select a "+_vm._s(_vm.displayKey)+":\n    ")]):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item[_vm.displayKey])}})]}},{key:"postItems",fn:function(){return [(_vm.isEmpty)?_c('span',{staticClass:"vue--key-value-input__post-item"},[_vm._v("\n      Enter a new key\n    ")]):_vm._e(),_vm._v(" "),(_vm.isNewKey)?_c('span',{staticClass:"vue--key-value-input__post-item"},[_vm._v("\n      Enter a value for this key\n    ")]):_vm._e()]},proxy:true},_vm._l((_vm.$slots),function(_,slot){return {key:slot,fn:function(){return [_vm._t(slot)]},proxy:true}})],null,true)},'BaseMultipleValueInput',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }