import type { Route, CodeownerRoute } from '../types';

/**
 * ⚠️⚠️⚠️
 * REQUIRED VALIDATION WARNING:
 *
 * IF ADDING A NEW TYPE OF URL PARAMETER TO THE ROUTER, A CORRESPONDING
 * VALIDATION CHECK MUST BE ADDED IN `src/web/routes/vue-routes/index.ts`
 * ⚠️⚠️⚠️
 */

import { routes as OrgRoutes, codeownerRoutes as OrgOwners } from './org';
import {
  routes as OrgProjectsRoutes,
  codeownerRoutes as OrgProjectsOwners,
} from './org/projects';
import { routes as OrgImportLogRoutes } from './org/import-log';
import {
  routes as OrgProjectRoutes,
  codeownerRoutes as OrgProjectOwners,
} from './org/project';
import { routes as AccountRoutes } from './account';
import { routes as OrgAddRoutes } from './org/add';
import { routes as RequestAccessToOrgRoutes } from './request-access-to-org';
import { routes as RedHatRoutes } from './redhat';
import { routes as LoginRoutes } from './login';
import {
  routes as GroupEnterpriseReportingRoutes,
  codeownerRoutes as GroupEnterpriseReportingOwners,
} from './group/enterprise-reporting';
import {
  routes as GroupManageRoutes,
  codeownerRoutes as GroupManageOwners,
} from './group/manage';
import { routes as GroupManageBetaFeaturesRoutes } from './group/manage/beta-features';
import { routes as GroupManageMembersRoutes } from './group/manage/members';
import { routes as OrgManageMembersRoutes } from './org/manage/members';
import { codeownerRoutes as GroupReportsOwners } from './group/reports';
import { codeownerRoutes as GroupPoliciesOwners } from './group/policies';
import { codeownerRoutes as OrgIntegrationsOwners } from './org/integrations';
import { codeownerRoutes as OrgManageOwners } from './org/manage';
import { codeownerRoutes as OrgReportsOwners } from './org/reports';
import { codeownerRoutes as OrgSourcesOwners } from './org/sources';
import { codeownerRoutes as OrgFixOwners } from './org/fix';
import { codeownerRoutes as VulnOwners } from './vuln';
import { codeownerRoutes as TestOwners } from './test';

export const routes: Route[] = [
  ...OrgRoutes,
  ...OrgProjectsRoutes,
  ...OrgImportLogRoutes,
  ...OrgProjectRoutes,
  ...AccountRoutes,
  ...OrgAddRoutes,
  ...RequestAccessToOrgRoutes,
  ...RedHatRoutes,
  ...LoginRoutes,
  ...GroupEnterpriseReportingRoutes,
  ...GroupManageRoutes,
  ...GroupManageMembersRoutes,
  ...GroupManageBetaFeaturesRoutes,
  ...OrgManageMembersRoutes,
];

export const codeownerRoutes: CodeownerRoute[] = routes
  .map(({ path, sentryTeam }) => ({ path, sentryTeam }))
  .concat(
    GroupManageOwners,
    GroupEnterpriseReportingOwners,
    GroupReportsOwners,
    GroupPoliciesOwners,
    OrgProjectsOwners,
    OrgProjectOwners,
    OrgOwners,
    OrgIntegrationsOwners,
    OrgManageOwners,
    OrgReportsOwners,
    OrgSourcesOwners,
    OrgFixOwners,
    VulnOwners,
    TestOwners,
  );
