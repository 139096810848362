// These must live outside the actual permissions modules to avoid annoying circular references resulting from trying to reference the enum types
export enum GROUP_PERMISSIONS {
  READ = 'group.read',
  EDIT = 'group.edit',

  // Reports
  REPORT_READ = 'group.report.read',

  // Orgs
  ORG_ADD = 'group.org.add',
  ORG_REMOVE = 'group.org.remove',
  ORG_LIST = 'group.org.list',

  // Users
  USER_ADD = 'group.user.add',
  USER_READ = 'group.user.read',
  USER_REMOVE = 'group.user.remove',
  USER_ROLE_EDIT = 'group.user.role.edit',

  // User Provisioning
  PROVISION_USER = 'group.user.provision',

  // Service Accounts
  SERVICE_ACCOUNT_CREATE = 'group.service_account.create',
  SERVICE_ACCOUNT_READ = 'group.service_account.read',
  SERVICE_ACCOUNT_EDIT = 'group.service_account.edit',
  SERVICE_ACCOUNT_DELETE = 'group.service_account.delete',

  // Audit Logs
  AUDIT_LOG_READ = 'group.audit.read',

  // Settings
  SETTINGS_READ = 'group.settings.read',
  SETTINGS_EDIT = 'group.settings.edit',

  // Notification Settings
  NOTIFICATION_SETTINGS_READ = 'group.notification_settings.read',
  NOTIFICATION_SETTINGS_EDIT = 'group.notification_settings.edit',

  // Policies
  POLICY_READ = 'group.policy.read',
  POLICY_CREATE = 'group.policy.create',
  POLICY_EDIT = 'group.policy.edit',
  POLICY_DELETE = 'group.policy.delete',

  // Tags
  TAG_READ = 'group.tag.read',
  TAG_CREATE = 'group.tag.create',
  TAG_DELETE = 'group.tag.delete',

  // Flags
  FEATURE_FLAG_READ = 'group.flags.read',
  FEATURE_FLAG_EDIT = 'group.flags.edit',

  // Request Access Settings
  REQUEST_ACCESS_SETTINGS_READ = 'group.settings.request_access.read',
  REQUEST_ACCESS_SETTINGS_EDIT = 'group.settings.request_access.edit',
}

export enum ORG_PERMISSIONS {
  READ = 'org.read',
  EDIT = 'org.edit',
  DELETE = 'org.delete',
  TEST = 'org.test',

  // Reports
  REPORT_READ = 'org.report.read',

  // Settings
  SETTINGS_READ = 'org.settings.read',
  SETTINGS_EDIT = 'org.settings.edit',

  // Notifications
  NOTIFICATION_SETTINGS_READ = 'org.notification_settings.read',
  NOTIFICATION_SETTINGS_EDIT = 'org.notification_settings.edit',

  // Projects
  PROJECT_ADD = 'org.project.add',
  PROJECT_READ = 'org.project.read',
  PROJECT_EDIT = 'org.project.edit',
  PROJECT_REMOVE = 'org.project.remove',
  PROJECT_MONITOR = 'org.project.monitor',
  PROJECT_ACTIVATE = 'org.project.activate',
  PROJECT_DEACTIVATE = 'org.project.deactivate',
  PROJECT_TEST = 'org.project.test',
  PROJECT_MOVE = 'org.project.move',
  PROJECT_IMPORT_READ = 'org.project.import.read',

  // Project Settings
  PROJECT_SETTINGS_READ = 'org.project.settings.read',
  PROJECT_SETTINGS_EDIT = 'org.project.settings.edit',
  PROJECT_INTEGRATION_EDIT = 'org.project.integration.edit',

  // Project Issues and Ignores
  PROJECT_IGNORE_CREATE = 'org.project.ignore.create',
  PROJECT_IGNORE_READ = 'org.project.ignore.read',
  PROJECT_IGNORE_EDIT = 'org.project.ignore.edit',
  PROJECT_IGNORE_DELETE = 'org.project.ignore.delete',

  // Project PRs
  PROJECT_PR_CREATE = 'org.project.pr.create',
  PROJECT_PR_MARK_AS_SUCCESSFUL = 'org.project.pr.canMarkAsSuccessful',

  // Project Jira Issues
  PROJECT_JIRA_ISSUE_READ = 'org.project.jira.issue.read',
  PROJECT_JIRA_ISSUE_CREATE = 'org.project.jira.issue.create',

  // Project Attributes
  PROJECT_ATTRIBUTES_EDIT = 'org.project.attributes.edit',

  // Project Tags
  PROJECT_TAG_APPLY = 'org.project.tag.apply',
  PROJECT_TAG_REMOVE = 'org.project.tag.remove',

  // Targets
  TARGET_READ = 'org.target.read',

  // Service Accounts
  SERVICE_ACCOUNT_CREATE = 'org.service_account.create',
  SERVICE_ACCOUNT_READ = 'org.service_account.read',
  SERVICE_ACCOUNT_EDIT = 'org.service_account.edit',
  SERVICE_ACCOUNT_DELETE = 'org.service_account.delete',

  // Users
  USER_ADD = 'org.user.add',
  USER_INVITE = 'org.user.invite',
  USER_READ = 'org.user.read',
  USER_REMOVE = 'org.user.remove',
  USER_ROLE_EDIT = 'org.user.role.edit',
  USER_LEAVE = 'org.user.leave',

  // User Provisioning
  PROVISION_USER = 'org.user.provision',

  // Integrations
  INTEGRATION_CREATE = 'org.integration.create',
  INTEGRATION_READ = 'org.integration.read',
  INTEGRATION_EDIT = 'org.integration.edit',
  INTEGRATION_DELETE = 'org.integration.delete',

  // Integration Settings
  INTEGRATION_SETTINGS_READ = 'org.integration.settings.read',
  INTEGRATION_SETTINGS_EDIT = 'org.integration.settings.edit',

  // Packages
  PACKAGE_TEST = 'org.package.test',

  // Billing
  BILLING_READ = 'org.billing.read',
  BILLING_EDIT = 'org.billing.edit',

  // Entitlements
  ENTITLEMENTS_READ = 'org.entitlements.read',

  // Audit Logs
  AUDIT_LOG_READ = 'org.audit_log.read',

  // Flags
  FEATURE_FLAG_READ = 'org.flags.read',
  FEATURE_FLAG_EDIT = 'org.flags.edit',

  // Outbound webhooks
  OUTBOUND_WEBHOOK_READ = 'org.outbound_webhook.read',
  OUTBOUND_WEBHOOK_CREATE = 'org.outbound_webhook.create',
  OUTBOUND_WEBHOOK_DELETE = 'org.outbound_webhook.delete',

  // Request Access Settings
  REQUEST_ACCESS_SETTINGS_READ = 'org.settings.request_access.read',
  REQUEST_ACCESS_SETTINGS_EDIT = 'org.settings.request_access.edit',

  // IaC Settings
  IAC_SETTINGS_READ = 'org.iac.settings.read',
  IAC_SETTINGS_EDIT = 'org.iac.settings.edit',

  // SAST Settings
  SAST_SETTINGS_READ = 'org.sast.settings.read',
  SAST_SETTINGS_EDIT = 'org.sast.settings.edit',

  // Snyk Apps
  INSTALL_SNYK_APP = 'org.app.install',

  // Snyk PR Checks
  // marks Snyk Security checks as successful in a PR
  // Customers get here from the PR checks in their SCM PRs (when clicking on details).
  // And they can see a button there to skip the failed PR check so that they can merge their PR
  SNYK_PR_CHECK_SKIP = 'org.snyk_pr_checks.skip',
}
