<template>
  <button
    type="button"
    class="vue--button-group-button"
    :class="computedClasses"
    :style="computedStyles"
    :disabled="disabled"
    :aria-pressed="selected"
    :data-snyk-test="`BaseButtonGroupButton: ${value}`"
    @click="onClick"
  >
    <component
      :is="tooltipComponent"
      v-bind="tooltip"
      class="vue--button-group-button__tooltip"
    >
      <component
        :is="icon"
        v-if="icon"
        class="vue--button-group-button__icon"
      />
      <span v-if="label || note" class="vue--button-group-button__text">
        <span v-if="label" class="vue--button-group-button__label">
          {{ label }}
        </span>
        <span v-if="note" class="vue--button-group-button__note">
          {{ note }}
        </span>
      </span>
    </component>
  </button>
</template>

<script>
import BaseTooltip from '~/components/BaseTooltip/BaseTooltip';

import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseButtonGroupButton',
  props: {
    variant: {
      type: String,
      validator: isInList(['pill']),
      default: null,
    },
    icon: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      validator: isInList('large'),
      default: null,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    tooltip: {
      type: Object,
      default: null,
    },
    activeColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    computedClasses: function () {
      return {
        [`vue--button-group-button--${this.size}`]: !!this.size,
        [`vue--button-group-button--icon-only`]:
          this.icon && !this.label && !this.note,
        [`vue--button-group-button--${this.variant}`]: !!this.variant,
      };
    },
    computedStyles() {
      return {
        ...(this.activeColor ? { '--activeColor': `${this.activeColor}` } : {}),
      };
    },
    tooltipComponent() {
      return this.tooltip ? BaseTooltip : 'span';
    },
  },
  methods: {
    onClick: function () {
      this.$emit('click', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--button-group-button {
  $self: &;

  --activeColor: hsl(244, 40%, 45%);

  border-radius: 0 !important; // Override Snyk-UI
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: space(xxs) space();
  height: rem(38px);
  min-width: rem(44px);
  font-size: rem(15px);
  line-height: 1.15;
  transition: background ease 0.2s, color ease 0.2s, border-color ease 0.2s;
  cursor: pointer;
  position: relative;
  z-index: 1;

  ::v-deep svg {
    height: rem(20px);
  }
  &__icon {
    font-size: inherit;
  }
  &__text {
    display: grid;
    align-items: center;

    &:nth-child(2) {
      margin-left: rem(6px);
    }
  }
  &__label {
    display: block;
    text-align: center;
  }
  &__note {
    display: block;
    color: color(base, dimmed);
    font-size: rem(12px);
    text-align: center;
  }
  &__tooltip {
    display: flex;
    position: relative;
    z-index: 4;
  }

  &--icon-only {
    padding: space(xxs) 0;
  }
  &--large {
    height: rem(44px);
    min-width: rem(48px);
    font-size: rem(15px);

    #{$self}__text:nth-child(2) {
      margin-left: rem(8px);
    }

    ::v-deep svg {
      height: rem(24px);
    }
    #{$self}__note {
      font-size: rem(13px);
    }
  }

  &:focus {
    box-shadow: 0 0 0 1px var(--activeColor) !important; // Override of Snyk-UI
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px color(base, anchor);
  }

  &:hover {
    background: color(neutral, 96);
    z-index: 3;
  }

  &:disabled {
    cursor: no-drop;
    background: color(ui, white);
    color: color(disabled, text);
  }

  &:not(:last-child) {
    border-right: 1px solid color(neutral, 84);
  }

  &:first-child {
    border-top-left-radius: global(
      border-radius,
      micro
    ) !important; // Override Snyk-UI
    border-bottom-left-radius: global(
      border-radius,
      micro
    ) !important; // Override Snyk-UI
  }
  &:last-child {
    border-top-right-radius: global(
      border-radius,
      micro
    ) !important; // Override Snyk-UI
    border-bottom-right-radius: global(
      border-radius,
      micro
    ) !important; // Override Snyk-UI
  }

  &[aria-pressed='true'] {
    color: color(ui, white);
    background: var(--activeColor);
    border-color: transparent;
    box-shadow: 0 0 0 1px var(--activeColor);
    z-index: 2;

    &:focus-visible {
      box-shadow: 0 0 0 1px var(--activeColor), inset 0 0 0 2px color(ui, white);
    }

    &:disabled {
      background: color(disabled, text);
      box-shadow: 0 0 0 1px color(disabled, text);
    }

    #{$self}__note {
      color: color(neutral, 72);
    }
  }

  &--pill {
    border-radius: global(border-radius, round) !important; // Override Snyk-UI;
    height: rem(32px);
    padding: 0 space();

    &[aria-pressed='true'] {
      box-shadow: none;
    }

    &:disabled {
      background: transparent;
    }

    &:focus {
      box-shadow: none !important; // Override of Snyk-UI
    }

    &:not(:last-child) {
      border-right: none;
      margin-right: space(xxxs);
    }

    &:first-child,
    &:last-child {
      border-radius: global(
        border-radius,
        round
      ) !important; // Override Snyk-UI;
    }

    &#{$self}--large {
      height: rem(44px);
      min-width: rem(48px);
      font-size: rem(15px);
      padding: space(xxs) space(m);
    }
  }
}
</style>
