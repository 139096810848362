import * as debounce from 'lodash.debounce';
import * as orderBy from 'lodash.orderby';
import omit = require('lodash.omit');
import difference = require('lodash.difference');

import {
  ADD_POLICY,
  UPDATE_POLICIES,
  UPDATE_POLICY,
  DELETE_POLICY,
  SET_FILTERED_POLICIES,
} from '~/store/mutation-types';
import { request } from '~/lib/request';
import { queryMatch } from '~/lib/search';
import { ActionContext } from 'vuex';

export enum PolicyTypeName {
  LICENSE = 'license',
  SECURITY = 'security',
}

export enum PROJECT_CRITICALITY {
  CRITICAL = 'critical',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum PROJECT_ENVIRONMENT {
  FRONTEND = 'frontend',
  BACKEND = 'backend',
  INTERNAL = 'internal',
  EXTERNAL = 'external',
  MOBILE = 'mobile',
  SAAS = 'saas',
  ONPREM = 'onprem',
  HOSTED = 'hosted',
  DISTRIBUTED = 'distributed',
}

export enum PROJECT_LIFECYCLE {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
  SANDBOX = 'sandbox',
}

export interface ProjectAttributes {
  criticality: PROJECT_CRITICALITY[];
  environment: PROJECT_ENVIRONMENT[];
  lifecycle: PROJECT_LIFECYCLE[];
}

export interface Policy {
  publicId: string;
  type: PolicyTypeName;
  name: string;
  description?: string;
  configuration: any;
  orgs: string[];
  projectAttributes: ProjectAttributes;
  isDefault: boolean;
}

interface State {
  allPolicies: Record<string, Policy>;
  policies: Record<string, Policy>;
}

export default {
  namespaced: true,

  /**
   * example state:
   * state: {
   *   policies: {
   *     '123-123432-1234': {
   *       publicId: '123-123432-1234',
   *       type: 'license',
   *       name: '',
   *       descripton: '',
   *       configuration: {
   *         licenses: [{
   *           licenseType: 'MIT',
   *           severity: 'high',
   *           instructions: '',
   *         }],
   *       }
   *       orgs: ['123-1234-4321'],
   *     }
   *   },
   *   allPolicies: { same as policies },
   * }
   */
  state: {
    allPolicies: {}, // do not mutate me 🙏
    policies: {},
    errors: [],
  } as State,

  getters: {
    allPolicies: (state: State) => {
      return state.allPolicies;
    },
  },

  actions: {
    async getPoliciesByType(
      { dispatch, commit }: ActionContext<State, any>,
      { groupPublicId, type, searchQuery = '' },
    ) {
      const url = `/group/${groupPublicId}/policies/api/${type}`;
      const response = await request(url);
      const { policies } = await response.json();

      commit(UPDATE_POLICIES, policies);
      dispatch('filterPolicies', {
        searchQuery,
      });
    },

    async addPolicy(
      { dispatch, commit }: ActionContext<State, any>,
      { baseUrl, groupPublicId, payload, searchQuery = '' },
    ) {
      const safeBaseUrl = baseUrl || '';
      const url = `${safeBaseUrl}/group/${groupPublicId}/policies/api/add`;
      const response = await request(url, {
        method: 'POST',
        body: payload,
      }).catch((e) => {
        throw new Error(e.body?.message || e.message);
      });
      const newPolicy = await response.json();

      commit(ADD_POLICY, newPolicy);
      dispatch('filterPolicies', {
        searchQuery,
      });
    },

    async editPolicy(
      { commit }: ActionContext<State, any>,
      { baseUrl, groupPublicId, payload },
    ) {
      const safeBaseUrl = baseUrl || '';
      const url = `${safeBaseUrl}/group/${groupPublicId}/policies/api/edit`;
      const response = await request(url, {
        method: 'POST',
        body: payload,
      }).catch((e) => {
        throw new Error(e.body?.message || e.message);
      });
      const newPolicy = await response.json();

      commit(UPDATE_POLICY, newPolicy);
    },

    async deletePolicy(
      { commit }: ActionContext<State, any>,
      { baseUrl, groupPublicId, payload },
    ) {
      const safeBaseUrl = baseUrl || '';
      const url = `${safeBaseUrl}/group/${groupPublicId}/policies/api/delete`;
      await request(url, { method: 'POST', body: payload }).catch((e) => {
        throw new Error(e.body?.message || e.message);
      });

      commit(DELETE_POLICY, payload.policy);
    },

    /**
     * Reorder policies in order of priority
     *
     * This fn is debounced to reduce server requests, however, since the page
     * is reloaded on navigation, we should still keep this short to make sure
     * that the request is run before a reload occurs.
     */
    reorderPoliciesDebounced: debounce(
      ({ dispatch }: ActionContext<State, any>, { callback, ...payload }) =>
        callback(dispatch('reorderPolicies', payload)),
      500,
    ),

    async reorderPolicies(
      // eslint-disable-next-line no-empty-pattern
      {}: ActionContext<State, any>,
      {
        baseUrl,
        groupPublicId,
        policyType,
        policies,
      }: {
        baseUrl: string;
        groupPublicId: string;
        policyType: string;
        policies: Policy[];
      },
    ) {
      const safeBaseUrl = baseUrl || '';
      const url = `${safeBaseUrl}/group/${groupPublicId}/policies/api/${policyType}/reorder`;
      const orderedPolicies = orderBy(
        Object.values(policies),
        ['isDefault', 'priorityIndex'],
        ['asc', 'desc'],
      );

      await request(url, {
        method: 'POST',
        body: {
          orderedPolicyIds: orderedPolicies.map((p) => p.publicId),
        },
      }).catch((e) => {
        throw new Error(e.body?.message || e.message);
      });
    },

    updatePolicies(
      { dispatch, commit }: ActionContext<State, any>,
      { searchQuery = '', policies },
    ) {
      commit(UPDATE_POLICIES, policies);
      dispatch('filterPolicies', {
        searchQuery,
      });
    },

    filterPolicies(
      { state, commit }: ActionContext<State, any>,
      { searchQuery },
    ) {
      const searchBase = searchQuery ? state.policies : state.allPolicies;
      let filteredPolicies = orderBy(
        Object.values(searchBase),
        ['isDefault', 'priorityIndex'],
        ['asc', 'desc'],
      );

      // Filter by search query
      if (searchQuery) {
        filteredPolicies = filteredPolicies.filter((policy) =>
          queryMatch(policy.name, searchQuery),
        );
      }

      // Revert policies array back to the format state expects for
      const mappedPolicies = {};
      filteredPolicies.forEach((policy) => {
        mappedPolicies[policy.publicId] = policy;
      });

      commit(SET_FILTERED_POLICIES, mappedPolicies);
    },
  },

  mutations: {
    [ADD_POLICY](state: State, newPolicy: Policy) {
      // loop through existing policies and remove orgs which
      // were applied to the new policy (if any)
      for (const policyObj of Object.values(state.policies)) {
        state.policies[policyObj.publicId].orgs = difference(
          policyObj.orgs,
          newPolicy.orgs,
        );
      }

      state.policies = { ...state.policies, [newPolicy.publicId]: newPolicy };
      state.allPolicies = { ...state.policies };
    },
    [UPDATE_POLICIES](state: State, policies: Policy[] = []) {
      const mappedPolicies = {};
      policies.forEach((policy) => {
        mappedPolicies[policy.publicId] = policy;
      });

      state.policies = { ...state.policies, ...mappedPolicies };
      state.allPolicies = { ...state.policies };
    },
    [UPDATE_POLICY](state: State, updatedPolicy: Policy) {
      const tempPolicies = {};

      // loop through existing policies and remove orgs which
      // were applied to the updated policy (if any)
      for (const policyObj of Object.values(state.policies)) {
        if (policyObj.publicId !== updatedPolicy.publicId) {
          policyObj.orgs = difference(policyObj.orgs, updatedPolicy.orgs);
          tempPolicies[policyObj.publicId] = { ...policyObj };
        } else {
          tempPolicies[policyObj.publicId] = { ...updatedPolicy };
        }
      }

      state.policies = { ...tempPolicies };
      state.allPolicies = { ...tempPolicies };
    },
    [DELETE_POLICY](state: State, policy: Policy) {
      state.policies = omit(state.policies, policy.publicId);
      state.allPolicies = { ...state.policies };
    },
    [SET_FILTERED_POLICIES](state: State, mappedPolicies) {
      state.policies = mappedPolicies;
    },
  },
};
