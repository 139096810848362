<template>
  <div
    :class="computedClasses"
    class="vue--score"
    :data-snyk-ignore-wcag2aa="ignoreWcag2aa"
  >
    <span class="vue--score__body">
      <slot />
    </span>
  </div>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export const scoreVariants = [
  'severity-critical',
  'severity-high',
  'severity-medium',
  'severity-low',
  'severity-none',
];

export default {
  name: 'BaseScore',

  status: 'ready',

  props: {
    /**
     * Variant of score to render. Available modifiers:
     * `severity-high`, `severity-medium`, `severity-low`, `severity-none`.
     */
    variant: {
      type: String,
      default: 'severity-none',
      validator: isInList(scoreVariants),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--score--${this.variant}`]: true,
      };
    },
    ignoreWcag2aa() {
      // Design decision - medium severity must be orange (not brown) and there's
      // more wiggle room for incidental information
      return this.variant === 'severity-medium';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--score {
  $self: &;

  @include font-stack(regular);

  background: transparent;
  border: 1px solid transparent;
  border-radius: 50%;
  color: color(ui, white);
  cursor: inherit;
  display: inline-block;
  font-size: rem(18px);
  width: rem(48px);
  height: rem(48px);
  position: relative;
  white-space: nowrap;
  font-feature-settings: 'pnum';

  &__body {
    position: absolute;
    display: flex;
    top: 1px;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
  &--severity-critical {
    background-color: color(severity, critical);
    border-color: color(severity, critical);
  }
  &--severity-high {
    background-color: color(severity, high);
    border-color: color(severity, high);
  }
  &--severity-medium {
    background-color: color(severity, medium);
    border-color: color(severity, medium);
  }
  &--severity-low {
    background-color: color(severity, low);
    border-color: color(severity, low);
  }
  &--severity-none {
    background-color: color(neutral, 72);
    border-color: color(neutral, 72);
    color: color(base, body);
  }
}
</style>
