import { ORG_PERMISSIONS } from '../../../permissions/defs';
import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName/project/:projectId/pr-check/:prCheckId/',
    component: 'PRCheck/PRCheck',
    sentryTeam: 'rhino',
    meta: {
      title: 'PR Check',
      routeName: 'PRCheck',
      pageDesc: 'Snyk PR Check results',
      requirements: {
        page: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_IMPORT_READ,
          ],
        },
      },
    },
  },
  {
    path: '/org/:orgName/project/:projectId/pr-check/:prCheckId/license',
    component: 'PRCheck/PRCheck',
    sentryTeam: 'rhino',
    meta: {
      title: 'PR Check (License)',
      routeName: 'PRCheck',
      pageDesc: 'Snyk PR Check license results',
      requirements: {
        page: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_IMPORT_READ,
          ],
        },
      },
    },
  },
];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/org/:orgName/project/:projectId/settings/integration/defaults/jira',
    sentryTeam: 'governance',
  },
  {
    path: '/org/:orgName/project/:projectId/settings/integration/:path*',
    sentryTeam: 'comet',
  },
  {
    path: '/org/:orgName/project/:path*',
    sentryTeam: 'unicorn',
  },
];
