import {
  SET_IMPORTING_SUBSCRIPTION,
  START_FREE_TRIAL,
  PLAN_CHANGED,
  PURCHASE_CANCELED,
  PLAN_AUTO_RENEWAL_CANCELED,
  BILLING_DETAILS,
  PURCHASE_CANCELED_FAILED,
  PLAN_AUTO_RENEWAL_CANCELED_FAILED,
} from '~/store/mutation-types';
import { request } from '~/lib/request';
import { Vue } from 'vue-property-decorator';
import type {
  BaseCommandResult,
  CommandResult,
  FreeTrialCommand,
  State,
} from './billing-store/models';
import type {
  BillingDetails,
  PendingSubscription,
  Subscription,
} from 'shared/billing/models';

const defaultState: State = {
  billingDetails: null,
  isImportingSubscription: false,
  planChanged: null,
  purchaseCancelled: null,
  planAutoRenewalCancelled: null,
};

export const fourteenDaysAheadIso = () => {
  const now = new Date();
  now.setDate(now.getDate() + 14);
  const [formattedDate] = now.toISOString().split('T');
  return formattedDate;
};

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    freeTrialStarted(state: State) {
      return state.freeTrialStarted;
    },
    billingDetails(state: State) {
      return state.billingDetails;
    },
    planChanged(state: State) {
      return state.planChanged;
    },
    purchaseCancelled(state: State) {
      return state.purchaseCancelled;
    },
    planAutoRenewalCancelled(state: State) {
      return state.planAutoRenewalCancelled;
    },
  },

  actions: {
    async setFreeTrial({ commit }, { snykPlatformSubscriberId }) {
      try {
        const response = await request(`billing/free-trial`, {
          method: 'POST',
          body: {
            snykPlatformSubscriberId,
          },
        });

        const { subscription } = await response.json();

        commit(START_FREE_TRIAL, {
          ok: true,
          payload: { subscription },
        });
      } catch (error) {
        commit(START_FREE_TRIAL, {
          ok: false,
          error: {
            name: 'RequestError',
            requestId: error.requestId,
            statusCode: error.statusCode,
            safeUserMessage: error.safeUserMessage,
          },
        });
      }
    },
    async cancelPlan({ commit }, { type }) {
      try {
        await request(`billing/cancel`, {
          method: 'POST',
          body: { cancellationType: type },
        });
        const commitType =
          type === 'RENEWAL' ? PLAN_AUTO_RENEWAL_CANCELED : PURCHASE_CANCELED;
        commit(commitType, { ok: true });
      } catch (error) {
        const failureType =
          type === 'RENEWAL'
            ? PLAN_AUTO_RENEWAL_CANCELED_FAILED
            : PURCHASE_CANCELED_FAILED;

        commit(failureType, {
          ok: false,
          error: {
            name: 'RequestError',
            requestId: error.requestId,
            statusCode: error.statusCode,
            safeUserMessage: error.safeUserMessage,
          },
        });
      }
    },
    async getBillingDetails({ commit }) {
      try {
        commit(SET_IMPORTING_SUBSCRIPTION, true);
        const res = await request(`billing/details`);
        commit(BILLING_DETAILS, await res.json());
      } catch (error) {
        commit(BILLING_DETAILS, {
          error: {
            name: 'RequestError',
            requestId: error.requestId,
            statusCode: error.statusCode,
            safeUserMessage: error.safeUserMessage,
          },
        });
      }
    },
    changePlan({ commit }) {
      // TODO Implement plan change functionality https://snyksec.atlassian.net/browse/BOLT-552
      commit(PLAN_CHANGED, { ok: true });
    },
    cancelPurchase({ commit }) {
      // TODO Implement cancel purchase functionality https://snyksec.atlassian.net/browse/BOLT-552
      commit(PURCHASE_CANCELED, { ok: true });
    },
  },

  mutations: {
    [START_FREE_TRIAL](
      state: State,
      freeTrialStarted: CommandResult<FreeTrialCommand>,
    ) {
      // NB: set is used because the reactivity does not work properly
      // when using assignment operator causing stale getter values
      if (!freeTrialStarted.ok) {
        Vue.set<BaseCommandResult>(state, 'freeTrialStarted', {
          error: freeTrialStarted.error,
          ok: false,
        });
        return;
      }
      Vue.set<BaseCommandResult>(state, 'freeTrialStarted', {
        ok: true,
        error: undefined,
      });

      Vue.set<Subscription>(
        state.billingDetails!,
        'subscription',
        freeTrialStarted.payload!.subscription,
      );
      Vue.set<boolean>(state.billingDetails!, 'isEligibleForFreeTrial', false);
    },
    [BILLING_DETAILS](state: State, billingDetails: BillingDetails) {
      state.billingDetails = billingDetails;
    },
    [SET_IMPORTING_SUBSCRIPTION](
      state: State,
      isImportingSubscription: boolean,
    ) {
      state.isImportingSubscription = isImportingSubscription;
    },
    [PLAN_CHANGED](state: State, planChanged: boolean) {
      state.planChanged = planChanged;
    },
    [PURCHASE_CANCELED](state: State, purchaseCancelled: boolean) {
      state.purchaseCancelled = purchaseCancelled;

      const pendingSubscription = {
        startDate: fourteenDaysAheadIso(),
        planName: 'Free',
        planCode: 'free',
        developers: 0,
      };
      Vue.set<Subscription>(state.billingDetails!, 'subscription', {
        ...state.billingDetails!.subscription,
        state: 'canceled',
        pendingSubscription,
      });
      Vue.set<PendingSubscription>(
        state.billingDetails!,
        'pendingSubscription',
        pendingSubscription,
      );
    },
    [PLAN_AUTO_RENEWAL_CANCELED](
      state: State,
      planAutoRenewalCancelled: boolean,
    ) {
      state.planAutoRenewalCancelled = planAutoRenewalCancelled;
    },
  },
};
