<template>
  <div class="vue--account-panel-link">
    <router-link
      v-if="routerAvailable && !isServerRouted"
      :to="path"
      :class="computedClasses"
      data-snyk="spa-routed"
      :data-snyk-test="`TheAccountPanelLink: ${label}`"
      v-on="listeners"
      @click.native="click"
    >
      {{ label }}
    </router-link>
    <a
      v-else
      :class="computedClasses"
      :href="path"
      :data-snyk-test="`TheAccountPanelLink: ${label}`"
      v-on="listeners"
      >{{ label }}</a
    >
  </div>
</template>

<script>
export default {
  name: 'TheAccountPanelLink',
  props: {
    path: {
      type: String,
      default: '/unset',
    },
    label: {
      type: String,
      default: 'Unlabelled link',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isServerRouted: {
      type: Boolean,
      default: false,
    },
    exactMatch: {
      type: Boolean,
      default: false,
    },
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        'vue--account-panel-link__link': true,
        [`vue--account-panel-link__link--inverted`]: this.inverted,
      };
    },
    routerAvailable() {
      return !!this.$router;
    },
    listeners() {
      return {
        focus: () => this.parentEmit('focusedIn'),
        focusout: () => this.parentEmit('focusedOut'),
        keyup: (e) => {
          if (e.keyCode === 'enter' || e.keyCode === 'space') {
            this.parentEmit('click');
          }
        },
        click: (e) => this.click(e),
      };
    },
  },
  methods: {
    parentEmit(name) {
      this.$parent.$emit(name, this.value);
    },
    click() {
      this.parentEmit('click');

      /**
       * Triggered on item click.
       */
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';
.vue--account-panel-link {
  &__link {
    font-size: rem(15px);
    display: block;
    padding: space(xs) space(l);

    @include media-query(m) {
      padding: space(xs) space(default) space(xs) space(m);
    }

    &:focus-visible {
      outline: global(focusState, outline);
    }

    &--inverted {
      color: color(ui, white);
      opacity: 0.8;

      &:hover {
        color: color(ui, white);
        opacity: 1;
        text-decoration: none;
      }

      &:focus-visible {
        outline: 2px solid color(ui, white);
      }
    }
  }
}
</style>
