import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/group/:groupPublicId/policies/:path*',
    sentryTeam: 'governance',
  },
];
