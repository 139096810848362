export = function errorHandler(error) {
  // eslint-disable-next-line no-console
  console.error(error);

  if ((window as any).Sentry) {
    (window as any).Sentry.captureException(error);
  }

  // Re-throw error for other components to catch.
  throw error;
};
