import {
  SET_PROJECT_GROUPS,
  SET_LATEST_PROJECT_GROUPS,
  SET_IMPORT_STATUS,
  SET_IS_IMPORTING,
  SET_IS_ERRORING,
  SET_RETAINED_STATE,
  SET_INITIAL_PROJECTS_STATE,
  INITIALISE_PROJECTS_STORE,
} from '~/store/mutation-types';
import errorHandler from '~/lib/error-handler';
import { request } from '~/lib/request';
import retainedStateLib from '~/lib/retained-state';

let importTimeout;
let targettedImportTimeout;
let importCompletionCounter = 0;

export default {
  namespaced: true,

  state: {
    org: null,
    totalProjects: 0,
    projectsSoftLimit: 0,
    projectsHardLimit: 0,
    filters: {},
    availableSorting: null,
    initialSelectedSorting: null,
    importIntegrations: [],
    hasOnlyGithubIntegration: false,
    latestProjectGroups: [],
    projectGroups: [],
    testLimitEndDate: '',
    hasGithubToken: true,
    privateTestsPermitted: true,
    importStatus: null,
    isImporting: false,
    isErroring: false,
    retainedState: {
      openFirstProjectsGroup: true,
      showFiltersOnload: true,
      showLastImportAlert: true,
      openFilters: null,
      filters: null,
      sorting: null,
    },
    showSastSettings: { show: false },
    showPullRequestsAssignees: { show: false, origin: '' },
    showBacklogPullRequests: { show: false, origin: '' },
  },

  getters: {
    totalProjects: (state) => state.totalProjects,
    projectsSoftLimit: (state) => state.projectsSoftLimit,
    projectsHardLimit: (state) => state.projectsHardLimit,
    availableSorting: (state) => state.availableSorting,
    filters: (state) => state.filters,
    initialSelectedSorting: (state) => state.initialSelectedSorting,
    importIntegrations: (state) => state.importIntegrations,
    hasOnlyGithubIntegration: (state) => state.hasOnlyGithubIntegration,
    latestProjectGroups: (state) => state.latestProjectGroups,
    projectGroups: (state) => state.projectGroups,
    testLimitEndDate: (state) => state.testLimitEndDate,
    hasGithubToken: (state) => state.hasGithubToken,
    privateTestsPermitted: (state) => state.privateTestsPermitted,
    importStatus: (state) => state.importStatus,
    isImporting: (state) => state.isImporting,
    retainedState: (state) => state.retainedState,
    showSastSettings: (state) => state.showSastSettings,
    showPullRequestsAssignees: (state) => state.showPullRequestsAssignees,
    showBacklogPullRequests: (state) => state.showBacklogPullRequests,
  },

  actions: {
    async getInitialState({ state, commit, dispatch }, { org }) {
      try {
        clearTimeout(importTimeout);
        commit('INITIALISE_PROJECTS_STORE', org);

        const response = await request(`/org/${org.name}/projects/state`, {
          method: 'POST',
          body: {
            filters: state.retainedState.filters,
            sorting: state.retainedState.sorting,
          },
        });
        const json = await response.json();

        commit('SET_INITIAL_PROJECTS_STATE', json);

        // Order is important here. SET_RETAINED_STATE uses
        // state.availableFilters which is set by SET_INITIAL_PROJECTS_STATE.
        commit('SET_RETAINED_STATE', {
          filters: json.filters.selected,
          sorting: json.initialSelectedSorting,
        });

        await dispatch('getImportStatus', {
          org,
          isSessionImport: false,
        });
      } catch (e) {
        errorHandler(e);
      }
    },
    async getProjectGroups({ commit }, { org, filters, sorting, searchQuery }) {
      try {
        commit('SET_LATEST_PROJECT_GROUPS', []);

        const response = await request(`/org/${org.name}/projects/groups`, {
          method: 'POST',
          body: {
            searchQuery,
            filters,
            sorting,
          },
        });
        const json = await response.json();

        commit('SET_RETAINED_STATE', {
          filters,
          sorting,
        });

        commit('SET_PROJECT_GROUPS', json.projectGroups);
      } catch (e) {
        errorHandler(e);
      }
    },
    async getImportStatus(
      { commit, dispatch },
      { org, isSessionImport = true },
    ) {
      try {
        const response = await request(
          `/org/${org.name}/import-log/latest/progress`,
        );
        const json = await response.json();

        if (json.error) {
          commit('SET_IS_ERRORING', true);
        } else {
          commit('SET_IS_ERRORING', false);

          if (json.state === 'complete' && isSessionImport) {
            commit('SET_IS_IMPORTING', false);
            dispatch('getLatestGroups', {
              org,
            });
          }

          if (json.state === 'pending') {
            commit('SET_IS_IMPORTING', true);
            commit('SET_RETAINED_STATE', {
              showLastImportAlert: true,
            });

            importTimeout = setTimeout(() => {
              dispatch('getImportStatus', { org });
            }, 3000);
          }

          if (
            json.state === 'pending' &&
            importCompletionCounter < json.complete
          ) {
            importCompletionCounter = json.complete;
            dispatch('getLatestGroups', {
              org,
            });
          }

          commit('SET_IMPORT_STATUS', json);
        }
      } catch (err) {
        commit('SET_IS_ERRORING', true);
        importTimeout = setTimeout(() => {
          dispatch('getImportStatus', { org });
        }, 3000);
      }
    },
    async watchNewTargetImport({ commit, dispatch }, { org, group }) {
      commit('SET_IS_IMPORTING', true);
      // Allow some time for the import to begin, and then start watching for
      // status changes. We do not currently have the ability to associate an
      // import job with the initiating action, so this is a "best guess"
      // message, and race conditions here are unavoidable.
      setTimeout(() => {
        dispatch('getTargetedImportStatus', { org, group });
      }, 3000);
    },
    async getTargetedImportStatus({ state, commit, dispatch }, { org, group }) {
      try {
        const response = await request(
          `/org/${org.name}/import-log/latest/progress`,
        );
        const json = await response.json();

        commit('SET_IS_ERRORING', false);

        if (json.state === 'complete') {
          clearTimeout(targettedImportTimeout);
          dispatch('getProjectGroups', {
            org: state.org,
            filters: state.retainedState.filters,
            sorting: state.retainedState.sorting,
          });
          commit('SET_IS_IMPORTING', false);
          commit('SET_IMPORT_STATUS', json);
          commit('SET_RETAINED_STATE', {
            showLastImportAlert: true,
          });
        } else if (json.state === 'failed') {
          clearTimeout(targettedImportTimeout);
          commit('SET_IS_IMPORTING', false);
          commit('SET_IMPORT_STATUS', json);
          commit('SET_RETAINED_STATE', {
            showLastImportAlert: true,
          });
        } else if (json.state === 'pending') {
          commit('SET_IS_IMPORTING', true);
          targettedImportTimeout = setTimeout(() => {
            dispatch('getTargetedImportStatus', { org, group });
          }, 3000);
        }
      } catch (err) {
        commit('SET_IS_ERRORING', true);
        targettedImportTimeout = setTimeout(() => {
          dispatch('getTargetedImportStatus', { org, group });
        }, 3000);
      }
    },
    async getLatestGroups({ commit }, { org }) {
      try {
        const response = await request(
          `/org/${org.name}/projects/latest-groups`,
        );
        const json = await response.json();

        const latestProjectGroups = json.latestProjectGroups.map((group) => {
          group.label = 'New';
          return group;
        });

        commit('SET_LATEST_PROJECT_GROUPS', latestProjectGroups);
      } catch (e) {
        errorHandler(e);
      }
    },
  },

  mutations: {
    [SET_PROJECT_GROUPS](state, projectGroups) {
      state.projectGroups = projectGroups;
    },
    [SET_LATEST_PROJECT_GROUPS](state, latestProjectGroups) {
      const projectGroupIds = state.projectGroups
        .map((group) => group.id)
        .reduce((acc, id) => {
          acc[id] = true;
          return acc;
        }, {});

      state.latestProjectGroups = latestProjectGroups
        ? latestProjectGroups.filter(
            (latestGroup) => !projectGroupIds[latestGroup.id],
          )
        : [];
    },
    [SET_IMPORT_STATUS](state, importStatus) {
      state.importStatus = importStatus;
    },
    [SET_IS_IMPORTING](state, isImporting) {
      state.isImporting = isImporting;
    },
    [SET_IS_ERRORING](state, isErroring) {
      state.isErroring = isErroring;
    },
    [SET_RETAINED_STATE](state, retainedState) {
      const key = { page: 'projects', concept: state.org.id };
      state.retainedState = retainedStateLib.set(key, {
        ...state.retainedState,
        ...retainedState,
      });
    },
    [INITIALISE_PROJECTS_STORE](state, org) {
      state.org = org;

      const retainedStateKey = { page: 'projects', concept: state.org.id };
      let retainedState = retainedStateLib.get(retainedStateKey);

      // 2021-02-01: Added temporary check to migrate retainedState from org name to org ID
      // which we should be safe to remove in a few months time
      if (retainedState === null) {
        const oldRetainedStateKey = {
          page: 'projects',
          concept: state.org.name,
        };
        retainedState = retainedStateLib.get(oldRetainedStateKey);
        if (retainedState !== null) {
          retainedStateLib.set(retainedStateKey, retainedState);
          retainedStateLib.delete(oldRetainedStateKey);
        }
      }

      if (retainedState) {
        state.retainedState = retainedState;
      } else {
        retainedStateLib.set(retainedStateKey, state.retainedState);
      }
    },
    [SET_INITIAL_PROJECTS_STATE](state, initialState) {
      state.totalProjects = initialState.totalProjects;
      state.projectsSoftLimit = initialState.projectsSoftLimit;
      state.projectsHardLimit = initialState.projectsHardLimit;
      state.filters = initialState.filters;
      state.availableSorting = initialState.availableSorting;
      state.initialSelectedSorting = initialState.initialSelectedSorting;
      state.importIntegrations = initialState.importIntegrations;
      state.hasOnlyGithubIntegration = initialState.hasOnlyGithubIntegration;
      state.projectGroups = initialState.projectGroups;
      state.testLimitEndDate = initialState.testLimitEndDate;
      state.hasGithubToken = initialState.hasGithubToken;
      state.privateTestsPermitted = initialState.privateTestsPermitted;
      state.showSastSettings = initialState.showSastSettings;
      state.showPullRequestsAssignees = initialState.showPullRequestsAssignees;
      state.showBacklogPullRequests = initialState.showBacklogPullRequests;
    },
  },
};
