<template>
  <div ref="org-list" class="vue--org-switcher-org-list">
    <BaseNoResults
      v-if="hasError"
      class="margin-top--m"
      preset-graphic="failure"
      title="Failed to fetch orgs"
    >
      <p>
        Please
        <BaseButton variant="link" size="small" @click="fetchOrgs"
          >try again</BaseButton
        >. If this problem persists, please contact
        <a href="mailto:support@snyk.io">support</a>.
      </p>
    </BaseNoResults>
    <template v-else>
      <BaseSearchInput
        v-if="showSearch"
        v-model="searchQuery"
        placeholder="Search organizations…"
        aria-label="Search organizations…"
        size="small"
        class="margin-bottom--xs"
        data-snyk-test="TheOrgSwitcherOrgList: search"
        @change="filterSearch"
      />
      <VirtualScroller
        wrapper="ul"
        spacer-tag="li"
        class="vue--org-switcher-org-list__list"
        :predicted-item-height="47"
        :container="$el"
        aria-live="polite"
      >
        <li
          v-if="showGroupOverview"
          class="vue--org-switcher-org-list__item"
          data-snyk-test="TheOrgSwitcherOrgList: group overview"
        >
          <BaseAnchor
            class="
              vue--org-switcher-org-list__link
              vue--org-switcher-org-list__group-overview
            "
            :href="groupOverviewUrl"
            variant="plain"
          >
            {{ activeGroup.name }}
            <BaseBadge
              variant="warning"
              size="small"
              class="vue--org-switcher-org-list__group-overview__badge"
              >Group Overview</BaseBadge
            >
          </BaseAnchor>
        </li>

        <li
          v-for="org in orgs"
          :key="org.id"
          class="vue--org-switcher-org-list__item"
          :data-snyk-test="orgTestHook(org)"
        >
          <div
            v-if="org.loading"
            class="vue--org-switcher-org-list__org-loading"
          >
            <BaseSkeleton :width="160" :height="22" />
          </div>
          <BaseAnchor
            v-else
            class="vue--org-switcher-org-list__link"
            :href="orgUrl(org)"
            variant="plain"
            @click.prevent="onOrgSelected(org)"
          >
            {{ org.displayName }}
          </BaseAnchor>
        </li>

        <li
          v-if="showLoadingSingleton"
          class="vue--org-switcher-org-list__item"
        >
          <div class="vue--org-switcher-org-list__org-loading">
            <BaseSkeleton :width="160" :height="22" />
          </div>
        </li>
      </VirtualScroller>

      <BaseNoResults
        v-if="noSearchOrgs"
        class="margin-top--m"
        :title="noSearchOrgsText"
      />
      <BaseNoResults
        v-else-if="noOrgs"
        class="margin-top--m"
        :title="noOrgsText"
      />
    </template>
  </div>
</template>

<script>
import { request } from '~/lib/request';
import { createDebounce } from '~/lib/debounce';
import qs from 'qs';
import VirtualScroller from '~/components/VirtualScroller/VirtualScroller';
import { itly } from '~/lib/analytics';

const CACHE_EXPIRY = 1000 * 60; // Don't re-fetch identical urls for 1 min

export default {
  name: 'TheOrgSwitcherOrgList',
  components: {
    VirtualScroller,
  },
  inject: ['org'],
  props: {
    activeGroup: {
      type: Object,
    },
    activeGroupIsPersonal: {
      type: Boolean,
      required: true,
    },
    urlPatterns: {
      type: Object,
    },
    search: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      setDebounce: createDebounce(),
      showLoadingSingleton: true,
      hasError: false,
      orgs: [],
      orgUrlPattern: '',
      limit: 100,
      searchQuery: this.search,
      activeSearchQuery: '',
    };
  },

  computed: {
    orgsCount() {
      return this.activeGroup?.orgsCount;
    },
    noOrgs() {
      if (this.isLoading) {
        return false;
      }
      return this.activeGroup?.orgsCount === 0;
    },
    noSearchOrgs() {
      if (this.showLoadingSingleton || !this.searchQuery) {
        return false;
      }
      return this.orgs.length === 0;
    },
    activeGroupId() {
      return this.activeGroup?.id;
    },
    showGroupOverview() {
      return !this.searchQuery && this.activeGroup?.showGroupOverview;
    },
    showSearch() {
      return this.activeGroup?.orgsCount > 0 || this.searchQuery;
    },
    groupOverviewUrl() {
      return this.urlPatterns.groupOverview.replace(':id', this.activeGroupId);
    },
    noSearchOrgsText() {
      return `No organizations found that match “${this.activeSearchQuery}”.`;
    },
    noOrgsText() {
      return this.activeGroupIsPersonal
        ? 'You have no personal organizations.'
        : `No organizations within the group “${this.activeGroup.name}”.`;
    },
  },

  watch: {
    async activeGroup() {
      this.resetScroll();
      await this.fetchOrgs();
    },
  },

  async mounted() {
    await this.fetchOrgs();
  },

  methods: {
    orgUrl(org) {
      return this.orgUrlPattern.replace(':name', org.name);
    },
    orgTestHook(org) {
      return `TheOrgSwitcherOrgList: org ${org.loading ? 'loading' : org.name}`;
    },
    async fetchOrgs() {
      this.showLoadingSingleton = true;
      this.hasError = false;
      if (!this.activeGroup) {
        return;
      }

      if (!this.searchQuery) {
        // We know the number of orgs we expect to receive in advance
        this.orgs = new Array(this.orgsCount).fill({ loading: true });
      }

      const context = {
        activeGroupId: this.activeGroupId,
        activeGroupIsPersonal: this.activeGroupIsPersonal,
        searchQuery: this.searchQuery,
      };

      const baseUrl = `/org-switcher/${
        this.activeGroupIsPersonal ? 'personal' : `group/${this.activeGroupId}`
      }`;

      const query = { limit: this.limit };

      if (this.searchQuery) {
        query.search = this.searchQuery;
      }
      if (!this.searchQuery) {
        // Show the list of loading orgs instead
        this.showLoadingSingleton = false;
      }

      const url = `${baseUrl}?${qs.stringify(query)}`;

      await this.fetchPage(context, url);
    },
    async fetchPage(context, url, page = 1) {
      try {
        const response = await request(url, {
          cache: true,
          cacheExpiry: CACHE_EXPIRY,
        });

        const contextHasChanged = Object.keys(context).some(
          (key) => context[key] !== this[key],
        );

        if (contextHasChanged) {
          // Context has changed, don't overwrite orgs
          return;
        }

        const json = await response.json();

        if (json.urlPatterns?.org) {
          this.orgUrlPattern = json.urlPatterns.org;
        }

        const offset = (page - 1) * this.limit;
        this.orgs.splice(offset, json.results.length, ...json.results);

        this.activeSearchQuery = this.searchQuery;

        const { next } = response.links;
        if (next) {
          await this.fetchPage(context, next.url, next.page);
        } else {
          this.showLoadingSingleton = false;
        }
      } catch (err) {
        this.hasError = true;

        if (window.Sentry && window.Sentry.captureException) {
          window.Sentry.captureException(err);
        }
      }
    },
    async filterSearch(value) {
      if (value === this.activeSearchQuery) return;
      this.showLoadingSingleton = true;
      this.orgs = [];
      this.resetScroll();

      if (value) {
        this.setDebounce(async () => {
          await this.fetchOrgs();
        });
      } else {
        await this.fetchOrgs();
      }
    },
    resetScroll() {
      this.$refs['org-list'].scrollTop = 0;
    },
    onOrgSelected(org) {
      const redirectUrl = this.orgUrl(org);

      let redirected = false;
      const redirect = () => {
        if (!redirected) {
          redirected = true;
          window?.location.assign(redirectUrl);
        }
      };

      itly.orgIsSelected(
        {
          orgId: org.id,
        },
        {
          segment: {
            callback: redirect,
          },
        },
      );

      setTimeout(redirect, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-org-list {
  overflow: auto;
  height: 100%;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: 0;
    border-bottom: 1px solid color(neutral, 84);
  }

  &__org-loading {
    display: block;
    padding: space(s);
  }

  &__link {
    display: block;
    padding: space(s);

    &:hover {
      background-color: color(neutral, 90);
    }

    &:focus-visible {
      outline: 1px solid color(ui, selection);
      background-color: color(neutral, 90);
    }
  }

  &__group-overview {
    display: flex;
    justify-content: space-between;

    &__badge {
      align-self: center;
      white-space: nowrap;
    }
  }
}
</style>
