<template>
  <div class="vue--support">
    <BaseDropdownMenu v-bind="$props" class="vue--support__menu" align="right">
      <template #handle>
        <HelpCircleOutlineIcon
          title="Expand help menu"
          class="vue--support__icon"
          name="Help Menu"
        />
      </template>
      <BaseDropdownMenuLink v-bind="linkItems[0]" />
      <BaseDropdownMenuLink v-bind="linkItems[1]" />
      <BaseDropdownMenuLink v-bind="linkItems[2]" />
      <template #secondary>
        <BaseDropdownMenuLink v-bind="linkItems[3]" />
        <BaseDropdownMenuLink v-bind="linkItems[4]" />
        <BaseDropdownMenuLink v-bind="linkItems[5]" />
      </template>
      <template #footer>
        <ul class="vue--support__footer">
          <BaseDropdownMenuLink v-bind="linkItems[6]" />
        </ul>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import HelpCircleOutlineIcon from 'icons/HelpCircleOutline';

export default {
  name: 'TheSupport',

  components: {
    HelpCircleOutlineIcon,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    forceClose: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    linkItems() {
      const supportLink = 'https://support.snyk.io/hc/en-us';
      const commonProps = {
        variant: 'nested',
        target: '_blank',
      };
      const items = [
        { href: `${supportLink}`, value: 'Documentation', ...commonProps },
        {
          href: `${supportLink}/categories/360000449098-Getting-started`,
          value: 'Getting started',
          ...commonProps,
        },
        {
          href: `https://updates.snyk.io/`,
          value: 'Product updates',
          ...commonProps,
        },
        {
          href: `${supportLink}/articles/360003812578-CLI-reference`,
          value: 'CLI',
          ...commonProps,
        },
        {
          href: `${supportLink}/categories/360000665657-Snyk-API`,
          value: 'API',
          ...commonProps,
        },
        {
          href: `${supportLink}/categories/360000116697-FAQs`,
          value: 'FAQs',
          ...commonProps,
        },
        {
          value: 'Snyk status report',
          href: 'https://snyk.statuspage.io/',
          ...commonProps,
        },
      ];
      return items;
    },
    showMenu() {
      if (typeof window === 'undefined') return false;
      return window.matchMedia('(min-width: 640px)').matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--support {
  $self: &;

  &__icon {
    color: color(base, inverted);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__footer {
    border-top: 1px solid color(neutral, 90);
    padding: space(s) 0 space(s) 0;
  }

  &:focus-within {
    #{$self}__icon {
      opacity: 1;
    }
  }

  ::v-deep .vue--dropdown-menu {
    &-link--nested {
      padding: space(xxs) space(m);
      font-size: rem(15px);
    }
    &__menu {
      width: rem(206px);
      border-radius: rem(2px);
      padding: 0;

      &--primary {
        padding: space(m) 0 space(s) 0;
      }
      &--secondary {
        background-color: color(ui, white);
        padding: space(s) 0;
      }
    }
    &__handle {
      padding: space(xs);
      &:focus-visible {
        outline: 2px solid color(ui, white);
        opacity: 1;
      }
    }
  }
}
</style>
