import VueAnnouncer from 'vue-announcer';
import { Vue, VueRouter, store, onDoneFetching } from '../lib';

import { routes } from '../../../src/shared/routes';

import {
  analyticsPage,
  speedCurve,
  allAnalyticsBlockingTasksDone,
  clearAnalyticsBlockingTasks,
} from '~/lib/analytics';

import TheStatusMessage from '~/components/TheStatusMessage/TheStatusMessage';
import { createAsyncComponent } from '~/lib/chunk-load-handler';

let initialLoad = true;

const target = document.querySelector('[data-vue="app"]');

if (target) {
  // Only initialize for vue pages
  init();
}

export { init };

function init() {
  const { user, org, group } = window.$embedded;
  const { headway } = window.$embedded;

  function generateRoute(route) {
    return {
      ...route,
      component: createAsyncComponent(
        () =>
          import(
            /* webpackChunkName: "pages~[request]" */ `~/pages/${route.component}.vue`
          ),
        {
          error: {
            component: TheStatusMessage,
            props: { header: 'Failed to load' },
            message: 'Please try again.',
          },
        },
      ),
      props: { user, org },
      children: route.children && route.children.map(generateRoute),
    };
  }

  const appRoutes = routes.map(generateRoute);

  const router = new VueRouter({
    routes: appRoutes,
    mode: 'history',
  });

  /**
   * Enable Vue announcer plugin.
   * -------------------------------------//
   * The idea of this plugin is to tell the screen reader what is happening
   * and primarily if you use single-page application.
   */
  Vue.use(VueAnnouncer, {}, router);

  router.beforeEach((to, from, next) => {
    // Clear any pending tasks before navigating
    clearAnalyticsBlockingTasks();

    // Clear any Sentry custom context from previous route
    if (window.Sentry) {
      window.Sentry.configureScope((scope) => scope.clear());
    }

    const meta = to.matched[to.matched.length - 1].meta;
    const requirements = meta?.requirements;

    store.dispatch('requirements/fetchRequirements', {
      context: {
        user,
        org,
        group,
      },
      requirements,
    });

    if (meta.title) {
      document.title = `${meta.title} | Snyk`;
      const pageTitleTags = document.querySelectorAll(
        '[data-snyk-vue="pageTitle"]',
      );
      pageTitleTags.forEach((tag) => {
        tag.setAttribute('content', meta.title);
      });
    }

    if (meta.pageDesc) {
      const pageDescTags = document.querySelectorAll(
        '[data-snyk-vue="pageDesc"]',
      );
      pageDescTags.forEach((tag) => {
        tag.setAttribute('content', meta.pageDesc);
      });
    }

    // Send LUX data to SpeedCurve on navigation start.
    // Note: initial load is sent automatically, so we mustn't send it twice.
    if (!initialLoad) {
      speedCurve.init(meta.routeName || document.title);
    }

    if (meta.luxData) {
      for (const { name, value } of meta.luxData) {
        speedCurve.addData(name, value);
      }
    }

    next();
  });

  router.afterEach(async (to) => {
    initialLoad = false;
    const meta = to.matched[to.matched.length - 1].meta;
    // Not await to not harm loading time of the the page
    analyticsPage(meta?.routeName);
    // Wait for all in-flight fetch requests to complete
    await onDoneFetching();
    await allAnalyticsBlockingTasksDone();
    speedCurve.send(meta.routeName || document.title);
  });

  new Vue({
    router,
    store,
    el: target,
    provide() {
      return {
        user,
        org,
        group,
        headway,
        requirements: this.requirements,
      };
    },
    data() {
      return {
        requirements: this.$store.getters['requirements/getRequirements'],
      };
    },
  });
}
