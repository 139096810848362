<template>
  <div class="vue--codeblock">
    <pre class="vue--codeblock__code">
      <code><slot>Code snippet not available</slot></code>
    </pre>
  </div>
</template>

<script>
export default {
  name: 'BaseCodeBlock',
  status: 'ready',
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--codeblock {
  $self: &;

  align-items: center;
  display: flex;

  #{$self}__code {
    background-color: color(neutral, 24);
    border-radius: global(border-radius, half);
    color: color(ui, white);
    margin: 0;
    overflow-x: auto;
    padding: space(s) - space(xxxs) space(s);
    white-space: pre;
    display: flex;
    width: 100%;
  }
}
</style>
