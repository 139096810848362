<template>
  <BaseDropdownMenu
    class="vue--dropdown-menu-handle-dotted"
    :class="computedClasses"
    :size="size"
    v-bind="$attrs"
  >
    <template #handle>
      <div class="vue--dropdown-menu-handle-dotted__handle" />
    </template>
    <slot />
  </BaseDropdownMenu>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseDropdownMenuHandleDotted',
  status: 'ready',

  props: {
    size: {
      type: String,
      default: null,
      validator: isInList(['extra-small', 'small']),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--dropdown-menu-handle-dotted--${this.size}`]: !!this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu-handle-dotted {
  $self: &;

  &__handle {
    cursor: pointer;
    width: 24px;
    height: 8px;
    background-image: radial-gradient(
      circle at center,
      color(base, dimmed) 3px,
      transparent 3px
    );
    background-size: 8px 8px;
    margin: space(xs) 0;
    transition: background-image 0.2s ease-in-out;
    z-index: 0;

    &:hover,
    &:focus {
      background-image: radial-gradient(
        circle at center,
        color(action) 3px,
        transparent 3px
      );
    }
  }

  &--extra-small,
  &--small {
    #{$self}__handle {
      width: 18px;
      height: 6px;
      background-image: radial-gradient(
        circle at center,
        color(base, dimmed) 2px,
        transparent 2px
      );
      background-size: 6px 6px;
      margin: space(xxs) 0;

      &:hover,
      &:focus {
        background-image: radial-gradient(
          circle at center,
          color(action) 2px,
          transparent 2px
        );
      }
    }
  }
}
</style>
