<template>
  <div class="vue--notifications">
    <transition name="slide">
      <BaseAlert
        v-if="alert"
        :key="key"
        class="vue--notifications__alert"
        v-bind="alert"
        @dismiss="onDismiss"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span class="vue--notifications__message" v-html="message" />
      </BaseAlert>
    </transition>
  </div>
</template>

<script>
import marked from 'marked';
import hash from 'object-hash';
import BaseAlert from '~/components/BaseAlert/BaseAlert';

export default {
  name: 'NotificationsRootContainer',

  components: {
    BaseAlert,
  },

  computed: {
    alert() {
      return this.$root.alert;
    },
    key() {
      return hash({
        ...this.alert,
        random: Math.floor(Math.random() * 100000),
      });
    },
    message() {
      return marked(this.alert.message, { headerIds: false });
    },
  },

  methods: {
    onDismiss() {
      this.$root.alert = null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--notifications {
  &__alert {
    left: 0;
    position: fixed;
    top: -1px;
    width: 100%;
    z-index: 9999;
  }

  &__message {
    ::v-deep p:last-of-type {
      margin-bottom: 0;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.5s;
  max-height: 100px;
}
.slide-enter,
.slide-leave-to {
  max-height: 0;
}
</style>
