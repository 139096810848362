/* eslint-disable @typescript-eslint/no-unused-vars */
declare global {
  interface Window {
    Sentry: any;
    $embedded: any;
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

import { pathToRegexp } from 'path-to-regexp';
import { codeownerRoutes } from '../../../src/shared/routes';
import { isCloudConfig } from '~/lib/cloud-config-utils';

function sentryOwnership() {
  if (!window.Sentry) {
    return;
  }

  window.Sentry.configureScope((scope: any) =>
    scope.addEventProcessor(async (event: any) => {
      let sentryTeam;

      if (event.contexts?.project) {
        // Lookup the team based on the project context
        sentryTeam = isCloudConfig(event.contexts.project.type)
          ? 'cloudconfig'
          : 'unicorn';
      } else {
        // Lookup the team based on the path (URL)
        const { pathname } = window.location;

        const matchingPathOwnership = codeownerRoutes.find(({ path }) => {
          return pathname.match(pathToRegexp(path));
        });

        if (matchingPathOwnership) {
          ({ sentryTeam } = matchingPathOwnership);
        }
      }

      event.tags = event.tags || {};
      if (sentryTeam) {
        event.tags.team = sentryTeam;
      }

      return event;
    }),
  );
}

export default sentryOwnership;
