<template>
  <span
    :aria-hidden="decorative"
    :aria-label="title"
    class="material-design-icon sort-icon"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg
      :fill="fillColor"
      :width="size"
      :height="size"
      class="material-design-icon__svg"
      viewBox="0 0 24 24"
    >
      <path v-if="showDown" d="M4 14h16l-8 8z"></path>
      <path v-if="showUp" d="M4 10l8-8 8 8z"></path>
    </svg>
  </span>
</template>

<script>
import { isInList } from '../../lib/prop-validators';
export default {
  name: 'SortIcon',
  status: 'ready',

  props: {
    title: {
      type: String,
      default: 'Sort icon',
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: Number,
      default: 24,
    },
    sortDirection: {
      type: String,
      required: false,
      validator: isInList(['ASC', 'DESC']),
      default: null,
    },
  },
  computed: {
    showDown() {
      return !this.sortDirection || this.sortDirection === 'DESC';
    },
    showUp() {
      return !this.sortDirection || this.sortDirection === 'ASC';
    },
  },
};
</script>
