<template>
  <th
    :class="computedClasses"
    :style="computedStyles"
    :aria-sort="ariaSort"
    :aria-label="ariaLabel"
    :tabindex="sortable ? '0' : null"
    :scope="scope"
    aria-controls="sortable-table"
    @click="sortColumn"
    @keyup.enter="sortColumn"
    @keyup.space="sortColumn"
  >
    {{ title }}
    <slot />
    <SortIcon v-if="sortable" :sort-direction="sortDirection" :size="10" />
  </th>
</template>

<script>
import SortIcon from '~/components/CustomIcons/Sort';
import { isInList } from '~/lib/prop-validators';

/**
 * The header for a column in a Table
 */
export default {
  name: 'BaseTableHeaderCell',
  status: 'ready',

  components: {
    SortIcon,
  },

  props: {
    /**
     * Title for the column
     */
    title: {
      type: String,
      required: true,
    },
    /**
     * If this column can be sorted
     */
    sortable: {
      type: Boolean,
      default: false,
    },
    /**
     * The direction this column is being sorted in, if it is being sorted
     */
    sortDirection: {
      type: String,
      required: false,
      default: null,
      validator: isInList(['ASC', 'DESC']),
    },
    /**
     * Optional width for the column (e.g. "50%")
     */
    width: {
      type: String,
      required: false,
    },
    /**
     * Header styling variant
     */
    variant: {
      type: String,
      default: null,
      validator: isInList('compact'),
    },
    /**
     * Scope for the header (defaults to col)
     */
    scope: {
      type: String,
      default: 'col',
      validator: isInList(['row', 'col', 'rowgroup', 'colgroup']),
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--table-header': true,
        'vue--table-header--sortable': this.sortable,
        [`vue--table-header--${this.variant}`]: !!this.variant,
      };
    },
    computedStyles() {
      return {
        width: this.width,
      };
    },
    ariaSort() {
      if (this.sortDirection === 'ASC') {
        return 'ascending';
      } else if (this.sortDirection === 'DESC') {
        return 'descending';
      }
      return 'none';
    },
    ariaLabel() {
      return this.sortable
        ? `activate to sort column ${
            this.sortDirection === 'ASC' ? 'descending' : 'ascending'
          }`
        : null;
    },
  },

  methods: {
    sortColumn() {
      if (this.sortable) {
        this.$emit('sort');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--table-header {
  text-transform: uppercase;
  padding: space(s);
  color: color(brand, light);
  letter-spacing: 1.5px;
  font-size: rem(12px);

  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }

  &:focus {
    @include hoverState();
  }

  &--sortable {
    cursor: pointer;

    &:hover {
      @include hoverState();
    }
  }

  &--compact {
    padding: space(xxs);
  }

  ::v-deep .sort-icon {
    svg {
      margin-top: -2px;
    }
  }
}
</style>
