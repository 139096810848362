<template>
  <header v-if="isVisible" ref="p" class="vue--site-header" :style="cssProps">
    <LayoutContainer :class="computedClasses" @close:header-items="closeMenus">
      <a :href="homeUrl" class="vue--site-header__home-link">
        <img
          v-if="partner"
          :src="partner.logoUrl"
          :alt="partner.name"
          width="200px"
          height="30px"
          class="vue--site-header__partner-logo"
        />
        <SnykLogo
          v-else
          :open="isNavOpen"
          class="vue--site-header__logo"
          fill-color="#fff"
          :size="52"
          data-snyk-test="TheSiteHeader: logo"
        />
      </a>
      <template v-if="user">
        <TheOrgSwitcher :class="computedOrgSwitcherClasses" />
        <FocusTrap
          :disabled="!isNavOpen"
          :is-visible="isNavOpen"
          :is-inline="true"
          @escape="escapeNav"
        >
          <ThePrimaryNavigation
            ref="primaryNav"
            :links="primaryNav"
            :current-url="currentUrl"
            :open="isNavOpen"
            class="vue--site-header__primary-nav"
            @closeNav="closeNav"
            @keydown.esc="closeNav"
          />
          <UpgradeButton
            v-if="showUpgradeButton"
            class="vue--site-header__upgrade-button"
            origin="Header"
          />
          <TheAccountMenu
            v-if="showAccountMenu"
            :links="accountNav"
            class="vue--site-header__account-menu"
            @closeNav="closeNav"
          />
        </FocusTrap>
        <TheSettings class="vue--site-header__settings" :links="settingsNav" />
        <TheProductUpdates class="vue--site-header__product-updates" />
        <TheSupport
          class="vue--site-header__support"
          :force-close="menusClosed"
        />
        <TheAccountPanel
          v-if="showAccountButton"
          :links="accountNav"
          :force-close="menusClosed"
          class="vue--site-header__account"
        />
        <ThePrimaryNavigationBurgerButton
          v-if="showBurgerMenu"
          ref="hamburger"
          :is-expanded="isNavOpen"
          class="vue--site-header__burger"
          @click="toggleNav"
        />
      </template>
      <template v-else>
        <TheLoggedOutSiteTitle
          class="vue--site-header__logged-out-site-title"
          :pathname="pathname"
        />
        <div class="vue--site-header__login-links">
          <BaseButton
            class="vue--site-header__login-links__login"
            wrapper="a"
            variant="inverted-link"
            data-snyk-test="TheSiteHeader: log in button"
            :href="loginLink"
            >Log in</BaseButton
          >
          <BaseButton
            class="vue--site-header__login-links__signup"
            wrapper="a"
            variant="inverted"
            :ghost="true"
            data-snyk-test="TheSiteHeader: sign up button"
            :href="signupLink"
            >Sign up</BaseButton
          >
        </div>
      </template>
    </LayoutContainer>
  </header>
</template>

<script>
import LayoutContainer from '~/components/LayoutContainer/LayoutContainer';
import TheOrgSwitcher from '~/components/TheOrgSwitcher/TheOrgSwitcher';
import TheLoggedOutSiteTitle from '~/components/TheLoggedOutSiteTitle/TheLoggedOutSiteTitle';
import ThePrimaryNavigation from '~/components/ThePrimaryNavigation/ThePrimaryNavigation';
import TheProductUpdates from '~/components/TheProductUpdates/TheProductUpdates';
import TheSupport from '~/components/TheSupport/TheSupport';
import TheSettings from '~/components/TheSettings/TheSettings';
import TheAccountPanel from '~/components/TheAccountPanel/TheAccountPanel';
import TheAccountMenu from '~/components/TheAccountMenu/TheAccountMenu';
import ThePrimaryNavigationBurgerButton from '~/components/ThePrimaryNavigationBurgerButton/ThePrimaryNavigationBurgerButton';
import UpgradeButton from '~/components/UpgradeButton/UpgradeButton';
import SnykLogo from '~/components/CustomIcons/Snyk';
import FocusTrap from '~/components/FocusTrap/FocusTrap';
import { createDebounce } from '~/lib/debounce';
import { getSiteHeader } from '~/lib/navigation';

export default {
  name: 'TheSiteHeader',
  components: {
    LayoutContainer,
    TheOrgSwitcher,
    TheLoggedOutSiteTitle,
    ThePrimaryNavigation,
    TheProductUpdates,
    ThePrimaryNavigationBurgerButton,
    TheSupport,
    TheSettings,
    TheAccountPanel,
    TheAccountMenu,
    SnykLogo,
    UpgradeButton,
    FocusTrap,
  },
  inject: ['user', 'org', 'group'],
  props: {
    partner: {
      type: Object,
    },
    showUpgradeButton: {
      type: Boolean,
      default: false,
    },
    hardwireUrl: {
      type: String,
    },
  },
  data() {
    return {
      isNavOpen: false,
      isSmallWidth: false,
      setDebounce: createDebounce(),
      offsetTop: 0,

      primaryNav: [],
      accountNav: [],
      settingsNav: [],

      menusClosed: false,
    };
  },
  computed: {
    computedClasses() {
      return {
        'vue--site-header__container': true,
        'vue--site-header__container--expanded': this.isNavOpen,
      };
    },
    computedOrgSwitcherClasses() {
      return {
        'vue--site-header__org-switcher': true,
        'vue--site-header__org-switcher--hidden': this.isNavOpen,
      };
    },
    cssProps() {
      // This compensates for things like the pride banner at the top of the page
      return {
        '--offset-top': `${this.offsetTop}px`,
      };
    },
    isVisible() {
      return this.$route
        ? !this.$route.meta.noSiteHeader
        : !window.$embedded?.noSiteHeader;
    },
    currentUrl() {
      if (this.hardwireUrl) return this.hardwireUrl;
      return this.$route ? this.$route.fullPath : this.pathname;
    },
    homeUrl() {
      return this.partner ? this.partner.homeUrl : '/';
    },
    pathname() {
      return typeof window === 'undefined' ? '' : window.location.pathname;
    },
    loginLink() {
      const page = this.pageQueryParam;
      return `/login?cta=login&loc=nav${page ? `&page=${page}` : ''}`;
    },
    signupLink() {
      const page = this.pageQueryParam;
      return `/login?cta=sign-up&loc=nav${page ? `&page=${page}` : ''}`;
    },
    pageQueryParam() {
      if (typeof window === 'undefined') {
        return '';
      }

      const pathname = window.location.pathname;

      if (pathname.startsWith('/test')) {
        return 'test-result';
      }

      if (pathname.startsWith('/vuln/SNYK-') || pathname === '/vuln') {
        return 'vuln-vuln';
      }

      if (pathname.startsWith('/vuln/') && pathname.includes('@')) {
        return 'vuln-package-version';
      }

      if (pathname.startsWith('/vuln/')) {
        return 'vuln-package';
      }

      return '';
    },
    showAccountButton() {
      return !this.isSmallWidth;
    },
    showAccountMenu() {
      return this.isSmallWidth && this.isNavOpen;
    },
    showBurgerMenu() {
      return this.isSmallWidth;
    },
  },
  async mounted() {
    if (!this.isVisible) return;

    this.resizeHandler();

    const context = {
      user: this.user,
      org: this.org,
      group: this.group,
    };
    try {
      const nav = await getSiteHeader(this.currentUrl, context, this.$router);
      if (nav) this.assignNavItems(nav);
    } catch (err) {
      if (window.Sentry && window.Sentry.captureException) {
        window.Sentry.captureException(err);
      }
    }
  },
  methods: {
    assignNavItems(nav) {
      this.primaryNav = nav?.primary;
      this.accountNav = nav?.account;
      this.settingsNav = nav?.settings;
    },
    toggleNav() {
      this.offsetTop = this.$refs.p.offsetTop;
      this.isNavOpen = !this.isNavOpen;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    escapeNav() {
      this.isNavOpen = false;
      this.$refs.hamburger.$el.focus();
    },
    async closeMenus() {
      this.menusClosed = true;
      await this.$nextTick();
      this.menusClosed = false;
    },
    updateOpen() {
      this.closeMenus();
    },
    resizeHandler() {
      if (typeof window === 'undefined') return false;
      this.setIsSmallWidth();
      window.addEventListener('resize', () => {
        this.setDebounce(() => {
          this.setIsSmallWidth();
        });
      });
    },
    setIsSmallWidth() {
      if (typeof window === 'undefined') return;
      this.isSmallWidth = window.matchMedia('(max-width: 930px)').matches;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--site-header {
  &__container {
    overflow: visible;

    display: flex;
    flex-wrap: wrap;
    padding-top: space(xxs);
    padding-bottom: space(s);

    @include media-query(s) {
      height: rem(64px);
      flex-wrap: nowrap;
      padding-top: space(s);
      background: none;
    }

    &--expanded {
      min-height: 100vh;
      top: var(--offset-top);
      bottom: 0;
      left: 0;
      right: 0;
      height: auto;
      flex-wrap: wrap;
      background: linear-gradient(
        to right,
        color(gradient, purple-start) 0%,
        color(gradient, purple-end) 50%
      );

      align-content: flex-start;
      @include media-query(s) {
        min-height: 64px;
      }
    }
  }

  &__home-link {
    display: inline-block;
    padding-right: space(s);
    flex-grow: 2;

    @include media-query(s) {
      flex-grow: 0;
    }

    &:focus-visible {
      outline: 2px solid color(ui, white);
      opacity: 1;
    }
  }

  &__container--expanded &__home-link {
    flex-grow: 2;
  }

  &__org-switcher {
    order: 2;
    flex-basis: 100%;

    &--hidden {
      display: none;

      @include media-query() {
        display: block;
      }
    }

    @include media-query(s) {
      order: 0;
    }

    @include media-query() {
      flex-basis: auto;
      width: rem(400px);
    }
  }

  &__logo {
    position: relative;
    top: -6px;
  }

  &__partner-logo {
    margin-top: 6px;
  }

  &__primary-nav {
    order: 1;
    flex-basis: 100%;

    @include media-query() {
      order: 0;
      flex-grow: 2;
      flex-basis: auto;
    }
  }

  &__upgrade-button {
    display: none;

    @include media-query(l) {
      display: block;
      padding: space(xxs) space(s);
    }
  }

  &__logged-out-site-title {
    order: 1;
    flex-basis: 100%;
    @include media-query() {
      order: 0;
      flex-grow: 2;
      flex-basis: auto;
    }
  }
  &__login-links {
    text-align: right;
    flex-grow: 2;

    &__login {
      color: color(ui, white);
    }

    &__signup {
      color: color(ui, white);
    }
  }

  &__settings {
    padding: space(xs);
    height: rem(40px);
  }

  &__account {
    padding: space(xxs) 0 0 space(xs);
  }

  &__account-menu {
    order: 3;
    flex-grow: 2;

    @include media-query() {
      flex-grow: 0;
    }
  }

  &__product-updates {
    padding: space(xs);
    display: none;
    @include media-query() {
      display: block;
    }
  }
  &__burger {
    @include media-query() {
      display: none;
    }
  }
}
</style>
<style>
[data-vue='site-header'] {
  height: 60px;
  background-color: lime;
}
</style>
