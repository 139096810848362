export { getNavigation, getRequirementsByPath, getSiteHeader };

import store from '~/store';
import { navigationItems } from './navigation-items';
import { pathToRegexp, compile } from 'path-to-regexp';
import { request } from '~/lib/request';

interface Context {
  user?: UserContext;
  org?: OrgContext;
  group?: GroupContext;
}

interface UserContext {
  name?: string;
  admin?: boolean;
  id?: string;
}
interface OrgContext {
  name: string;
}
interface GroupContext {
  id: string;
  orgs: object[];
}
interface NavigationItem {
  label: string;
  path: string;
  exactMatch?: boolean;
  requirements?: RequirmentsObject;
  validators?: string[];
  active?: ActiveFunction | boolean;
  checks?: Object;
  serverRouted?: boolean;
  _safeRouteName?: string;
  _isInvalid?: boolean;
}
interface RequirmentsObject {
  permissions: string[];
  entitlements: string[];
  features: string[];
}
interface ActiveFunction {
  (path: Tokeniser): boolean;
}
interface NavigationList extends Array<NavigationItem> {}

class Tokeniser {
  path: string;

  constructor(path) {
    this.path = path;
  }

  isEqual(pattern: string, exactMatch: boolean): boolean {
    pattern = this.normalizePathname(pattern);
    if (!exactMatch) pattern = `${pattern}(.*)`;
    return !!this.path.match(pathToRegexp(pattern));
  }

  normalizePathname(pathname: string): string {
    return decodeURI(pathname)
      .replace(/\/+/g, '/')
      .replace('https:/', '/')
      .normalize();
  }
}

type NavigationType =
  | 'primary'
  | 'group'
  | 'org'
  | 'account'
  | 'footer'
  | 'mock';

/**
 * Gets a particular navigation set
 */
async function getNavigation(
  type: NavigationType,
  path: string,
  context: Context,
  router?: object,
) {
  if (!path) return '';
  const list = getNavigationList(type, path);
  const filteredList = await addPermissionChecks(list, context);
  const validatedList = await validateList(filteredList, path, context);
  const tokeniser = new Tokeniser(path);
  const formattedList = markItemsActive(validatedList, tokeniser);
  const hydratedList = hydrateUrls(formattedList, context, router);
  return hydratedList;
}

function getNavigationList(type: NavigationType, path: string): NavigationList {
  if (type === 'primary') {
    type = getGroupOrOrg(path);
  }
  return navigationItems[type];
}

/**
 * Gets all the navigation items used in the header in one single hit
 */
async function getSiteHeader(
  path: string,
  context: Context,
  router?: object,
): Promise<Object | void> {
  if (!path) return;
  if (!context.user) return;

  const navs = {
    primary: navigationItems[getGroupOrOrg(path)],
    account: navigationItems['account'],
    settings: navigationItems['settings'],
  };

  const sections = [...navs.primary, ...navs.account, ...navs.settings];

  const requirements = aggregateRequirements(sections);
  const checks = await makeRequirementsCheck(context, requirements);
  const tokeniser = new Tokeniser(path);

  // Stub out object for the results, with the same keys as `navs` above.
  const result = Object.keys(navs).reduce(
    (current, key) => ({ ...current, [key]: [] }),
    {},
  );

  for (const [section] of Object.entries(result)) {
    const filteredList = await addPermissionChecks(
      navs[section],
      context,
      checks,
    );
    const validatedList = await validateList(filteredList, path, context);
    const formattedList = markItemsActive(validatedList, tokeniser);
    result[section] = hydrateUrls(formattedList, context, router);
  }
  return result;
}

function getGroupOrOrg(path: string): NavigationType {
  return path.split('/')[1] === 'group' ? 'group' : 'org';
}

async function addPermissionChecks(
  list: NavigationList,
  context: Context,
  checks?: [Object],
): Promise<NavigationList> {
  if (!checks) {
    const requirements = aggregateRequirements(list);
    checks = await makeRequirementsCheck(context, requirements);
  }

  list.forEach((item: NavigationItem) => {
    if (item._safeRouteName) {
      if (checks && checks[item._safeRouteName]) {
        item.checks = checks[item._safeRouteName];
      } else {
        item.checks = { permitted: !item.requirements };
      }
    }
  });
  return list;
}

function aggregateRequirements(list: NavigationList): Object {
  const requirements = {};
  list.forEach((item: NavigationItem) => {
    item._safeRouteName = safeRouteName(item.path);
    if (item.requirements) {
      requirements[item._safeRouteName] = item.requirements;
    }
  });
  return requirements;
}

async function makeRequirementsCheck(
  context: Context,
  requirements: Object,
): Promise<[Object]> {
  const payload = { context, requirements, forceFetch: false };
  await store.dispatch('requirements/fetchRequirements', payload);
  const checks = await store.getters['requirements/getAllRequirementsChecks'](
    payload,
  );
  return checks;
}

const getRequirementsByPath = async (
  type: NavigationType,
  path: string,
  context: Context,
) => {
  let requirements = {};
  const navItem = getNavigationList(type, path).find(
    (item) => item.label === path,
  );
  if (navItem?.requirements) {
    requirements = navItem.requirements;
  }
  const payload = { context, requirements };
  await store.dispatch('requirements/fetchRequirements', payload);

  const checks = await store.getters['requirements/getAllRequirementsChecks'](
    payload,
  );
  return checks;
};

function safeRouteName(path: string): string {
  path = path.replace(/[^a-z0-9/]+/gi, '');
  path = path.replace(/\/\w/g, (x) => x[1].toUpperCase());
  return `Route_${path}`;
}

function markItemsActive(
  list: NavigationList,
  tokeniser: Tokeniser,
): NavigationList {
  return list.map((item: NavigationItem) => {
    if (typeof item.active === 'function') {
      const isActive = item.active(tokeniser);
      item.active = !!isActive;
      return item;
    }
    if (tokeniser.isEqual(item.path, !!item.exactMatch)) item.active = true;
    return item;
  });
}

function hydrateUrls(
  list: NavigationList,
  context: Context,
  router?,
): NavigationList {
  return list.map((item: NavigationItem) => {
    const segments = item.path.split('/');

    if (segments[0] === 'https:') {
      item.serverRouted = true;
      return item;
    }
    item.serverRouted = isServerRouted(item, router);

    const toPath = compile(item.path, { encode: encodeURIComponent });

    switch (segments[1]) {
      case 'org':
        item.path = toPath({
          orgName: context?.org?.name,
        });
        break;
      case 'group':
        item.path = toPath({
          groupPublicId: context?.group?.id,
        });
        break;
    }

    return item;
  });
}

function isServerRouted(item: NavigationItem, router?): boolean {
  if (!router) {
    return true;
  }
  const { path } = item;
  const isMatched = router.resolve({ path }).route.matched.length > 0;
  return !isMatched;
}

async function validateList(
  list: NavigationList,
  path: string,
  context: Context,
): Promise<NavigationList> {
  const processedList = await Promise.all(
    list.map(async (item: NavigationItem) => {
      if (!item.validators) return item;
      let failures: number = 0;
      let i: number, len: number;
      for (i = 0, len = item.validators.length; i < len; i++) {
        const validator = item.validators[i];
        switch (validator) {
          case 'NON_ZERO_ORGS_LENGTH':
            if ((await getOrgCountForGroup(context.group)) < 1) {
              failures++;
            }
            break;
          case 'IS_ADMIN':
            if (!context.user?.admin) {
              failures++;
            }
            break;
          case 'ORG_CONTEXT':
            if (getGroupOrOrg(path) !== 'org') {
              failures++;
            }
            break;
          case 'GROUP_CONTEXT':
            if (getGroupOrOrg(path) !== 'group') {
              failures++;
            }
            break;
        }
      }
      if (failures === 0) return item;
      item._isInvalid = true;
      return item;
    }),
  );

  return processedList.filter((item: NavigationItem) => !item._isInvalid);
}

async function getOrgCountForGroup(group?: GroupContext): Promise<number> {
  if (!group || !group.id) return 0;
  try {
    /* TODO: Fetching of nav data should be migrated to a store 
      shared with TheOrgSwitcher */
    const response = await request('/org-switcher');
    const json = await response.json();
    const currentGroup = json.groups.filter((item) => item.id == group.id)[0];
    if (!currentGroup) return 0;
    return currentGroup.orgsCount;
  } catch (err) {
    return 0;
  }
}
