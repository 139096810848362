<template>
  <transition name="modal-fade">
    <FocusTrap :active="true">
      <div :class="computedClasses" class="vue--form-modal" role="dialog">
        <component
          :is="conditionalFormElement"
          class="vue--form-modal__wrap"
          @submit.prevent="submit"
          @keydown.esc="close"
        >
          <header class="vue--form-modal__header">
            <LayoutContainer>
              <LayoutSpaceBetween>
                <div class="vue--form-modal__title">
                  <!-- @slot Optional icon slot -->
                  <slot name="icon" />
                  <h1 class="vue--form-modal__heading">
                    <!-- @slot Title slot -->
                    <slot name="title" />
                  </h1>
                </div>

                <div v-if="hasFormActions" class="vue--form-modal__actions">
                  <BaseButton variant="inverted" ghost @click="close">
                    <!-- @slot Cancel button text override slot -->
                    <slot name="cancel">Cancel</slot>
                  </BaseButton>
                  <BaseButton :disabled="isSubmitting" type="submit">
                    <template v-if="!isSubmitting">
                      <!-- @slot Proceed button text override slot -->
                      <slot name="proceed">Submit</slot>
                    </template>
                    <BaseLoadingSpinner v-if="isSubmitting" />
                  </BaseButton>
                </div>
                <div v-else class="vue--form-modal__actions">
                  <BaseButton variant="inverted" ghost @click="close">
                    <CloseIcon title="Close this dialog" /> Close
                  </BaseButton>
                </div>
              </LayoutSpaceBetween>
            </LayoutContainer>
          </header>

          <div class="vue--form-modal__alert">
            <!-- @slot Optional alert placement slot -->
            <slot name="alert" />
          </div>

          <div ref="modal" class="vue--form-modal__body" tabindex="0">
            <section class="vue--form-modal__content">
              <!-- @slot Body content slot -->
              <slot name="body">
                <BaseLoadingSpinner size="large" />
              </slot>
            </section>
          </div>
        </component>
      </div>
    </FocusTrap>
  </transition>
</template>

<script>
import LayoutContainer from '~/components/LayoutContainer/LayoutContainer';
import LayoutSpaceBetween from '~/components/LayoutSpaceBetween/LayoutSpaceBetween';
import CloseIcon from 'icons/CloseCircleOutline';
import FocusTrap from '~/components/FocusTrap/FocusTrap';

export default {
  name: 'BaseModal',

  status: 'ready',

  components: {
    LayoutContainer,
    LayoutSpaceBetween,
    CloseIcon,
    FocusTrap,
  },

  props: {
    /**
     * Restrict body width.
     */
    narrow: {
      type: Boolean,
      default: false,
    },
    /**
     * Have modal overlay page content.
     */
    overlay: {
      type: Boolean,
      default: false,
    },
    /**
     * Show modal in submitting state.
     */
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    /**
     * Use standard proceed and cancel form controls
     */
    controls: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--form-modal--narrow': this.narrow,
        'vue--form-modal--overlay': this.overlay,
      };
    },
    conditionalFormElement() {
      return this.hasFormActions ? 'form' : 'div';
    },
    hasFormActions() {
      return !!this.controls || !!this.$slots.proceed || !!this.$slots.cancel;
    },
  },

  mounted() {
    document.body.classList.add('vue--form-modal--active');
    this.$refs.modal?.focus();
  },

  beforeDestroy() {
    document.body.classList.remove('vue--form-modal--active');
  },

  methods: {
    close() {
      /**
       * Triggered on close action.
       */
      this.$emit('close');
    },
    submit() {
      /**
       * Triggered on submit action.
       */
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--form-modal {
  $self: &;

  background-color: color(neutral, 24);
  min-height: 100vh;

  &__header {
    background-color: color(action);
    color: color(ui, white);
    padding: space();
    width: 100%;
  }

  &__title {
    display: flex;

    #{$self}__icon {
      flex: 0 1 rem(34px);
    }

    #{$self}__heading {
      align-self: center;
      flex: 1 0 80%;
    }

    @include media-query(s) {
      align-items: center;
      max-width: rem(720px);
      padding-right: rem(16px);
    }
  }

  &__actions {
    flex-shrink: 0;
    margin-top: space(xs);

    @include media-query(xs) {
      margin-top: 0;
    }

    [disabled] {
      vertical-align: top;
    }
  }

  &__heading {
    font-size: rem(18px);
    font-weight: 500;
    margin: 0;
    color: color(ui, white);
  }

  * + &__heading {
    margin-left: rem(8px);
  }

  &__body {
    margin: 0 auto;
    max-width: rem(1440px);
    padding: space(xl) space(l);
    width: 100%;
  }

  &__content {
    background-color: color(ui, white);
    padding: space(l);
  }

  // Variations
  &--narrow {
    #{$self}__body {
      max-width: rem(720px);
    }
  }

  &--overlay {
    background-color: rgba(color(neutral, 24), 0.95);
    bottom: 0;
    height: 100vh;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1100; // make it larger than the site navigation.

    #{$self}__wrap {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      max-height: 100vh;
    }

    #{$self}__alert {
      width: 100%;
    }

    #{$self}__body {
      flex-shrink: 1;
      overflow-y: auto;
    }

    #{$self}__content {
      padding-left: space(m);
      padding-right: space(m);

      @include media-query(m) {
        padding: rem(48px) rem(52px);
      }
    }
  }

  &--active {
    overflow-y: hidden;
    position: relative;
  }

  #{$self}-fade-enter-active {
    transition: transform 0.5s ease;
  }

  #{$self}-fade-enter {
    transform: translateY(100%);
  }

  #{$self}-fade-enter-to {
    transform: translateY(0);
  }

  #{$self}-fade-leave-active {
    opacity: 0;
    transition: opacity 400ms ease;
  }
}
</style>
