<template>
  <button
    :class="computedClasses"
    aria-label="Show menu"
    aria-controls="snyk-app-nav"
    :aria-expanded="isExpanded"
    @click="click"
  >
    <span class="vue--primary-nav-btn__container">
      <span
        class="vue--primary-nav-btn__line vue--primary-nav-btn__line1"
      ></span>
      <span
        class="vue--primary-nav-btn__line vue--primary-nav-btn__line2"
      ></span>
      <span
        class="vue--primary-nav-btn__line vue--primary-nav-btn__line3"
      ></span>
      <span
        class="vue--primary-nav-btn__line vue--primary-nav-btn__line4"
      ></span
    ></span>
  </button>
</template>

<script>
export default {
  name: 'ThePrimaryNavigationBurgerButton',
  props: {
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        'vue--primary-nav-btn': true,
        'vue--primary-nav-btn--active': !!this.isExpanded,
      };
    },
  },
  methods: {
    click() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--primary-nav-btn {
  background-color: transparent;
  border-radius: 0;
  cursor: pointer;
  display: block;
  padding: 0;
  transform: rotate(0deg);
  opacity: 0.5;
  height: rem(40px);

  &:hover {
    background-color: transparent;
    opacity: 1;
  }

  &:active,
  &:focus {
    box-shadow: none;
    opacity: 1;
  }

  &:focus-visible {
    outline: 2px solid color(ui, white);
    opacity: 1;
  }

  &__container {
    display: block;
    height: rem(12px);
    width: rem(18px);
    position: relative;
    margin: rem(14px) space(s);
  }

  &__line {
    transform: rotate(0deg);
    background-color: color(ui, white);
    display: block;
    height: 2px;
    left: 0;
    opacity: 1;
    position: absolute;
    transition: 0.25s ease-in-out;
    width: 100%;
  }

  &__line1 {
    top: 0;
  }

  &__line2 {
    top: 5px;
  }

  &__line3 {
    top: 5px;
  }

  &__line4 {
    top: 10px;
  }

  &--active {
    .vue--primary-nav-btn {
      &__line1 {
        left: 50%;
        top: 5px;
        width: 0;
      }
      &__line2 {
        transform: rotate(45deg);
      }
      &__line3 {
        transform: rotate(-45deg);
      }
      &__line4 {
        left: 50%;
        top: 5px;
        width: 0;
      }
    }
  }
}
</style>
