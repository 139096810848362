<template>
  <LayoutContainer class="vue--status-message">
    <BaseBlock class="vue--status-message__block">
      <h1 class="vue--status-message__header">{{ header }}</h1>

      <div class="vue--status-message__description">
        <slot />
      </div>

      <div class="vue--status-message__footer">
        <slot name="footer" />
      </div>
    </BaseBlock>
  </LayoutContainer>
</template>

<script>
import LayoutContainer from '~/components/LayoutContainer/LayoutContainer';

export default {
  name: 'TheStatusMessage',
  status: 'alpha',
  components: {
    LayoutContainer,
  },
  props: {
    header: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--status-message {
  &__block {
    max-width: 640px;
    margin: space(l) auto;
    text-align: center;
    padding: space(l);

    ::v-deep p,
    ::v-deep div {
      text-align: center;
    }
  }
  &__header {
    text-align: center;
    font-size: rem(36px);
    font-feature-settings: 'pnum';
    font-variant: common-ligatures proportional-nums;
    font-weight: 500;
    line-height: 1;
    text-rendering: optimizeLegibility;
    margin: space(xs) 0 space(l) 0;
  }

  &__description {
    font-size: rem(20px);
    text-align: center;
    margin: space(s) 0;
  }
}
</style>
