<template>
  <a
    v-if="hasLinks"
    :href="path"
    :title="label"
    class="vue--settings"
    data-snyk-test="TheSettings: button"
  >
    <SettingsOutlineIcon
      :class="computedClasses"
      :title="label"
      name="Settings"
    />
  </a>
</template>

<script>
import SettingsOutlineIcon from 'icons/CogOutline';

export default {
  name: 'TheSettings',
  components: {
    SettingsOutlineIcon,
  },
  props: {
    links: {
      type: Array,
    },
  },
  data() {
    return {
      selectedLink: {},
    };
  },
  computed: {
    computedClasses() {
      return {
        'vue--settings__icon': true,
        'vue--settings--active': this.selectedLink?.active,
      };
    },
    hasLinks() {
      return !!this.links.length;
    },
    path() {
      return this.selectedLink.path;
    },
    label() {
      return this.selectedLink.label;
    },
  },
  watch: {
    links() {
      if (this.links.length) {
        this.links.forEach((link) => {
          if (link.checks.permitted) {
            this.selectedLink = link;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--settings {
  &__icon {
    color: color(base, inverted);
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &--active {
    opacity: 1;
  }

  &:focus-visible {
    outline: 2px solid color(ui, white);
    opacity: 1;
    & .vue--settings__icon {
      opacity: 1;
    }
  }
}
</style>
