<template>
  <component :is="`h${level}`" class="vue--heading" :class="computedClasses">
    <slot />
  </component>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseHeading',

  props: {
    level: {
      type: Number,
      default: 1,
      validator: isInList([1, 2, 3, 4]),
    },
    regular: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--heading--regular`]: this.regular,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--heading {
  @include font-stack(semibold, sans-serif, normal, true);

  color: color(base, heading);
  font-feature-settings: 'pnum';

  &--regular {
    @include font-stack(regular, sans-serif, normal, true);
  }
}
h1.vue--heading {
  font-size: rem(24px);
  line-height: rem(30px);

  ::v-deep svg {
    height: rem(30px);
    width: rem(30px);
  }

  @include media-query(m) {
    font-size: rem(32px);
    line-height: rem(40px);

    ::v-deep svg {
      height: rem(40px);
      width: rem(40px);
    }
  }
}
h2.vue--heading {
  font-size: rem(18px);
  line-height: rem(24px);

  ::v-deep svg {
    height: rem(24px);
    width: rem(24px);
  }

  @include media-query(m) {
    font-size: rem(24px);
    line-height: rem(32px);

    ::v-deep svg {
      height: rem(32px);
      width: rem(32px);
    }
  }
}
h3.vue--heading {
  font-size: rem(15px);
  line-height: rem(24px);

  ::v-deep svg {
    height: rem(24px);
    width: rem(24px);
  }

  @include media-query(m) {
    font-size: rem(18px);
    line-height: rem(26px);

    ::v-deep svg {
      height: rem(26px);
      width: rem(26px);
    }
  }
}
h4.vue--heading {
  font-size: rem(13px);
  line-height: rem(21px);
  ::v-deep svg {
    height: rem(21px);
    width: rem(21px);
  }
  @include media-query(m) {
    font-size: rem(15px);
    line-height: rem(24px);
    ::v-deep svg {
      height: rem(24px);
      width: rem(24px);
    }
  }
}
</style>
