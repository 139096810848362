import { ORG_PERMISSIONS } from '../../../permissions/defs';
import type { Route } from '../../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName/import-log/latest',
    component: 'ImportLog/ImportLog',
    sentryTeam: 'comet',
    meta: {
      title: 'ImportLog',
      routeName: 'ImportLog',
      pageDesc: 'snyk ImportLog',
      requirements: {
        page: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_IMPORT_READ,
          ],
        },
      },
    },
  },
  {
    path: '/org/:orgName/import-log/:jobPublicId',
    component: 'ImportLog/ImportLog',
    sentryTeam: 'comet',
    meta: {
      title: 'ImportLog',
      routeName: 'ImportLog',
      pageDesc: 'snyk ImportLog',
      requirements: {
        page: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_IMPORT_READ,
          ],
        },
      },
    },
  },
];
