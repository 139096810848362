<template>
  <div class="vue--org-switcher" :class="computedClasses">
    <TheOrgSwitcherButton
      :url="currentUrl"
      :menu-visible="menuVisible"
      :focussed="buttonFocussed"
      @click="toggleMenu"
      @close="closeMenu"
      @open="openMenu"
    />
    <div
      v-if="menuVisible"
      class="vue--org-switcher__underlay"
      data-snyk-test="TheOrgSwitcher: underlay"
      @click="toggleMenu"
    ></div>
    <TheOrgSwitcherMenu :visible="menuVisible" @close="closeMenu" />
  </div>
</template>

<script>
import TheOrgSwitcherButton from '~/components/TheOrgSwitcherButton/TheOrgSwitcherButton';
import TheOrgSwitcherMenu from '~/components/TheOrgSwitcherMenu/TheOrgSwitcherMenu';

export default {
  name: 'TheOrgSwitcher',
  components: {
    TheOrgSwitcherButton,
    TheOrgSwitcherMenu,
  },
  data() {
    return {
      menuVisible: false,
      buttonFocussed: false,
    };
  },
  computed: {
    currentUrl() {
      return typeof window == 'undefined' ? null : window.location.pathname;
    },
    computedClasses() {
      return {
        'vue--org-switcher--open': this.menuVisible,
      };
    },
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    openMenu() {
      this.menuVisible = true;
    },
    closeMenu() {
      this.menuVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';
.vue--org-switcher {
  &--open {
    z-index: 1000;
  }

  &__underlay {
    background-color: transparent;
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
