import depGraph from './dep-graph';
import issues from './issues';
import vulns from './vulns';
import sastIssues from './sast-issues';

export default {
  namespaced: true,

  getters: {},

  actions: {},

  mutations: {},

  modules: {
    depGraph,
    issues,
    vulns,
    sastIssues,
  },
};
