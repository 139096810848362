import type { Route } from '../../types';

export const routes: Route[] = [
  {
    path: '/redhat/snyk-token',
    component: 'RedHatInterstitial/RedHatInterstitial',
    sentryTeam: 'bolt',
    meta: {
      title: 'Connect Snyk and Red Hat',
      pageDesc: 'Connect Snyk and Red Hat',
      routeName: 'Connect Snyk and Red Hat',
      noSiteHeader: true,
    },
  },
];
