<template>
  <component
    :is="component"
    :href="href"
    class="vue--org-switcher-navatar"
    data-snyk-test="TheOrgSwitcherNavatar: button"
    :title="title"
    @click="onClick"
    @keydown.enter="onClick"
    @keydown.space="onClick"
  >
    <span v-if="group.avatarUrl" :class="computedClasses">
      <img :src="group.avatarUrl" :alt="group.name" loading="lazy"
    /></span>
    <span v-else :class="computedClasses">{{ text }}</span>
  </component>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'TheOrgSwitcherNavatar',
  props: {
    group: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: null,
      validator() {
        return isInList(['add']);
      },
    },
  },
  computed: {
    isAdd() {
      return this.variant === 'add';
    },
    component() {
      return this.isAdd ? 'a' : 'button';
    },
    title() {
      return this.isAdd
        ? 'Add group'
        : this.group.displayName || this.group.name;
    },
    href() {
      return this.isAdd ? '/group/create' : undefined;
    },
    text() {
      if (this.isAdd) {
        return '+';
      }
      if (!this.group.name) return '??';
      const groupName = this.group.name.replace(/\W+/g, ' ').trim();

      if (groupName.match(/\s/)) {
        return groupName
          .split(' ')
          .slice(0, 2)
          .map((chunk) => chunk.charAt(0))
          .join('')
          .toUpperCase();
      }

      return groupName.slice(0, 2).toUpperCase();
    },
    computedClasses() {
      return {
        [`vue--org-switcher-navatar__avatar--selected`]: this.group.selected,
        [`vue--org-switcher-navatar__avatar`]: true,
        [`vue--org-switcher-navatar__avatar--add`]: this.variant === 'add',
        [`vue--org-switcher-navatar__avatar--initials`]: !this.group.avatarUrl,
        [`vue--org-switcher-navatar__avatar--image`]: !!this.group.avatarUrl,
      };
    },
  },
  methods: {
    onClick(e) {
      if (this.isAdd) {
        e.stopPropagation();
      } else {
        this.$emit('click', this.group);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-navatar {
  display: flex;
  width: fit-content;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border-radius: global(border-radius);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &__avatar {
    border: 2px solid color(neutral, 72);
    border-radius: global(border-radius);
    background-color: color(ui, white);

    &:hover {
      border-color: color(neutral, 48);
    }

    &--initials,
    &--add {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;
      color: color(ui, white);
      background-color: color(brand, light);
      font-size: rem(28px);
    }

    &--add {
      background-color: color(neutral, 96);
      color: color(neutral, 66);
      font-size: rem(40px);

      &:hover {
        color: color(neutral, 48);
      }
    }

    &--selected {
      box-shadow: space(xxs) space(xxs) 0 rgba(color(neutral, 48), 0.2);
      border: 2px solid color(brand);
    }

    &--image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 64px;
      height: 64px;

      & img {
        border-radius: global(border-radius) - 2px;
      }
    }
  }
}
</style>
