import {
  SET_FILE,
  SET_ISSUES,
  SET_FILTERS,
  SET_FILTERED_ISSUES,
  SET_IGNORED_ISSUE,
  ADD_ISSUE_AS_VIEWING,
  REMOVE_ISSUE_AS_VIEWING,
  CLEAR_ALL_VIEWING_ISSUES,
} from '../mutation-types';
import { queryMatch } from '~/lib/search';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  state: {
    file: null,
    issues: [],
    filteredIssues: [],
    viewingIssues: [],
    filters: null,
  },

  getters: {
    file: (state) => {
      return state.file;
    },
    fileLines: (state) => {
      return state.file ? state.file.split('\n') : [];
    },
    issues: (state) => {
      return state.issues;
    },
    issuesGroupedByLineNumber(state) {
      const groupedIssues = {};
      const filteredIssues = state.filteredIssues;

      for (let i = 0; i < filteredIssues.length; i++) {
        if (
          Object.keys(groupedIssues).includes(
            filteredIssues[i].lineNumber.toString(),
          )
        ) {
          groupedIssues[filteredIssues[i].lineNumber].push(filteredIssues[i]);
        } else {
          groupedIssues[filteredIssues[i].lineNumber] = [filteredIssues[i]];
        }
      }

      return groupedIssues;
    },
    filteredIssues(state) {
      return state.filteredIssues;
    },
    viewingIssues(state) {
      return state.viewingIssues;
    },
  },

  actions: {
    async getCloudConfigIssues(
      { rootState, dispatch, commit },
      { org, project, filters, searchQuery, snapshot },
    ) {
      const res = await request(
        `${rootState.baseUrl}/org/${org.name}/project/${project.publicId}/file-issues/${snapshot.publicId}`,
      );
      const json = await res.json();

      commit('SET_FILE', json.file);
      commit('SET_ISSUES', json.issues);
      dispatch('filterIssues', { filters, searchQuery });
    },
    filterIssues({ state, commit }, { filters, searchQuery }) {
      let filteredIssues = state.issues;

      // Filter by issue severity
      if (filters['Severity level']) {
        filteredIssues = filteredIssues.filter((issue) => {
          return filters['Severity level'].includes(issue.severity);
        });
      }

      if (filters.Status) {
        // Filter by status
        if (!filters.Status.includes('isIgnored')) {
          filteredIssues = filteredIssues.filter((issue) => {
            return !issue.isIgnored;
          });
        }

        if (
          filters.Status.includes('isIgnored') &&
          !filters.Status.includes('isOpen')
        ) {
          filteredIssues = filteredIssues.filter((issue) => {
            return issue.isIgnored;
          });
        }

        if (!filters.Status.length) {
          filteredIssues = [];
        }
      }

      // Filter by search query
      if (searchQuery) {
        filteredIssues = filteredIssues.filter((issue) => {
          return (
            queryMatch(issue.title, searchQuery) ||
            queryMatch(issue.issue, searchQuery) ||
            queryMatch(issue.impact, searchQuery) ||
            queryMatch(issue.resolve, searchQuery)
          );
        });
      }

      commit('SET_FILTERED_ISSUES', filteredIssues);
      commit('SET_FILTERS', filters);
    },
    ignoreIssue({ state, commit, dispatch }, ignoredIssue) {
      commit('SET_IGNORED_ISSUE', ignoredIssue);
      commit('REMOVE_ISSUE_AS_VIEWING', ignoredIssue);
      dispatch('filterIssues', { filters: state.filters });
    },
  },

  mutations: {
    [SET_FILE](state, file) {
      state.file = file;
    },
    [SET_ISSUES](state, issues) {
      state.issues = issues;
    },
    [SET_FILTERS](state, filters) {
      state.filters = filters;
    },
    [SET_FILTERED_ISSUES](state, issues) {
      state.filteredIssues = issues;
    },
    [SET_IGNORED_ISSUE](state, ignoredIssue) {
      state.issues = state.issues.map((issue) => {
        if (issue.id === ignoredIssue.id) {
          issue.isIgnored = !!ignoredIssue.ignored.length;
          issue.ignored = ignoredIssue.ignored;
          return issue;
        }

        return issue;
      });
    },
    [ADD_ISSUE_AS_VIEWING](state, addIssue) {
      if (
        !state.viewingIssues.filter(
          (issue) =>
            issue.id === addIssue.id &&
            issue.lineNumber === addIssue.lineNumber,
        ).length
      ) {
        state.viewingIssues.push(addIssue);
      }
    },
    [REMOVE_ISSUE_AS_VIEWING](state, removeIssue) {
      state.viewingIssues = state.viewingIssues.filter((issue) => {
        return issue.id !== removeIssue.id;
      });
    },
    [CLEAR_ALL_VIEWING_ISSUES](state) {
      state.viewingIssues = [];
    },
  },
};
