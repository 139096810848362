function addIconsToIntegrationsList(integrations) {
  return integrations.map((integration) => {
    const integrationData = IntegrationsMap[integration.value];
    if (integrationData) {
      return {
        ...integration,
        icon: integrationData.icon,
        gradient: integration.gradient && {
          light: integrationData.light,
          dark: integrationData.dark,
        },
      };
    }
    return integration;
  });
}

const IntegrationsMap = {
  'artifactory-cr': {
    icon: () => import('~/components/CustomIcons/Artifactory'),
    light: '#61bb2f',
    dark: '#60a53a',
  },
  'artifactory-plugin': {
    icon: () => import('~/components/CustomIcons/Artifactory'),
    light: '#61bb2f',
    dark: '#60a53a',
  },
  artifactory: {
    icon: () => import('~/components/CustomIcons/Artifactory'),
    light: '#61bb2f',
    dark: '#60a53a',
    preRelease: 'Beta',
  },
  npm: {
    icon: () => import('~/components/CustomIcons/Npm'),
    light: '#c12127',
    dark: '#ad1d23',
    preRelease: 'Beta',
  },
  'aws-lambda': {
    icon: () => import('icons/Lambda'),
    light: '#fb7e14',
    dark: '#e66c04',
  },
  'azure-functions': {
    icon: () => import('icons/MicrosoftAzure'),
    light: '#0072c6',
    dark: '#00497f',
  },
  'azure-repos': {
    icon: () => import('icons/MicrosoftAzure'),
    light: '#0072c6',
    dark: '#00497f',
  },
  'azure-devops-server': {
    icon: () => import('icons/MicrosoftAzure'),
    light: '#0072c6',
    dark: '#00497f',
  },
  acr: {
    icon: () => import('~/components/CustomIcons/ACR'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  kubernetes: {
    icon: () => import('~/components/CustomIcons/Kubernetes'),
    light: '#326DE6',
    dark: '#2963D9',
    colorize: true,
  },
  'bitbucket-server': {
    icon: () => import('icons/Bitbucket'),
    light: '#2683fe',
    dark: '#0052cb',
  },
  'bitbucket-cloud': {
    icon: () => import('icons/Bitbucket'),
    light: '#2683fe',
    dark: '#0052cb',
  },
  'bitbucket-pipelines': {
    icon: () => import('icons/Bitbucket'),
    light: '#2683fe',
    dark: '#0052cb',
  },
  'azure-pipelines': {
    icon: () => import('~/components/CustomIcons/AzurePipelines'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  bluemix: {
    icon: () => import('~/components/CustomIcons/IbmCloud'),
    light: '#35cce5',
    dark: '#1b7ff9',
  },
  api: {
    icon: () => import('~/components/CustomIcons/Api'),
    light: '#7d43d0',
    dark: '#5f21b7',
  },
  check: {
    icon: () => import('icons/Check'),
    light: '#2683fe',
    dark: '#0052cb',
  },
  cli: {
    icon: () => import('~/components/CustomIcons/Cli'),
    light: '#7d43d0',
    dark: '#5f21b7',
  },
  circleci: {
    icon: () => import('~/components/CustomIcons/CircleCI'),
    light: '#333333',
    dark: '#161616',
  },
  'cloud-foundry': {
    icon: () => import('~/components/CustomIcons/CloudFoundry'),
    light: '#1aa3f1',
    dark: '#008cdb',
  },
  'docker-hub': {
    icon: () => import('icons/Docker'),
    light: '#0088d1',
    dark: '#03579b',
  },
  ecr: {
    icon: () => import('~/components/CustomIcons/ECR'),
    light: '#fb7e14',
    dark: '#e66c04',
  },
  'fortify-ssc': {
    icon: () => import('~/components/CustomIcons/FortifySsc'),
    light: '#44bff3',
    dark: '#00abf3',
  },
  'risk-sense': {
    icon: () => import('~/components/CustomIcons/RiskSense'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  saml: {
    icon: () => import('~/components/CustomIcons/Saml'),
    light: '#fff',
    dark: '#fff',
  },
  activedirectory: {
    icon: () => import('~/components/CustomIcons/ActiveDirectory'),
    light: '#fff',
    dark: '#fff',
  },
  azure: {
    icon: () => import('~/components/CustomIcons/Azure'),
    light: '#fff',
    dark: '#fff',
  },
  github: {
    icon: () => import('icons/Github'),
    light: '#5c417e',
    dark: '#242e60',
  },
  'github-enterprise': {
    icon: () => import('icons/Github'),
    light: '#121518',
    dark: '#24292d',
  },
  gitlab: {
    icon: () => import('icons/Gitlab'),
    light: '#fb7e14',
    dark: '#e66c04',
  },
  'google-cloud': {
    icon: () => import('~/components/CustomIcons/GoogleCloudPlatform'),
    light: '#161616',
    dark: '#161616',
  },
  gcr: {
    icon: () => import('~/components/CustomIcons/GoogleCloudPlatform'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  google: {
    icon: () => import('~/components/CustomIcons/Google'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'google-artifact-cr': {
    icon: () => import('~/components/CustomIcons/GoogleArtifactCr'),
    light: '#3367D6',
    dark: '#1849B2',
  },
  'harbor-cr': {
    icon: () => import('~/components/CustomIcons/Harbor'),
    light: '#817E7F',
    dark: '#696566',
  },
  heroku: {
    icon: () => import('~/components/CustomIcons/Heroku'),
    light: '#7d43d0',
    dark: '#5f21b7',
  },
  'ibm-cloud': {
    icon: () => import('~/components/CustomIcons/IbmCloud'),
    light: '#35cce5',
    dark: '#1b7ff9',
  },
  'visual-studio-code': {
    icon: () => import('icons/MicrosoftVisualStudioCode'),
    light: '#1479cc',
    dark: '#0e66b8',
  },
  'vscode-vuln-cost': {
    icon: () => import('icons/MicrosoftVisualStudioCode'),
    light: '#1479cc',
    dark: '#0e66b8',
  },
  'visual-studio': {
    icon: () => import('icons/MicrosoftVisualStudio'),
    light: '#854cc7',
    dark: '#6c33af',
  },
  'android-studio': {
    icon: () => import('~/components/CustomIcons/AndroidStudio'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  appcode: {
    icon: () => import('~/components/CustomIcons/AppCode'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  goland: {
    icon: () => import('~/components/CustomIcons/GoLand'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  intellij: {
    icon: () => import('~/components/CustomIcons/Intellij'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  phpstorm: {
    icon: () => import('~/components/CustomIcons/PhpStorm'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  pycharm: {
    icon: () => import('~/components/CustomIcons/PyCharm'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'quay-cr': {
    icon: () => import('~/components/CustomIcons/Quay'),
    light: '#40B4E5',
    dark: '#003764',
  },
  rider: {
    icon: () => import('~/components/CustomIcons/Rider'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  rubymine: {
    icon: () => import('~/components/CustomIcons/RubyMine'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  webstorm: {
    icon: () => import('~/components/CustomIcons/WebStorm'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  eclipse: {
    icon: () => import('~/components/CustomIcons/Eclipse'),
    light: '#42337E',
    dark: '#2C2254',
  },
  jenkins: {
    icon: () => import('~/components/CustomIcons/Jenkins'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  jira: {
    icon: () => import('icons/Jira'),
    light: '#0052cc',
    dark: '#062a62',
  },
  nexus: {
    icon: () => import('~/components/CustomIcons/Nexus'),
    light: '#4ba8e9',
    dark: '#684beb',
  },
  pivotal: {
    icon: () => import('~/components/CustomIcons/PivotalWebServices'),
    light: '#008379',
    dark: '#01554f',
  },
  slack: {
    icon: () => import('icons/Slack'),
    light: '#3ddf9a',
    dark: '#2eb67c',
  },
  'snyk-api': {
    icon: () => import('~/components/CustomIcons/SnykApi'),
    light: '#56c5ab',
    dark: '#508bab',
  },
  teamcity: {
    icon: () => import('~/components/CustomIcons/TeamCity'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  other: {
    icon: () => import('icons/Plus.vue'),
    light: 'hsl(244, 8%, 66%)',
    dark: 'hsl(244, 8%, 50%)',
  },
  brinqa: {
    icon: () => import('~/components/CustomIcons/Brinqa'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  vulcan: {
    icon: () => import('~/components/CustomIcons/Vulcan'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'nucleus-security': {
    icon: () => import('~/components/CustomIcons/NucleusSecurity'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'kenna-security': {
    icon: () => import('~/components/CustomIcons/KennaSecurity'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'code-dx': {
    icon: () => import('~/components/CustomIcons/CodeDX'),
    light: '#ffffff',
    dark: '#f3f3f3',
  },
  'aws-config': {
    icon: () => import('~/components/CustomIcons/AWSConfig'),
    light: '#ff9900',
    dark: '#ff9900',
  },
  'github-cr': {
    icon: () => import('icons/Github'),
    light: '#5c417e',
    dark: '#242e60',
  },
  'nexus-cr': {
    icon: () => import('~/components/CustomIcons/NexusCR'),
    light: '#0F9E5B',
    dark: '#29B473',
  },
  'digitalocean-cr': {
    icon: () => import('~/components/CustomIcons/DigitalOceanCR'),
    light: '#0080FF',
    dark: '#086CD0',
  },
  'gitlab-cr': {
    icon: () => import('icons/Gitlab'),
    light: '#fb7e14',
    dark: '#e66c04',
  },
  'terraform-cloud': {
    icon: () => import('~/components/CustomIcons/TerraformCloud'),
    light: '#4040B2',
    dark: '#4040B2',
  },
};
const connectionStatuses = {
  VALID: 'valid',
  INVALID: 'invalid',
  UNCONFIGURED: 'unconfigured',
  ERROR: 'error',
};

export { IntegrationsMap, addIconsToIntegrationsList, connectionStatuses };
