<template>
  <div class="vue--caption"><slot /></div>
</template>

<script>
export default {
  name: 'BaseCaption',
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--caption {
  @include font-stack(regular, sans-serif, normal, true);

  font-size: rem(12px);
  line-height: rem(16px);
}
</style>
