<template>
  <BaseMultipleValueInput
    class="vue--user-selector"
    :class="computedClasses"
    data-snyk-test="UserSelector"
    v-bind="$attrs"
    :values="values"
    :data="users"
    :placeholder="placeholder"
    input-placeholder="Search users…"
    selection-only
    :search-match="['name', 'email']"
    @update="update"
    v-on="$listeners"
  >
    <template #chip="{ value, isRemovable, removeValue }">
      <div
        class="vue--user-selector__selected-user"
        data-snyk-test="UserSelector: selected user"
      >
        <BaseAvatarUsername :label="value.name" v-bind="value" />
        <CloseIcon
          v-if="isRemovable"
          title="Remove user from selection"
          class="vue--user-selector__remove"
          data-snyk-test="UserSelector: remove"
          @click="removeValue(value)"
        />
      </div>
    </template>
    <template #item="{ item }">
      <BaseAvatarUsername
        class="vue--user-selector__item"
        :label="item.name"
        v-bind="item"
        :display-email="displayEmail"
      />
    </template>

    <template #postItems>
      <BaseNoResults
        v-if="error"
        preset-graphic="failure"
        :title="error"
        size="small"
        inline
      />
    </template>
  </BaseMultipleValueInput>
</template>

<script>
import CloseIcon from 'icons/Close';

import has from 'lodash/has';

export default {
  name: 'BaseUserSelector',

  components: {
    CloseIcon,
  },

  model: {
    prop: 'values',
    event: 'change',
  },

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    users: {
      type: Array,
      default: () => [],
    },
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Add a user',
    },
    displayEmail: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--user-selector--single': has(this.$attrs, 'single'),
      };
    },
  },

  methods: {
    update(values) {
      this.$emit('change', values);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--user-selector {
  $self: &;

  &__selected-user {
    align-items: center;
    display: flex;
    height: rem(28px);
    margin-right: space(xs);
    width: 100%;
  }
  &__remove {
    color: color(base, dimmed);
    line-height: 1;
    margin-left: space(xs);
    transition: all 0.25s ease-in-out;
    text-align: center;

    ::v-deep svg {
      height: 18px;
      width: 18px;
    }

    &:hover,
    &:focus {
      color: color(action);
      cursor: pointer;
    }
  }
  &__item {
    margin: space(xxs) 0;
  }

  &--single {
    #{$self}__selected-user {
      display: flex;
      justify-content: space-between;
    }
    #{$self}__remove {
      margin-left: space(s);
    }
  }
}
</style>
