export class RequestError extends Error {
  public statusCode: number;
  public requestId?: string | null;
  public innerError?: Error;
  public safeUserMessage?: string;
  public isClientError: boolean;
  public isServerError: boolean;
  public body?: object;

  public constructor(
    name: string,
    statusCode: number,
    requestId: string | null,
    safeUserMessage?: string,
    body?: object,
  ) {
    super(name);
    this.name = this.constructor.name;
    this.requestId = requestId;
    this.innerError = undefined;
    this.statusCode = statusCode;
    this.safeUserMessage = safeUserMessage;
    this.body = body;
    this.isClientError = statusCode > 399 && statusCode < 500;
    this.isServerError = statusCode > 499;
  }
}
