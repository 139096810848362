import 'core-js/features/array';
import 'core-js/features/map';
import 'core-js/features/number';
import 'core-js/features/object';
import 'core-js/features/promise';
import 'core-js/features/string';
import 'core-js/features/symbol';
import 'core-js/web/dom-collections';

import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import PortalVue from 'portal-vue';
import store from '~/store';
import registerGlobalComponents from './register-global-components';
import renderSiteHeader from './render-site-header';
import registerOnDoneFetching from './on-done-fetching';
import sentryOwnership from './sentry-ownership';
import Notifications from '~/plugins/Notifications';

if (/^app\.[^.]+\.snyk\.io$/.test(window.location.host)) {
  __webpack_public_path__ = // eslint-disable-line no-undef
    'https://static.dev.snyk.io/dev/static-assets/js/build/frontend/';
} else if (window.location.host === 'app.snyk.io') {
  __webpack_public_path__ = // eslint-disable-line no-undef
    'https://static.snyk.io/prod/static-assets/js/build/frontend/';
} else {
  __webpack_public_path__ = '/js/build/frontend/'; // eslint-disable-line no-undef
}

/**
 * Enable the Composition API.
 * -------------------------------------//
 * The Composition API is "a reactive API coupled with the ability to register
 * lifecycle hooks using globally imported functions" developed for Vue 3 and
 * back ported to Vue 2 (see link below for more info).
 * It also introduces the `defineComponent` fn which gives us enhanced
 * Typescript support for Vue components (more seamless than `Vue.extend`).
 * Note: `defineComponent` is compatible with the existing Options API
 * https://v3.vuejs.org/guide/composition-api-introduction.html
 */
Vue.use(VueCompositionAPI);

/**
 * Enable VueX plugin.
 * -------------------------------------//
 * Vuex is a state management pattern + library for Vue.js applications.
 * It serves as a centralized store for all the components in an
 * application, with rules ensuring that the state can only be mutated
 * in a predictable fashion.
 */
Vue.use(Vuex);

/**
 * Enable Vue router plugin.
 * -------------------------------------//
 * Vue Router is the official router for Vue.js.
 * It deeply integrates with Vue.js core to make building Single Page
 * Applications with Vue.js a breeze.
 */
Vue.use(VueRouter);

/**
 * Enable our custom Notifications plugin.
 * -------------------------------------//
 * This plugin allows us to use e.g. `this.$notify.success()` from
 * anywhere in the system to get a notification fixed to the top
 * of the viewport.
 */
Vue.use(Notifications);

/**
 * Enable portal-vue plugin.
 * -------------------------------------//
 * This plugin allows us to render a component in a target location
 * within the UI, such as defining a modal within a component and rendering
 * the modal outside of the component e.g. anywhere in the DOM.
 * We're using this plugin as a prelude to the "teleport" feature introduced
 * in Vue 3.0.
 */
Vue.use(PortalVue);

// Setup error handler.
Vue.config.errorHandler = require('~/lib/error-handler');

// Globally register `Base` components.
registerGlobalComponents(Vue);

renderSiteHeader(Vue);

sentryOwnership();

const onDoneFetching = registerOnDoneFetching(Vue);

export { Vue, VueRouter, Vuex, store, onDoneFetching };
