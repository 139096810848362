import TheSiteHeader from '~/components/TheSiteHeader/TheSiteHeader';

function renderSiteHeader(Vue) {
  const target = document.querySelector('[data-vue="site-header"]');
  const { user, org, group, headway } = window.$embedded;

  const props = { partner: window.$embedded.partner };

  if (target) {
    new Vue({
      el: target,
      provide: { user, org, group, headway },
      render: (h) => h(TheSiteHeader, { props }),
    });
  }
}

export default renderSiteHeader;
