<template>
  <LayoutFormElement class="vue--textarea" :inline="inline" :fill-width="true">
    <template v-if="label" #label>
      <label class="vue--textarea__label" :for="$attrs.id" v-text="label" />
    </template>
    <textarea
      v-bind="$attrs"
      class="vue--textarea__field"
      :value="value"
      :class="computedTextareaClasses"
      v-on="listeners"
    />
    <BaseInputNote v-if="note" :variant="noteVariant">
      {{ note }}
    </BaseInputNote>
  </LayoutFormElement>
</template>

<script>
import LayoutFormElement from '~/components/LayoutFormElement/LayoutFormElement';
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseTextarea',

  status: 'ready',

  components: { LayoutFormElement },

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: null,
    },
    /**
     * The label of the text area field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Whether to show an error for this field
     * `true, false`
     */
    error: {
      type: Boolean,
      default: false,
    },
    /**
     * Manually trigger various states of the input.
     * `hover, active, focus`
     */
    state: {
      type: String,
      default: null,
      validator: isInList(['hover', 'active', 'focus']),
    },
    /**
     * Whether the form text area field is displayed inline.
     * `true, false`
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * A note to be displayed below this input.
     */
    note: {
      type: String,
      default: null,
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (e) => this.$emit('input', e.target.value),
        focus: (e) => this.$emit('focus', e.target.value),
        keyup: (e) => {
          if (e.keyCode === 13) {
            this.$emit('enter');
          } else {
            this.$emit('keyup', e);
          }
        },
        keydown: (e) => this.$emit('keydown', e),
        blur: (e) => this.$emit('blur', e.target.value),
      };
    },
    computedTextareaClasses() {
      return {
        [this.state]: this.state !== null,
        'vue--textarea__field--error': this.error,
      };
    },
    noteVariant() {
      return this.error ? 'error' : 'default';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--textarea {
  $self: &;

  &__field {
    @include fieldStyles();
    @include hoverState();

    display: block;

    &[disabled],
    &:disabled {
      @include disabledState();
    }

    &--error {
      @include errorState();
    }
  }
}
</style>
"
