<template>
  <ul
    role="none"
    class="vue--org-switcher-group-list"
    data-snyk-test="TheOrgSwitcherGroupList: list"
  >
    <template v-if="!groupsLoaded">
      <li class="vue--org-switcher-group-list__item">
        <BaseSkeleton :width="64" :height="64" />
      </li>
    </template>
    <template v-for="group in groups">
      <li
        :key="group.id"
        class="vue--org-switcher-group-list__item"
        role="none"
      >
        <TheOrgSwitcherNavatar
          :group="group"
          :data-snyk-test="testHook(group)"
          :data-group="group.id"
          @click="propagate('click', $event, false)"
        />
      </li>
    </template>
    <template v-if="showCreateGroup">
      <li role="none" class="vue--org-switcher-group-list__item">
        <TheOrgSwitcherNavatar
          data-snyk-test="TheOrgSwitcherGroupList: create group"
          variant="add"
        />
      </li>
    </template>
    <template v-if="showPersonalOrgs && personal">
      <li
        role="none"
        class="
          vue--org-switcher-group-list__item
          vue--org-switcher-group-list--ungrouped
        "
      >
        <TheOrgSwitcherNavatar
          :group="personal"
          @click="propagate('click', $event, true)"
        />
      </li>
    </template>
  </ul>
</template>

<script>
import TheOrgSwitcherNavatar from '~/components/TheOrgSwitcherNavatar/TheOrgSwitcherNavatar';

export default {
  name: 'TheOrgSwitcherGroupList',
  components: {
    TheOrgSwitcherNavatar,
  },
  inject: ['group'],
  props: {
    groups: {
      type: Array,
    },
    personal: {
      type: Object,
      default: null,
    },
    showCreateGroup: {
      type: Boolean,
      default: false,
    },
    showPersonalOrgs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    groupsLoaded() {
      return !!this.groups;
    },
  },
  methods: {
    propagate(type, group, isPersonal) {
      switch (type) {
        case 'click':
          this.$emit('click', { group, isPersonal });
          break;
      }
    },
    testHook(group) {
      return `TheOrgSwitcherGroupList: group ${group.name}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-group-list {
  background-color: color(neutral, 96);
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  scrollbar-gutter: stable;
  padding: space(xs);

  margin: 0;
  list-style: none;

  &__item {
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: none;
    min-width: 64px;
  }

  &__item + &__item {
    margin-top: space(xs);
  }

  &--ungrouped {
    flex: 1;
    justify-content: flex-end;

    &:before {
      border-top: 2px solid color(neutral, 84);
      content: '';
      display: block;
      margin-bottom: space(xs);
      margin-top: space(xs);
    }
  }
}
</style>
