export function highlight(string, match) {
  if (!match) return string;

  // Escape match string for RegExp usage i.e. () becomes \(\)
  match = match.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

  return match
    ? string.replace(
        new RegExp(`(${match})(?!([^<]+)?>)`, 'ig'),
        '<span class="highlight">$&</span>',
      )
    : string;
}

export function queryMatch(value, query) {
  if (!value || !query) return;

  return (
    query &&
    query.length &&
    value
      .replace(/<\/?[^>]+(>|$)/g, '')
      .toLowerCase()
      .includes(query.toLowerCase())
  );
}
