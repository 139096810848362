<template>
  <span
    :aria-hidden="decorative"
    :aria-label="title"
    class="material-design-icon snyk-icon"
    role="img"
    @click="$emit('click', $event)"
  >
    <svg
      :fill="fillColor"
      :height="size"
      :width="size"
      class="material-design-icon__svg"
      viewBox="0 0 24 24"
    >
      <path
        :fill="fillColor"
        d="M3.646 14.917c-0.676 0-1.225-0.116-1.695-0.341l0.143-1.080c0.497 0.244 1.057 0.36 1.526 0.36 0.343 0 0.568-0.117 0.568-0.322 0-0.593-2.139-0.451-2.139-2 0-0.99 0.909-1.511 2.049-1.511 0.568 0 1.154 0.154 1.534 0.288l-0.154 1.062c-0.398-0.154-0.94-0.297-1.391-0.297-0.278 0-0.504 0.098-0.504 0.278 0 0.585 2.184 0.469 2.184 1.98 0 1.006-0.895 1.583-2.121 1.583l-0 0zM9.56 14.816v-2.675c0-0.611-0.271-0.9-0.786-0.9-0.251 0-0.515 0.071-0.695 0.18v3.395h-1.587v-4.636l1.553-0.128-0.038 0.758h0.053c0.335-0.45 0.902-0.792 1.58-0.792 0.812 0 1.514 0.503 1.514 1.692v3.106h-1.593zM20.046 14.816l-1.271-2.206h-0.128v2.205h-1.587v-5.491l1.587-2.476v5.124c0.316-0.386 1.38-1.853 1.38-1.853h1.958l-1.857 1.961 1.921 2.739h-2.004v-0.004zM15.004 10.134l-0.651 2.115c-0.127 0.398-0.251 1.129-0.251 1.129s-0.098-0.758-0.233-1.156l-0.696-2.089h-1.778l1.933 4.682c-0.263 0.623-0.669 1.148-1.211 1.148-0.098 0-0.195-0.004-0.29-0.015l-0.631 0.975c0.199 0.112 0.579 0.229 1.030 0.229 1.173 0 1.959-0.949 2.508-2.352l1.842-4.667-1.572 0.001z"
      ></path>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'SnykIcon',

  status: 'ready',

  props: {
    title: {
      type: String,
      default: 'Snyk',
    },
    decorative: {
      type: Boolean,
      default: false,
    },
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    size: {
      type: Number,
      default: 24,
    },
  },
};
</script>
