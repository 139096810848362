<template>
  <div class="vue--status-bar">
    <span
      :class="computedClasses"
      :style="computedStyles"
      class="vue--status-bar__progress"
    ></span>
  </div>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

const variants = ['default', 'success', 'processing', 'failure'];

export default {
  name: 'BaseStatusBar',
  status: 'ready',

  props: {
    /**
     * Variant of status bar. Must be one of `success`, `processing`, `failure`.
     * Defaults to `default`
     */
    variant: {
      type: String,
      default: 'default',
      validator: isInList(variants),
    },

    /**
     * The progress percentage of the status bar which controls the width.
     * If outside the range of 0-100, or not provided, the bar will display at 100% width.
     */
    progress: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--status-bar__progress--${this.variant}`]: true,
      };
    },

    computedStyles() {
      const width =
        this.progress < 0 || this.progress > 100 ? 100 : this.progress;

      return {
        width: `${width}%`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--status-bar {
  height: 4px;
  width: 100%;
  background-color: color(neutral, 90);

  &__progress {
    display: block;
    background-color: color(brand, light);
    height: 100%;
    position: relative;

    &--success {
      background-color: color(alert, success-text);
    }

    &--failure {
      background-color: color(alert, danger-text);
    }

    &--processing {
      background-color: color(alert, warning-border);

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: linear-gradient(
          -45deg,
          rgba(255, 255, 255, 0.2) 25%,
          transparent 25%,
          transparent 50%,
          rgba(255, 255, 255, 0.2) 50%,
          rgba(255, 255, 255, 0.2) 75%,
          transparent 75%,
          transparent
        );
        z-index: 1;
        background-size: 50px 50px;
        animation: move 2s linear infinite;
        overflow: hidden;
      }

      @keyframes move {
        0% {
          background-position: 0 0;
        }
        100% {
          background-position: 50px 50px;
        }
      }
    }
  }
}
</style>
