export function detectQuadrant(
  x: number,
  y: number,
  elementWidth: number,
  elementHeight = 0,
  containerX = 0,
  containerY = 0,
  containerWidth: number,
  containerHeight: number,
) {
  const totalContextWidth = containerWidth || document.body.clientWidth || 0;
  const totalContextHeight = containerHeight || document.body.clientHeight || 0;
  const quadrantWidth = totalContextWidth / 3;
  const quadrantHeight = totalContextHeight / 3;
  const quadrants = {
    topLeft: {
      width: {
        start: 0,
        end: quadrantWidth,
      },
      height: {
        start: 0,
        end: quadrantHeight,
      },
    },
    topCenter: {
      width: {
        start: quadrantWidth,
        end: quadrantWidth * 2,
      },
      height: {
        start: 0,
        end: quadrantHeight,
      },
    },
    topRight: {
      width: {
        start: quadrantWidth * 2,
        end: totalContextWidth,
      },
      height: {
        start: 0,
        end: quadrantHeight,
      },
    },
    centerLeft: {
      width: {
        start: 0,
        end: quadrantWidth,
      },
      height: {
        start: quadrantHeight,
        end: quadrantHeight * 2,
      },
    },
    centerCenter: {
      width: {
        start: quadrantWidth,
        end: quadrantWidth * 2,
      },
      height: {
        start: quadrantHeight,
        end: quadrantHeight * 2,
      },
    },
    centerRight: {
      width: {
        start: quadrantWidth * 2,
        end: totalContextWidth,
      },
      height: {
        start: quadrantHeight,
        end: quadrantHeight * 2,
      },
    },
    bottomLeft: {
      width: {
        start: 0,
        end: quadrantWidth,
      },
      height: {
        start: quadrantHeight * 2,
        end: totalContextHeight,
      },
    },
    bottomCenter: {
      width: {
        start: quadrantWidth,
        end: quadrantWidth * 2,
      },
      height: {
        start: quadrantHeight * 2,
        end: totalContextHeight,
      },
    },
    bottomRight: {
      width: {
        start: quadrantWidth * 2,
        end: totalContextWidth,
      },
      height: {
        start: quadrantHeight * 2,
        end: totalContextHeight,
      },
    },
  };

  const location = {
    quadrant: 'unknown',
    overflowsContainer: false,
  };
  const posX = x - containerX;
  const posY = y - containerY;
  for (const [key, quadrant] of Object.entries(quadrants)) {
    if (
      posX >= quadrant.width.start &&
      posX <= quadrant.width.end &&
      posY >= quadrant.height.start &&
      posY <= quadrant.height.end
    ) {
      location.quadrant = key;
    }
  }

  if (
    posX + elementWidth > totalContextWidth ||
    posY + elementHeight > totalContextHeight
  )
    location.overflowsContainer = true;

  return location;
}
