<template>
  <div class="vue--skeleton" :style="dimensions" data-snyk-test="Skeleton">
    <div class="vue--skeleton__background" />
  </div>
</template>

<script>
export default {
  name: 'BaseSkeleton',
  status: 'ready',

  props: {
    height: {
      type: Number,
      default: null,
    },
    width: {
      type: Number,
      default: null,
    },
  },

  computed: {
    dimensions() {
      return `height: ${this.height}px; width: ${this.width}px;`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--skeleton {
  background: color(neutral, 96);
  overflow: hidden;
  position: relative;

  &__background {
    background: color(neutral, 96);
    background-image: linear-gradient(
      to right,
      color(neutral, 96) 0%,
      color(neutral, 90) 50%,
      color(neutral, 96) 100%
    );
    background-repeat: no-repeat;
    box-shadow: none;
    position: relative;
    height: 100%;
    width: 100%;

    animation-duration: 5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: Shimmer;
    animation-timing-function: linear;
  }
}

@keyframes Shimmer {
  0% {
    transform: translateX(-100%);
  }

  20%,
  100% {
    transform: translateX(100%);
  }
}
</style>
