export { request };

import * as parse from 'parse-link-header';
import * as cache from '~/lib/cache';

type ResponseWithLinks = Response & { links: any };

import { RequestError } from './request-error';
/* eslint-disable @typescript-eslint/no-unused-vars */
declare global {
  interface Window {
    $embedded: any;
    Sentry: any;
    analytics: any;
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

interface RequestConfig {
  method: 'GET' | 'POST';
  body?: object;
  abortController?: AbortController;
  headers?: object;
  cache?: boolean;
  cacheExpiry?: number;
}

async function request(
  url: string,
  config: RequestConfig = {
    method: 'GET',
    headers: {},
  },
): Promise<ResponseWithLinks> {
  if (config.cache) {
    const result = cache.get(url) as ResponseWithLinks | undefined;

    if (result) {
      // Note: clone is important here as it allows re-reads
      // of the body. See https://jakearchibald.com/2014/reading-responses/
      return cloneResponse(result);
    }
  }
  const response = (await fetch(url, {
    method: config.method,
    credentials: 'same-origin',
    headers: new Headers({
      ...config.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      ...(window.$embedded &&
        window.$embedded.csrfToken && {
          'X-CSRF-TOKEN': window.$embedded.csrfToken,
        }),
    }),
    body: JSON.stringify(config.body),
    signal: config.abortController ? config.abortController.signal : undefined,
  })) as ResponseWithLinks;

  if (!response.ok) {
    if (response.headers.get('x-snyk-not-authenticated')) {
      // Session has expired, reload page to get redirected to login
      window.location.reload();
    }
    let json, safeUserMessage;
    try {
      json = await response.json();
      safeUserMessage = json.safeUserMessage;
    } catch (err) {
      // Continue regardless of error as we throw after
    }

    const requestId = response.headers.get('snyk-request-id');

    if (window.Sentry) {
      window.Sentry.onLoad(() => {
        window.Sentry.setContext('request', {
          url,
          method: config.method,
          requestId,
          statusCode: response.status,
        });
      });
    }

    throw new RequestError(
      'Failed to fetch',
      response.status,
      requestId,
      safeUserMessage,
      json,
    );
  }

  if (response.headers.has('link')) {
    response.links = parse(response.headers.get('link'));
  }

  if (config.cache) {
    // Note: clone is important here as it allows re-reads
    // of the body. See https://jakearchibald.com/2014/reading-responses/
    cache.set(url, cloneResponse(response), config.cacheExpiry);
  }

  return response;
}

// Creates a cloned version of the response, to allow multiple uses of
// body objects
// See: https://developer.mozilla.org/en-US/docs/Web/API/Response/clone
function cloneResponse(response) {
  const clone = response.clone() as ResponseWithLinks;
  clone.links = response.links;
  return clone;
}
