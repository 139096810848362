import { Plugin } from './itly';
import type { Event, Properties, ValidationResponse } from './itly';

export default class extends Plugin {
  constructor() {
    super('snyk-itly-error-plugin-sentry');
  }

  postIdentify(
    userId: string | undefined,
    properties: Properties | undefined,
    validationResponses: ValidationResponse[],
  ) {
    this.reportError(validationResponses);
  }

  postGroup(
    userId: string | undefined,
    groupId: string,
    properties: Properties | undefined,
    validationResponses: ValidationResponse[],
  ) {
    this.reportError(validationResponses);
  }

  postPage(
    userId: string | undefined,
    category: string | undefined,
    name: string | undefined,
    properties: Properties | undefined,
    validationResponses: ValidationResponse[],
  ) {
    this.reportError(validationResponses);
  }

  postTrack(
    userId: string | undefined,
    event: Event,
    validationResponses: ValidationResponse[],
  ) {
    this.reportError(validationResponses);
  }

  reportError(validationResponses: ValidationResponse[]) {
    const errorMessage = validationResponses
      .filter((r) => !r.valid)
      .map((r) => r.message)
      .join('\n');
    if (errorMessage) {
      const err = new Error(errorMessage);
      if (typeof window == 'undefined' || !window.Sentry) {
        console.error(err);
      } else {
        window.Sentry.captureException(err);
      }
    }
  }
}
