<template>
  <div :class="parentClasses" class="vue--search-input">
    <MagnifyIcon
      decorative
      :size="searchIconSize"
      class="vue--search-input__search-icon"
    />
    <input
      v-bind="$attrs"
      :value="value"
      :size="size"
      type="search"
      class="vue--search-input__field"
      :placeholder="placeholder"
      :aria-label="placeholder"
      v-on="listeners"
    />
    <CloseIcon
      v-if="showCloseIcon"
      title="Reset"
      :class="computedClasses"
      :size="closeIconSize"
      data-snyk-test="BaseSearchInput: close"
      class="vue--search-input__close-icon"
      @click="clearInput"
    />
  </div>
</template>

<script>
import MagnifyIcon from 'icons/Magnify';
import CloseIcon from 'icons/Close';
import { isInList } from '~/lib/prop-validators';

/**
 * Search input field .
 */
export default {
  name: 'BaseSearchInput',

  status: 'ready',

  components: {
    MagnifyIcon,
    CloseIcon,
  },

  inheritAttrs: false,

  model: {
    event: 'change',
  },

  props: {
    /**
     * Sizing for this search input.
     * `small`
     */
    size: {
      type: String,
      default: null,
      validator: isInList(['small']),
    },
    /**
     * Value for search input.
     */
    value: {
      type: String,
      default: null,
    },
    /**
     * Optional override for hiding the clear icon
     */
    clearable: {
      type: Boolean,
      default: true,
    },
    /**
     * Placeholder text to display when search input is empty
     */
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        // TODO: if we change 'change' to 'input' v-model
        // will work out of the box. Requires a refactor of
        // anything listening for the 'change' even however.
        change: (e) => this.$emit('change', e.target.value),
        input: (e) => this.$emit('change', e.target.value),
        focus: (e) => {
          this.toggleFocus();
          this.$emit('focus', e.target.value);
        },
        keyup: (e) => {
          if (e.keyCode === 13) {
            this.$emit('enter');
          } else {
            this.$emit('keyup', e);
          }
        },
        keydown: (e) => this.$emit('keydown', e),
        blur: (e) => {
          this.toggleFocus();
          this.$emit('blur', e.target.value);
        },
      };
    },
    computedClasses() {
      return {
        [`vue--search-input__close-icon--show`]: !!this.value,
      };
    },
    parentClasses() {
      return {
        [`vue--search-input--focused`]: this.isFocused,
        [`vue--search-input--${this.size}`]: this.size !== null,
        'vue--search-input--disabled': this.isDisabled,
      };
    },
    searchIconSize() {
      return this.size === 'large' ? 24 : 18;
    },
    closeIconSize() {
      return this.size === 'large' ? 22 : 16;
    },
    isDisabled() {
      return (
        this.$attrs.disabled !== undefined && this.$attrs.disabled !== false
      );
    },
    showCloseIcon() {
      return this.clearable && !!this.value && !this.isDisabled;
    },
  },

  methods: {
    toggleFocus() {
      this.isFocused = !this.isFocused;
    },
    clearInput() {
      this.$emit('change', null);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--search-input {
  $self: &;

  position: relative;

  &__field {
    @include fieldStyles();
    @include hoverState();

    padding-left: space(s) + 26px + space(xxs);
    padding-right: space(s) + 26px + space(xs);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button {
      display: none;
    }

    &[disabled],
    &:disabled {
      @include disabledState();
    }
  }

  &__search-icon {
    transition: global(hoverState, transition);
    position: absolute;
    left: space(s);
    color: color(neutral, 24);
    top: 10px;

    ::v-deep svg {
      height: 26px;
      width: 26px;
    }
  }

  &__close-icon {
    position: absolute;
    right: space(s);
    color: color(neutral, 72);
    cursor: pointer;
    display: none;
    top: 12px;

    ::v-deep svg {
      height: 21px;
      width: 21px;
    }

    &--show {
      display: block;
    }
  }

  &--focused {
    #{$self}__search-icon {
      color: color(action);
    }
  }

  &--small {
    #{$self}__field {
      line-height: rem(22px);
    }

    #{$self}__search-icon,
    #{$self}__close-icon {
      top: 9px;

      ::v-deep svg {
        height: 18px;
        width: 18px;
      }
    }
  }

  &--disabled {
    #{$self}__search-icon {
      opacity: 0.5;
    }
  }
}
</style>
