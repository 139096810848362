<template>
  <div
    class="vue--focus-trap"
    :class="computedClasses"
    @keydown.esc="$emit('escape')"
  >
    <div :tabindex="getTabindex" @focus="handleFocusStart" />
    <div
      ref="focusTrap"
      class="vue--focus-trap__container"
      :class="computedClasses"
    >
      <slot />
    </div>
    <div :tabindex="getTabindex" @focus="handleFocusEnd" />
  </div>
</template>

<script>
const focusableElementsSelector = [
  ...['input', 'select', 'button', 'textarea'].map(
    (field) => `${field}:not([disabled])`,
  ),
  'a[href]',
  'video[controls]',
  'audio[controls]',
  '[tabindex]:not([tabindex^="-"])',
  '[contenteditable]:not([contenteditable="false"])',
].join(',');

export default {
  name: 'FocusTrap',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      alreadyFocused: false,
    };
  },

  computed: {
    computedClasses() {
      return {
        'vue--focus-trap--inline': this.isInline,
      };
    },
    getTabindex() {
      return this.disabled ? -1 : 0;
    },
  },

  watch: {
    isVisible: 'focusFirst',
  },

  mounted() {
    this.focusFirst(this.isVisible || true);
  },

  methods: {
    getFocusableElements() {
      const focusableElements =
        this.$refs.focusTrap &&
        this.$refs.focusTrap.querySelectorAll(focusableElementsSelector);
      if (focusableElements && focusableElements.length)
        return focusableElements;
      return [];
    },

    focusFirst(visible) {
      if (!visible) return;
      const elements = this.getFocusableElements();
      if (elements.length) setTimeout(() => elements[0].focus(), 200);
    },

    handleFocusStart() {
      const elements = this.getFocusableElements();
      if (elements.length) {
        const index = this.alreadyFocused ? elements.length - 1 : 0;
        this.alreadyFocused = true;
        elements[index].focus();
      }
    },

    handleFocusEnd() {
      const elements = this.getFocusableElements();
      elements.length && elements[0].focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--focus-trap {
  &--inline {
    display: contents;
  }

  &__container {
    height: 100%;
  }
}
</style>
