<template>
  <div class="vue--button-dropdown" @focusout="onClose">
    <BaseButton
      aria-label="Expand list of options"
      class="vue--button-dropdown__btn"
      :size="size"
      tabindex="0"
      v-bind="$attrs"
      @click="toggleDropdown($event)"
    >
      {{ text }}
      <ChevronDownIcon
        decorative
        :class="computedIconClass"
        class="vue--button-dropdown__icon"
      />
    </BaseButton>
    <div :class="computedClasses" class="vue--button-dropdown__content">
      <div v-if="isOpen" class="vue--button-dropdown__container">
        <slot name="default" />
      </div>
    </div>
  </div>
</template>

<script>
import ChevronDownIcon from 'icons/ChevronDown';
import { isInList } from '~/lib/prop-validators';

/**
 * Button component which opens a dropdown when clicked on
 */
export default {
  name: 'BaseButtonDropdown',

  status: 'beta',

  components: {
    ChevronDownIcon,
  },
  props: {
    /**
     * Text to display within the button
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * Size variant
     */
    size: {
      type: String,
      default: null,
      validator: isInList(['small', 'large']),
    },
    /**
     * Positioning for the dropdown. Defaults to `left`
     */
    position: {
      type: String,
      default: 'left',
      validator: isInList(['right', 'left']),
    },
    /**
     * If the dropdown currently has focus
     */
    trappedFocus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      value: null,
    };
  },
  computed: {
    computedClasses() {
      return {
        [`vue--button-dropdown__content--${this.position}`]: true,
        [`vue--button-dropdown__content--focus`]: this.trappedFocus,
        open: this.isOpen,
      };
    },
    computedIconClass() {
      return {
        'vue--button-dropdown__icon--rotated': this.isOpen,
      };
    },
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      this.$emit('toggleDropdown', { isOpen: this.isOpen });
    },
    onClose() {
      setTimeout(() => {
        if (!this.trappedFocus) {
          this.isOpen = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--button-dropdown {
  position: relative;
  display: inline;

  &__btn {
    padding: 0 0 0 space();
  }

  &__icon {
    margin-left: space(s);
    display: inline-block;
    transition: transform 0.2s linear;
    -ms-transition: none;

    &--rotated {
      transform-origin: 50% 55%; // Adjust the origin so it rotates around center of the chevron
      transform: rotate(-180deg);
    }
  }

  &__content {
    position: absolute;
    display: flex;
    display: -ms-grid; // IE 11 fix
    box-shadow: global(hoverState, defaultBoxShadow), global(box-shadow, tile);
    margin: space(xs) 0 0 0;
    opacity: 0;
    transition: all ease 0.2s;
    -ms-transition: none;
    z-index: 999;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: auto;
    }

    &--focus {
      box-shadow: global(hoverState, hoverBoxShadow), global(box-shadow, tile);
    }

    &--right {
      right: 0;
      transform-origin: top right;
    }

    &--left {
      left: 0;
      transform-origin: top left;
    }
  }
}
</style>
