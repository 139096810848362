<template>
  <span class="vue--avatar-username" :class="computedClasses">
    <BaseAvatar
      :avatar="avatar"
      :size="size"
      :email="email"
      :alt="`${label} avatar`"
      class="vue--avatar-username__avatar"
    />
    <span class="vue--avatar-username__label">
      <strong class="vue--avatar-username__username">
        <slot name="label" :label="label">
          {{ label }}
        </slot>
      </strong>
      <span v-if="displayEmail" class="vue--avatar-username__email">
        <slot name="email" :email="email">
          {{ email }}
        </slot>
      </span>
    </span>
  </span>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseAvatarUsername',

  props: {
    /**
     * Provide an avatar URL or fallback to gravatar.
     */
    avatar: {
      type: String,
      default: null,
    },
    /**
     * The name of the user
     */
    label: {
      required: true,
      type: String,
    },
    /**
     * Email for the user
     */
    email: {
      type: String,
      default: null,
    },
    /**
     * Whether or not to display the email in the UI
     */
    displayEmail: {
      type: Boolean,
      default: false,
    },
    /**
     * Size modifier.
     */
    size: {
      type: String,
      default: 'small',
      validator: isInList(['extra-small', 'small', 'large']),
    },
    /**
     * Layout modifier
     */
    variant: {
      type: String,
      default: 'default',
      validator: isInList(['default', 'compact']),
    },
    /**
     * Should the colour be inverted for a dark background
     */
    inverted: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--avatar-username--${this.size}`]: this.size !== 'small',
        [`vue--avatar-username--${this.variant}`]: this.variant !== 'default',
        ['vue--avatar-username--inverted']: this.inverted,
        'vue--avatar-username--with-email': this.displayEmail,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--avatar-username {
  $self: &;

  display: flex;
  align-items: center;

  &__label {
    line-height: rem(21px);
    margin: space(xxxs) 0 0 space(xs);
    display: flex;
    flex-direction: column;
  }

  &__email {
    color: color(neutral, 48);
  }

  &__avatar {
    flex-shrink: 0;
  }

  &--extra-small {
    #{$self} {
      &__label {
        font-size: rem(13px);
        line-height: rem(18px);
        margin-top: 0;
      }
    }
  }

  &--compact {
    align-items: center;

    #{$self} {
      &__username {
        font-size: rem(15px);
        line-height: rem(12px);
        padding-bottom: space(xxs);
        color: color(base, heading);
      }
      &__email {
        font-size: rem(12px);
        line-height: rem(12px);
        color: color(base, dimmed);
      }
    }
  }

  &--inverted {
    #{$self} {
      &__username {
        color: color(ui, white);
      }
      &__email {
        color: color(ui, white);
      }
    }
  }

  &--with-email {
    align-items: start;
  }
}
</style>
