interface CacheItem {
  value: object;
  expiresAt?: Date;
}

interface Cache {
  [key: string]: CacheItem;
}

const cache: Cache = {};

export function get(key: string) {
  const result = cache[key];

  if (result && !isExpired(result.expiresAt)) {
    return result.value;
  }
}

export function set(key: string, value: object, expiry?: number) {
  const expiresAt = expiry ? new Date(Date.now() + expiry) : undefined;

  cache[key] = { value, expiresAt };

  if (expiry) {
    expire(key, expiry);
  }
}

export function del(key: string) {
  delete cache[key];
}

function isExpired(expiresAt?: Date) {
  return expiresAt ? Date.now() > expiresAt.getTime() : false;
}

function expire(key: string, expiry: number) {
  function checkExpired() {
    const result = cache[key];
    if (isExpired(result.expiresAt)) {
      del(key);
    }
  }

  setTimeout(checkExpired, expiry);
}
