<template>
  <table
    id="sortable-table"
    class="vue--table"
    :class="computedClasses"
    aria-live="polite"
    aria-atomic="true"
  >
    <thead class="vue--table__thead">
      <tr class="vue--table__row">
        <BaseTableHeaderCell
          v-for="column in columns"
          :key="column.dataKey"
          class="vue--table__header"
          :sort-direction="columnSortDirection(column)"
          :title="column.title"
          :sortable="column.sortable"
          :class="column.stylingClass"
          :width="column.width"
          :variant="variant"
          :data-snyk-test="`BaseTableHeaderCell: ${column.title}`"
          scope="col"
          @sort="sortByColumn(column)"
        >
          <span v-if="column.headerCellComponent">
            <component
              :is="headerCellComponent(column)"
              v-bind="headerCellProps(column)"
            >
              {{ column.headerCellContent }}
            </component>
          </span>
        </BaseTableHeaderCell>
      </tr>
    </thead>
    <tbody class="vue--table__tbody">
      <tr
        v-for="(row, rowIndex) in sortedRows"
        :key="rowIndex"
        class="vue--table__row"
        :class="computedRowClasses(row)"
      >
        <td v-for="column in columns" :key="column.dataKey">
          <slot v-if="column.slot" :name="column.dataKey" :row="row"></slot>
          <component
            :is="cellComponent(row, column)"
            v-else
            v-bind="cellProps(row, column)"
          >
            {{ cellValue(row, column) }}
          </component>
        </td>
      </tr>
    </tbody>
    <tfoot v-if="hasFooter" class="vue--table__tfoot">
      <tr>
        <td :colspan="columns.length">
          <slot name="footer"></slot>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

/**
 * A table component for displaying data.
 */
export default {
  name: 'BaseTable',
  status: 'ready',

  props: {
    /**
     * A list describing the columns in the table, each column has the following properties:
     *
     * - dataKey: (required) identifier for the column corresponding to a key in the rows objects
     * - title: (required) the title for the column
     * - customDataGetter: optional function for building the values for the cells in this column
     * - cellComponent: optional component to be displayed instead of the default TableCell
     * - cellPropsMapper: optional mapper function to provide the props for a custom cell component
     * - stylingClass: optional class to apply to each column; can be used to style the column
     * - slot: optional boolean to determine whether to render a component or a slot
     * - compact: optional boolean to determine whether to render the table with normal or compact rows
     * - headerCellComponent: optional component to be displayed next to the TableCellHeaderCell title
     * - headerCellProps: optional mapper function to provide the props for a custom header cell component
     * - headerCellContent: optional text displayed inside the headerCellComponent
     */
    columns: {
      type: Array,
      required: true,
    },
    /**
     * The data to be displayed in the table, expected to be a list of json objects of any shape
     */
    rows: {
      type: Array,
      required: true,
    },
    /**
     * Optional initial column to sort by when the table is first loaded
     */
    initialSortField: {
      type: String,
      default: null,
    },
    /**
     * Optional direction for the initial sort when the table is first loaded
     */
    initialSortDirection: {
      type: String,
      default: 'ASC',
      validator: isInList(['ASC', 'DESC']),
    },
    /**
     * Sorting functionality is handled on the server
     */
    serverSort: {
      type: Boolean,
      default: false,
    },
    /**
     * Table styling variant
     */
    variant: {
      type: String,
      default: null,
      validator: isInList('compact'),
    },
  },

  data() {
    return {
      sortField: this.initialSortField,
      sortDirection: this.initialSortDirection,
    };
  },
  computed: {
    computedClasses() {
      return {
        [`vue--table--${this.variant}`]: !!this.variant,
      };
    },
    sortedRows() {
      if (this.serverSort || !this.sortField || !this.sortDirection) {
        return this.rows;
      }
      const sorted = this.rows.slice().sort((a, b) => {
        const aValue = a[this.sortField];
        const bValue = b[this.sortField];

        if (typeof aValue === 'string') {
          return aValue.localeCompare(bValue);
        }
        return aValue - bValue;
      });
      if (this.sortDirection === 'DESC') {
        sorted.reverse();
      }
      return sorted;
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
  },

  methods: {
    columnSortDirection(column) {
      return this.sortField === column.dataKey ? this.sortDirection : null;
    },
    sortByColumn(column) {
      const currentSortDirection = this.columnSortDirection(column);

      this.sortField = column.dataKey;
      this.sortDirection = currentSortDirection === 'ASC' ? 'DESC' : 'ASC';

      this.$emit('update:sort', {
        sortBy: this.sortField,
        sortDirection: this.sortDirection,
      });
    },
    cellValue(row, column) {
      return column.customDataGetter
        ? column.customDataGetter(row)
        : row[column.dataKey];
    },
    cellComponent(row, column) {
      if (typeof column.cellComponent === 'function') {
        return column.cellComponent(row);
      }
      return column.cellComponent || 'span';
    },
    cellProps(row, column) {
      return column.cellPropsMapper ? column.cellPropsMapper(row) : row;
    },
    headerCellComponent(column) {
      return column.headerCellComponent || 'span';
    },
    headerCellProps(column) {
      return column.headerCellPropsMapper();
    },
    computedRowClasses(row) {
      const classes = {};

      if (row?.options?.variant) {
        classes[`vue--table__row--${row.options.variant}`] = true;
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--table {
  $self: &;

  width: 100%;

  &__tfoot {
    td {
      @include font-stack(regular, sans-serif, italic);

      color: color(neutral, 72);
      padding: space(m) 0 space(s);
    }
  }

  &__thead,
  &__tbody {
    #{$self}__row {
      border-bottom: 1px solid color(neutral, 84);
    }

    td {
      padding: space(s);
      vertical-align: middle;

      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  &__tbody {
    #{$self}__row:last-of-type {
      border-bottom: none;
    }
  }

  &__row {
    &--dimmed {
      color: color(base, dimmed);
    }
  }

  &--compact {
    #{$self}__thead,
    #{$self}__tbody {
      td {
        padding: space(xxs);
      }
    }
  }
}
</style>
