import Vue from 'vue';
import Vuex from 'vuex';
import config from 'config';
import cloneDeep from 'lodash/cloneDeep';
import orgs from '~/store/modules/orgs';
import requirements from '~/store/modules/requirements';
import policyManager from '~/store/modules/policy-manager';
import snapshot from '~/store/modules/snapshot/index';
import cloudConfigIssues from './modules/cloud-config-issues';
import prCheck from './modules/pr-check';
import projects from './modules/projects';
import runtime from './modules/runtime';
import billing from './modules/billing';

Vue.use(Vuex);

export const storeConfig = {
  state: {
    baseUrl: config.baseUrl,
  },

  getters: {},

  actions: {},

  mutations: {},

  modules: {
    orgs,
    requirements,
    policyManager,
    snapshot,
    cloudConfigIssues,
    projects,
    runtime,
    billing,
    prCheck,
  },
};

// Initialize Vuex store.
export default new Vuex.Store(storeConfig);

// Used in Storybook & tests where we want isolated stores
export function getClonedStore() {
  return new Vuex.Store(cloneDeep(storeConfig));
}
