<template>
  <tr class="vue--meta-table-row">
    <th scope="row" class="vue--meta-table-row__label">
      <BaseAllCaps wrapper="span" size="small">{{ label }}</BaseAllCaps>
    </th>
    <td :data-snyk-test="testHook" class="vue--meta-table-row__value">
      <slot>
        <component
          :is="cellIcon"
          v-if="icon"
          :title="text"
          class="vue--meta-table-row__icon"
        />
        <component :is="textComponent" :href="url">{{ text }}</component>
        <span
          v-if="note"
          class="vue--meta-table-row__note"
          data-snyk-test="BaseMetaTableRow: note"
        >
          {{ note }}
        </span>
        <BaseTooltip
          v-if="tooltip"
          v-bind="tooltip"
          class="vue--meta-table-row__tooltip"
        />
      </slot>
    </td>
  </tr>
</template>

<script>
import { IntegrationsMap } from '~/lib/integrations-utils';
import { PackageManagerIconMap } from '~/lib/package-manager-utils';
import WarningIcon from '~/components/CustomIcons/Warning';

export default {
  name: 'BaseMetaTableRow',
  status: 'ready',
  components: {
    WarningIcon,
  },
  props: {
    /**
     * Title for the row
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Text to be displayed in the row
     */
    text: {
      type: [String, Number],
      default: null,
    },
    /**
     * Name for an icon to be shown at the beginning of the row
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Secondary text to be displayed in the row
     */
    note: {
      type: String,
      default: null,
    },
    /**
     * Link href if one is required
     */
    url: {
      type: String,
      default: null,
    },
    /**
     * Details for a tooltip to be attached to the row
     */
    tooltip: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      IntegrationsMap,
    };
  },
  computed: {
    testHook() {
      return `BaseMetaTableRow: ${this.label}`;
    },
    textComponent() {
      return this.url ? 'a' : 'span';
    },
    cellIcon() {
      if (this.icon === 'warning') {
        return WarningIcon;
      }

      const iconInMap =
        (IntegrationsMap[this.icon] && IntegrationsMap[this.icon].icon) ||
        PackageManagerIconMap[this.icon];

      return iconInMap ? iconInMap : '';
    },
  },
  mounted() {
    if (this.icon && !this.cellIcon && window.Sentry) {
      window.Sentry.captureMessage('Icon not found in integrations map', {
        level: 'error',
        extra: {
          component: 'ProjectSummary',
          iconName: this.icon,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--meta-table-row {
  $self: &;

  display: flex;
  flex-direction: column;

  &__label {
    padding-bottom: space(xs);
  }

  &__value {
    word-break: break-word;
    flex: 1.75;
    align-items: flex-start;
    display: flex;
    line-height: rem(28px);
  }

  &__tooltip {
    margin-left: space(xxs);
  }

  &__icon {
    margin-right: space(xs);
    position: relative;
    top: -1px;
  }

  &__note {
    color: color(neutral, 48);
    margin-left: space(xxs);
  }
}
</style>
