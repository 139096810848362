import type { Route } from '../../types';

export const routes: Route[] = [
  {
    path: '/request-access-to-org/request-access',
    component: 'RequestAccessToOrg/RequestAccessToOrg',
    sentryTeam: 'bolt',
    meta: {
      title: 'Request Access To Org',
      pageDesc: 'request access to org',
      routeName: 'Request Access To Org',
      noSiteHeader: true,
    },
  },
  {
    path: '/request-access-to-org/pending-approval',
    component: 'PendingApproval/PendingApproval',
    sentryTeam: 'bolt',
    meta: {
      title: 'Pending approval',
      pageDesc: 'request access to org pending approval',
      noSiteHeader: true,
    },
  },
];
