<template>
  <ul class="vue--pagination-nav">
    <li class="vue--pagination-nav__item">
      <button
        aria-label="First page"
        class="vue--pagination-nav__button vue--pagination-nav__start"
        @keydown.enter="onGoToStart"
        @keydown.space="onGoToStart"
        @click="onGoToStart"
      >
        <ChevronDoubleLeftIcon title="First page" />
      </button>
    </li>
    <li class="vue--pagination-nav__item">
      <button
        aria-label="Previous page"
        class="vue--pagination-nav__button vue--pagination-nav__prev"
        @keydown.enter="onPrev"
        @keydown.space="onPrev"
        @click="onPrev"
      >
        <ChevronLeftIcon title="Previous page" />
      </button>
    </li>
    <li
      v-for="(item, index) in pageItems"
      :key="index"
      class="vue--pagination-nav__item"
    >
      <button
        :aria-label="`Page ${item}`"
        class="vue--pagination-nav__button vue--pagination-nav__number"
        :class="{
          'vue--pagination-nav__number--active': item === currentPage,
          'vue--pagination-nav__number--spacer': item === '…',
        }"
        :aria-current="item === currentPage"
        @keydown.space="item !== '…' ? onGoToPage(item) : null"
        @keydown.enter="item !== '…' ? onGoToPage(item) : null"
        @click="item !== '…' ? onGoToPage(item) : null"
      >
        <span class="vue--pagination-nav__text" v-text="item" />
      </button>
    </li>
    <li class="vue--pagination-nav__item">
      <button
        aria-label="Next page"
        class="vue--pagination-nav__button vue--pagination-nav__next"
        @keydown.space="onNext"
        @keydown.enter="onNext"
        @click="onNext"
      >
        <ChevronRightIcon title="Next page" />
      </button>
    </li>
    <li class="vue--pagination-nav__item">
      <button
        aria-label="Final page"
        class="vue--pagination-nav__button vue--pagination-nav__end"
        @keydown.space="onGoToEnd"
        @keydown.enter="onGoToEnd"
        @click="onGoToEnd"
      >
        <ChevronDoubleRightIcon title="Final page" />
      </button>
    </li>
  </ul>
</template>

<script>
import ChevronLeftIcon from 'icons/ChevronLeft';
import ChevronDoubleLeftIcon from 'icons/ChevronDoubleLeft';
import ChevronRightIcon from 'icons/ChevronRight';
import ChevronDoubleRightIcon from 'icons/ChevronDoubleRight';

export default {
  name: 'BasePaginationNav',
  status: 'ready',

  components: {
    ChevronLeftIcon,
    ChevronDoubleLeftIcon,
    ChevronRightIcon,
    ChevronDoubleRightIcon,
  },

  props: {
    /**
     * Current page being viewed.
     */
    currentPage: {
      type: Number,
      required: true,
    },
    /**
     * Total pages of pagination.
     */
    totalPages: {
      type: Number,
      required: true,
    },
    /**
     * Maximum number of page numbers shown in pagination.
     */
    maxPagesShown: {
      type: Number,
      default: 5,
    },
  },

  computed: {
    pageItems() {
      const pages = [];
      const numbers =
        this.totalPages < this.maxPagesShown
          ? this.totalPages
          : this.maxPagesShown;
      const centerPoint = Math.ceil(this.maxPagesShown / 2);
      const shouldShowEllipsis = this.totalPages > this.maxPagesShown;
      let startIndex =
        this.totalPages > this.maxPagesShown && this.currentPage > centerPoint
          ? this.currentPage - centerPoint + 1
          : 1;

      if (
        this.totalPages > this.maxPagesShown &&
        startIndex > this.totalPages - this.maxPagesShown + 1
      ) {
        startIndex = this.totalPages - this.maxPagesShown + 1;
      }

      if (this.currentPage > centerPoint && shouldShowEllipsis) {
        pages.push('…');
      }

      for (let i = 0; i < numbers; i++) {
        pages.push(startIndex);
        startIndex++;
      }

      if (
        this.currentPage <= this.totalPages - centerPoint &&
        shouldShowEllipsis
      ) {
        pages.push('…');
      }

      return pages;
    },
  },

  methods: {
    onNext() {
      this.$emit('next');
    },
    onPrev() {
      this.$emit('prev');
    },
    onGoToPage(page) {
      this.$emit('goToPage', page);
    },
    onGoToStart() {
      this.$emit('goToStart');
    },
    onGoToEnd() {
      this.$emit('goToEnd');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--pagination-nav {
  $self: &;

  background-color: color(ui, white);
  border: 1px solid color(neutral, 84);
  border-left: none;
  border-radius: global(border-radius, micro);
  align-items: center;
  display: inline-flex;
  justify-content: center;
  list-style: none;
  padding: 0;

  &__item {
    height: 28px;
    margin: 0;
    border-left: 1px solid color(neutral, 84);
    &:first-of-type {
      border-radius: global(border-radius, micro) 0 0
        global(border-radius, micro);
    }
  }

  &__button {
    color: color(base, dimmed);
    cursor: pointer;
    height: 28px;
    margin: 0;
    text-align: center;
    user-select: none;
    width: 27px;

    &:hover,
    &:focus {
      background-color: color(neutral, 96);
    }

    &:focus-visible {
      outline: 2px solid color(ui, selection);
      border: none;
      z-index: 2;
      position: relative;
    }
  }

  &__number {
    border-radius: 0;
    font-size: rem(13px);
    user-select: none;

    &--active,
    &--active:focus {
      @include font-stack(semibold);

      background-color: color(action);
      border-left: none;
      color: color(ui, white);
      position: relative;
      height: 30px;
      width: 30px;
      left: -1px;
      top: -1px;
      margin-right: -2px;
      margin-bottom: -2px;

      &:hover {
        background-color: color(action);
      }
    }

    &--spacer {
      cursor: default;
    }
  }

  &__start,
  &__end,
  &__prev,
  &__next {
    background-color: color(neutral, 96);
    line-height: rem(28px);
    user-select: none;

    ::v-deep svg {
      position: relative;
      top: -2px;
    }

    &:hover,
    &:focus {
      background-color: color(neutral, 90);
    }
  }
}
</style>
