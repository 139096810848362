<template>
  <BaseCard variant="info">
    <template #heading>
      {{ title }}
    </template>
    <!-- eslint-disable -->
    <div
      v-if="action"
      data-snyk-test="docker remediation action"
      v-html="action"
    />
    <template v-if="baseImage">
      <!-- eslint-enable -->
      <BaseTable :columns="tableColumns" :rows="tableRows">
        <template #type="{ row }">
          <strong v-text="row.type" />
        </template>
        <template #callToActions="{ row }">
          <div
            v-for="callToAction in row.callToActions"
            :key="callToAction.href"
          >
            <BaseButton
              :variant="callToAction.type"
              :href="callToAction.href"
              :wrapper="callToAction.wrapper"
              :data-snyk-test="callToAction.snykTest"
              class="vue--image-upgrade__action__button"
              @click="onFixClick"
            >
              <SourcePullIcon decorative :size="18" />
              Open a fix PR
            </BaseButton>
          </div>
        </template>
      </BaseTable>

      <BaseButton
        v-if="proposedImages.length > 1"
        variant="link"
        size="small"
        class="margin-top--default"
        data-snyk-test="image remediation toggle link"
        @click="toggleTableExpanded"
      >
        {{ tableExpanded ? 'Show less' : 'Show more upgrade types' }}
      </BaseButton>
    </template>
  </BaseCard>
</template>

<script>
import BaseSeverity from '~/components/BaseSeverity/BaseSeverity';
import SourcePullIcon from 'icons/SourcePull';

export default {
  name: 'CardBaseImageUpgrade',
  status: 'ready',
  components: {
    SourcePullIcon,
  },
  props: {
    /** Title to be displayed on the card */
    title: {
      type: String,
      required: true,
    },
    /** A suggested remediation action */
    action: {
      type: String,
      default: '',
    },
    /** The current base image in use */
    baseImage: {
      type: Object,
      default: null,
    },
    /** Suggested alternative images to upgrade to */
    proposedBaseImages: {
      type: Array,
      default: () => [],
    },
    /** Show buttons to raise fixPRs */
    fixPrCallToAction: {
      type: Object,
      default: null,
    },
  },
  data: function () {
    const tableColumns = [
      {
        dataKey: 'type',
        title: '',
        slot: true,
      },
      {
        dataKey: 'baseImage',
        title: 'Base Image',
      },
      {
        dataKey: 'vulnerabilities',
        title: 'Vulnerabilities',
      },
      {
        dataKey: 'severity',
        title: 'Severity',
        cellComponent: BaseSeverity,
        cellPropsMapper: (row) => ({
          size: 'small',
          issuesLow: row.severity.issuesLow,
          issuesMedium: row.severity.issuesMedium,
          issuesHigh: row.severity.issuesHigh,
          issuesCritical: row.severity.issuesCritical,
          showCriticalSeverity: typeof row.severity.issuesCritical === 'number',
        }),
      },
    ];

    if (this.fixPrCallToAction?.supportsFix) {
      tableColumns.push({
        dataKey: 'callToActions',
        title: '',
        slot: true,
      });
    }

    return {
      tableExpanded: false,
      tableColumns,
    };
  },
  computed: {
    tableRows() {
      const rows = [
        {
          type: 'Current image',
          baseImage: this.baseImage.name,
          vulnerabilities: this.baseImage.totalIssues,
          severity: {
            issuesLow: this.baseImage.issuesBySeverity.low,
            issuesMedium: this.baseImage.issuesBySeverity.medium,
            issuesHigh: this.baseImage.issuesBySeverity.high,
            issuesCritical: this.baseImage.issuesBySeverity.critical,
          },
          callToActions: [],
        },
      ];

      for (const image of this.imagesToShow) {
        rows.push({
          type: image.title,
          baseImage: image.name,
          vulnerabilities: image.totalIssues,
          severity: {
            issuesLow: image.issuesBySeverity.low,
            issuesMedium: image.issuesBySeverity.medium,
            issuesHigh: image.issuesBySeverity.high,
            issuesCritical: image.issuesBySeverity.critical,
          },
          callToActions: this.fixPrCallToAction?.supportsFix
            ? [
                {
                  type: 'cta',
                  href: `${
                    this.fixPrCallToAction.url
                  }?baseImage=${encodeURIComponent(image.name)}`,
                  wrapper: 'a',
                  snykTest: 'open fix PR label',
                },
              ]
            : [],
        });
      }

      return rows;
    },
    proposedImages() {
      const images = [];
      for (const baseImage of this.proposedBaseImages) {
        baseImage.images.forEach((image, index) => {
          if (index === 0) images.push({ ...image, title: baseImage.title });
          else images.push(image);
        });
      }
      return images;
    },
    imagesToShow() {
      if (this.tableExpanded) {
        return this.proposedImages;
      }
      return this.proposedImages.slice(0, 1);
    },
  },

  methods: {
    toggleTableExpanded() {
      this.tableExpanded = !this.tableExpanded;
    },
    onFixClick() {
      this.$emit('fix-triggered');
    },
  },
};
</script>
