export const ADD_POLICY = 'ADD_POLICY';
export const UPDATE_POLICIES = 'UPDATE_POLICIES';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';
export const SET_FILTERED_POLICIES = 'SET_FILTERED_POLICIES';

export const UPDATE_ORGS = 'UPDATE_ORGS';
export const UPDATE_ORG = 'UPDATE_ORG';

export const SET_FILE = 'SET_FILE';
export const SET_ISSUES = 'SET_ISSUES';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_FILTERED_ISSUES = 'SET_FILTERED_ISSUES';
export const SET_IGNORED_ISSUE = 'SET_IGNORED_ISSUE';
export const ADD_ISSUE_AS_VIEWING = 'ADD_ISSUE_AS_VIEWING';
export const REMOVE_ISSUE_AS_VIEWING = 'REMOVE_ISSUE_AS_VIEWING';
export const CLEAR_ALL_VIEWING_ISSUES = 'CLEAR_ALL_VIEWING_ISSUES';

export const SET_SNAPSHOT_DEP_GRAPH = 'SET_SNAPSHOT_DEP_GRAPH';
export const SET_SNAPSHOT_DEP_GRAPH_LOADING = 'SET_SNAPSHOT_DEP_GRAPH_LOADING';
export const SET_SNAPSHOT_DEP_GRAPH_LOADING_ERROR =
  'SET_SNAPSHOT_DEP_GRAPH_LOADING_ERROR';

export const SET_SNAPSHOT_ISSUES = 'SET_SNAPSHOT_ISSUES';
export const SET_SNAPSHOT_ISSUES_LOADING = 'SET_SNAPSHOT_ISSUES_LOADING';
export const SET_SNAPSHOT_ISSUES_LOADING_ERROR =
  'SET_SNAPSHOT_ISSUES_LOADING_ERROR';

export const SET_SNAPSHOT_VULNS = 'SET_SNAPSHOT_VULNS';
export const SET_SNAPSHOT_VULNS_LOADING = 'SET_SNAPSHOT_VULNS_LOADING';
export const SET_SNAPSHOT_VULNS_LOADING_ERROR =
  'SET_SNAPSHOT_VULNS_LOADING_ERROR';

export const SET_SNAPSHOT_SAST_ISSUES = 'SET_SNAPSHOT_SAST_ISSUES';
export const SET_SNAPSHOT_SAST_ISSUES_LOADING =
  'SET_SNAPSHOT_SAST_ISSUES_LOADING';
export const SET_SNAPSHOT_SAST_ISSUES_LOADING_ERROR =
  'SET_SNAPSHOT_SAST_ISSUES_LOADING_ERROR';
export const UPDATE_SNAPSHOT_SAST_ISSUES = 'UPDATE_SNAPSHOT_SAST_ISSUES';

export const SET_RUNTIME_DATA = 'SET_RUNTIME_DATA';
export const SET_RUNTIME_DATA_LOADING = 'SET_RUNTIME_DATA_LOADING';
export const SET_RUNTIME_DATA_LOADING_ERROR = 'SET_RUNTIME_DATA_LOADING_ERROR';

export const SET_REQUIREMENTS_CHECKS = 'SET_REQUIREMENTS_CHECKS';
export const CLEAR_REQUIREMENTS_CHECKS = 'CLEAR_REQUIREMENTS_CHECKS';
export const SET_REQUIREMENTS = 'SET_REQUIREMENTS';
export const CLEAR_REQUIREMENTS = 'CLEAR_REQUIREMENTS';
export const SET_REQUIREMENTS_LOADING = 'SET_REQUIREMENTS_LOADING';

export const SET_PROJECT_GROUPS = 'SET_PROJECT_GROUPS';
export const SET_LATEST_PROJECT_GROUPS = 'SET_LATEST_PROJECT_GROUPS';
export const SET_IMPORT_STATUS = 'SET_IMPORT_STATUS';
export const SET_IS_IMPORTING = 'SET_IS_IMPORTING';
export const SET_IS_ERRORING = 'SET_IS_ERRORING';
export const SET_IS_GREENKEEPER_MIGRATION = 'SET_IS_GREENKEEPER_MIGRATION';
export const SET_INITIAL_PROJECTS_STATE = 'SET_INITIAL_PROJECTS_STATE';
export const SET_RETAINED_STATE = 'SET_RETAINED_STATE';
export const INITIALISE_PROJECTS_STORE = 'INITIALISE_PROJECTS_STORE';

export const START_FREE_TRIAL = 'START_FREE_TRIAL';
export const BILLING_DETAILS = 'BILLING_DETAILS';
export const SET_IMPORTING_SUBSCRIPTION = 'SET_IMPORTING_SUBSCRIPTION';
export const PLAN_CHANGED = 'PLAN_CHANGED';
export const PURCHASE_CANCELED = 'PURCHASE_CANCELED';
export const PURCHASE_CANCELED_FAILED = 'PURCHASE_CANCELED_FAILED';
export const PLAN_AUTO_RENEWAL_CANCELED = 'PLAN_AUTO_RENEWAL_CANCELED';
export const PLAN_AUTO_RENEWAL_CANCELED_FAILED =
  'PLAN_AUTO_RENEWAL_CANCELED_FAILED';

export const SET_PR_CHECK_ISSUES = 'SET_PR_CHECK_ISSUES';
export const SET_PR_CHECK_ISSUES_LOADING = 'SET_PR_CHECK_ISSUES_LOADING';
export const SET_PR_CHECK_ISSUES_LOADING_ERROR =
  'SET_PR_CHECK_ISSUES_LOADING_ERROR';
export const UPDATE_PR_CHECK_ISSUES = 'UPDATE_PR_CHECK_ISSUES';
export const UPDATE_PR_ISSUE_SNIPPETS = 'UPDATE_PR_ISSUE_SNIPPETS';
export const PR_CHECK_MARK_AS_SUCCESSFUL = 'PR_CHECK_MARK_AS_SUCCESSFUL';
