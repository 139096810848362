<template>
  <BaseMultipleValueInput
    class="vue--key-value-input"
    v-bind="$attrs"
    :values="tags"
    :data="preparedData"
    :display-key="displayKey"
    :loading="isLoading"
    :placeholder="placeholder"
    :new-label-context="displayKey"
    :selection-only="selectionOnly"
    :restrict-characters="restrictCharacters"
    :character-limit="characterLimits[displayKey]"
    :read-only="readOnly"
    @create="handleCreation"
    @loading="setLoading"
    @query="setInputValue"
    @update="update"
    @opened="opened"
    @closed="closed"
  >
    <template #chip="{ value: tag, isRemovable, removeValue }">
      <div
        class="vue--key-value-input__chip"
        :class="{
          'shake-vertical-subtle': animateDuplicate === tagAsString(tag),
        }"
        @animationend="animateDuplicate = false"
      >
        <BaseChip
          :value="tag"
          :text="`${tag.key}:${tag.value}`"
          :removable="isRemovable"
          color="orange"
          @click="removeValue(tag)"
          @keydown.enter.prevent="removeValue(tag)"
        >
          <!-- eslint-disable vue/no-lone-template -->
          <!-- prettier-ignore -->
          <template><strong>{{ tag.key }}:</strong>{{ tag.value }}</template>
          <!-- eslint-enable vue/no-lone-template -->
        </BaseChip>
      </div>
    </template>

    <template #preInput>
      <span
        v-if="temporaryTag"
        class="vue--key-value-input__temporary"
        data-snyk-test="BaseTagsInput: pre input"
      >
        <strong>{{ temporaryTag.key }}:</strong>
      </span>
    </template>

    <template #preItems>
      <strong v-if="!readOnly" data-snyk-test="BaseTagsInput: pre items">
        Select a {{ displayKey }}:
      </strong>
    </template>

    <template #item="{ item }">
      <span v-text="item[displayKey]" />
    </template>

    <template #postItems>
      <span v-if="isEmpty" class="vue--key-value-input__post-item">
        Enter a new key
      </span>
      <span v-if="isNewKey" class="vue--key-value-input__post-item">
        Enter a value for this key
      </span>
    </template>

    <template v-for="(_, slot) of $slots" #[slot]>
      <slot :name="slot" />
    </template>
  </BaseMultipleValueInput>
</template>

<script>
import has from 'lodash/has';
import cloneDeep from 'lodash/cloneDeep';
import { createDebounce } from '~/lib/debounce';

export default {
  name: 'BaseTagsInput',

  model: {
    prop: 'tags',
    event: 'update',
  },

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Add a key/value…',
    },
    selectionOnly: {
      type: Boolean,
      default: false,
    },
    restrictCharacters: {
      type: String,
      default: '[A-Za-z0-9\\.\\-\\,\\_]',
    },
    characterLimits: {
      type: Object,
      default: () => {
        return {
          key: 30,
          value: 50,
        };
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      setDebounce: createDebounce(),
      resetInput: false,
      displayKey: 'key',
      selectedValue: null,
      inputValue: '',
      isLoading: this.loading,
      temporaryTag: null,
      animateDuplicate: false,
    };
  },

  computed: {
    preparedData() {
      const unique = [];
      const preparedData = [];

      [...cloneDeep(this.data), ...cloneDeep(this.tags)].forEach((item) => {
        if (
          this.selectedValue &&
          item.key === this.selectedValue &&
          !unique.includes(item.value) &&
          this.tagIsUnique(item)
        ) {
          unique.push(item.value);
          item.key = null;
          preparedData.push(item);
        } else if (!this.selectedValue && !unique.includes(item.key)) {
          unique.push(item.key);
          item.value = null;
          preparedData.push(item);
        }
      });

      return preparedData;
    },
    tagsAsStrings() {
      return this.tags.map((tag) => this.tagAsString(tag));
    },
    isNewKey() {
      return (
        this.temporaryTag &&
        this.temporaryTag.key &&
        !this.preparedData.length &&
        !this.inputValue &&
        !this.selectionOnly
      );
    },
    isEmpty() {
      return (
        !this.inputValue &&
        !this.tags.length &&
        !this.data.length &&
        !this.temporaryTag &&
        !this.selectionOnly
      );
    },
  },

  watch: {
    loading() {
      this.isLoading = this.loading;
    },
  },

  methods: {
    tagAsString(tag) {
      return `${tag.key}:${tag.value}`;
    },
    tagIsUnique(tag) {
      return !this.tagsAsStrings.includes(this.tagAsString(tag));
    },
    setInputValue(value) {
      this.isLoading = false;
      this.inputValue = value;
      this.setQuery(value);
    },
    setLoading(value) {
      this.isLoading = value;
    },
    update(tags) {
      if (!this.temporaryTag) {
        this.$emit('update', tags);
      }
      this.reset();
    },
    reset() {
      this.inputValue = '';
      this.selectedValue = null;
      this.temporaryTag = null;
      this.displayKey = 'key';
    },
    handleCreation(tag) {
      if (!has(tag, 'value')) {
        this.temporaryTag = tag;
        this.selectedValue = tag.key;
        this.inputValue = '';
        this.displayKey = 'value';
      } else {
        const newTag = { ...this.temporaryTag, ...tag };
        if (!this.tagIsUnique(newTag)) {
          this.triggerAnimateDuplicate(newTag);
          return;
        }
        this.$emit('update', [...this.tags, newTag]);
        this.reset();
      }
    },
    triggerAnimateDuplicate(value) {
      this.animateDuplicate = this.tagAsString(value);
    },
    setQuery(value) {
      if (this.$listeners.asyncQuery) {
        this.isLoading = true;
        this.setDebounce(() => {
          const payload = { ...this.temporaryTag };
          payload[this.displayKey] = value;
          this.$emit('asyncQuery', payload);
        });
      }
      if (this.$listeners.query) {
        this.$emit('query', value);
      }
    },
    opened() {
      this.$emit('opened');
    },
    closed() {
      this.reset();
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--key-value-input {
  &__temporary {
    font-size: rem(13px);
    list-style: none;
  }
  &__post-item {
    color: color(base, dimmed);
    padding: space(xxs) 0;
  }
}
</style>
