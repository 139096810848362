<template>
  <div
    :class="computedClasses"
    :style="computedStyles"
    class="vue--alert"
    role="alert"
    @mouseenter="pauseDismissal"
    @mouseleave="resumeDismissal"
  >
    <div class="vue--alert__content">
      <component :is="container" class="vue--alert__container">
        <div class="vue--alert__message" data-snyk-test="alert-message">
          <!-- @slot The alerts main body -->
          <slot />
        </div>
        <div
          v-if="showDismissible"
          class="vue--alert__dismiss"
          data-snyk-test="alert-dismiss"
          tabindex="0"
          @click.prevent="dismiss"
          @keydown.enter="dismiss"
        >
          <CloseCircleOutlineIcon />
        </div>
      </component>
    </div>
  </div>
</template>

<script>
import LayoutContainer from '~/components/LayoutContainer/LayoutContainer';
import { isInList } from '~/lib/prop-validators';

let timeoutRegister = null;

export default {
  name: 'BaseAlert',

  status: 'ready',

  components: {
    LayoutContainer,
    CloseCircleOutlineIcon: () => import('icons/CloseCircleOutline'),
  },

  props: {
    /**
     * Variant of alert message. Must be one of `info`, `warning`, `danger`
     * `success`. Defaults to `default`
     */
    variant: {
      type: String,
      default: 'info',
      validator: isInList([
        'default',
        'info',
        'warning',
        'error',
        'danger',
        'success',
      ]),
    },
    /**
     * Render alert as inline.
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * Control how alert is dismissed.<br/>
     * `true`: User can manually dismiss the alert.<br/>
     * `false`: The alert is fixed and cannot be dismissed.<br/>
     * `auto`: The alert will dismiss itself at end of `autoDismissDuration`.
     */
    dismissible: {
      type: [Boolean, String],
      default: false,
    },
    /**
     * Duration before alert auto dismisses (in seconds).
     */
    autoDismissDuration: {
      type: Number,
      default: 10,
    },
    /**
     * Make the alert fill the width.
     */
    expanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCreated: false,
      shouldDismiss: false,
      dismissalDuration: this.autoDismissDuration * 1000,
      dismissalStart: null,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--alert--${this.variant}`]: true,
        'vue--alert--inline': this.inline,
        'vue--alert--expanded': this.expanded,
        'vue--alert--dismissible': this.showDismissible,
        'vue--alert--fixed': this.dismissible === false,
        'vue--alert--auto-dismiss': this.autoDismissEnabled && this.isCreated,
        'vue--alert--dismiss': this.shouldDismiss,
      };
    },
    computedStyles() {
      return {
        '--duration': `${this.autoDismissDuration}s`,
      };
    },
    showDismissible() {
      return [true, 'auto'].includes(this.dismissible);
    },
    container() {
      return this.inline ? 'div' : LayoutContainer;
    },
    autoDismissEnabled() {
      return this.dismissible === 'auto';
    },
  },

  created() {
    // Triggers the setting of the &--auto-dismiss
    // modifier until after the Alert has rendered,
    // to trigger the CSS animation.
    this.$nextTick(() => {
      this.isCreated = true;
    });

    if (this.autoDismissEnabled) {
      this.dismissalStart = Date.now();
      this.autoDismiss();
    }
  },

  methods: {
    autoDismiss() {
      timeoutRegister = setTimeout(() => {
        this.shouldDismiss = true;
        this.dismiss();
      }, this.dismissalDuration);
    },
    resetTimeouts() {
      clearTimeout(timeoutRegister);
    },
    dismiss() {
      this.resetTimeouts();
      this.$emit('dismiss');
    },
    pauseDismissal() {
      if (this.autoDismissEnabled) {
        this.resetTimeouts();
        this.dismissalDuration -= Date.now() - this.dismissalStart;
      }
    },
    resumeDismissal() {
      if (this.autoDismissEnabled) {
        this.dismissalStart = Date.now();
        this.autoDismiss();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--alert {
  $self: &;

  --duration: 20s;

  background-color: color(ui, white);
  max-height: 600px;
  overflow: hidden;
  position: relative;
  transition: max-height 1s ease-out;

  &__container {
    position: relative;
  }

  &__content {
    border: 1px solid transparent;
    border-left: none;
    border-right: none;
    padding: space(s) space(xxs);
  }

  &__message {
    margin: 0;
  }

  &__dismiss {
    margin-top: -12px;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 3;
  }

  &::after {
    border-right: 1px solid transparent;
    display: block;
    content: '';
    position: absolute;
    left: -1px;
    top: 0;
    height: 100%;
    width: 0;
    z-index: 2;
  }

  &--auto-dismiss {
    &::after {
      animation: autoDismiss var(--duration) linear forwards;
    }
    &:hover::after {
      animation-play-state: paused;
    }
  }

  &--default,
  &--info {
    #{$self}__content {
      background-color: color(alert, info);
      border-color: color(alert, info-border);
      color: color(alert, info-text);
    }

    &::after {
      background-color: color(alert, info-dismiss-bg);
      border-color: color(alert, info-dismiss-border);
    }
  }

  &--warning {
    #{$self}__content {
      background-color: color(alert, warning);
      border-color: color(alert, warning-border);
      color: color(alert, warning-text);
    }

    &::after {
      background-color: color(alert, warning-dismiss-bg);
      border-color: color(alert, warning-dismiss-border);
    }
  }

  &--error,
  &--danger {
    #{$self}__content {
      background-color: color(alert, danger);
      border-color: color(alert, danger-border);
      color: color(alert, danger-text);
    }

    &::after {
      background-color: color(alert, danger-dismiss-bg);
      border-color: color(alert, danger-dismiss-border);
    }
  }

  &--success {
    #{$self}__content {
      background-color: color(alert, success);
      border-color: color(alert, success-border);
      color: color(alert, success-text);
    }

    &::after {
      background-color: color(alert, success-dismiss-bg);
      border-color: color(alert, success-dismiss-border);
    }
  }

  &--inline {
    display: inline-block;
    position: relative;

    #{$self}__content {
      border-radius: global(border-radius, half);
      border-left-width: 1px;
      border-right-width: 1px;
      border-left-style: solid;
      border-right-style: solid;
      padding-left: space();
      padding-right: space();
    }
    #{$self}__dismiss {
      right: -#{space(l)};
    }

    &#{$self}--dismissible {
      #{$self}__content {
        padding-right: space(m) + space(m) - 4px;
      }
    }
  }

  &--expanded {
    display: block;
    width: 100%;
  }

  &--dismissible {
    #{$self}__content {
      padding-right: space(xxs) + space(m) - 4px;
    }

    #{$self}__dismiss {
      ::v-deep svg {
        cursor: pointer;
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover,
      &:focus {
        ::v-deep svg {
          opacity: 1;
        }
      }
    }
  }

  &--dismiss {
    max-height: 0;
  }
}

@keyframes autoDismiss {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
</style>
