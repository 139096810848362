<template>
  <router-link
    v-if="routerAvailable && !isServerRouted"
    :to="path"
    class="vue--primary-navigation-link"
    active-class="vue--primary-navigation-link--active"
    :exact="exactMatch"
    data-snyk="spa-routed"
    :data-snyk-test="`ThePrimaryNavigationLink: ${label}`"
  >
    {{ label }}
  </router-link>
  <a
    v-else
    :class="computedClasses"
    :href="path"
    :data-snyk-test="`ThePrimaryNavigationLink: ${label}`"
    >{{ label }}</a
  >
</template>

<script>
export default {
  name: 'ThePrimaryNavigationLink',
  props: {
    path: {
      type: String,
      default: '/unset',
    },
    label: {
      type: String,
      default: 'Unlabelled link',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isServerRouted: {
      type: Boolean,
      default: false,
    },
    exactMatch: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    computedClasses() {
      return {
        'vue--primary-navigation-link': true,
        'vue--primary-navigation-link--active': this.isActive,
      };
    },
    routerAvailable() {
      return !!this.$router;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--primary-navigation-link {
  color: color(ui, white);
  padding: space(xs) 0;
  opacity: 0.8;
  text-decoration: none;

  @include media-query() {
    padding: space(default) space(xxs);
  }

  &--active {
    opacity: 1;

    @include media-query() {
      &::after {
        content: '';
        display: inline-block;
        background-color: color(ui, white);
        opacity: 0.8;
        position: absolute;
        /* Very specific px dimensions used for the visual underline effect */
        height: rem(3px);
        bottom: -(space(default) + 4px);
        left: rem(3px);
        right: rem(3px);
        border-radius: space(xxxs) space(xxxs) 0 0;
      }
    }
  }

  &:hover {
    opacity: 1;
    color: color(ui, white);
    text-decoration: none;
  }

  &:focus-visible {
    outline: 2px solid color(ui, white);
    opacity: 1;
  }
}
</style>
