export enum VulnSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
  CRITICAL = 'critical',
}

export const VulnSeverityLevels = Object.values(VulnSeverity);

export function getVulnSeverityLevels(orderDesc = false) {
  return orderDesc ? [...VulnSeverityLevels].reverse() : VulnSeverityLevels;
}

export function isValidSeverity(severity: any): severity is VulnSeverity {
  if (typeof severity !== 'string') {
    return false;
  }

  return (
    VulnSeverityLevels.indexOf(severity.toLowerCase() as VulnSeverity) !== -1
  );
}
