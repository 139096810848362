<template>
  <nav id="snyk-app-nav" :class="computedClasses">
    <ul class="vue--primary-navigation-list">
      <template v-for="item in links">
        <li
          v-if="shouldDisplay(item)"
          :key="item.path"
          class="vue--primary-navigation-list-item"
        >
          <ThePrimaryNavigationLink
            :path="item.path"
            :label="item.label"
            :is-active="item.active"
            :is-server-routed="item.serverRouted"
            :exact-match="item.exactMatch"
            @click="closeNav"
          />
        </li>
      </template>
    </ul>
  </nav>
</template>

<script>
import ThePrimaryNavigationLink from '~/components/ThePrimaryNavigationLink/ThePrimaryNavigationLink';

export default {
  name: 'ThePrimaryNavigation',
  components: {
    ThePrimaryNavigationLink,
  },
  props: {
    currentUrl: {
      type: String,
    },
    open: {
      type: Boolean,
      default: true,
    },
    links: {
      type: Array,
    },
  },
  computed: {
    computedClasses() {
      return {
        'vue--primary-navigation': true,
        'vue--primary-navigation__open': !!this.open,
      };
    },
  },
  methods: {
    closeNav() {
      this.$emit('closeNav');
    },
    shouldDisplay(item) {
      return !!item.checks.permitted;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--primary-navigation {
  display: none;
  padding: 0 0 space(l) 0;

  &__open {
    display: block;
  }

  flex-grow: 2;

  @include media-query() {
    display: block;
    padding: 0 0 0 space(s);
  }

  &-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 3px 0 0 0;

    @include media-query() {
      flex-direction: row;
    }
  }

  &-list-item {
    position: relative;
    flex: 0 1 auto;
    align-self: auto;
    white-space: nowrap;
    @include media-query() {
      padding-left: space(s);
      padding-right: space(s);
    }
  }
}
</style>
