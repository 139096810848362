<template>
  <transition name="vue--dialog-fade">
    <div :class="computedClasses" class="vue--dialog" role="dialog">
      <div class="vue--dialog__mask">
        <div class="vue--dialog__wrapper">
          <div class="vue--dialog__container" :style="computedContainerStyles">
            <CloseIcon
              v-if="dismissible"
              title="Close this dialog"
              class="vue--dialog__close-icon"
              data-snyk-test="BaseDialog: close"
              @click="close"
            />

            <header class="vue--dialog__header">
              <slot name="header" />
            </header>

            <div class="vue--dialog__body">
              <slot name="body" />
            </div>

            <div class="vue--dialog__footer">
              <slot name="footer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import CloseIcon from 'icons/Close';

export default {
  name: 'BaseDialog',
  status: 'beta',

  components: {
    CloseIcon,
  },

  props: {
    overlay: {
      type: Boolean,
      default: false,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--dialog--overlay': this.overlay,
        'vue--dialog--no-padding': this.noPadding,
      };
    },
    computedContainerStyles() {
      return {
        width: this.width,
      };
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dialog {
  $self: &;

  @media print {
    display: none;
  }

  &__wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &__container {
    position: relative;
    background-color: #fff;
    border-radius: global(border-radius, half);
    margin: 0 auto;
    padding: rem(20px) rem(30px);
    transition: all 0.3s ease;
    width: rem(500px);
  }

  &__header {
    font-weight: 500;
    font-size: rem(18px);
  }

  &__body {
    margin: rem(20px) 0;
    min-height: rem(150px);
  }

  &__close-icon {
    color: color(base, dimmed);
    position: absolute;
    right: space(s);
    top: space(s);
    cursor: pointer;
  }

  &__enter {
    opacity: 0;
  }

  &__leave-active {
    opacity: 0;
  }

  &__enter &__container,
  &__leave-active &__container {
    transform: scale(1.1);
  }

  &--overlay {
    #{$self}__mask {
      background-color: rgba(0, 0, 0, 0.5);
      display: table;
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      transition: opacity 0.3s ease;
      width: 100%;
      z-index: 1000;
    }
  }

  &--no-padding &__container {
    padding: 0;
  }
}
</style>
