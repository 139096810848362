<template>
  <div class="vue--prose" :class="computedClasses">
    <slot />
  </div>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseProse',

  props: {
    size: {
      type: String,
      default: null,
      validator: isInList(['small']),
    },
    lead: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--prose--${this.size}`]: !!this.size,
        [`vue--prose--lead`]: this.lead,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--prose {
  font-size: rem(13px);
  line-height: rem(21px);
  color: inherit;

  @include media-query(m) {
    font-size: rem(15px);
    line-height: rem(24px);
  }

  p {
    margin: space(s) 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    @include font-stack('semibold');
  }

  em {
    font-style: italic;
  }

  ol {
    margin: 0;
    padding: 0 0 0 space();
    list-style: decimal;
    font-feature-settings: 'pnum';

    li {
      margin: space(xs) 0;
    }
  }

  ul {
    margin: 0;
    padding: 0 0 0 space();
    list-style: disc;
    font-feature-settings: 'pnum';

    li {
      margin: space(xs) 0;
    }
  }

  &--lead {
    font-size: rem(18px);
    line-height: rem(32px);
  }

  &--small {
    font-size: rem(13px);
    line-height: rem(20px);
  }
}
</style>
