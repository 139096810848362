const CLOUD_CONFIG_TYPES: string[] = [
  'k8sconfig',
  'helmconfig',
  'terraformconfig',
  'terraformplan',
  'aws-config',
  'cloudformationconfig',
];

export function isCloudConfig(type: string): boolean {
  return CLOUD_CONFIG_TYPES.includes(type);
}

export function getSecurityRuleLinkId(policyId: string) {
  return `https://snyk.io/security-rules/${policyId}`;
}
