<template>
  <span class="vue--chip" :class="computedClasses">
    <span class="vue--chip__value">
      <slot>{{ text }}</slot>
    </span>
    <button
      v-if="removable"
      type="button"
      class="vue--chip__remove"
      data-snyk-test="BaseChip: remove"
      tabindex="0"
      @click="$emit('click', value)"
      @keydown.enter.prevent="$emit('keydown.enter', value)"
    >
      <CloseIcon />
    </button>
  </span>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export const colors = ['default', 'blue', 'orange'];

export default {
  name: 'BaseChip',

  components: {
    CloseIcon: () => import('icons/Close'),
  },

  props: {
    value: {
      type: [String, Number, Object],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'default',
      validator: isInList(colors),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--chip--${this.color}`]: !!this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--chip {
  $self: &;

  display: inline-flex;
  border-radius: global(border-radius, micro);
  font-size: rem(13px);
  word-break: break-all;

  &__value {
    line-height: 1;
    padding: space(xs) - 1px;
  }

  &__remove {
    display: inline-block;
    color: color(base, dimmed);
    padding: 0 space(xxs);

    ::v-deep svg {
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }

  &--default {
    background-color: color(chip);

    #{$self}__remove {
      &:hover,
      &:focus {
        background-color: darken(color(chip), 5%);
      }
    }
  }

  &--blue {
    background-color: color(chip, blue);

    #{$self}__remove {
      &:hover,
      &:focus {
        background-color: darken(color(chip, blue), 5%);
      }
    }
  }

  &--orange {
    background-color: color(chip, orange);

    #{$self}__remove {
      &:hover,
      &:focus {
        background-color: darken(color(chip, orange), 5%);
      }
    }
  }
}
</style>
