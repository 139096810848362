<template>
  <component
    :is="computedWrapper"
    :aria-describedby="computedDescribedById"
    :rel="computedRel"
    :href="href"
    class="vue--anchor"
    :class="computedClasses"
    :target="computedTarget"
    v-on="listeners"
    ><slot /><OpenInNewIcon
      v-if="showExternal"
      title="Open an external page"
      class="vue--anchor__external"
    /><span
      v-if="blank"
      :id="computedDescribedById"
      data-snyk-test="BaseAnchor screen reader description"
      class="vue--anchor__offscreen"
    >
      Open this link in a new tab
    </span>
  </component>
</template>

<script>
import { isInList } from '~/lib/prop-validators';
import { makeId } from '~/lib/makeId';

export default {
  name: 'BaseAnchor',

  components: {
    OpenInNewIcon: () => import('icons/OpenInNew'),
  },

  props: {
    /**
     * Href to URL.
     */
    href: {
      type: String,
      default: null,
    },
    /**
     * Open link in a new tab.
     */
    blank: {
      type: Boolean,
      default: false,
    },
    /**
     * Disable link.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Invert the anchor styles for dark backgrounds.
     */
    variant: {
      type: String,
      default: null,
      validator() {
        return isInList(['cta', 'inverted', 'plain']);
      },
    },
    /**
     * Fallback wrapper element used when href isn't isn't provided.
     */
    fallbackWrapper: {
      type: String,
      default: 'span',
    },
    /**
     * Show an external link icon.
     */
    external: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return {
        'vue--anchor--disabled': this.disabled,
        [`vue--anchor--${this.variant}`]: !!this.variant,
      };
    },
    computedTarget() {
      return this.blank ? '_blank' : null;
    },
    computedWrapper() {
      return this.href ? 'a' : this.fallbackWrapper;
    },
    listeners() {
      const disabled = {};
      if (this.disabled) {
        disabled.click = (e) => e.preventDefault();
      }

      return {
        ...this.$listeners,
        ...disabled,
      };
    },
    showExternal() {
      return this.href && this.external;
    },
    computedDescribedById() {
      return this.blank ? `describedBy${makeId(10)}` : null;
    },
    computedRel() {
      return this.blank ? 'noopener noreferrer' : null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

a.vue--anchor {
  $self: &;

  color: color(base, anchor);
  cursor: pointer;
  text-decoration: none;
  transition: opacity 0.25s ease-in-out;

  &:hover,
  &:focus {
    color: color(base, anchor);
    text-decoration: underline;
  }

  &--cta {
    color: color(action, create);

    &:hover,
    &:focus {
      color: color(action, create);
      text-decoration: underline;
    }
  }

  &--inverted {
    color: color(ui, white);
    opacity: 0.8;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: color(ui, white);
      opacity: 1;
    }
  }

  &--plain {
    color: color(base, body);
    text-decoration: none;

    &:hover,
    &:focus {
      color: color(base, body);
      text-decoration: none;
    }
  }

  &--disabled {
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }
}

.vue--anchor {
  &__external {
    line-height: 1em;
    position: relative;
    top: -0.25em;
    margin-left: space(xxs);

    ::v-deep svg {
      height: 0.6em !important;
      width: 0.6em !important;
    }
  }

  &__offscreen {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}
</style>
