import {
  SET_SNAPSHOT_SAST_ISSUES,
  SET_SNAPSHOT_SAST_ISSUES_LOADING,
  SET_SNAPSHOT_SAST_ISSUES_LOADING_ERROR,
  UPDATE_SNAPSHOT_SAST_ISSUES,
} from '../../mutation-types';
import { request } from '~/lib/request';
import has from 'lodash/has';

export default {
  namespaced: true,

  state: {
    sastIssues: null,
    sastIssuesLoading: false,
    sastIssuesLoadingError: null,
    sastIssuesRequestId: null,
    sastSnippetPaths: new Set(),
    sastSnippetPathsLoading: new Set(),
    sastSnippetPathsLoaded: new Set(),
  },

  getters: {
    sastIssues: (state) => state.sastIssues,
    sastIssuesLoading: (state) => state.sastIssuesLoading,
    sastIssuesLoadingError: (state) => state.sastIssuesLoadingError,
    sastIssuesRequestId: (state) => state.sastIssuesRequestId,
    sastSnippetPaths: (state) => state.sastSnippetPaths,
    sastSnippetPathsLoading: (state) => state.sastSnippetPathsLoading,
    sastSnippetPathsLoaded: (state) => state.sastSnippetPathsLoaded,
  },

  actions: {
    async getSastIssues(
      { commit, state, dispatch },
      { org, project, snapshot, isHistoricSnapshot },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.sastIssuesLoading && !state.sastIssues) {
        commit(SET_SNAPSHOT_SAST_ISSUES_LOADING, true);

        try {
          const projectRoot = `/org/${org.name}/project/${project.publicId}`;
          const path = `${projectRoot}/sast-issues/${
            snapshot.publicId
          }?latest=${!isHistoricSnapshot}`;
          const res = await request(path);
          const { sastData } = await res.json();
          commit(SET_SNAPSHOT_SAST_ISSUES, sastData);

          // If there is a reasonable amount of snippet files to load, load them right away.
          const maxInitialSnippetPaths = 25;
          if (state.sastSnippetPaths.size <= maxInitialSnippetPaths) {
            await dispatch('getSastSnippets', {
              org,
              project,
              snapshot,
              isHistoricSnapshot,
              filePaths: [...state.sastSnippetPaths],
            });
          }
        } catch (err) {
          commit(SET_SNAPSHOT_SAST_ISSUES_LOADING_ERROR, err.requestId);
        }
        commit(SET_SNAPSHOT_SAST_ISSUES_LOADING, false);
      }
    },
    async getSastSnippets(
      { commit, state },
      { org, project, snapshot, isHistoricSnapshot, filePaths },
    ) {
      const snippetFilePaths = filePaths.filter(
        (path) =>
          !state.sastSnippetPathsLoaded.has(path) &&
          !state.sastSnippetPathsLoading.has(path),
      );
      if (!snippetFilePaths.length) {
        // No new file paths to get snippets for.
        return;
      }

      commit(UPDATE_SNAPSHOT_SAST_ISSUES, {
        snippets: state.snippets || null,
        status: 'pending',
        snippetFilePaths,
      });

      try {
        const projectRoot = `/org/${org.name}/project/${project.publicId}`;
        const path = `${projectRoot}/sast-issues/${
          snapshot.publicId
        }/snippets?latest=${!isHistoricSnapshot}`;

        const res = await request(path, {
          method: 'POST',
          body: { snippetFilePaths },
        });

        const { snippets } = await res.json();

        commit(UPDATE_SNAPSHOT_SAST_ISSUES, {
          snippets,
          status: 'complete',
          snippetFilePaths,
        });
      } catch (err) {
        commit(UPDATE_SNAPSHOT_SAST_ISSUES, {
          status: 'error',
          snippetFilePaths,
        });
      }
    },
  },

  mutations: {
    [SET_SNAPSHOT_SAST_ISSUES](state, sastIssues) {
      state.sastIssues = sastIssues.map((issue) => {
        issue.snippet.status = 'pending';
        return issue;
      });
      sastIssues.forEach((issue) => {
        state.sastSnippetPaths.add(issue.metadata.primaryFilePath);
      });
    },
    [SET_SNAPSHOT_SAST_ISSUES_LOADING](state, sastIssuesLoading) {
      state.sastIssuesLoading = sastIssuesLoading;
    },
    [SET_SNAPSHOT_SAST_ISSUES_LOADING_ERROR](state, requestId) {
      state.sastIssuesLoadingError = true;
      state.sastIssuesRequestId = requestId;
    },
    [UPDATE_SNAPSHOT_SAST_ISSUES](
      state,
      { snippets, status, snippetFilePaths = [] },
    ) {
      const isIssueAffected = (issue) => {
        return (snippetFilePaths || []).includes(
          issue.metadata.primaryFilePath,
        );
      };

      if (status === 'error' || status === 'pending') {
        state.sastIssues.forEach(function (issue, index) {
          if (isIssueAffected(issue)) {
            // Only alter status if it's null
            // or the issue is affected by the currently processed file paths.
            this[index].snippet.status = status;
          }
        }, state.sastIssues);

        // Update set of loading snippet paths.
        if (status === 'pending') {
          snippetFilePaths.forEach((path) => {
            state.sastSnippetPathsLoading.add(path);
          });
        } else if (status === 'error') {
          snippetFilePaths.forEach((path) => {
            state.sastSnippetPathsLoading.delete(path);
          });
        }
      } else {
        state.sastIssues.forEach(function (issue, index) {
          if (!isIssueAffected(issue)) {
            return;
          }

          const uniqueRuleId = `${issue.list[0].result.locations?.[0].physicalLocation?.artifactLocation?.uri}:${issue.list[0].result.locations?.[0].physicalLocation?.region.startLine}`;
          if (has(snippets, uniqueRuleId)) {
            const snippet = snippets[uniqueRuleId];
            this[index].snippet = {
              content: snippet.content,
              startLineNumber:
                //snippet.startLineNumber is always the last line in snippet.content
                snippet.startLineNumber - (snippet.content.length - 1),
              status: 'complete',
            };
          } else {
            this[index].snippet.status = 'error';
          }
        }, state.sastIssues);

        // Mark snippet file paths as processed.
        snippetFilePaths.forEach((path) => {
          state.sastSnippetPathsLoaded.add(path);
          state.sastSnippetPathsLoading.delete(path);
        });
      }
    },
  },
};
