import { ORG_PERMISSIONS } from '../../../../permissions/defs';
import type { Route } from '../../../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName/manage/members',
    component: 'OrgMembers/OrgMembers',
    sentryTeam: 'unicorn',
    meta: {
      title: 'Member Settings',
      routeName: 'Org members page',
      pageDesc: 'Manage the organisation members.',
      assertFlag: 'newOrgMembers',
      requirements: {
        page: {
          permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.USER_READ],
        },
        canInviteUsers: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.USER_READ,
            ORG_PERMISSIONS.USER_INVITE,
          ],
        },
        canCancelUserInvite: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.USER_READ,
            ORG_PERMISSIONS.USER_INVITE,
          ],
        },
        canInviteGroupUsers: {
          permissions: [
            ORG_PERMISSIONS.USER_ADD,
            ORG_PERMISSIONS.USER_READ,
            ORG_PERMISSIONS.READ,
          ],
        },
        canEditRequestAccessSettings: {
          permissions: [ORG_PERMISSIONS.REQUEST_ACCESS_SETTINGS_EDIT],
        },
        canRemoveUser: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.USER_READ,
            ORG_PERMISSIONS.USER_REMOVE,
          ],
        },
        canEditUserRole: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.USER_READ,
            ORG_PERMISSIONS.USER_ROLE_EDIT,
          ],
          entitlements: ['allowCollaborators'],
        },
        canManageUsers: {
          permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.USER_READ],
          entitlements: ['userManagement'],
        },
      },
    },
  },
];
