import { capitalize } from '~/lib/capitalize';

function capitalizePackageManager(type) {
  switch (type) {
    case 'k8sconfig': {
      return 'Kubernetes';
    }
    case 'helmconfig': {
      return 'Helm';
    }
    case 'terraformconfig': {
      return 'Terraform';
    }
    case 'terraformplan': {
      return 'Terraform Plan';
    }
    case 'cloudformationconfig': {
      return 'CloudFormation';
    }
    case 'aws-config': {
      return 'AWS';
    }
    default: {
      return capitalize(type);
    }
  }
}

const PackageManagerIconMap = {
  apk: () => import('~/components/CustomIcons/Apk'),
  cocoapods: () => import('~/components/CustomIcons/CocoaPods'),
  composer: () => import('~/components/CustomIcons/Composer'),
  cpp: () => import('~/components/CustomIcons/Cpp'),
  deb: () => import('~/components/CustomIcons/Deb'),
  'docker-hub': () => import('~/components/CustomIcons/Docker'),
  dockerfile: () => import('~/components/CustomIcons/Docker'),
  golang: () => import('~/components/CustomIcons/Golang'),
  golangdep: () => import('~/components/CustomIcons/Golangdep'),
  gomodules: () => import('~/components/CustomIcons/Golang'),
  govendor: () => import('~/components/CustomIcons/Golang'),
  gradle: () => import('~/components/CustomIcons/Gradle'),
  helmconfig: () => import('~/components/CustomIcons/Helm'),
  k8sconfig: () => import('~/components/CustomIcons/Kubernetes'),
  terraformconfig: () => import('~/components/CustomIcons/Terraform'),
  terraformplan: () => import('~/components/CustomIcons/Terraform'),
  cloudformationconfig: () => import('~/components/CustomIcons/CloudFormation'),
  maven: () => import('~/components/CustomIcons/Maven'),
  hex: () => import('~/components/CustomIcons/Hex'),
  npm: () => import('~/components/CustomIcons/Npm'),
  nuget: () => import('~/components/CustomIcons/Nuget'),
  paket: () => import('~/components/CustomIcons/Paket'),
  pip: () => import('~/components/CustomIcons/Pip'),
  poetry: () => import('~/components/CustomIcons/Poetry'),
  rpm: () => import('~/components/CustomIcons/Rpm'),
  rubygems: () => import('~/components/CustomIcons/Rubygems'),
  sast: () => import('~/components/CustomIcons/Code'),
  sbt: () => import('~/components/CustomIcons/Sbt'),
  yarn: () => import('~/components/CustomIcons/Yarn'),
  'yarn-workspace': () => import('~/components/CustomIcons/Yarn'),
  'aws-config': () => import('~/components/CustomIcons/AWSConfig'),
};

export { PackageManagerIconMap, capitalizePackageManager };
