import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/org/:orgName/manage/languages/:path*',
    sentryTeam: 'boost',
  },
  {
    path: '/org/:orgName/manage/integrations/:path*',
    sentryTeam: 'comet',
  },
  {
    path: '/org/:orgName/manage/cloud-config/:path*',
    sentryTeam: 'cloudconfig',
  },
  {
    path: '/org/:orgName/manage/:path*',
    sentryTeam: 'unicorn',
  },
];
