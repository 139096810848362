import type { Route, CodeownerRoute } from '../../types';

export const routes: Route[] = [];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/vuln/:path*',
    sentryTeam: 'apollo',
  },
];
