<template>
  <div class="vue--drag-handle"></div>
</template>

<script>
export default {
  name: 'BaseDragHandle',
  status: 'ready',
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--drag-handle {
  cursor: grab;
  width: 14px;
  height: 21px;
  background-image: radial-gradient(
    circle at center,
    color(base, dimmed) 2px,
    transparent 2px
  );
  background-size: 7px 7px;
}
</style>
