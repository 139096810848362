<template>
  <BaseDropdownMenu
    class="vue--dropdown-menu-handle-select"
    :class="computedClasses"
    :size="size"
    v-bind="$attrs"
    @click="onChange"
  >
    <template #handle>
      <div class="vue--dropdown-menu-handle-select__handle">
        <span v-text="label" />
        <strong v-text="selected" />
        <ChevronDownIcon
          title="Expand available list of options"
          class="vue--dropdown-menu-handle-select__icon"
        />
      </div>
    </template>
    <slot />
  </BaseDropdownMenu>
</template>

<script>
import { isInList } from '~/lib/prop-validators';
import ChevronDownIcon from 'icons/ChevronDown';

export default {
  name: 'BaseDropdownMenuHandleSelect',
  status: 'ready',

  components: {
    ChevronDownIcon,
  },

  props: {
    label: {
      type: String,
      default: '',
    },
    defaultSelected: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: null,
      validator: isInList(['extra-small', 'small']),
    },
  },

  data() {
    return {
      selected: this.defaultSelected,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--dropdown-menu-handle-select--${this.size}`]: !!this.size,
      };
    },
  },

  methods: {
    onChange(selected) {
      this.selected = selected;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu-handle-select {
  $self: &;

  &__icon {
    position: relative;
    left: -#{space(xxs)};
    top: -1px;
  }

  &--extra-small {
    #{$self}__icon {
      left: -#{space(xxxs)};

      ::v-deep svg {
        height: rem(16px);
        width: rem(16px);
      }
    }
  }

  &--small {
    #{$self}__icon {
      left: -#{space(xxxs)};
      ::v-deep svg {
        height: rem(21px);
        width: rem(21px);
      }
    }
  }
}
</style>
