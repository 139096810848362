import { GROUP_PERMISSIONS } from '../../../../permissions/defs';
import type { Route } from '../../../../types';

export const routes: Route[] = [
  {
    path: '/group/:groupPublicId/manage/beta-features',
    component: 'GroupSettingsBetaFeatures/GroupSettingsBetaFeatures',
    sentryTeam: 'apollo',
    meta: {
      title: 'Snyk Preview',
      pageDesc: 'Snyk Preview',
      requirements: {
        page: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.SETTINGS_READ,
            GROUP_PERMISSIONS.SETTINGS_EDIT,
          ],
        },
        countContributingDevs: { features: ['countContributingDevs'] },
        canManageUsers: { entitlements: ['userManagement'] },
        canManageServiceAccounts: { entitlements: ['serviceAccounts'] },
        canManageNotifications: { entitlements: ['allowEmails'] },
      },
    },
  },
];
