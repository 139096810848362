<!-- cSpell:words metatable  -->
<template>
  <table class="vue--metatable" data-snyk-test="metatable">
    <tbody class="vue--metatable__body">
      <slot />
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BaseMetaTable',
  status: 'ready',
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--metatable {
  $self: &;

  border-collapse: collapse;
  border-spacing: 0;
  font-variant-numeric: tabular-nums;
  width: 100%;

  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  tr {
    border-bottom: 1px solid color(neutral, 84);
    border-top: 1px solid color(neutral, 84);
    padding: space(s) 0;
    width: 100%;

    &:nth-of-type(n + 2) {
      border-top: none;
    }

    @include media-query(m) {
      width: 45%;
      margin-right: 10%;

      &:nth-of-type(n + 2) {
        border-top: 1px solid color(neutral, 84);
      }

      &:nth-of-type(n + 3) {
        border-top: none;
      }

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }

    @include media-query(l) {
      margin-right: 5%;
      width: 21.25%;

      &:nth-of-type(n + 3) {
        border-top: 1px solid color(neutral, 84);
      }

      &:nth-of-type(n + 5) {
        border-top: none;
      }

      &:nth-of-type(2n) {
        margin-right: 5%;
      }

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }
}
</style>
