import { GROUP_PERMISSIONS } from '../../../permissions/defs';
import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [
  {
    path: '/group/:groupPublicId/manage',
    component: 'GroupSettingsGeneral/GroupSettingsGeneral',
    sentryTeam: 'unicorn',
    meta: {
      title: 'Group Settings',
      pageDesc: 'Group settings configuration',
      routeName: 'Group Settings',
      requirements: {
        page: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.SETTINGS_READ,
          ],
        },
        canEditGroupSettings: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.SETTINGS_READ,
            GROUP_PERMISSIONS.SETTINGS_EDIT,
          ],
        },
        canRequestReadAccess: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.SETTINGS_READ,
            GROUP_PERMISSIONS.REQUEST_ACCESS_SETTINGS_EDIT,
          ],
        },
        countContributingDevs: { features: ['countContributingDevs'] },
        canManageUsers: { entitlements: ['userManagement'] },
        canManageServiceAccounts: { entitlements: ['serviceAccounts'] },
        canManageNotifications: { entitlements: ['allowEmails'] },
      },
    },
  },
];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/group/:groupPublicId/manage/:path*',
    sentryTeam: 'unicorn',
  },
];
