<template>
  <BaseMultipleValueInput
    class="vue--cwe-input"
    v-bind="$attrs"
    :placeholder="placeholder"
    :input-placeholder="inputPlaceholder"
    :character-limit="characterLimit"
    :values="cwes"
    :restrict-characters="restrictCharacters"
    :duplicate-value="animateDuplicateValue"
    :additional-entry-keys="['Comma', 'Space']"
    @create="handleCreation"
    @update="handleUpdate"
    @paste="handlePaste"
  />
</template>

<script>
export default {
  name: 'BaseCWEInput',

  model: {
    prop: 'cwes',
    event: 'update',
  },

  props: {
    cwes: {
      type: Array,
      default: () => [],
    },
    restrictCharacters: {
      type: String,
      default: '[0-9]',
    },
    placeholder: {
      type: String,
      default: 'Add a CWE number',
    },
    inputPlaceholder: {
      type: String,
      default: 'e.g. 502 (or paste a comma separated string)',
    },
    characterLimit: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      animateDuplicateValue: null,
    };
  },

  methods: {
    handleCreation(number) {
      const cwe = `CWE-${number}`;

      if (this.cwes.includes(cwe)) {
        this.animateDuplicateValue = cwe;
        return;
      }

      this.$emit('update', [...this.cwes, cwe]);
    },
    handleUpdate(values) {
      this.$emit('update', values);
    },
    handlePaste(string) {
      const regex = new RegExp(
        `^${this.restrictCharacters}{0,${this.characterLimit}}$`,
      );
      const pastedCwes = string.includes(',') ? string.split(',') : [string];
      const validatedCwes = [];

      pastedCwes.forEach((cwe) => {
        if (!cwe.length) return;

        const uppercaseCwe = cwe.toUpperCase();
        const formattedCwe = (
          uppercaseCwe.startsWith('CWE-') ? uppercaseCwe : `CWE-${uppercaseCwe}`
        ).trim();
        const cweNumber = formattedCwe.substring(4, formattedCwe.length);

        if (
          regex.test(cweNumber) &&
          !this.cwes.includes(formattedCwe) &&
          !validatedCwes.includes(formattedCwe)
        ) {
          validatedCwes.push(formattedCwe);
        }
      });

      this.$emit('update', [...this.cwes, ...validatedCwes]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--cwe-input {
  &__prefix {
    font-size: rem(13px);
  }
}
</style>
