const setGlobalDebounce = createDebounce();

export function setDebounce(callback) {
  console.info(
    'setDebounce() is deprecated, please use createDebounce() instead.',
  );

  setGlobalDebounce(callback);
}

export function createDebounce() {
  const debounces = new Map();

  return function setDebounce(callback, timer = 300) {
    const key = callback.toString();
    if (debounces.has(key)) {
      clearTimeout(debounces.get(key));
      debounces.delete(key);
    }
    debounces.set(key, setTimeout(callback, timer));
  };
}
