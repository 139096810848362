<template>
  <div :class="computedClasses" class="vue--tooltip">
    <div
      ref="slot"
      :aria-describedby="id"
      :aria-controls="id"
      class="vue--tooltip__label"
      data-snyk-test="BaseTooltip: label"
      @mouseenter="showContent"
      @mouseleave="handleHideContent"
    >
      <slot>
        <HelpIcon />
      </slot>
    </div>
    <div
      v-if="isVisible"
      :id="id"
      ref="tooltip"
      :aria-hidden="isVisible"
      class="vue--tooltip__description"
      data-snyk-test="BaseTooltip: description"
      @mouseenter="cancelHideContent"
      @mouseleave="hideContent"
    >
      <BaseProse
        v-if="hasDescription || typeof description === 'string'"
        size="small"
      >
        <slot name="description">
          {{ description }}
        </slot>
      </BaseProse>
      <BaseProse
        v-for="(row, key) in description"
        v-else
        :key="key"
        size="small"
      >
        {{ row }}
        <br />
      </BaseProse>
      <div ref="arrow" class="vue--tooltip__arrow" />
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

import HelpIcon from '~/components/CustomIcons/Help';
import { isInList } from '~/lib/prop-validators';

let timeoutRegister = null;

/**
 * A tooltip component to display a useful description when the user hovers over the contained component.
 *
 * This component has a slot for the component to display, with a default of a question mark icon.
 */
export default {
  name: 'BaseTooltip',
  status: 'beta',

  components: {
    HelpIcon,
  },

  props: {
    /**
     * Unique identifier for the tooltip. Required for accessibility to function correctly.
     */
    id: {
      type: String,
      default: null,
    },

    /**
     * The content to be displayed in the tooltip.
     * This can be either a single string, or an array of strings to be displayed on separate lines
     */
    description: {
      type: [Array, String],
    },

    /**
     * Automatically resize the tooltip to fit content width
     */
    auto: {
      type: Boolean,
      default: false,
    },

    /**
     * Use a lighter shade for the icon
     */
    quiet: {
      type: Boolean,
      default: false,
    },

    /**
     * Size of tooltip.
     */
    size: {
      type: String,
      default: null,
      validator: isInList(['small', 'large']),
    },

    /**
     * Force open state.
     */
    open: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVisible: false,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--tooltip--${this.size}`]: !!this.size,
        'vue--tooltip--auto': this.auto,
        'vue--tooltip--quiet': this.quiet,
        'vue--tooltip--help': !this.hasSlot,
      };
    },
    hasSlot() {
      return !!this.$slots?.default;
    },
    hasDescription() {
      return !!this.$slots?.description?.length;
    },
  },

  async created() {
    if (this.open) this.isVisible = true;
    await this.setupPopper();
  },

  methods: {
    async setupPopper() {
      await this.$nextTick();
      if (this.$refs.slot && this.$refs.tooltip) {
        createPopper(this.$refs.slot, this.$refs.tooltip, {
          placement: 'top',
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: this.$refs.arrow,
                padding: 6,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        });
      }
    },

    async showContent() {
      this.isVisible = true;
      await this.setupPopper();
    },

    handleHideContent() {
      if (this.open) return;

      const duration = this.hasDescription ? 250 : 100;

      timeoutRegister = setTimeout(() => {
        this.hideContent();
      }, duration);
    },

    cancelHideContent() {
      clearTimeout(timeoutRegister);
    },

    hideContent() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--tooltip {
  $self: &;

  display: inline-block;
  position: relative;

  &__label {
    color: inherit;
    display: block;

    ::v-deep svg {
      height: rem(16px);
      width: rem(16px);
    }
  }

  &__arrow,
  &__arrow::before {
    position: absolute;
    z-index: -1;
  }

  &__arrow::before {
    content: '';
    border: 6px solid transparent;
    transform: translateX(-50%);
  }

  &__description {
    background-color: color(brand, default);
    border-radius: global(border-radius, half);
    color: color(ui, white);
    left: 50%;
    padding: space(s);
    text-transform: none;
    transform: translateX(calc(-50%));
    word-break: normal;
    width: 350px;
    z-index: 10;

    &[data-popper-placement^='top'] > #{$self}__arrow {
      bottom: 0;

      &:before {
        border-top-color: rgba(color(brand), 1);
      }
    }

    &[data-popper-placement^='bottom'] > #{$self}__arrow {
      top: -12px;

      &:before {
        border-bottom-color: rgba(color(brand), 1);
      }
    }
  }

  &--small {
    #{$self}__label {
      ::v-deep svg {
        height: rem(14px);
        width: rem(14px);
      }
    }
  }

  &--large {
    #{$self}__label {
      ::v-deep svg {
        height: rem(18px);
        width: rem(18px);
      }
    }
  }

  &--auto {
    #{$self}__description {
      width: auto;
      white-space: nowrap;
    }
  }

  &--quiet {
    color: color(neutral, 48);
  }

  &--help {
    #{$self}__label:hover {
      cursor: help;
    }
  }
}
</style>
