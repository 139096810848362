import { render, staticRenderFns } from "./BaseChipSeverity.vue?vue&type=template&id=0b9387d1&scoped=true&"
import script from "./BaseChipSeverity.vue?vue&type=script&lang=js&"
export * from "./BaseChipSeverity.vue?vue&type=script&lang=js&"
import style0 from "./BaseChipSeverity.vue?vue&type=style&index=0&id=0b9387d1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b9387d1",
  null
  
)

export default component.exports