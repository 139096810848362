<template>
  <span class="vue--chip-severity">
    <BaseSeverity
      class="vue--chip-severity__severity"
      :issues-high="value.severity === 'high' ? 1 : 0"
      :issues-medium="value.severity === 'medium' ? 1 : 0"
      :issues-low="value.severity === 'low' ? 1 : 0"
      size="small"
      compact
    />
    <span class="vue--chip-severity__value" v-text="text" />
    <button
      v-if="removable"
      type="button"
      class="vue--chip-severity__remove"
      data-snyk-test="BaseChipSeverity: remove"
      tabindex="0"
      @click="$emit('click', value)"
      @keydown.enter.prevent="$emit('keydown.enter', value)"
    >
      <CloseIcon />
    </button>
  </span>
</template>

<script>
export default {
  name: 'BaseChipSeverity',

  components: {
    CloseIcon: () => import('icons/Close'),
  },

  props: {
    value: {
      type: [String, Number, Object],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--chip-severity {
  display: inline-flex;
  background-color: color(neutral, 96);
  box-shadow: global(hoverState, defaultBoxShadow);
  border-radius: global(border-radius, micro);
  font-size: rem(13px);
  line-height: 1.2;
  min-height: rem(30px);
  padding-left: 22px + space(xxs);
  position: relative;
  word-break: break-all;

  &__severity {
    left: space(xxs);
    margin-right: space(xxs);
    position: absolute;
    top: space(xxs);
  }

  &__value {
    line-height: rem(30px);
    padding: 0 space(xs) - 1px;
  }

  &__remove {
    display: inline-block;
    color: color(base, dimmed);
    padding: 0 space(xxs);

    ::v-deep svg {
      height: 12px;
      width: 12px;
    }

    &:hover,
    &:focus {
      background-color: darken(color(neutral, 96), 5%);
      cursor: pointer;
    }
  }
}
</style>
