<template>
  <BaseMultipleValueInput
    class="vue--cve-input"
    v-bind="$attrs"
    :placeholder="placeholder"
    :input-placeholder="inputPlaceholder"
    :character-limit="characterLimit"
    :values="cves"
    :restrict-characters="restrictCharacters"
    :duplicate-value="animateDuplicateValue"
    :additional-entry-keys="['Comma', 'Space']"
    @create="handleCreation"
    @update="handleUpdate"
    @paste="handlePaste"
  />
</template>

<script>
export default {
  name: 'BaseCVEInput',

  model: {
    prop: 'cves',
    event: 'update',
  },

  props: {
    cves: {
      type: Array,
      default: () => [],
    },
    restrictCharacters: {
      type: String,
      default: '[0-9-]',
    },
    placeholder: {
      type: String,
      default: 'Add a CVE value',
    },
    inputPlaceholder: {
      type: String,
      default: 'e.g. 2021-1234 (or paste a comma separated string)',
    },
    characterLimit: {
      type: Number,
      default: 12,
    },
  },

  data() {
    return {
      animateDuplicateValue: null,
    };
  },

  methods: {
    handleCreation(value) {
      if (!this.validateCve(value)) {
        return;
      }

      const cve = `CVE-${value}`;

      if (this.cves.includes(cve)) {
        this.animateDuplicateValue = cve;
        return;
      }

      this.$emit('update', [...this.cves, cve]);
    },
    handleUpdate(values) {
      this.$emit('update', values);
    },
    handlePaste(string) {
      const pastedCves = string.includes(',') ? string.split(',') : [string];
      const validatedCves = [];

      pastedCves.forEach((cve) => {
        if (!cve.length) return;

        const uppercaseCve = cve.toUpperCase();
        const formattedCve = (
          uppercaseCve.startsWith('CVE-') ? uppercaseCve : `CVE-${uppercaseCve}`
        ).trim();
        const cveValue = formattedCve.substring(4, formattedCve.length);

        if (
          this.validateCve(cveValue) &&
          !this.cves.includes(formattedCve) &&
          !validatedCves.includes(formattedCve)
        ) {
          validatedCves.push(formattedCve);
        }
      });

      this.$emit('update', [...this.cves, ...validatedCves]);
    },
    validateCve(value) {
      // CVE values without prefix is structured like: YYYY-NNNN
      // YYYY - year, starting from 1999 (https://www.cvedetails.com/browse-by-date.php)
      // NNNN - arbitrary digits: 4-7 (https://cve.mitre.org/cve/identifiers/syntaxchange.html#new)
      return /^(1999|200\d|201\d|202\d)-[0-9]{4,7}$/.test(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--cve-input {
  &__prefix {
    font-size: rem(14px);
  }
}
</style>
