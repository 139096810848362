<template>
  <span>
    <span v-for="(text, i) in parsedString" :key="i" :class="getClass(text)">{{
      text
    }}</span>
  </span>
</template>

<script>
export default {
  name: 'BaseHighlight',

  props: {
    /**
     * The message to display
     */
    string: {
      type: String,
      required: true,
    },
    /**
     * The search query to highlight
     */
    searchQuery: {
      type: String,
      default: null,
    },
  },
  computed: {
    parsedSearch: function () {
      // Escape match string for RegExp usage i.e. () becomes \(\)
      return `(${this.searchQuery.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')})`;
    },
    parsedString: function () {
      return this.searchQuery
        ? this.string.split(new RegExp(this.parsedSearch, 'gi'))
        : [this.string];
    },
  },

  methods: {
    getClass: function (text) {
      return {
        highlight:
          this.searchQuery &&
          text.toUpperCase() === this.searchQuery.toUpperCase(),
      };
    },
  },
};
</script>
