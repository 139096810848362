<template>
  <button
    class="vue--org-switcher-button"
    :aria-expanded="menuIsExpanded"
    data-snyk-test="TheOrgSwitcherButton: button"
    @hover="$emit('hover')"
    @click="$emit('click')"
    @keydown.esc="$emit('close')"
  >
    <img
      v-if="hasGroupLogo"
      :src="group.logoUrl"
      :alt="group.name"
      class="vue--org-switcher-button__image"
      data-snyk-test="TheOrgSwitcherButton: group logo"
    />
    <span v-if="isGroupContext" class="vue--org-switcher-button__label">{{
      group.name
    }}</span>
    <span v-else-if="org" class="vue--org-switcher-button__label">{{
      org.displayName
    }}</span>
    <BaseBadge
      v-if="isGroupContext"
      variant="warning"
      size="small"
      class="vue--org-switcher-button__badge"
      data-snyk-test="TheOrgSwitcherButton: group overview badge"
      >Group Overview</BaseBadge
    >
    <IconChevronDown
      class="vue--org-switcher-button__icon"
      decorative
      title="Switch organization"
    />
  </button>
</template>

<script>
import IconChevronDown from 'icons/ChevronDown';

export default {
  name: 'TheOrgSwitcherButton',
  components: {
    IconChevronDown,
  },
  inject: ['org', 'group'],
  props: {
    url: {
      type: String,
      default: '/',
    },
    menuVisible: {
      type: Boolean,
      default: false,
    },
    focussed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isGroupContext() {
      return !!this.group && !!this.url.match(/^\/group\/([a-zA-Z0-9-_.]+)/);
    },
    hasGroupLogo() {
      return !!this.group?.logoUrl;
    },
    menuIsExpanded() {
      // Needs to be a string so that boolean false doesn't remove the attribute.
      return this.menuVisible ? 'true' : 'false';
    },
  },
  watch: {
    focussed(val) {
      if (val) {
        this.$el.focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--org-switcher-button {
  color: color(ui, white);
  border: 1px solid lighten(color(action), 20%);
  border-radius: global(border-radius, micro);
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: space(xs) space(s) space(xs) space(xxs);
  width: 100%;
  font-size: 1rem;
  line-height: 1.3;

  @include media-query(s) {
    max-width: rem(400px);
    width: 100%;
  }

  &__label {
    color: color(ui, white);
    flex: 1;
    text-align: left;
    margin-left: space(xs);
    margin-right: space(xs);
    font-family: 'Lucida Sans Unicode', 'Lucida Grande', Verdana, Arial,
      Helvetica, sans-serif;
    @include font-stack(bold);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__badge {
    float: right;
    margin-right: space(xs);
  }

  &__image {
    border-right: 2px solid lighten(color(action), 20%);
    height: rem(23px);
    padding-left: rem(6px);
    padding-right: space(s);
  }

  &:focus {
    outline: 2px solid color(base, inverted);
  }
}
</style>
