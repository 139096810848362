import {
  SET_SNAPSHOT_VULNS,
  SET_SNAPSHOT_VULNS_LOADING,
  SET_SNAPSHOT_VULNS_LOADING_ERROR,
} from '../../mutation-types';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  state: {
    vulns: null,
    vulnsLoading: false,
    vulnsLoadingError: null,
    vulnsRequestId: null,
  },

  getters: {
    vulns: (state) => state.vulns,
    vulnsLoading: (state) => state.vulnsLoading,
    vulnsLoadingError: (state) => state.vulnsLoadingError,
    vulnsRequestId: (state) => state.vulnsRequestId,
  },

  actions: {
    async getVulns(
      { rootState, commit, state },
      { orgName, projectPublicId, snapshotPublicId, isHistoricSnapshot },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.vulnsLoading && !state.vulns) {
        commit(SET_SNAPSHOT_VULNS_LOADING, true);

        try {
          const projectRoot = `${rootState.baseUrl}/org/${orgName}/project/${projectPublicId}`;
          const path = `${projectRoot}/vulns/${snapshotPublicId}?latest=${!isHistoricSnapshot}`;
          const res = await request(path);
          const { vulnData } = await res.json();
          commit(SET_SNAPSHOT_VULNS, vulnData);
        } catch (err) {
          commit(SET_SNAPSHOT_VULNS_LOADING_ERROR, err.requestId);
        }
        commit(SET_SNAPSHOT_VULNS_LOADING, false);
      }
    },
  },

  mutations: {
    [SET_SNAPSHOT_VULNS](state, vulns) {
      state.vulns = vulns;
    },
    [SET_SNAPSHOT_VULNS_LOADING](state, vulnsLoading) {
      state.vulnsLoading = vulnsLoading;
    },
    [SET_SNAPSHOT_VULNS_LOADING_ERROR](state, requestId) {
      state.vulnsLoadingError = true;
      state.vulnsRequestId = requestId;
    },
  },
};
