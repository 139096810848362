<template>
  <LayoutFormElement
    class="vue--checkbox"
    :class="computedClasses"
    spacing="none"
  >
    <label
      class="vue--checkbox__label"
      :title="label"
      @click.prevent="handleChange"
      @keyup.enter="handleChange"
      @keyup.space="handleChange"
    >
      <span :class="labelClasses">
        <slot />
        {{ label }}
      </span>
      <input
        v-bind="$attrs"
        :checked="isChecked"
        :value="value"
        type="checkbox"
        class="vue--checkbox__input"
        tabindex="-1"
      />
      <span
        :disabled="$attrs.disabled"
        :aria-checked="isChecked.toString()"
        class="vue--checkbox__toggle"
        :tabindex="$attrs.disabled ? -1 : 0"
        :aria-labelledby="$attrs.id"
      />
    </label>
    <BaseInputNote v-if="note" :variant="noteVariant">
      {{ note }}
    </BaseInputNote>
  </LayoutFormElement>
</template>

<script>
import isEqual from 'lodash/isEqual';
import LayoutFormElement from '~/components/LayoutFormElement/LayoutFormElement';
import { isInList } from '~/lib/prop-validators';

/**
 * Please note that as this component relies on the state given to it a `v-model` or
 * an externally managed `checked` property is required for this component to function
 */
export default {
  name: 'BaseCheckbox',

  status: 'ready',

  components: { LayoutFormElement },

  inheritAttrs: false,

  model: {
    prop: 'checked',
    event: 'change',
  },

  props: {
    /**
     * Value for the checkbox (on).
     */
    value: {
      type: [String, Array, Object, Number, Boolean],
      default: true,
    },
    /**
     * Value for the checkbox (off).
     */
    uncheckedValue: {
      type: [String, Array, Object, Number, Boolean],
      default: false,
    },
    /**
     * Checked state - determines weather value or uncheckedValue is emitted.
     */
    checked: {
      type: [String, Array, Object, Number, Boolean],
      default: null,
    },
    /**
     * BaseBadge for the checkbox field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Whether the form input field is displayed inline.
     * `true, false`
     */
    inline: {
      type: Boolean,
      default: false,
    },
    /**
     * The font-weight for the label
     * `semibold`
     */
    weight: {
      type: String,
      default: null,
      validator: isInList(['semibold']),
    },
    /**
     * Callback to be executed before processing a change event.
     * The event will only proceed if the callback returns a truthy value.
     * An example usage would be a confirmation dialog before applying the change
     */
    beforeChange: {
      type: Function,
      default: null,
    },
    /**
     * A note to be displayed below this input.
     */
    note: {
      type: String,
      default: null,
    },
  },

  computed: {
    isChecked() {
      if (Array.isArray(this.checked)) {
        return !!this.checked.filter((value) => isEqual(value, this.value))
          .length;
      }

      return this.checked === this.value ? true : false;
    },
    computedClasses() {
      return {
        'vue--checkbox--required': this.$attrs.required !== undefined,
        'vue--checkbox--inline': this.inline,
        'vue--checkbox--disabled':
          this.$attrs.disabled !== undefined && this.$attrs.disabled !== false,
      };
    },
    labelClasses() {
      return {
        'vue--checkbox__label-text': true,
        'vue--checkbox__label-text--semibold': !!this.weight,
      };
    },
    noteVariant() {
      return this.error ? 'error' : 'default';
    },
  },
  methods: {
    /**
     * Proceed with the change event if there is no `beforeChange` or returns true
     */
    handleChange($event) {
      if (
        !this.$attrs.disabled &&
        (!this.beforeChange || this.beforeChange($event))
      ) {
        this.onChange();
      }
    },

    onChange() {
      if (
        this.$attrs.disabled === undefined ||
        this.$attrs.disabled === false
      ) {
        let value;
        if (Array.isArray(this.checked)) {
          value = this.checked;
          if (value.includes(this.value)) {
            value = value.filter((item) => item !== this.value);
          } else {
            value.push(this.value);
          }
        } else {
          value = this.isChecked ? this.uncheckedValue : this.value;
        }

        /**
         * Triggers on checkbox checking.
         */
        this.$emit('change', value);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--checkbox {
  $self: &;

  display: block;
  line-height: rem(30px);
  padding: 0 0 0 1.5em;
  position: relative;

  &--disabled #{$self}__label {
    color: color(disabled, text);
  }

  &__toggle {
    @include fieldStyles();
    @include hoverState();

    height: 1em;
    left: 0;
    margin: 0 1px;
    padding: 0;
    position: absolute;
    top: rem(8px);
    width: 1em;
  }

  &__label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &__label-text--semibold {
    @include font-stack(semibold);
  }

  &__input {
    display: none;

    &:checked ~ #{$self}__toggle {
      box-shadow: inset 0 0 0 1px color(action), 0 0 0 2px transparent;

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        box-shadow: inset 0 0 0 1px color(action), 0 0 0 1px color(action);
        outline: none;
      }
      // Checkmark
      &::after {
        border: solid color(action);
        border-width: 0 2px 2px 0;
        content: '';
        height: 0.45em;
        left: 34%;
        position: absolute;
        top: 16%;
        transform: rotate(45deg);
        width: 0.2em;
        box-sizing: content-box;
      }
    }

    &[disabled] ~ #{$self}__toggle {
      @include disabledState();

      &::after {
        top: 13.5%;
        border-color: color(disabled, border);
      }
    }
  }

  &--inline {
    display: inline-block;
    width: auto;

    + #{$self} {
      margin-left: space();
    }
  }
}
</style>
