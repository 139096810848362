<template>
  <LayoutFormElement class="vue--radio-group">
    <template v-if="label" #label>
      <label class="vue--radio-group__label" v-text="label" />
    </template>
    <BaseRadio
      v-for="item in radioButtons"
      :key="item.id"
      v-bind="item"
      :checked="selected === item.value"
      :name="item.name"
      @change="onChange(item.value)"
    />
  </LayoutFormElement>
</template>

<script>
import LayoutFormElement from '~/components/LayoutFormElement/LayoutFormElement';
import { makeId } from '~/lib/makeId.js';

export default {
  name: 'BaseRadioGroup',
  status: 'FormElement',

  components: { LayoutFormElement },

  props: {
    /**
     * BaseBadge for the radio group
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Array or radio button objects, with the following structure:
     * {
     *  {String}  id (required)
     *  {String}  [name]
     *  {String}  [value]
     *  {String}  [label]
     *  {Boolean} [required]
     *  {Boolean} [disabled]
     * }
     */
    radioButtons: {
      type: Array,
      required: true,
      validator(items) {
        return items.every((item) => typeof item.id === 'string');
      },
    },
    /**
     * The value for the selected radio button
     */
    selectedValue: {
      type: String,
      default: null,
    },

    name: {
      type: String,
      default: function () {
        return makeId(6);
      },
    },
  },
  data: function () {
    return {
      selected: this.selectedValue || this.radioButtons[0].value,
    };
  },
  methods: {
    onChange(value) {
      this.selected = value;
      this.$emit('change', value);
    },
  },
};
</script>
