import { GROUP_PERMISSIONS } from '../../../../permissions/defs';
import type { Route } from '../../../../types';

export const routes: Route[] = [
  {
    path: '/group/:groupPublicId/manage/members',
    component: 'GroupMembers/GroupMembers',
    sentryTeam: 'unicorn',
    meta: {
      title: 'Member Settings',
      routeName: 'Group members page',
      pageDesc: 'Manage the group members.',
      assertFlag: 'newGroupMembers',
      requirements: {
        page: {
          permissions: [GROUP_PERMISSIONS.READ, GROUP_PERMISSIONS.USER_READ],
        },
        canRemoveMembers: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.USER_READ,
            GROUP_PERMISSIONS.USER_REMOVE,
          ],
        },
        canEditMemberRole: {
          permissions: [
            GROUP_PERMISSIONS.READ,
            GROUP_PERMISSIONS.USER_READ,
            GROUP_PERMISSIONS.USER_ROLE_EDIT,
          ],
          entitlements: ['allowCollaborators'],
        },
      },
    },
  },
];
