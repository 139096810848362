import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/group/:groupPublicId/reports/:path*',
    sentryTeam: 'governance',
  },
];
