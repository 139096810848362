<template>
  <BaseAnchor class="vue--anchor-integration" v-bind="$attrs"
    ><component
      :is="icon"
      class="vue--anchor-integration__icon"
      :aria-label="integration" /><span class="vue--anchor-integration__text"
      ><slot /></span
  ></BaseAnchor>
</template>

<script>
import { IntegrationsMap } from '~/lib/integrations-utils';
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseAnchorIntegration',
  status: 'ready',

  props: {
    /**
     * Integration name to determine which icon to use.
     */
    integration: {
      type: String,
      required: true,
      validator: isInList(Object.keys(IntegrationsMap)),
    },
  },

  computed: {
    icon() {
      return IntegrationsMap[this.integration].icon;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--anchor-integration {
  display: inline-flex;
  align-items: center;

  &__icon {
    color: color(base, body);
    height: 22px;
    margin-right: space(xxs);
    width: 22px;

    ::v-deep svg {
      height: 22px;
      width: 22px;
    }
  }

  &__text {
    position: relative;
    top: 2px;
  }
}
</style>
