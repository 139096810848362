<template>
  <nav class="vue--breadcrumbs">
    <ol class="vue--breadcrumbs__list">
      <li
        v-for="(item, index) in items"
        :key="index"
        class="vue--breadcrumbs__list-item"
      >
        <a
          v-if="item.url"
          :href="item.url"
          class="vue--breadcrumbs__list-item__url"
        >
          <span v-if="item.icon" class="vue--breadcrumbs__list-item__icon">
            <component :is="item.icon" :size="16" decorative />
          </span>
          {{ item.label }}
        </a>

        <span v-else>
          <span v-if="item.icon" class="vue--breadcrumbs__list-item__icon">
            <component :is="item.icon" :size="16" decorative />
          </span>
          {{ item.label }}
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { hasRequiredKeys } from '~/lib/prop-validators';

export default {
  name: 'BaseBreadcrumbs',

  status: 'ready',

  props: {
    items: {
      type: Array,
      validator: (list) => list.every(hasRequiredKeys(['label'])),
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--breadcrumbs {
  $self: &;

  &__list {
    display: flex;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    list-style-type: none;
    margin: 0;

    &::after {
      content: '›';
      margin: 0 space(xs) 0 space(xxs);
    }

    &:last-child::after {
      display: none;
    }
  }
}
</style>
