interface RetainedStateKey {
  page: string;
  concept: string;
}

const getKey = ({ page, concept }: RetainedStateKey) =>
  `snyk.${page}.${concept}`;

export default {
  set({ page, concept }: RetainedStateKey, value: object) {
    const key = getKey({ page, concept });
    const oldValue = localStorage.getItem(key);
    if (oldValue === null) {
      localStorage.setItem(key, JSON.stringify(value));
      return value;
    }
    const parsedOldValue = JSON.parse(oldValue);
    const mergedValue = { ...parsedOldValue, ...value };
    localStorage.setItem(key, JSON.stringify(mergedValue));
    return mergedValue;
  },
  get({ page, concept }: RetainedStateKey) {
    const key = getKey({ page, concept });
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  },
  delete({ page, concept }: RetainedStateKey) {
    const key = getKey({ page, concept });
    localStorage.removeItem(key);
  },
};
