import { ORG_PERMISSIONS } from '../../../permissions/defs';
import type { Route, CodeownerRoute } from '../../../types';

export const routes: Route[] = [
  {
    path: '/org/:orgName/projects',
    component: 'Projects/Projects',
    sentryTeam: 'rhino',
    meta: {
      title: 'Projects',
      routeName: 'Projects',
      pageDesc: 'Snyk projects',
      requirements: {
        page: {
          permissions: [ORG_PERMISSIONS.READ, ORG_PERMISSIONS.PROJECT_READ],
        },
        canAddProjects: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_ADD,
          ],
          enabled: ['showAddProjects'],
        },
        canAccessProjectSettings: { enabled: ['showProjectSettings'] },
        canActivateProjects: {
          permissions: [
            ORG_PERMISSIONS.READ,
            ORG_PERMISSIONS.PROJECT_READ,
            ORG_PERMISSIONS.PROJECT_ACTIVATE,
          ],
          enabled: ['showProjectActivateDeactivate'],
        },
        canFilterByTags: { entitlements: ['tags'] },
        showCriticalSeverity: { features: ['showCriticalSeverity'] },
        targetRefGrouping: { features: ['targetRefGrouping'] },
        canViewReports: {
          entitlements: ['reports'],
          permissions: [ORG_PERMISSIONS.REPORT_READ],
        },
      },
    },
  },
];

export const codeownerRoutes: CodeownerRoute[] = [
  {
    path: '/org/:orgName/projects/:path*',
    sentryTeam: 'unicorn',
  },
];
