import NotificationsRootContainer from '~/plugins/Notifications/NotificationsRootContainer/NotificationsRootContainer';

export default {
  install(Vue) {
    const root = new Vue({
      data: { alert: null, text: null },
      render: (createElement) => createElement(NotificationsRootContainer),
    });

    root.$mount(document.body.appendChild(document.createElement('div')));

    const show = (options) => {
      root.$set(root, 'alert', options);
      return root;
    };

    const notify = {
      success(message, options) {
        show({
          variant: 'success',
          message,
          dismissible: 'auto',
          ...options,
        });
      },
      info(message, options) {
        show({
          variant: 'info',
          message,
          dismissible: 'auto',
          ...options,
        });
      },
      danger(message, options) {
        show({
          variant: 'danger',
          message,
          dismissible: 'auto',
          ...options,
        });
      },
      warning(message, options) {
        show({
          variant: 'warning',
          message,
          dismissible: 'auto',
          ...options,
        });
      },
      root,
    };

    Vue.prototype.$notify = notify;
  },
};
