<template>
  <div v-if="showMenu" class="vue--account-panel">
    <BaseDropdownMenu
      :open="open"
      :open-on-focus="false"
      :force-close="forceClose"
      align="right"
    >
      <template #handle>
        <button
          class="vue--account-panel__button"
          data-snyk-test="TheAccountPanel: Toggle"
          tabindex="-1"
          aria-label="Expand the account menu"
        >
          <BaseAvatar
            :avatar="user.avatar"
            :alt="user.name"
            :email="user.email"
            size="large"
            inverted
          />
          <ChevronDownIcon
            decorative
            class="vue--account-panel__down-icon"
          /></button
      ></template>
      <template #header>
        <div class="vue--account-panel__header">
          <BaseAvatarUsername
            :avatar="user.avatar"
            :label="user.name"
            :email="user.email"
            :display-email="true"
            size="large"
            variant="compact"
          />
        </div>
      </template>
      <template v-for="item in links">
        <li
          v-if="shouldDisplay(item)"
          :key="item.path"
          :class="computedClasses(item)"
        >
          <TheAccountPanelLink
            :path="item.path"
            :label="item.label"
            :is-active="item.active"
            :is-server-routed="item.serverRouted"
            :exact-match="item.exactMatch"
          />
        </li>
      </template>
      <template #footer>
        <div class="vue--account-panel__footer">
          <form method="post" action="/logout">
            <input :value="csrfToken" type="hidden" name="_csrf" />
            <BaseButton
              class="vue--account-panel__logout-button"
              wrapper="button"
              type="submit"
              variant="link"
              data-snyk-test="TheAccountPanel: Log out button"
            >
              Log out
            </BaseButton>
          </form>
        </div>
      </template>
    </BaseDropdownMenu>
  </div>
</template>

<script>
import ChevronDownIcon from 'icons/ChevronDown';
import TheAccountPanelLink from '~/components/TheAccountPanelLink/TheAccountPanelLink';

export default {
  name: 'TheAccountPanel',
  components: { ChevronDownIcon, TheAccountPanelLink },
  inject: ['user'],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    links: {
      type: Array,
    },
    forceClose: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    csrfToken() {
      if (typeof window === 'undefined') return '';
      return (
        window.$embedded?.csrfToken ||
        window.$embedded?.injectVueProps?.csrfToken
      );
    },
    showMenu() {
      if (typeof window === 'undefined') return false;
      return window.matchMedia('(min-width: 640px)').matches;
    },
  },
  methods: {
    computedClasses(item) {
      return {
        'vue--account-panel__link': true,
        'vue--account-panel__link--separated': item.separator,
      };
    },
    shouldDisplay(item) {
      return !!item.checks.permitted;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--account-panel {
  $self: &;

  width: rem(66px);

  &__button {
    display: flex;
    flex-direction: row;

    &:focus {
      box-shadow: none;
    }
    &:focus-visible {
      outline: 2px solid color(ui, white);
      opacity: 1;
    }
  }

  &__down-icon {
    margin-top: space(xxs);
    margin-left: space(xxxs);
    display: inline-block;
    opacity: 0.5;
    color: color(ui, white);

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    &__down-icon {
      opacity: 1;
    }
  }

  &__header {
    padding: space(m) space(m) space(default) space(m);
    border-bottom: 1px solid color(neutral, 90);
  }

  &__link {
    list-style: none;
    margin: 0;

    &--separated {
      padding-top: space(xs);
      margin-top: space(xs);
      border-top: 1px solid color(neutral, 90);
    }
  }

  &__footer {
    border-top: 1px solid color(neutral, 90);
  }

  &__logout-button:focus-visible {
    outline: global(focusState, outline);
  }

  ::v-deep .vue--button {
    width: 100%;
    padding: space(default) space(m) space(s) space(m);
    font-size: rem(15px);
  }

  ::v-deep .vue--dropdown-menu__menu--primary {
    padding: space(xs) 0;
  }

  ::v-deep .vue--dropdown-menu__menu {
    width: rem(300px);
    border-radius: rem(2px);
  }

  ::v-deep .vue--dropdown-menu__handle:focus-visible {
    outline: 2px solid color(ui, white);
    opacity: 1;

    #{$self}__down-icon {
      opacity: 1;
    }
  }

  ::v-deep .vue--avatar-username__email {
    max-width: rem(240px);
    word-break: break-all;
    text-overflow: ellipsis;
  }
}
</style>
