<template>
  <div v-if="headway.enabled" class="vue--product-updates">
    <a
      class="vue--product-updates__link"
      :class="computedClasses"
      href="https://updates.snyk.io"
    >
      <span v-if="unseenCount" class="vue--product-updates__count">
        {{ unseenCount }}
      </span>
      <Bell class="vue--product-updates__icon" title="Product updates" />
    </a>
  </div>
</template>

<script>
import Bell from 'icons/BellOutline';

export default {
  name: 'TheProductUpdates',
  components: {
    Bell,
  },
  inject: ['headway'],
  props: {
    /**
     * Default unseen count (only used for documentation & tests)
     */
    defaultUnseenCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      widget: undefined,
      unseenCount: this.defaultUnseenCount,
      headwayConfig: {
        selector: '.vue--product-updates',
        account: this.headway.accountId,
        position: {
          x: 'left',
          y: 'bottom',
        },
        translations: {
          title: 'Product updates',
        },
        callbacks: {
          onWidgetReady: (widget) => {
            this.widget = widget;
            this.unseenCount = this.widget.getUnseenCount();
          },
          onShowWidget: () => {
            this.$parent.$emit('close:header-items', [this.id]);
            this.unseenCount = 0;
          },
        },
      },
    };
  },
  computed: {
    computedClasses() {
      return {
        [`vue--product-updates__link--alerts`]: this.unseenCount > 0,
      };
    },
  },
  async mounted() {
    if (typeof window === 'undefined' || !this.headway.enabled) {
      return;
    }

    // Inject Headway app script
    await new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = '//cdn.headwayapp.co/widget.js';
      script.id = 'headway-script';
      script.setAttribute('async', true);
      document.head.appendChild(script);
      script.onload = resolve; //Resolve when loaded
      script.onerror = reject;
    });

    // Init the widget now that the script has loaded
    window.Headway.init(this.headwayConfig);
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--product-updates {
  position: relative;
  max-width: 100px;

  &__link {
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    padding: space(s) space(xs);

    &:focus-visible {
      outline: 2px solid color(ui, white);
      opacity: 1;
    }
  }

  &:hover &__link {
    color: color(ui, white);
  }

  &__link--alerts {
    color: color(ui, white);
  }

  &__icon {
    margin: 0 auto;
  }

  &__count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: color(action, destroy);
    border-radius: 100%;
    color: color(ui, white);
    font-size: rem(12px);
    line-height: 12px;
    min-width: 17px;
    height: 17px;
    padding: 2px;
    text-align: center;
    vertical-align: middle;
  }

  ::v-deep #HW_badge_cont {
    text-indent: -10000px;
  }
}
</style>
