<template>
  <div class="vue--account-menu">
    <button
      aria-label="Expand the account menu"
      class="vue--account-menu__button"
      @click="toggleMenu"
    >
      <ChevronDownIcon decorative :class="computedClasses" />
      <BaseAvatarUsername
        :avatar="user.avatar"
        :label="user.name"
        :email="user.email"
        size="large"
        variant="compact"
        inverted
      />
    </button>
    <ul v-if="showMenu" class="vue--account-menu__list">
      <template v-for="item in links">
        <li
          v-if="shouldDisplay(item)"
          :key="item.path"
          class="vue--account-menu__item"
        >
          <TheAccountPanelLink
            :path="item.path"
            :label="item.label"
            :is-active="item.active"
            :is-server-routed="item.serverRouted"
            :exact-match="item.exactMatch"
            inverted
            @click="closeNav"
          />
        </li>
      </template>
      <li class="vue--account-menu__item">
        <form method="post" action="/logout">
          <input :value="csrfToken" type="hidden" name="_csrf" />
          <BaseButton
            wrapper="button"
            type="submit"
            variant="link"
            class="vue--account-menu__item__button"
          >
            Log out
          </BaseButton>
        </form>
      </li>
    </ul>
  </div>
</template>

<script>
import TheAccountPanelLink from '~/components/TheAccountPanelLink/TheAccountPanelLink';
import ChevronDownIcon from 'icons/ChevronDown';

export default {
  name: 'TheAccountMenu',
  components: { TheAccountPanelLink, ChevronDownIcon },
  inject: ['user'],
  props: {
    links: {
      type: Array,
    },
  },
  data() {
    return { isMenuOpen: false };
  },
  computed: {
    computedClasses() {
      return {
        'vue--account-menu__arrow': true,
        'vue--account-menu__arrow--collapsed': !this.isMenuOpen,
      };
    },
    csrfToken() {
      if (typeof window === 'undefined') return '';
      return (
        window.$embedded.csrfToken || window.$embedded.injectVueProps.csrfToken
      );
    },
    showMenu() {
      return this.isMenuOpen;
    },
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeNav() {
      this.$emit('closeNav');
    },
    shouldDisplay(item) {
      return !!item.checks.permitted;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--account-menu {
  color: color(ui, white);

  &__button {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__arrow {
    padding: 0 space(s) 0 0;

    & svg {
      transition: transform 0.2s ease-in-out;
    }

    &--collapsed svg {
      transform: rotate(-90deg);
    }
  }

  &__list {
    padding: space(m) 0;
  }
  &__item {
    list-style: none;
    margin: 0;

    ::v-deep .vue--button--link:not([disabled]) {
      font-size: rem(15px);
      color: color(ui, white);
      opacity: 0.8;
      width: 100%;
      padding: space(xs) space(l);

      &:hover {
        text-decoration: none;
        opacity: 1;
        color: color(ui, white);
      }
    }
  }
}
</style>
