<template>
  <div class="vue--copy-to-clipboard">
    <BaseInput
      :id="randomId"
      class="vue--copy-to-clipboard__input"
      type="text"
      :value="value"
      tabindex="-1"
    />
    <div class="vue--copy-to-clipboard__text">
      <template v-if="hasSlot">
        <!-- @slot Optionally a slot can be used for the copyable text to clipboard. -->
        <slot />
      </template>
      <template v-else>
        {{ value }}
      </template>
    </div>
    <BaseButton
      type="button"
      size="small"
      class="vue--copy-to-clipboard__button"
      @click="copyToClipboard"
      v-text="buttonText"
    />
  </div>
</template>

<script>
import * as clipboard from 'clipboard-polyfill';
import { randomString } from '~/lib/random';

export default {
  name: 'BaseCopyToClipboard',
  status: 'ready',

  props: {
    /**
     * Value to be copied to clipboard.
     */
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      copied: false,
      pasteValue: null,
    };
  },

  computed: {
    buttonText() {
      return this.copied ? 'Copied' : 'Copy';
    },
    randomId() {
      return randomString(10);
    },
    hasSlot() {
      return this.$slots.default;
    },
  },

  methods: {
    copyToClipboard() {
      const copy = this.hasSlot ? this.$slots.default[0].text : this.value;

      clipboard.writeText(copy).then(() => {
        this.copied = true;

        setTimeout(() => {
          this.copied = false;
        }, 2000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--copy-to-clipboard {
  position: relative;

  &::after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 40%
    );
    border-radius: global(border-radius, micro) global(border-radius, micro) 0 0;
    display: block;
    content: '';
    height: 36px;
    width: space(xxxl);
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 3;
  }

  &__input {
    position: absolute;
    height: 1px;
    left: space(s);
    top: space(s);
    width: 1px;
    z-index: 1;

    ::v-deep input {
      padding: 0;
    }
  }

  &__text {
    @include fieldStyles();
    border: 1px solid color(neutral, 72);
    overflow-y: hidden;
    overflow-x: auto;
    padding-right: space(xxxl);
    position: relative;
    white-space: nowrap;
    z-index: 2;
  }

  &__button {
    position: absolute;
    right: space(xs);
    top: space(xs);
    z-index: 4;
  }
}
</style>
