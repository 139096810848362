<template>
  <li
    class="vue--dropdown-menu-item"
    :class="computedClasses"
    :tabindex="tabIndex"
    v-on="listeners"
  >
    <slot>
      <strong>{{ value }}</strong>
    </slot>
  </li>
</template>

<script>
import { isInList } from '~/lib/prop-validators';
export default {
  name: 'BaseDropdownMenuItem',
  status: 'ready',

  props: {
    variant: {
      type: String,
      default: null,
      validator: isInList(['heading', 'nested']),
    },
    value: {
      type: String,
      default: null,
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--dropdown-menu-item--${this.variant}`]: !!this.variant,
      };
    },
    listeners() {
      if (this.variant !== 'heading') {
        return {
          focus: () => this.parentEmit('focusedIn'),
          focusout: () => this.parentEmit('focusedOut'),
          keyup: (e) => {
            if (e.keyCode === 'enter' || e.keyCode === 'space') {
              this.parentEmit('click');
            }
          },
          click: (e) => this.click(e),
        };
      }
      return {};
    },
    tabIndex() {
      return this.variant === 'heading' ? -1 : 0;
    },
  },

  methods: {
    parentEmit(name) {
      this.$parent.$emit(name, this.value);
    },
    click() {
      this.parentEmit('click');

      /**
       * Triggered on item click.
       */
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--dropdown-menu-item {
  cursor: pointer;
  list-style: none;
  margin: 0;
  padding: space(xxs) space(m);
  white-space: nowrap;
  color: color(base, body);

  &--nested {
    padding-left: space(l);
  }

  &:hover,
  &:focus {
    color: color(action);
  }

  &:focus {
    outline: global(focusState, outline);
  }

  &--heading {
    text-transform: uppercase;
    color: color(base, dimmed);
    &:hover,
    &:focus {
      color: color(base, dimmed);
    }
  }
}
</style>
