<template>
  <div class="vue--tags-filter-input">
    <ul v-if="!open" class="vue--tags-filter-input__items">
      <template v-for="(tag, index) in groupedTags">
        <li
          v-if="index > 0 && groupedTags[index - 1].key !== tag.key"
          :key="`${tag.key}:${tag.value}`"
          class="
            vue--tags-filter-input__item vue--tags-filter-input__item--operator
          "
        >
          <BaseAllCaps size="small">And</BaseAllCaps>
        </li>
        <li
          :key="`operator-${tag.key}:${tag.value}`"
          class="vue--tags-filter-input__item"
        >
          <span
            class="vue--tags-filter-input__value"
            data-snyk-test="BaseTagsFilterInput: value"
            @click="toggleInput"
          >
            <BaseChip
              :value="tag"
              :text="`${tag.key}:${tag.value}`"
              :removable="false"
              color="orange"
            >
              <!-- eslint-disable vue/no-lone-template -->
              <!-- prettier-ignore -->
              <template v-if="tag"><strong>{{ tag.key }}:</strong>{{ tag.value }}</template>
              <!-- eslint-enable vue/no-lone-template -->
            </BaseChip>

            <button
              aria-label="Remove value"
              type="button"
              class="vue--tags-filter-input__remove"
              data-snyk-test="BaseTagsFilterInput: remove"
              tabindex="0"
              @click.stop="removeValue(tag)"
            >
              <CloseIcon />
            </button>
          </span>
        </li>
      </template>
    </ul>

    <BaseTagsInput
      v-if="open"
      v-model="values"
      v-bind="$attrs"
      :open="open"
      placeholder="Filter by tag"
      selection-only
      class="vue--tags-filter-input__input"
      data-snyk-test="BaseTagsFilterInput: input"
      @update="updateValues"
      @opened="opened"
      @closed="closed"
      @asyncQuery="asyncQuery"
    />
    <BaseButton
      v-if="!open && !values.length"
      variant="link"
      class="vue--tags-filter-input__add-values"
      data-snyk-test="BaseTagsFilterInput: add value"
      @click.stop="toggleInput"
    >
      <PlusCircleOutlineIcon />
      Filter by tag
    </BaseButton>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy';

export default {
  name: 'BaseTagsFilterInput',

  components: {
    CloseIcon: () => import('icons/Close'),
    PlusCircleOutlineIcon: () => import('icons/PlusCircleOutline'),
  },

  model: {
    prop: 'tags',
    event: 'update',
  },

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      open: false,
      values: this.tags || [],
    };
  },

  computed: {
    groupedTags() {
      return sortBy(this.values, ['key', 'value']);
    },
  },

  watch: {
    tags() {
      this.values = this.tags || [];
    },
  },

  methods: {
    toggleInput() {
      this.open = !this.open;
    },
    updateValues(tags) {
      this.$emit('update', tags);
    },
    removeValue(tag) {
      const updateValues = this.values.filter((item) => item !== tag);

      this.$emit('update', updateValues);
    },
    opened() {
      this.$emit('opened');
    },
    asyncQuery(payload) {
      this.$emit('asyncQuery', payload);
    },
    closed() {
      this.toggleInput();
      this.$emit('closed');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--tags-filter-input {
  &__items {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    margin: 0;

    &--operator {
      padding: space(xxs) space(xxs) 0;
    }
  }

  &__value {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: space(xxs);

    &:hover {
      background-color: color(neutral, 96);
      cursor: pointer;
    }
  }

  &__remove {
    border-radius: global(border-radius, micro);
    padding: 0 space(xxs);

    ::v-deep svg {
      height: 14px;
      width: 14px;
    }

    &:hover {
      background-color: color(neutral, 90);
      cursor: pointer;
    }
  }

  &__input {
    left: -#{space(xs)};
    margin-bottom: -#{space(xs)};
    position: relative;
    top: -#{space(xxs)};
  }

  &__add-values {
    padding: space(xs);
    width: 100%;

    &:not([disabled]):hover {
      background-color: color(neutral, 96);
      text-decoration: none;
      cursor: pointer;
    }
  }
}
</style>
