















import { defineComponent, PropType } from '@vue/composition-api';

import { isInList } from '~/lib/prop-validators';
import { VulnSeverity, VulnSeverityLevels } from 'shared/vuln-severity';

export default defineComponent({
  name: 'BaseMultiSeverity',
  props: {
    mainSeverity: {
      type: String as PropType<VulnSeverity>,
      required: true,
      validator: isInList(VulnSeverityLevels),
    },
    topCornerSeverity: {
      type: String as PropType<VulnSeverity>,
      required: false,
    },
    bottomCornerSeverity: {
      type: String as PropType<VulnSeverity>,
      required: false,
    },
  },

  computed: {
    bottomCornerClasses() {
      const classes = {};
      if (this.bottomCornerSeverity) {
        classes[`vue--multi-severity__corner`] = true;
        classes[`vue--multi-severity__corner__bottom`] = true;
        classes[
          `vue--multi-severity__corner--${this.bottomCornerSeverity}-severity`
        ] = true;
      }
      return classes;
    },
    topCornerClasses() {
      const classes = {};
      if (this.topCornerSeverity) {
        classes[`vue--multi-severity__corner`] = true;
        classes[`vue--multi-severity__corner__top`] = true;
        classes[
          `vue--multi-severity__corner--${this.topCornerSeverity}-severity`
        ] = true;
      }
      return classes;
    },
  },
});
