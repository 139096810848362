<template>
  <component :is="wrapper" class="vue--all-caps" :class="computedClasses">
    <slot />
  </component>
</template>

<script>
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseAllCaps',

  props: {
    wrapper: {
      type: String,
      default: 'h3',
      validator: isInList(['h3', 'span']),
    },
    size: {
      type: String,
      default: null,
      validator: isInList(['small']),
    },
  },

  computed: {
    computedClasses() {
      return {
        [`vue--all-caps--${this.size}`]: !!this.size,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--all-caps {
  @include font-stack(semibold, sans-serif, normal, true);
  display: block;
  font-size: rem(13px);
  line-height: rem(16px);
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: color(base, dimmed);
  font-feature-settings: 'pnum';

  &--small {
    font-size: rem(12px);
  }
}
</style>
