<template>
  <BaseAnchor
    class="vue--anchor-package-manager"
    v-bind="$attrs"
    :aria-label="packageManager"
    @click="onClick"
    ><component
      :is="icon"
      decorative
      class="vue--anchor-package-manager__icon" /><span
      class="vue--anchor-package-manager__text"
      ><slot /></span
  ></BaseAnchor>
</template>

<script>
import { PackageManagerIconMap } from '~/lib/package-manager-utils';
import { isInList } from '~/lib/prop-validators';

export default {
  name: 'BaseAnchorPackageManager',
  status: 'ready',

  props: {
    /**
     * Package manager name to determine which icon to use.
     */
    packageManager: {
      type: String,
      required: true,
      validator: isInList(Object.keys(PackageManagerIconMap)),
    },
  },

  computed: {
    icon() {
      return PackageManagerIconMap[this.packageManager];
    },
  },
  methods: {
    onClick(e) {
      /**
       * Triggers on click
       * @event click
       */
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--anchor-package-manager {
  display: inline-flex;
  align-items: center;

  &__icon {
    color: color(base, body);
    height: 22px;
    margin-right: space(xxs);
    width: 22px;

    ::v-deep svg {
      height: 22px;
      width: 22px;
    }
  }
}
</style>
