import Vue from 'vue';
import {
  SET_REQUIREMENTS_CHECKS,
  CLEAR_REQUIREMENTS_CHECKS,
  SET_REQUIREMENTS,
  CLEAR_REQUIREMENTS,
  SET_REQUIREMENTS_LOADING,
} from '~/store/mutation-types';
import objectHash from 'object-hash';
import { request } from '~/lib/request';
import config from 'config';

const timeout = 1000 * 60 * 10; // Don't re-fetch identical permissions for 10 mins

function findRequirementCheck(state, context, requirement) {
  const hash = createHash(context, requirement);
  const requirementCheck = state.requirementsChecks[hash];

  if (requirementCheck && !isExpired(requirementCheck.expiry)) {
    return requirementCheck;
  }
}

const isExpired = (expiry) => (expiry ? Date.now() > expiry : false);

export function createHash(context, requirement) {
  return objectHash({ context: simpleContext(context), requirement });
}

function requirementsCheckPending(context, requirement) {
  return {
    context,
    requirement,
    pending: true,
  };
}

function requirementsCheckError(context, requirement) {
  return {
    context,
    requirement,
    missingRequirements: {},
    permitted: false,
    statusCode: 500,
    pending: false,
  };
}

function simpleContext(context) {
  return {
    userId: context.user && context.user.id,
    orgId: context.org && context.org.id,
    groupId: context.group && context.group.id,
  };
}

export default {
  namespaced: true,

  /**
   * example state:
   * state: {
   *   requirementsChecks: {
   *     135a908495e0ae8cb12166631217de6c43a4012f: {
   *       context: { user: {...}, org: {...}, group: {...} },
   *       requirement: { entitlements: ['api'], features: [], permissions: [] },
   *       missingRequirements: { entitlements: ['api'], features: [], permissions: [] },
   *       permitted: false,
   *       statusCode: 403,
   *       pending: false,
   *     }
   *   }
   * }
   */
  state: {
    requirementsChecks: {},
    requirements: {
      isLoading: true,
    },
  },

  getters: {
    requirementsPending:
      (state) =>
      ({ context, requirements }) => {
        return Object.values(requirements).some((requirement) => {
          const requirementsCheck = findRequirementCheck(
            state,
            context,
            requirement,
          );
          return requirementsCheck ? requirementsCheck.pending : true;
        });
      },
    hasRequirement:
      (state) =>
      ({ context, requirement }) => {
        const requirementsCheck = findRequirementCheck(
          state,
          context,
          requirement,
        );
        return requirementsCheck && !requirementsCheck.pending
          ? requirementsCheck.permitted
          : false;
      },
    getRequirementsCheck:
      (state) =>
      ({ context, requirement }) => {
        const requirementsCheck = findRequirementCheck(
          state,
          context,
          requirement,
        );
        return requirementsCheck;
      },
    getAllRequirementsChecks:
      (state) =>
      ({ context, requirements }) => {
        const results = {};
        Object.keys(requirements).forEach((key) => {
          results[key] = findRequirementCheck(
            state,
            context,
            requirements[key],
          );
        });
        return results;
      },
    getRequirements: (state) => state.requirements,
  },

  actions: {
    fetchRequirements: async (
      { state, commit, getters },
      { context, requirements, forceFetch = false },
    ) => {
      if (!requirements) {
        commit(SET_REQUIREMENTS_LOADING, false);
        return;
      }

      if (typeof requirements !== 'object') {
        throw new Error('requirements must be an object');
      }

      if (forceFetch) {
        commit(CLEAR_REQUIREMENTS_CHECKS);
      }

      const requirementsToFetch = Object.values(requirements).filter(
        (requirement) => {
          const requirementsCheck = findRequirementCheck(
            state,
            context,
            requirement,
          );

          if (requirementsCheck) {
            return false;
          }

          // For reactivity to work, the requirement must be set as "pending" first
          commit(
            SET_REQUIREMENTS_CHECKS,
            requirementsCheckPending(context, requirement),
          );

          return true;
        },
      );

      if (requirementsToFetch.length === 0) {
        return;
      }

      if (!forceFetch) {
        commit(SET_REQUIREMENTS_LOADING, true);
      }

      try {
        const url = `${config.baseUrl || ''}/requirements`;
        const response = await request(url, {
          method: 'POST',
          body: {
            context: simpleContext(context),
            requirements: requirementsToFetch,
          },
        });

        const results = await response.json();

        // Update the "pending" requirement to be completed with result
        results.forEach((result) =>
          commit(SET_REQUIREMENTS_CHECKS, {
            context,
            ...result,
            pending: false,
            expiry: Date.now() + timeout,
          }),
        );

        commit(
          SET_REQUIREMENTS,
          getters.getAllRequirementsChecks({ context, requirements }),
        );
        commit(SET_REQUIREMENTS_LOADING, false);
      } catch (e) {
        if (window.Sentry) {
          window.Sentry.captureException(e, {
            contexts: {
              user: { id: context.user?.id, created: context.user?.created },
              org: context.org,
              group: context.group,
            },
          });
        }

        // Update the "pending" requirement to be completed with error
        requirementsToFetch.forEach((requirement) =>
          commit(
            SET_REQUIREMENTS_CHECKS,
            requirementsCheckError(context, requirement),
          ),
        );
        commit(
          SET_REQUIREMENTS,
          getters.getAllRequirementsChecks({ context, requirements }),
        );
        commit(SET_REQUIREMENTS_LOADING, false);
      }
    },

    clearRequirementChecks: ({ commit }) => {
      commit(CLEAR_REQUIREMENTS);
      commit(SET_REQUIREMENTS_LOADING, true);
    },
  },

  mutations: {
    [SET_REQUIREMENTS](state, allRequirementsChecks) {
      Object.keys(allRequirementsChecks).forEach((check) => {
        Vue.set(state.requirements, check, allRequirementsChecks[check]);
      });
    },
    [SET_REQUIREMENTS_LOADING](state, isLoading) {
      state.requirements.isLoading = isLoading;
    },
    [CLEAR_REQUIREMENTS](state) {
      Object.keys(state.requirements).forEach((check) => {
        delete state.requirements[check];
      });
    },
    [CLEAR_REQUIREMENTS_CHECKS](state) {
      Object.keys(state.requirementsChecks).forEach((check) => {
        delete state.requirementsChecks[check];
      });
    },
    [SET_REQUIREMENTS_CHECKS](state, requirementsCheck) {
      const hash = createHash(
        requirementsCheck.context,
        requirementsCheck.requirement,
      );

      const existing = state.requirementsChecks[hash];

      if (existing) {
        // Overwrite the values within the requirementsCheck
        // Note: do not replace the requirementsCheck or you
        // break reactivity!
        Object.keys(requirementsCheck).forEach(
          (key) => (existing[key] = requirementsCheck[key]),
        );
      } else {
        // Set the requirementsCheck using Vue.set to enable
        // reactivity.
        Vue.set(state.requirementsChecks, hash, requirementsCheck);
      }
    },
  },
};
