import {
  SET_RUNTIME_DATA,
  SET_RUNTIME_DATA_LOADING,
  SET_RUNTIME_DATA_LOADING_ERROR,
} from '../mutation-types';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  state: {
    runtimeData: null,
    runtimeDataLoading: false,
    runtimeDataLoadingError: null,
  },

  getters: {
    runtimeData: (state) => state.runtimeData,
    runtimeDataLoading: (state) => state.runtimeDataLoading,
    runtimeDataLoadingError: (state) => state.runtimeDataLoadingError,
  },

  actions: {
    async getRuntimeData(
      { rootState, commit, state },
      { orgName, projectPublicId, snapshotPublicId },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.runtimeDataLoading && !state.runtimeData) {
        commit(SET_RUNTIME_DATA_LOADING, true);

        try {
          const path = `${rootState.baseUrl}/org/${orgName}/project/${projectPublicId}/runtime/${snapshotPublicId}`;
          const res = await request(path);

          const { runtimeData } = await res.json();
          commit(SET_RUNTIME_DATA, runtimeData);
        } catch (err) {
          commit(SET_RUNTIME_DATA_LOADING_ERROR, true);
        }
        commit(SET_RUNTIME_DATA_LOADING, false);
      }
    },
  },

  mutations: {
    [SET_RUNTIME_DATA](state, runtimeData) {
      state.runtimeData = runtimeData;
    },
    [SET_RUNTIME_DATA_LOADING](state, runtimeDataLoading) {
      state.runtimeDataLoading = runtimeDataLoading;
    },
    [SET_RUNTIME_DATA_LOADING_ERROR](state, runtimeDataLoadingError) {
      state.runtimeDataLoadingError = runtimeDataLoadingError;
    },
  },
};
