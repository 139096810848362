import { render, staticRenderFns } from "./BaseAvatarUsername.vue?vue&type=template&id=fc46e5fe&scoped=true&"
import script from "./BaseAvatarUsername.vue?vue&type=script&lang=js&"
export * from "./BaseAvatarUsername.vue?vue&type=script&lang=js&"
import style0 from "./BaseAvatarUsername.vue?vue&type=style&index=0&id=fc46e5fe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc46e5fe",
  null
  
)

export default component.exports