/* global analytics, Sentry */
import errorHandler from '../error-handler';
import Cookies from 'js-cookie';
import config from 'config';

import SegmentPlugin from '@itly/plugin-segment';
import itly, { Validation } from './itly';
import ItlyErrorPlugin from './itly-error-plugin';
import { maskUrl } from 'shared/analytics';

export {
  analyticsPage,
  analyticsTrack,
  speedCurve,
  SPEED_CURVE_MARKS,
  clearAnalyticsBlockingTasks,
  delayAnalyticsUntilDone,
  allAnalyticsBlockingTasksDone,
  itly,
};

const SPEED_CURVE_MARKS = {
  REPORTS: {
    CHARTS_EXPOSURE_WINDOW_LOADED: 'charts_exposure_window_loaded',
    CHARTS_ISSUES_OVER_TIME_LOADED: 'charts_issues_over_time_loaded',
    VULN_ISSUES_BY_SEVERITY_METRICS_LOADED:
      'vuln_issues_by_severity_metrics_loaded',
    LICENSES_ISSUES_BY_SEVERITY_METRICS_LOADED:
      'licenses_issues_by_severity_metrics_loaded',
    REPORTS_ACTIVITY_METRICS_LOADED: 'reports_activity_metrics_loaded',
  },
  POLICIES: {
    POLICIES_LICENSE_LOADED: 'policies_license_loaded',
    POLICIES_SECURITY_LOADED: 'policies_security_loaded',
    POLICY_ORGS_LOADED: 'policy_orgs_loaded',
  },
};

export function itlyInit() {
  const segment =
    typeof window !== 'undefined' &&
    new SegmentPlugin(window?.$embedded?.analyticsSecret);

  itly.load({
    disabled:
      typeof window == 'undefined' || !window?.$embedded?.analyticsSecret,
    environment:
      config.environment === 'production' ? 'production' : 'development',
    plugins: [segment, new ItlyErrorPlugin()],
    validation:
      config.environment === 'production'
        ? Validation.SkipOnInvalid
        : Validation.ErrorOnInvalid,
  });

  const user = typeof window !== 'undefined' && window.$embedded?.user;

  if (user) {
    itly.identify(
      user.id,
      {
        name: user.name,
        email: user.email,
        username: user.username,
        createdAt: Math.floor(Date.parse(user.created) / 1000),
        isSnyk: user.email.toLowerCase().endsWith('@snyk.io'),
      },
      {
        segment: {
          options: {
            Intercom: { user_hash: window.$embedded.intercomUserHash },
          },
        },
      },
    );
  }
}

itlyInit();

function getCampaign(queryParams) {
  const campaign = {};
  if (queryParams.utm_campaign) {
    campaign.name = queryParams.utm_campaign;
  }
  if (queryParams.utm_medium) {
    campaign.medium = queryParams.utm_medium;
  }
  if (queryParams.utm_source) {
    campaign.source = queryParams.utm_source;
  }
  return campaign;
}

async function analyticsPage(pageName) {
  const qs = await import(/* webpackChunkName: "qs" */ 'qs');
  if (window.analytics) {
    analytics.ready(() => {
      const url = maskUrl(window?.location?.href);
      const path = maskUrl(window?.location?.pathname);
      const referrer = maskUrl(document?.referrer);

      const anonymousId = analytics.user
        ? analytics.user().anonymousId()
        : null;
      const parsedSearch = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const campaign = getCampaign(parsedSearch);
      const context = {
        googleAnalyticsClientId: (Cookies.get('_ga') || '').replace(
          'GA1.2.',
          '',
        ),
        anonymousId,
        campaign,
      };

      if (pageName) {
        analytics.page(pageName, { url, path, referrer, context });
      } else {
        analytics.page({ url, path, referrer, context });
      }
    });
  } else {
    analyticsError('Analytics page track failed');
  }
}

function analyticsTrack(eventName, props) {
  if (window.analytics) {
    analytics.track(eventName, props);
  } else {
    analyticsError('Analytics tracking event failed');
  }
}

function analyticsError(errorMessage, eventName, props) {
  if (window.Sentry) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setExtra('eventName', eventName);
      scope.setExtra('props', props);
      Sentry.captureMessage(errorMessage);
    });
  }
}

const speedCurve = {
  init: (routeName) => {
    if (window.LUX) {
      try {
        window.LUX.label = routeName;
        window.LUX.init();
      } catch (error) {
        errorHandler(error);
      }
    }
  },
  mark: (markName) => {
    if (window.LUX) {
      try {
        window.LUX.mark(markName);
      } catch (error) {
        errorHandler(error);
      }
    }
  },
  send: (routeName) => {
    if (window.LUX) {
      try {
        if (window.LUX.label === routeName) {
          window.LUX.send();
        }
      } catch (error) {
        errorHandler(error);
      }
    }
  },
  addData: (key, value) => {
    if (window.LUX) {
      try {
        window.LUX.addData(key, value);
      } catch (error) {
        errorHandler(error);
      }
    }
  },
};

let analyticsBlockingTasks = [];

/**
 * Create a new task, that will prevent `allAnalyticsBlockingTasksDone` from
 * resolving, until it is completed.
 *
 * @returns {function} Resolver function to mark the blocking task as complete
 */
function delayAnalyticsUntilDone() {
  let promiseResolve;

  const pendingTaskPromise = new Promise((resolve) => {
    promiseResolve = resolve;
  });

  analyticsBlockingTasks.push(pendingTaskPromise);
  return promiseResolve;
}

/**
 * Await all analytics blocking tasks having completed
 */
async function allAnalyticsBlockingTasksDone() {
  await Promise.all(analyticsBlockingTasks.map((p) => p.catch(() => null)));
}

/**
 * Clear any as yet unresolved analytics blocking tasks.
 * This can be used when any 'forgotten' tasks can be safely discarded
 * For example on a page change, where previous blocks are no longer relevant.
 */
function clearAnalyticsBlockingTasks() {
  analyticsBlockingTasks = [];
}
