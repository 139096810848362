<template>
  <LayoutFormElement class="vue--multiple-value-input" :class="computedClasses">
    <template v-if="label" #label>
      <label
        class="vue--multiple-value-input__label"
        :for="$attrs.id"
        v-text="label"
      />
    </template>
    <div
      class="vue--multiple-value-input__container"
      :style="computedContainerStyles"
    >
      <div ref="body" class="vue--multiple-value-input__body">
        <div
          v-on-clickaway="showLess"
          class="vue--multiple-value-input__wrapper"
          data-snyk-test="BaseMultipleValueInput: open"
          :style="computedWrapperStyles"
          @click="showMore"
        >
          <div
            ref="container"
            class="vue--multiple-value-input__chip-container"
          >
            <div
              v-for="(value, index) in getVisibleValues"
              :key="`value-${index}`"
              class="vue--multiple-value-input__chip"
              :class="{
                'shake-vertical-subtle': isEqual(animateDuplicate, value),
              }"
              @animationend="animateDuplicate = false"
            >
              <!-- @slot Override the default `BaseChip` chip. -->
              <slot
                name="chip"
                :value="value"
                :is-removable="isRemovable"
                :removeValue="removeValue"
              >
                <BaseChip
                  :value="value"
                  :text="displayKey ? value[displayKey] : value"
                  :color="color"
                  :removable="isRemovable"
                  @click="removeValue(value)"
                  @keydown.enter.prevent="removeValue(value)"
                />
              </slot>
            </div>

            <span
              v-if="hasPreInput"
              class="vue--multiple-value-input__pre-input"
            >
              <!-- @slot Add content before the input field. Useful for building up a representation of a final values e.g. `color:` to represent the key of a key/value pairs. -->
              <slot name="preInput" />
            </span>

            <input
              v-if="showInput"
              ref="input"
              v-model="input"
              class="vue--multiple-value-input__field"
              data-snyk-test="BaseMultipleValueInput: input field"
              type="text"
              :placeholder="inputPlaceholder"
              :maxlength="characterLimit"
              v-on="inputListeners"
            />

            <BaseButton
              v-if="showHiddenCounter"
              ref="showMore"
              variant="link"
              class="vue--multiple-value-input__show-more"
              data-snyk-test="BaseMultipleValueInput: show more"
              tabindex="0"
              size="small"
              :disabled="disabled"
              type="button"
            >
              {{ showMoreText }}
            </BaseButton>

            <BaseButton
              v-else-if="showAddValue"
              variant="link"
              class="vue--multiple-value-input__add"
              data-snyk-test="BaseMultipleValueInput: add value"
              tabindex="0"
              size="small"
              :icon="showAddValue"
              :disabled="disabled"
              type="button"
            >
              <PlusCircleOutlineIcon />
              <span v-if="hasNoValues" v-text="placeholder" />
            </BaseButton>

            <span
              v-else-if="readOnly && hasNoValues"
              class="vue--multiple-value-input__read-only"
              v-text="readOnlyPlaceholder"
            />

            <span
              v-if="showLimit"
              class="vue--multiple-value-input__limit"
              :class="{ 'shake-vertical-subtle': animateLimit }"
              @animationend="animateLimit = false"
              v-text="limitCounter"
            />
          </div>

          <ul
            v-if="showAutoComplete"
            class="vue--multiple-value-input__auto-complete"
            data-snyk-test="BaseMultipleValueInput: auto-complete"
          >
            <li
              v-if="hasPreItems"
              class="
                vue--multiple-value-input__item
                vue--multiple-value-input__item--pre-item
              "
            >
              <!-- @slot Add content before the items listing. -->
              <slot name="preItems" />
            </li>
            <li
              v-if="isLoading"
              class="
                vue--multiple-value-input__item
                vue--multiple-value-input__item--loading
              "
            >
              <BaseLoadingSpinner size="small" :text="loadingText" inline />
            </li>
            <template v-else-if="!readOnly">
              <li
                v-for="(item, index) in getFilteredData"
                :key="`item-${index}`"
                class="vue--multiple-value-input__item"
                data-snyk-test="BaseMultipleValueInput: item"
                :class="isFocusedItem(index) && 'focus'"
                @click="addValue(item)"
              >
                <!-- @slot Override the default items listing item. -->
                <slot name="item" :item="item">
                  <span
                    class="vue--multiple-value-input__default-item"
                    v-text="displayKey ? item[displayKey] : item"
                  />
                </slot>
              </li>
              <li
                v-if="showNewTagItem"
                class="
                  vue--multiple-value-input__item
                  vue--multiple-value-input__item--new
                "
                :class="isFocusedItem(-2) && 'focus'"
                data-snyk-test="BaseMultipleValueInput: new item"
                @click="addNewValue"
                v-text="newLabelText"
              />
            </template>
            <li
              v-if="hasNoResults"
              class="
                vue--multiple-value-input__item
                vue--multiple-value-input__item--no-results
              "
            >
              <BaseNoResults preset-graphic="search" size="small" inline />
            </li>
            <li
              v-if="hasPostItems"
              class="
                vue--multiple-value-input__item
                vue--multiple-value-input__item--post-item
              "
            >
              <!-- @slot Add content after the items listing. -->
              <slot name="postItems" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </LayoutFormElement>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway';
import has from 'lodash/has';
import isEqual from 'lodash/isEqual';
import { queryMatch } from '~/lib/search';
import { isInList } from '~/lib/prop-validators';
import { createDebounce } from '~/lib/debounce';
import { detectQuadrant } from '~/lib/detect-quadrant';
import { colors } from '~/components/BaseChip/BaseChip';
import LayoutFormElement from '~/components/LayoutFormElement/LayoutFormElement';
import BaseChip from '~/components/BaseChip/BaseChip';

export default {
  name: 'BaseMultipleValueInput',

  components: {
    BaseChip,
    LayoutFormElement,
    PlusCircleOutlineIcon: () => import('icons/PlusCircleOutline'),
  },

  directives: {
    onClickaway,
  },

  model: {
    prop: 'values',
    event: 'update',
  },

  props: {
    /**
     * The label of the form input field.
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Values for components (mapped for v-model).
     */
    values: {
      type: Array,
      default: () => [],
    },
    /**
     * Type ahead data.
     */
    data: {
      type: Array,
      default: () => [],
    },
    /**
     * Override add new placeholder text.
     */
    placeholder: {
      type: String,
      default: 'Add a value',
    },
    /**
     * Show loading state.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * Override loading text.
     */
    loadingText: {
      type: String,
      default: 'Fetching data…',
    },
    /**
     * Optional input field placeholder.
     */
    inputPlaceholder: {
      type: String,
      default: null,
    },
    /**
     * Override new value label e.g. Create new CONTEXT "foo"
     */
    newLabelContext: {
      type: String,
      default: 'value',
    },
    /**
     * Limit number of values.
     */
    limit: {
      type: Number,
      default: 0,
    },
    /**
     * Restrict character enter to regex e.g. [A-Za-z0-9\\.\\-\\,\\_]
     */
    restrictCharacters: {
      type: String,
      default: null,
    },
    /**
     * Character limit for new values.
     */
    characterLimit: {
      type: Number,
      default: 50,
    },
    /**
     * Style variant for component: form, minimal
     */
    variant: {
      type: String,
      default: 'form',
      validator: isInList(['form', 'minimal']),
    },
    /**
     * Color of chip.
     */
    color: {
      type: String,
      default: 'default',
      validator: isInList(colors),
    },
    /**
     * Only allow selection of supplied data. No new creation.
     */
    selectionOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Single selection mode.
     */
    single: {
      type: Boolean,
      default: false,
    },
    /**
     * Readonly mode. No selection or new creation.
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Optional read only placeholder.
     */
    readOnlyPlaceholder: {
      type: String,
      default: null,
    },
    /**
     * When values is an array of objects, specify the key to display.
     */
    displayKey: {
      type: String,
      default: null,
    },
    /**
     * WHen data is an array of objects, specify they key to use as the values value.
     */
    valueKey: {
      type: String,
      default: null,
    },
    /**
     * When values is an array of object, specify an array of keys to search match against.
     */
    searchMatch: {
      type: Array,
      default: null,
    },
    /**
     * Force open state.
     */
    open: {
      type: Boolean,
      default: false,
    },
    /**
     * When changed the duplicate animation is triggered.
     */
    duplicateValue: {
      type: [Object, String],
      default: null,
    },
    /**
     * Disabled state.
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /*
     * Add additional entry key codes to trigger value addition. e.g. Comma, Space
     */
    additionalEntryKeys: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      setDebounce: createDebounce(),
      input: '',
      hiddenValues: 0,
      showAll: false,
      calculating: true,
      focussedItemIndex: -1,
      animateDuplicate: false,
      animateLimit: false,
      isLoading: this.loading,
      isEqual,
      wrapperOverflowHeight: 0,
      bodyHeight: 0,
      location: null,
    };
  },

  computed: {
    computedClasses() {
      return {
        [`vue--multiple-value-input--${this.variant}`]: !!this.variant,
        'vue--multiple-value-input--open': this.isOpen,
        'vue--multiple-value-input--calculating': this.calculating,
        'vue--multiple-value-input--editable': this.isRemovable,
        'vue--multiple-value-input--read-only': this.readOnly,
        'vue--multiple-value-input--with-auto-complete':
          !!this.showAutoComplete,
        'vue--multiple-value-input--has-values': !!this.values.length,
        'vue--multiple-value-input--has-hidden-values': !!this.hiddenValues,
        'vue--multiple-value-input--single': this.single,
        'vue--multiple-value-input--disable-opened-state':
          this.hasDisableOpenedState,
        'vue--multiple-value-input--overflows':
          this.location && this.location.overflowsContainer,
        'vue--multiple-value-input--disabled': this.disabled,
      };
    },
    computedContainerStyles() {
      return {
        height: `${this.bodyHeight}px`,
      };
    },
    computedWrapperStyles() {
      return !this.isOpen && this.wrapperOverflowHeight
        ? {
            maxHeight: `${this.wrapperOverflowHeight}px`,
          }
        : null;
    },
    isOpen() {
      return this.showAll;
    },
    isRemovable() {
      return this.isOpen && !this.readOnly && !this.calculating;
    },
    getVisibleValues() {
      return this.isOpen
        ? this.displayedValues
        : this.displayedValues.slice(
            0,
            this.displayedValues.length - this.hiddenValues,
          );
    },
    displayedValues() {
      return this.valueKey
        ? this.data.filter((item) => this.values.includes(item[this.valueKey]))
        : this.values;
    },
    getFilteredData() {
      return this.data
        .filter((item) => {
          if (this.displayedValues.includes(item)) return;

          if (this.newValue) {
            // Filter data against multiple search matches.
            if (this.searchMatch) {
              let match = false;
              this.searchMatch.forEach((key) => {
                if (item[key] && queryMatch(item[key], this.newValue))
                  match = true;
              });
              return match;

              // Filter data single search matches.
            } else {
              const query = this.displayKey ? item[this.displayKey] : item;
              return queryMatch(query, this.newValue);
            }
          }

          return item;
        })
        .slice(0, 6);
    },
    showMoreText() {
      return this.hiddenValues ? `+${this.hiddenValues}` : null;
    },
    showHiddenCounter() {
      return this.hiddenValues && !this.isOpen;
    },
    showAddValue() {
      if (this.hasReachedSingleValueLimit || this.isOpen || this.readOnly)
        return;

      return this.hiddenValues === 0 || this.hasNoValues;
    },
    showInput() {
      return this.isOpen && !this.readOnly && !this.calculating;
    },
    showAutoComplete() {
      if (this.readOnly && !this.hasPostItems) return;

      return (
        this.isOpen &&
        (this.hasSelectableData ||
          (this.displayKey && this.newValue) ||
          this.hasPostItems ||
          this.hasPreItems ||
          this.isLoading)
      );
    },
    showNewTagItem() {
      return (
        (this.newValue.length &&
          !this.displayKey &&
          !this.getFilteredData.includes(this.newValue) &&
          !this.values.includes(this.newValue) &&
          !this.selectionOnly) ||
        (this.newValue.length &&
          this.displayKey &&
          !this.getFilteredData.filter((item) => {
            return item[this.displayKey] === this.newValue;
          }).length &&
          !this.selectionOnly)
      );
    },
    showLimit() {
      if (this.single || !this.isOpen || this.calculating || this.readOnly)
        return;

      return this.limit > 1 || this.hasReachedSingleValueLimit;
    },
    hasSelectableData() {
      return (
        this.data.length &&
        ((this.data.filter((item) => !this.displayedValues.includes(item)) &&
          !this.displayedValues.includes(this.newValue)) ||
          !this.getFilteredData.includes(this.newValue))
      );
    },
    hasNoValues() {
      return this.values.length === 0;
    },
    hasNoResults() {
      if (this.isLoading) return;

      return (
        this.data.length > 0 &&
        this.getFilteredData.length === 0 &&
        this.selectionOnly
      );
    },
    hasPreInput() {
      return !!this.$slots.preInput;
    },
    hasPreItems() {
      return !!this.$slots.preItems && this.getFilteredData.length;
    },
    hasPostItems() {
      return !!(
        this.$slots.postItems &&
        this.$slots.postItems.filter((node) => node.tag).length
      );
    },
    hasReachedSingleValueLimit() {
      return this.single && this.values.length === 1;
    },
    hasDisableOpenedState() {
      return this.hiddenValues === 0 && this.readOnly && !this.hasPostItems;
    },
    inputListeners() {
      return {
        paste: (e) => {
          // If restricted characters defer paste handling to parent.
          if (this.$listeners.paste) {
            this.$emit('paste', e.clipboardData.getData('text'));
            setTimeout(() => {
              this.clearInput();
              this.focusInput();
            });
          }
        },
        input: (e) => {
          this.setQuery(e.target.value);
        },
        keydown: (e) => {
          if (e.code === 'Backspace') this.handleBackspace();
          if (
            e.code === 'Enter' ||
            e.code === 'NumpadEnter' ||
            this.additionalEntryKeys.includes(e.code)
          ) {
            const value = this.getNewValue();

            if (!value) return;

            e.preventDefault();
            this.addValue(value);
          }
          if (e.code === 'Tab' || e.code === 'Escape') this.showLess(e);
          return this.restrictInput(e);
        },
        keyup: (e) => {
          if (e.code === 'ArrowUp') this.moveFocusUpOne();
          if (e.code === 'ArrowDown') this.moveFocusDownOne();
          if (e.code === 'Home') this.moveFocusToTop();
          if (e.code === 'End') this.moveFocusToBottom();
        },
      };
    },
    newValue() {
      return this.input.trim();
    },
    newLabelText() {
      return `Create new ${this.newLabelContext} "${this.newValue}"`;
    },
    limitCounter() {
      return `${this.values.length}/${this.limit}`;
    },
  },

  watch: {
    loading(value) {
      this.isLoading = value;
    },
    open() {
      this.open ? this.showMore() : this.showLess();
    },
    values() {
      if (!this.values.length) {
        this.hiddenValues = 0;
      }
    },
    duplicateValue(value) {
      this.triggerAnimateDuplicate(value);
    },
    getFilteredData(value) {
      const hasValues = value.length >= 1;
      const isSelectionOnly = this.selectionOnly;
      const isNotNewValue = !this.showNewTagItem;

      if ((hasValues && isSelectionOnly) || isNotNewValue) {
        this.focussedItemIndex = 0;
      } else {
        this.focussedItemIndex = -1;
      }
    },
    input(value) {
      if (!value.length) {
        this.focussedItemIndex = -1;
      }
    },
  },

  mounted() {
    this.countHiddenValues();
    this.calculateOverflowHeight();
    this.resizeHandler();
    this.setBodyHeight();
    setTimeout(() => {
      if (this.open) {
        this.showMore();
      }
    });
  },

  methods: {
    addValue(value) {
      let v = value;
      if (typeof value === 'string' && this.valueKey) {
        v = this.data.find((item) => item[this.displayKey] === value)[
          this.valueKey
        ];
      } else if (this.valueKey) {
        v = value[this.valueKey];
      }

      let updateValues = [...this.values, v];
      if (this.hasReachedSingleValueLimit) {
        updateValues = [v];
      }

      if (this.limit > 0 && this.values.length === this.limit) {
        this.triggerAnimateLimit();
        return;
      }

      if (this.valueIsDuplicate(value)) {
        this.triggerAnimateDuplicate(value);
        return;
      }

      if (this.$listeners.create) {
        return this.deferCreation(value);
      }

      let latestInput =
        updateValues.length > 0 && updateValues[updateValues.length - 1];

      if (typeof latestInput === 'string' && latestInput.includes(',')) {
        let last = updateValues.slice(-1).pop();
        const values = last
          .trim()
          .split(',')
          .filter((v) => v);

        updateValues = updateValues.slice(0, updateValues.length - 1);
        values.forEach((value) => {
          updateValues = updateValues.concat(value);
        });
      }

      /**
       * Triggers on value update.
       * @event update
       */
      this.$emit('update', updateValues);
      this.clearInput();
      this.clearFocusedItem();

      setTimeout(() => {
        if (this.single) this.showLess();
      });
    },
    getNewValue() {
      const value =
        ![-1, -2].includes(this.focussedItemIndex) &&
        this.getFilteredData.length
          ? this.getFilteredData[this.focussedItemIndex]
          : this.newValue;

      if (
        this.selectionOnly &&
        !this.data.filter((item) => {
          if (this.displayKey) {
            return typeof value !== 'string'
              ? item[this.displayKey] === value[this.displayKey]
              : item[this.displayKey] === value;
          }
          return item === value;
        }).length
      ) {
        return;
      }

      return value;
    },
    addNewValue() {
      const value = this.getNewValue();

      if (!value) return;
      this.addValue(value);
    },
    deferCreation(value) {
      const createValue = has(value, this.displayKey)
        ? value[this.displayKey]
        : value;

      const payload = this.displayKey
        ? { [this.displayKey]: createValue }
        : createValue;

      /**
       * Triggers on creation deferral to parent component.
       * @event create
       */
      this.$emit('create', payload);
      this.clearInput();
      return;
    },
    valueIsDuplicate(value) {
      return this.displayedValues.filter((existingValue) =>
        isEqual(existingValue, value),
      ).length;
    },
    clearInput() {
      this.input = '';
      this.clearFocusedItem();
      this.calculateOverflowHeight();
    },
    async focusInput() {
      await this.$nextTick();
      if (this.isRemovable && this.$refs.input) {
        this.$refs.input.focus();
      }
    },
    removeValue(value) {
      const v = this.valueKey ? value[this.valueKey] : value;
      const updateValues = this.values.filter((item) => item !== v);

      this.$emit('update', updateValues);

      if (updateValues.length === 0) this.focusInput();
    },
    handleBackspace() {
      if (this.newValue.length === 0) {
        this.$emit('update', this.values.slice(0, -1));
      }
    },
    moveFocusUpOne() {
      if (this.focussedItemIndex === 0) {
        this.moveFocusToBottom();
      } else if (this.focussedItemIndex === -2) {
        this.focussedItemIndex = this.getFilteredData.length - 1;
      } else {
        this.focussedItemIndex--;
      }
    },
    moveFocusDownOne() {
      if (
        (this.focussedItemIndex === this.getFilteredData.length - 1 &&
          !this.showNewTagItem) ||
        this.focussedItemIndex === -2
      ) {
        this.moveFocusToTop();
      } else if (
        this.focussedItemIndex === this.getFilteredData.length - 1 &&
        this.showNewTagItem
      ) {
        this.moveFocusToNewTag();
      } else {
        this.focussedItemIndex++;
      }
    },
    moveFocusToTop() {
      this.focussedItemIndex = 0;
    },
    moveFocusToBottom() {
      this.showNewTagItem
        ? (this.focussedItemIndex = -2)
        : (this.focussedItemIndex = this.getFilteredData.length - 1);
    },
    moveFocusToNewTag() {
      this.focussedItemIndex = -2;
    },
    restrictInput(e) {
      const regex = this.restrictCharacters
        ? new RegExp(this.restrictCharacters, 'g')
        : null;

      // If user is pasting allow this through.
      if (regex && ['MetaLeft', 'KeyV', 'ControlLeft'].includes(e.code))
        return true;

      if (regex && !e.key.match(regex) && e.code !== 'Backspace') {
        e.preventDefault();
      }
    },
    isFocusedItem(index) {
      return this.focussedItemIndex === index;
    },
    clearFocusedItem() {
      this.focussedItemIndex = -1;
    },
    countHiddenValues() {
      /**
       * If single, cancel value counting.
       */
      if (this.single) {
        this.calculating = false;
        return;
      }

      this.hiddenValues = 0;
      this.calculating = true;

      /**
       * Close the element to get the container width.
       */
      this.showAll = false;
      this.$nextTick(() => {
        const containerWidth = this.$refs['container']?.clientWidth || 0;

        /**
         * Then open the element to count the values.
         */
        this.showAll = true;
        this.$nextTick(() => {
          const valueWidths = this.getValueWidths();

          const showMoreWidth = 24;
          const spacing = 8;
          let availableSpace = containerWidth - showMoreWidth;
          let shownValues = 0;

          let stop = false;
          valueWidths.forEach((width) => {
            if (!stop) {
              if (width + spacing < availableSpace) {
                shownValues++;
                availableSpace = availableSpace - width - spacing;
              } else {
                stop = true;
              }
            }
          });

          this.hiddenValues = valueWidths.length - shownValues;
        });

        /**
         * Close the element again.
         */
        this.showAll = false;
        this.calculating = false;
      });
    },
    getValueReferences() {
      return [
        ...this.$el.querySelector('.vue--multiple-value-input__chip-container')
          .children,
      ].filter((child) => {
        return !['BUTTON', 'INPUT', 'SPAN'].includes(child.tagName);
      });
    },
    getValueWidths() {
      const valueReferences = this.getValueReferences();

      const valueWidths = [];
      valueReferences.forEach((valueElement) => {
        valueWidths.push(valueElement.offsetWidth);
      });

      return valueWidths;
    },
    calculateOverflowHeight() {
      const valueReferences = this.getValueReferences();
      this.wrapperOverflowHeight = 0;

      if (valueReferences.length) {
        const tallestValue = valueReferences.reduce((tallest, value) =>
          value.offsetHeight > tallest.offsetHeight ? value : tallest,
        );

        this.wrapperOverflowHeight = tallestValue.offsetHeight;
      }
    },
    resizeHandler() {
      window.addEventListener('resize', () => {
        this.setDebounce(() => {
          this.countHiddenValues();
        });
      });
    },
    setBodyHeight() {
      this.bodyHeight = this.$refs['body']?.offsetHeight;
    },
    async showMore() {
      if (this.isOpen || this.disabled) return;

      this.showAll = true;

      await this.$nextTick();

      if (!this.readOnly) this.focusInput();

      this.setLocation();

      if (this.$listeners.opened) {
        /**
         * Triggers on component opening.
         * @event opened
         */
        this.$emit('opened');
      }
    },
    showLess(e) {
      // If user is clicking out of the component with a new value being
      // set, create it.
      if (this.newValue && e && e.code !== 'Escape') this.addNewValue();

      if (this.isOpen) {
        // If shift key is held i.e. shift-tab
        // don't collapse the element.
        if (e && e.shiftKey) return;

        if (this.showAll) this.countHiddenValues();
        this.showAll = false;
        this.clearInput();
        this.clearFocusedItem();

        this.$nextTick(() => {
          this.setBodyHeight();
        });

        if (this.$listeners.closed) {
          /**
           * Triggers on component closing.
           * @event opened
           */
          this.$emit('closed');
        }
      }
    },
    triggerAnimateLimit() {
      this.animateLimit = true;
    },
    triggerAnimateDuplicate(value) {
      this.animateDuplicate = value;
    },
    setQuery(value) {
      if (this.$listeners.asyncQuery) {
        this.isLoading = true;
        this.setDebounce(() => {
          /**
           * Triggers on debounced value input entry and sets component to loading prior to emission.
           * @event asyncQuery
           */
          this.$emit('asyncQuery', value);
        });
      }
      if (this.$listeners.query) {
        /**
         * Triggers on value input entry.
         * @event opened
         */
        this.$emit('query', value);
      }
    },
    setLocation() {
      this.$nextTick(() => {
        const elBounding = this.$el.getBoundingClientRect();
        this.location = detectQuadrant(
          elBounding.x,
          elBounding.y,
          this.$refs['body'].clientWidth,
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'utils';

.vue--multiple-value-input {
  $self: &;

  position: relative;
  width: 100%;

  &__body {
    position: absolute;
    width: 100%;
  }

  &__wrapper {
    cursor: pointer;
    max-height: rem(28px); // Default, gets overridden based on chip.
    overflow: hidden;
    padding-bottom: 0;
    padding-top: 0;
    position: relative;
  }

  &__chip-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -#{space(xs)};
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  &__label {
    font-size: rem(15px);
  }
  &__pre-input {
    display: inline-block;
    margin-bottom: space(xs);
  }
  &__field {
    -webkit-appearance: none;
    background-color: color(input, bg);
    border: none;
    box-shadow: none;
    flex: 1;
    font-size: rem(13px);
    line-height: rem(28px);
    margin-bottom: space(xs);
    min-width: 26%;
    position: relative;

    &:focus {
      box-shadow: none;
    }
  }
  &__auto-complete {
    border-top: 1px solid color(neutral, 90);
    display: block;
    list-style: none;
    margin-top: space(s);
    padding: space(s) 0 0;
    width: 100%;
  }
  &__item {
    border-radius: global(border-radius, micro);
    cursor: pointer;
    display: block;
    margin: 0;
    padding: space(xxs) space(xs);
    width: 100%;

    &:last-of-type:not(&#{$self}--no-results) {
      margin-bottom: 0;
    }

    &:hover,
    &:focus,
    &.focus {
      background-color: color(neutral, 96);
    }

    &--no-results {
      margin-bottom: space(xs);
    }

    &--pre-item {
      padding-left: 0;
    }

    &--loading,
    &--no-results {
      padding: 0;
    }

    &--pre-item,
    &--post-item,
    &--loading,
    &--no-results {
      font-size: rem(13px);

      &:hover,
      &:focus,
      &.focus {
        background-color: transparent;
        cursor: default;
      }
    }

    &--new {
      font-size: rem(13px);
      margin-top: space(s) * 2;
      position: relative;

      &:before {
        border-top: 1px solid color(neutral, 90);
        display: block;
        left: 0;
        content: '';
        position: absolute;
        right: 0;
        top: -#{space(s)};
      }

      &:only-child {
        margin-top: 0;

        &:before {
          display: none;
        }
      }
    }
  }
  &__default-item {
    font-size: rem(13px);
  }
  &__chip {
    display: inline-block;
    margin-bottom: space(xs);
    margin-right: space(xs);
  }
  &__add {
    height: auto;
    font-size: rem(15px);
    line-height: rem(28px);
    min-height: 28px;
    margin-bottom: space(xs);

    .material-design-icon {
      top: -1px !important;

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }
  &__show-more {
    font-size: rem(15px);
    height: auto;
    margin-bottom: space(xs);
  }
  &__read-only {
    display: inline-block;
    color: color(base, dimmed);
    font-size: rem(13px);
    line-height: rem(28px);
    margin-bottom: space(xs);
  }
  &__limit {
    background-color: color(neutral, 90);
    border-radius: global(border-radius, micro);
    bottom: 0;
    color: color(base, dimmed);
    font-size: rem(12px);
    line-height: 1.2;
    margin-bottom: space(xs);
    padding: space(xxs) + 1px space(xxs) space(xxs) - 1px;
    position: absolute;
    right: 0;
  }

  &--open:not(&--calculating) {
    #{$self} {
      &__body {
        z-index: 11;
      }
      &__wrapper {
        max-height: none;
        z-index: 10;
      }

      &__chip-container {
        padding-right: 0;
      }
      &__add {
        font-size: rem(13px);

        .material-design-icon {
          svg {
            height: 18px;
            width: 18px;
          }
        }
      }
      &__show-more {
        display: none;
      }
    }
  }

  &--editable {
    cursor: text;
  }

  &--form {
    #{$self}__body {
      @include fieldStyles();

      box-shadow: global(hoverState, defaultBoxShadow);
      transition: global(hoverState, transition);
      line-height: inherit;
    }
    &#{$self}--open {
      #{$self}__body {
        box-shadow: global(hoverState, hoverBoxShadow);
        min-width: 400px;
        width: 100%;
      }

      &#{$self}--overflows {
        #{$self}__body {
          left: auto;
          right: 0;
        }
      }
    }
    &#{$self}--disabled {
      #{$self}__body {
        background-color: color(disabled, bg);
        box-shadow: global(hoverState, disabledBoxShadow);
        color: color(disabled, text);
      }
    }
  }

  &--minimal {
    &:not(#{$self}--disable-opened-state) {
      #{$self}__add {
        &:hover {
          text-decoration: none;
        }
      }

      #{$self}__body {
        &:hover {
          background-color: color(neutral, 96);
          box-shadow: -#{space(xxxs)} -#{space(xxxs)} 0 space(xxxs)
              color(neutral, 96),
            -#{space(xxxs)} space(xxxs) 0 space(xxxs) color(neutral, 96);
          cursor: pointer;
        }
      }

      &#{$self}--open {
        #{$self}__body {
          @include fieldStyles();
          @include hoverState();

          line-height: inherit;
          left: -#{space(s)};
          top: -#{space(s)};
          min-width: 400px;
          width: calc(100% + #{(space(xs) * 2)});
          padding-top: space(s);
        }

        &#{$self}--overflows {
          #{$self}__body {
            left: auto;
            right: -#{space(s)};
          }
        }
      }

      &#{$self}--disabled {
        #{$self}__body {
          color: color(disabled, text);

          &:hover {
            background-color: transparent;
            box-shadow: none;
          }
        }
      }
    }
  }

  &--read-only {
    #{$self}__wrapper {
      cursor: default;
    }
  }

  &--single {
    #{$self} {
      &__field {
        width: 100%;
      }
      &__chip {
        margin-right: 0;
        width: 100%;
      }
    }
    &#{$self}--open {
      #{$self} {
        &__chip-container {
          padding-bottom: 0;
        }
      }
      &#{$self}--has-values {
        #{$self} {
          &__field {
            border-top: 1px solid color(neutral, 90);
            margin-top: space(xxs);
            padding-top: space(xs);
          }
        }
      }
    }
  }

  &--disabled {
    #{$self} {
      &__wrapper {
        cursor: default;
      }
    }
  }
}
</style>
