import BaseAlert from '~/components/BaseAlert/BaseAlert';
import BaseLoadingSpinner from '~/components/BaseLoadingSpinner/BaseLoadingSpinner';
import { request } from '~/lib/request';

// Usage:
// createAsyncComponent(
//   () => import(/* webpackChunkName: "MyComponent" */ '~/components/MyComponent'),
// );
export function createAsyncComponent(importModule, options = {}) {
  const loading = {
    component: BaseLoadingSpinner,
    props: {},
    ...options.loading,
  };

  const error = {
    component: BaseAlert,
    props: { variant: 'danger', inline: true },
    message: 'Failed to load',
    ...options.error,
  };

  return () => ({
    component: importModule().catch((err) => {
      handleError(err);

      return {
        render(h) {
          return h(error.component, { props: error.props }, error.message);
        },
      };
    }),
    loading: {
      render(h) {
        return h(loading.component, { props: loading.props }, loading.message);
      },
    },
  });
}

async function handleError(err) {
  if (typeof window == 'undefined' || !window.Sentry) {
    return;
  }

  if (!err.request) {
    return window.Sentry.captureException(err);
  }

  try {
    await request(err.request, { method: 'HEAD' });
  } catch (result) {
    const { statusCode } = result;
    if (statusCode && (statusCode < 200 || statusCode > 399)) {
      err.status = result.statusCode;
      window.Sentry.captureException(err);
    }
    // Ignore network errors
  }
}
