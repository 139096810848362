import {
  SET_SNAPSHOT_ISSUES,
  SET_SNAPSHOT_ISSUES_LOADING,
  SET_SNAPSHOT_ISSUES_LOADING_ERROR,
} from '../../mutation-types';
import { request } from '~/lib/request';

export default {
  namespaced: true,

  state: {
    issues: null,
    issuesLoading: false,
    issuesLoadingError: null,
  },

  getters: {
    issues: (state) => state.issues,
    issuesLoading: (state) => state.issuesLoading,
    issuesLoadingError: (state) => state.issuesLoadingError,
  },

  actions: {
    async getIssues(
      { rootState, commit, state },
      { orgName, projectPublicId, snapshotPublicId },
    ) {
      // Only initiate a new request if there is not already one in progress
      // TODO: Clear state when the project or snapshotId changes, required for SPA
      if (!state.issuesLoading && !state.issues) {
        commit(SET_SNAPSHOT_ISSUES_LOADING, true);

        try {
          const path = `${rootState.baseUrl}/org/${orgName}/project/${projectPublicId}/issues/${snapshotPublicId}`;
          const res = await request(path);

          const { issues } = await res.json();
          commit(SET_SNAPSHOT_ISSUES, issues);
        } catch (err) {
          commit(SET_SNAPSHOT_ISSUES_LOADING_ERROR, true);
        }
        commit(SET_SNAPSHOT_ISSUES_LOADING, false);
      }
    },
  },

  mutations: {
    [SET_SNAPSHOT_ISSUES](state, issues) {
      state.issues = issues;
    },
    [SET_SNAPSHOT_ISSUES_LOADING](state, issuesLoading) {
      state.issuesLoading = issuesLoading;
    },
    [SET_SNAPSHOT_ISSUES_LOADING_ERROR](state, issuesLoadingError) {
      state.issuesLoadingError = issuesLoadingError;
    },
  },
};
